<template>
  <div class="auth-container">
    <div class="inner-container">
      <div class="logo-container">
        <img
          :src="`${publicPath}images/defaultLogo.png`"
          width="140"
          loading="lazy"
          alt="OnBord"
          class="logo"
        />
      </div>
      <div class="error-title">Oops! Something Went Wrong</div>
      <div class="error-message">
        The page you're looking for could not be found.
      </div>
      <div class="error-instruction">Please go back or try again later.</div>
      <div class="error-action-buttons">
        <router-link :to="{ name: this.goto }" class="btn">Go Home</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Themable from '@/common/mixins/themable.mixin';
import { mapGetters } from 'vuex';
import PublicAssets from '@/common/mixins/publicasset.mixin';
import { MENUS } from '@/common/constants';

export default {
  name: 'Error',

  mixins: [PublicAssets, Themable],

  data: () => ({
    goto: MENUS.CLIENTS.id,
  }),

  validations() {},

  computed: {
    ...mapGetters(['sessionTimedOut']),
  },

  methods: {},

  mounted() {},
};
</script>
<style lang="scss" scoped>
.error-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}

.error-message {
  font-size: 18px;
  margin-bottom: 10px;
  text-align: center;
}

.error-instruction {
  font-size: 16px;
  margin-bottom: 20px;
  text-align: center;
}

.error-action-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.btn:hover {
  background-color: #6fc5ec;
}
</style>
