<template>
  <div class="step-container advisor">
    <v-form name="AccountFeatures" class="form">
      <v-container class="features-step-container">
        <v-row class="mb-8">
          <v-col class="instructions">
            <h1>
              <span class="headerlight">
                Select your <strong>account features.</strong>
              </span>
            </h1>
          </v-col>
        </v-row>

        <!-- No Accounts -->
        <div class="alert" v-if="noAccountsChosen">
          <div class="pr-2">
            <span class="fa-icon left">
              <i class="fas fa-exclamation-triangle"></i>
            </span>
          </div>
          <div>
            You must complete the Account Selection screen before choosing
            features.
          </div>
        </div>

        <!-- Client One -->
        <div
          class="justify-center features-table"
          v-if="accountTypesOne.length > 0"
        >
          <v-row class="header-row">
            <v-col class="col-6 first">
              <h4>{{ clientOneName }}</h4>
            </v-col>
            <v-col class="col-2"> {{ FEATURES_TYPES.MONEY_LINK }} </v-col>
            <v-col class="col-2"> {{ FEATURES_TYPES.ACAT }} </v-col>
            <v-col class="col-2"> {{ FEATURES_TYPES.IRA_DISTRIBUTION }} </v-col>
          </v-row>
          <v-row
            v-for="(account, index) in accountTypesOne"
            :key="account.nickname"
          >
            <v-col class="col-6 first">
              {{ account.name }} ({{ account.nickname }})

              <v-select
                v-model="account.masterAccountNumber"
                :items="masterAccountNumbers"
                :backgroundColor="inputBackgroundColor"
                outlined
                placeholder="Master Account Number"
                hide-details="auto"
                dense
                @change="
                  $v.accountTypesOne.$each[index].masterAccountNumber.$touch()
                "
                :error-messages="accountTypesOneErrors(index)"
              >
              </v-select>
            </v-col>
            <v-col class="col-2">
              <v-checkbox
                hide-details
                dense
                :ripple="false"
                v-model="account.moveMoney"
                :color="primaryColor"
                @change="$v.$touch()"
                v-if="!isOfflineAccount(account)"
              >
                <template v-slot:label>
                  <span class="cb-label mobile-label">
                    {{ FEATURES_TYPES.MONEY_LINK }}
                  </span>
                </template>
              </v-checkbox>
            </v-col>
            <v-col class="col-2">
              <v-checkbox
                hide-details
                dense
                :ripple="false"
                v-model="account.acat"
                :color="primaryColor"
                @change="$v.$touch()"
                v-if="!isOfflineAccount(account)"
              >
                <template v-slot:label>
                  <span class="cb-label mobile-label">
                    {{ FEATURES_TYPES.ACAT }}
                  </span>
                </template>
              </v-checkbox>
            </v-col>
            <v-col class="col-2">
              <v-checkbox
                hide-details
                dense
                :ripple="false"
                v-model="account.iraDistribution"
                :color="primaryColor"
                @change="$v.$touch()"
                v-if="account.name === ACCOUNT_TYPES.IRA"
              >
                <template v-slot:label>
                  <span class="cb-label mobile-label">
                    {{ FEATURES_TYPES.IRA_DISTRIBUTION }}
                  </span>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
        </div>

        <!-- Client 2 -->
        <div
          class="justify-center features-table mt-7"
          v-if="accountTypesTwo.length > 0"
        >
          <v-row class="header-row">
            <v-col class="col-6 first">
              <h4>{{ clientTwoName }}</h4>
            </v-col>
            <v-col class="col-2"> {{ FEATURES_TYPES.MONEY_LINK }} </v-col>
            <v-col class="col-2"> {{ FEATURES_TYPES.ACAT }} </v-col>
            <v-col class="col-2"> {{ FEATURES_TYPES.IRA_DISTRIBUTION }} </v-col>
          </v-row>
          <v-row
            v-for="(account, index) in accountTypesTwo"
            :key="account.nickname"
          >
            <v-col class="col-6 first">
              {{ account.name }} ({{ account.nickname }})
              <v-select
                v-model="account.masterAccountNumber"
                :items="masterAccountNumbers"
                :backgroundColor="inputBackgroundColor"
                outlined
                placeholder="Master Account Number"
                hide-details="auto"
                dense
                @change="
                  $v.accountTypesTwo.$each[index].masterAccountNumber.$touch()
                "
                :error-messages="accountTypesTwoErrors(index)"
              >
              </v-select>
            </v-col>
            <v-col class="col-2">
              <v-checkbox
                hide-details
                :ripple="false"
                v-model="account.moveMoney"
                :color="primaryColor"
                @change="$v.$touch()"
                v-if="!isOfflineAccount(account)"
              >
                <template v-slot:label>
                  <span class="cb-label mobile-label">
                    {{ FEATURES_TYPES.MONEY_LINK }}
                  </span>
                </template>
              </v-checkbox>
            </v-col>
            <v-col class="col-2">
              <v-checkbox
                hide-details
                :ripple="false"
                v-model="account.acat"
                :color="primaryColor"
                @change="$v.$touch()"
                v-if="!isOfflineAccount(account)"
              >
                <template v-slot:label>
                  <span class="cb-label mobile-label">
                    {{ FEATURES_TYPES.ACAT }}
                  </span>
                </template>
              </v-checkbox>
            </v-col>
            <v-col class="col-2">
              <v-checkbox
                hide-details
                dense
                :ripple="false"
                v-model="account.iraDistribution"
                :color="primaryColor"
                @change="$v.$touch()"
                v-if="account.name === ACCOUNT_TYPES.IRA"
              >
                <template v-slot:label>
                  <span class="cb-label mobile-label">
                    {{ FEATURES_TYPES.IRA_DISTRIBUTION }}
                  </span>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
        </div>

        <!-- Joint -->
        <div
          class="justify-center features-table mt-7"
          v-if="accountTypesJoint.length > 0"
        >
          <v-row class="header-row">
            <v-col class="col-8 first">
              <h4>{{ jointName }}</h4>
            </v-col>
            <v-col class="col-2"> {{ FEATURES_TYPES.MONEY_LINK }} </v-col>
            <v-col class="col-2"> {{ FEATURES_TYPES.ACAT }} </v-col>
          </v-row>
          <v-row
            v-for="(account, index) in accountTypesJoint"
            :key="account.nickname"
          >
            <v-col class="col-8 first">
              {{ account.name }} ({{ account.nickname }})
              <v-select
                v-model="account.masterAccountNumber"
                :items="masterAccountNumbers"
                :backgroundColor="inputBackgroundColor"
                outlined
                placeholder="Master Account Number"
                hide-details="auto"
                dense
                @change="
                  $v.accountTypesJoint.$each[index].masterAccountNumber.$touch()
                "
                :error-messages="accountTypesJointErrors(index)"
              >
              </v-select>
            </v-col>
            <v-col class="col-2">
              <v-checkbox
                hide-details
                :ripple="false"
                v-model="account.moveMoney"
                :color="primaryColor"
                @change="$v.$touch()"
              >
                <template v-slot:label>
                  <span class="cb-label mobile-label">
                    {{ FEATURES_TYPES.MONEY_LINK }}
                  </span>
                </template>
              </v-checkbox>
            </v-col>
            <v-col class="col-2">
              <v-checkbox
                hide-details
                :ripple="false"
                v-model="account.acat"
                :color="primaryColor"
                @change="$v.$touch()"
              >
                <template v-slot:label>
                  <span class="cb-label mobile-label">
                    {{ FEATURES_TYPES.ACAT }}
                  </span>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-form>
    <bottom-nav v-on:go-prev="prev()" v-on:go-next="next()"></bottom-nav>
  </div>
</template>
<script>
import BottomNav from '@/components/common/BottomNav';
import AdvisorClientMixin from '@/common/mixins/advisor.client.mixin';
import { MASTER_ACCOUNT_NUMBER } from '@/stores/actions.type';
import { MENUS, FEATURES_TYPES } from '@/common/constants';
import { required } from 'vuelidate/lib/validators';
import {
  NOTIFICATION_TYPES,
  NotificationService,
} from '@/services/notification.service';
import { mapGetters } from 'vuex';

export default {
  name: 'AccountFeatures',

  mixins: [AdvisorClientMixin],
  components: { BottomNav },

  data: () => ({
    accountTypesOne: [],
    accountTypesTwo: [],
    accountTypesJoint: [],
    masterAccountNumbers: [],
  }),

  validations() {
    return {
      accountTypesOne: {
        $each: {
          masterAccountNumber: {
            required,
          },
        },
      },
      accountTypesTwo: {
        $each: {
          masterAccountNumber: {
            required,
          },
        },
      },
      accountTypesJoint: {
        $each: {
          masterAccountNumber: {
            required,
          },
        },
      },
      validationGroup: [
        'accountTypesOne',
        'accountTypesTwo',
        'accountTypesJoint',
      ],
    };
  },

  computed: {
    ...mapGetters(['currentCompany']),

    noAccountsChosen() {
      // It is assumed that the account selection page
      // cannot be left without client 1 and client 2
      // having chosen at least one account

      return (
        this.accountTypesOne.length === 0 &&
        this.accountTypesTwo.length === 0 &&
        this.accountTypesJoint.length === 0
      );
    },
    jointName() {
      return `${this.clientOneName} and ${this.clientTwoName}`;
    },
  },

  methods: {
    isOfflineAccount(account) {
      return account.name.includes('offline');
    },
    /* Validatons for a account type */
    accountTypesOneErrors(idx) {
      const errors = [];
      if (!this.$v.accountTypesOne.$each[idx].masterAccountNumber.$dirty)
        return errors;
      !this.$v.accountTypesOne.$each[idx].masterAccountNumber.required &&
        errors.push('A selection is required');
      return errors;
    },
    accountTypesTwoErrors(idx) {
      const errors = [];
      if (!this.$v.accountTypesTwo.$each[idx].masterAccountNumber.$dirty)
        return errors;
      !this.$v.accountTypesTwo.$each[idx].masterAccountNumber.required &&
        errors.push('A selection is required');
      return errors;
    },
    accountTypesJointErrors(idx) {
      const errors = [];
      if (!this.$v.accountTypesJoint.$each[idx].masterAccountNumber.$dirty)
        return errors;
      !this.$v.accountTypesJoint.$each[idx].masterAccountNumber.required &&
        errors.push('A selection is required');
      return errors;
    },

    /* Get Master Account Numbers */
    getMasterAccountNumbers() {
      this.$store
        .dispatch(MASTER_ACCOUNT_NUMBER, {
          organisationId: this.currentCompany.id,
        })
        .then((response) => {
          this.masterAccountNumbers = response;
        })
        .catch((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: 'Error getting Master Account Numbers!',
              okTitle: 'Ok',
            },
            response,
          );
        });
    },

    /* Initialize Account Features object for view */
    initializeAccountFeatures() {
      // Get Master Account Numbers
      this.getMasterAccountNumbers();

      const accountFeatures = this.getAccountFeatureSelections();
      const findByName = (name) => accountFeatures.find((f) => f.name === name);

      // Client One Features
      let clientFeatures = findByName(this.clientOneName);
      if (clientFeatures) {
        this.accountTypesOne = clientFeatures.features;
      }

      // Client Two Features
      clientFeatures = findByName(this.clientTwoName);
      if (clientFeatures) {
        this.accountTypesTwo = clientFeatures.features;
      }

      // Joint Features
      clientFeatures = findByName(this.jointName);
      if (clientFeatures) {
        this.accountTypesJoint = clientFeatures.features;
      }
    },

    /* Set the features by client/account in the working client */
    setAccountFeatures() {
      // Set Client One Account Features
      this.accountTypesOne.forEach((a) => {
        let accountObj = this.clientOne.accounts.find(
          (ca) => ca.label === a.nickname,
        );
        if (accountObj) {
          accountObj.moveMoney = a.moveMoney;
          accountObj.acat = a.acat;
          accountObj.iraDistribution = a.iraDistribution;
          accountObj.masterAccountNumber = a.masterAccountNumber;
        }
      });

      // Set Client Two Account Features
      this.accountTypesTwo.forEach((a) => {
        let accountObj = this.clientTwo.accounts.find(
          (ca) => ca.label === a.nickname,
        );
        if (accountObj) {
          accountObj.moveMoney = a.moveMoney;
          accountObj.acat = a.acat;
          accountObj.iraDistribution = a.iraDistribution;
          accountObj.masterAccountNumber = a.masterAccountNumber;
        }
      });

      // Set Joint Account Features on Client One and Client Two
      this.accountTypesJoint.forEach((a) => {
        let accountObj = this.clientOne.accounts.find(
          (ca) => ca.label === a.nickname,
        );
        if (accountObj) {
          accountObj.moveMoney = a.moveMoney;
          accountObj.acat = a.acat;
          accountObj.iraDistribution = a.iraDistribution;
          accountObj.masterAccountNumber = a.masterAccountNumber;
        }

        accountObj = this.clientTwo.accounts.find(
          (ca) => ca.label === a.nickname,
        );
        if (accountObj) {
          accountObj.moveMoney = a.moveMoney;
          accountObj.acat = a.acat;
          accountObj.iraDistribution = a.iraDistribution;
          accountObj.masterAccountNumber = a.masterAccountNumber;
        }
      });
    },

    /* Map form data to working client */
    mapData() {
      this.setAccountFeatures();
    },

    /* Nav to Next Page in flow */
    next(route = null) {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.mapData();

      const to = route ? route.name : MENUS.ADVISOR.CLIENT.ADVISORY_RATE.id;
      this.goNext(to);
    },

    /* Nav to Previous Page in flow */
    prev() {
      this.goPrev(MENUS.ADVISOR.CLIENT.ACCOUNT_SELECTION.id);
    },
  },

  created() {
    this.FEATURES_TYPES = FEATURES_TYPES;

    // Initialize the current account features
    this.initializeAccountFeatures();

    // If No accounts have been chosen yet, don't mark the page as
    // visted yet. Accounts must exist for the client(s)
    // UNUSED
    this.currentClient.featuresVisited = !this.noAccountsChosen;
  },
};
</script>
<style lang="scss" scoped>
.features-step-container {
  max-width: 1024px;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.features-table {
  width: 100%;
  max-width: 100%;
  border: 2px solid var(--secondary-color);
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  .header-row {
    font-weight: 500;

    .col-2 {
      @media screen and (max-width: 960px) {
        display: none !important;
      }
    }
  }

  div:not(:first-of-type) {
    border-top: 2px solid var(--secondary-color);
  }

  .row {
    margin: 0;

    .cb-label {
      color: var(--color-text);
      font-size: 16px;
      font-weight: 500;
      margin-bottom: -5px;
    }

    .col {
      padding: 20px 26px;
      border-top: none;

      &.first {
        background-color: var(--color-light-grey);
      }

      @media screen and (max-width: 960px) {
        min-width: 100%;
      }

      .mobile-label {
        display: none;
        @media screen and (max-width: 960px) {
          display: inline-block;
        }
      }
    }

    .col-2 {
      justify-content: center;
      display: flex;
      align-items: center;

      @media screen and (max-width: 960px) {
        justify-content: start;
      }
    }

    div:not(:last-of-type) {
      border-right: 2px solid var(--secondary-color);

      @media screen and (max-width: 960px) {
        border-right: none;
      }
    }
  }
}
</style>
