<template>
  <div
    class="step-container client"
    :class="clientData.navDisabled ? 'no-footer' : ''"
  >
    <client-instructions></client-instructions>
    <help-drawer :title="helpTitle">
      <div>
        <p v-if="!residentHelpSection">
          All persons born or naturalized in the United States, and subject to
          the jurisdiction thereof, are citizens of the United States and the
          State wherein they reside.
        </p>
        <p v-else>
          Lawful permanent residents (LPRs), also known as “green card” holders,
          are non-citizens who are lawfully authorized to live permanently
          within the United States.
        </p>
      </div>
    </help-drawer>
    <v-form name="Citizenship" class="form">
      <v-container class="client-step-container">
        <v-row class="mb-8">
          <v-col class="instructions">
            <h1>
              <span class="headerlight">
                Are you a <strong>U.S Citizen?</strong>
              </span>
            </h1>
            <div class="help-link">
              <a @click="toggleHelp(false)">{{ citizenHelpTitle }} </a>
            </div>
          </v-col>
        </v-row>
        <data-submitted-message
          :submitted="isCitizenshipComplete"
          v-on:hide-form="toggleCitizenFormState"
        />
        <div v-if="!formHidden">
          <v-row class="justify-center">
            <v-col class="step-form-container radio-container mb-4">
              <v-radio-group
                v-model="citizen"
                :error-messages="citizenErrors"
                @change="citizenResidentChanged()"
                hide-details="auto"
              >
                <v-radio
                  :ripple="false"
                  class="v-faux-radio mb-4"
                  label="Yes"
                  :value="true"
                ></v-radio>
                <v-radio
                  :ripple="false"
                  class="v-faux-radio"
                  label="No"
                  :value="false"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <div class="client-step-container" v-if="citizen === true">
            <v-row class="mb-8">
              <v-col class="instructions">
                <h1>
                  <span class="headerlight">
                    Are you a <strong>legal resident of the U.S?</strong>
                  </span>
                </h1>
                <div class="help-link">
                  <a @click="toggleHelp(true)"> {{ residentHelpTitle }} </a>
                </div>
              </v-col>
            </v-row>
            <v-row class="justify-center">
              <v-col class="step-form-container radio-container mb-4">
                <v-radio-group
                  v-model="resident"
                  @change="citizenResidentChanged(true)"
                  :error-messages="residentErrors"
                  hide-details="auto"
                >
                  <v-radio
                    :ripple="false"
                    class="v-faux-radio mb-4"
                    label="Yes"
                    :value="true"
                  ></v-radio>
                  <v-radio
                    :ripple="false"
                    class="v-faux-radio"
                    label="No"
                    :value="false"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </div>
          <div
            v-if="resident === false || citizen === false"
            class="client-step-container"
          >
            <div class="step-form-container">
              <div class="stop-message">
                Oh no! If you aren't at least a U.S. citizen or legal resident,
                you can't complete this process online — you will have to do it
                manually with your advisor.
              </div>
              <a @click="notifyAdvisor" class="btn">
                Notify Advisor &amp; End Session
              </a>
            </div>
          </div>
        </div>
      </v-container>
    </v-form>
    <bottom-nav
      :hasPrevious="false"
      v-if="!navDisabled"
      v-on:go-next="next()"
    ></bottom-nav>
  </div>
</template>

<script>
import ClientInstructions from '@/components/client/dialog/InterviewInstructions';
import HelpDrawer from '@/components/HelpDrawerComponent';
import BottomNav from '@/components/common/BottomNav';
import DataSubmittedMessage from '@/components/client/DataSubmittedMessage';
import { EventService } from '@/services/event.service';
import InterviewClientMixin from '@/common/mixins/interview.client.mixin';
import { required, requiredIf } from 'vuelidate/lib/validators';
import { MENUS, CLIENT_EXIT_KEYS } from '@/common/constants';
import { UPDATE_INTERVIEW } from '@/stores/actions.type';

export default {
  name: 'Citizenship',

  components: {
    BottomNav,
    ClientInstructions,
    HelpDrawer,
    DataSubmittedMessage,
  },
  mixins: [InterviewClientMixin],

  data: () => ({
    citizen: null,
    resident: null,
    residentHelpSection: false,
    navDisabled: true,
  }),

  validations() {
    return {
      citizen: {
        required,
      },
      resident: {
        required: requiredIf(() => this.citizen !== null),
      },
      validationGroup: ['citizen', 'resident'],
    };
  },

  computed: {
    /* Dynamic errors */
    citizenErrors() {
      const errors = [];
      if (!this.$v.citizen.$dirty) return errors;
      !this.$v.citizen.required && errors.push('A selection is required');
      return errors;
    },
    residentErrors() {
      const errors = [];
      if (!this.$v.resident.$dirty) return errors;
      !this.$v.resident.required && errors.push('A selection is required');
      return errors;
    },
    helpTitle() {
      return !this.residentHelpSection
        ? this.citizenHelpTitle
        : this.residentHelpTitle;
    },
    citizenHelpTitle() {
      return 'Who qualifies as a citizen?';
    },
    residentHelpTitle() {
      return 'How do I know if I am?';
    },
  },

  methods: {
    /* Toggle this form's state */
    toggleCitizenFormState(hide) {
      this.toggleFormState(hide);
      this.setNavDisabled();
    },

    /* Determine whether further navigation is disabled based on answers */
    setNavDisabled() {
      let disabled = false;
      if (this.isCitizenshipComplete && this.formHidden) {
        // Nav is not disabled. Only way isCitizenshipComplete is true
        // is if client had previously answered yes to both questions.
        disabled = false;
      } else {
        // Nav disabled if form is not filled out or
        // Either question is No
        const isUnfilled = this.citizen === null || this.resident === null;
        disabled =
          isUnfilled || this.citizen === false || this.resident === false
            ? true
            : false;
      }
      this.navDisabled = disabled;
      EventService.emit('client-nav-disabled', disabled);
    },

    /* Answers have changed, mark form as dirty and recalc nav disabled state */
    citizenResidentChanged(isResident = false) {
      isResident ? this.$v.resident.$touch() : this.$v.citizen.$touch();
      this.setNavDisabled();
    },

    /* Toggle the help drawer for a specific section */
    toggleHelp(isResidentHelp = false) {
      this.residentHelpSection = isResidentHelp;
      EventService.emit('toggle-help');
    },

    /* Notify advisor */
    notifyAdvisor() {
      this.$v.$reset();

      const thisPage = this.getPage(MENUS.CLIENT.CITIZENSHIP.id);
      const payload = {
        id: thisPage.id,
        interviewName: 'onbord',
        pageName: MENUS.CLIENT.CITIZENSHIP.id,
        instance: null,
        data: {
          citizen: this.citizen,
          resident: this.resident,
        },
      };

      this.$store.dispatch(UPDATE_INTERVIEW, payload).finally(() => {
        // Redirect to appropriate exit page
        if (this.isAuthenticated) {
          this.$router.push({ name: MENUS.CLIENTS.id });
        } else {
          EventService.emit(
            'session-timeout',
            CLIENT_EXIT_KEYS.EXIT_CANT_PROGRESS,
          );
        }
      });
    },

    /* Nav to Next Page in flow */
    next(route = null) {
      const to = route || MENUS.CLIENT.LEGAL_NAME.id;
      if (!this.formHidden) {
        this.$v.$touch();
        if (this.$v.$invalid) return;
      } else {
        this.goNext(to);
        return;
      }

      const data = {
        citizen: this.citizen,
        resident: this.resident,
      };

      this.completeStep(MENUS.CLIENT.CITIZENSHIP.id, data)
        .then(() => {
          this.goNext(to);
        })
        .catch(() => {});
    },
  },
  created() {
    // Initialize form data for current interview
    this.formHidden = this.isCitizenshipComplete;
    this.setNavDisabled();
  },
};
</script>

<style lang="scss" scoped>
.step-container.no-footer {
  height: calc(100vh - 82px);
}

.stop-message {
  margin-bottom: 15px;
  padding: 5px 0px 5px 15px;
  border-left: 2px solid #fcb400;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}
</style>
