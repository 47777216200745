<template>
  <div>
    <v-layout v-resize="onResize" column style="">
      <v-data-table
        show-select
        v-model="selected"
        :headers="headers"
        :items="clients"
        :items-per-page="15"
        :hide-default-header="isMobile"
        class="responsive-table client-table"
        :class="{ mobile: isMobile }"
        :loading="tableLoading"
        ref="clientTable"
        :page.sync="pagination.page"
        @pagination="updatePage"
        :custom-sort="customSort"
      >
        <template v-slot:top>
          <v-row>
            <v-col>
              <v-text-field
                outlined
                type="text"
                maxlength="256"
                placeholder="Search Clients..."
                v-model.trim="search"
                :backgroundColor="inputBackgroundColor"
                clearable
                class="search-input mb-2"
                single-line
                hide-details
                ref="searchClients"
                @click:clear="resetItems()"
              >
                <template v-slot:append>
                  <span
                    class="search"
                    v-if="!search || search.length === 0"
                    :key="tableLoading"
                  >
                    <v-icon small>fas fa-search</v-icon>
                  </span>
                </template>
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                outlined
                type="text"
                maxlength="256"
                placeholder="Search Primary Advisor..."
                v-model.trim="searchRep"
                :backgroundColor="inputBackgroundColor"
                clearable
                class="search-input mb-2"
                single-line
                hide-details
                ref="searchReps"
                @click:clear="resetItems()"
              >
                <template v-slot:append>
                  <span
                    class="search"
                    v-if="!searchRep || searchRep.length === 0"
                    :key="tableLoading"
                  >
                    <v-icon small>fas fa-search</v-icon>
                  </span>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="filter-action-row align-center">
            <v-col class="status-col">
              <v-select
                v-model="filterStatus"
                :items="statuses"
                :backgroundColor="inputBackgroundColor"
                outlined
                placeholder="Status"
                ref="statusFilter"
                hide-details
                dense
              >
                <template v-slot:prepend-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        class="prepend"
                        @click="clearStatusFilter()"
                      >
                        Clear Filter
                        <a class="close">
                          <div><i class="fas fa-times"></i></div>
                        </a>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <v-col class="o-status-col">
              <v-select
                v-model="filterOnbord"
                :items="onbordStatuses"
                :backgroundColor="inputBackgroundColor"
                outlined
                placeholder="OnBord"
                ref="onbordFilter"
                hide-details
                dense
              >
                <template v-slot:prepend-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        class="prepend"
                        @click="clearOnBordFilter()"
                      >
                        Clear Filter
                        <a class="close">
                          <div><i class="fas fa-times"></i></div>
                        </a>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <v-col class="o-status-col">
              <v-btn-toggle v-model="fetchMode" mandatory @change="getClients">
                <v-btn :value="fetchStatuses.ACTIVE">Active</v-btn>
                <v-btn :value="fetchStatuses.ARCHIVED">Archived</v-btn>
                <v-btn :value="fetchStatuses.ALL">All</v-btn>
              </v-btn-toggle>
            </v-col>
            <v-col class="button-area">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    rounded
                    v-show="selected.length > 0"
                    v-bind="attrs"
                    v-on="on"
                    class="batch-activator"
                    >Batch ({{ selected.length }})
                    <span class="fa-icon"
                      ><i class="fas fa-ellipsis-v"></i></span
                  ></v-btn>
                </template>
                <v-list>
                  <v-list-item @click="sendBatch">Send Text</v-list-item>
                  <v-list-item @click="deleteBatch">Archive</v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    rounded
                    v-show="canAddClient"
                    v-bind="attrs"
                    v-on="on"
                    class="batch-activator"
                    >Add Client<span class="fa-icon"
                      ><i class="fas fa-plus-circle"></i></span
                  ></v-btn>
                </template>
                <v-list>
                  <v-list-item @click="addNewClient">Add Single</v-list-item>
                  <v-list-item @click="addNewBatch">Add Batch</v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </template>
        <template slot="item" slot-scope="props" v-if="!tableLoading">
          <tr v-if="!isMobile" :key="getItemId(item)">
            <td>
              <v-checkbox
                :input-value="props.isSelected"
                style="margin: 0; padding: 0"
                hide-details
                @click="props.select(!props.isSelected)"
              >
              </v-checkbox>
            </td>
            <td v-for="col in tableColumns" :key="col" :width="col.width">
              <div v-if="!col.isActionColumn">
                <span
                  :class="col.classFn(props.item)"
                  @click="col.clickFn ? col.clickFn(props.item) : () => {}"
                >
                  {{ col.valueFn(props.item) }}
                </span>
              </div>
              <div v-else class="action-column">
                <v-menu
                  left
                  nudge-left="15"
                  nudge-bottom="25"
                  v-if="canPerformActions(props.item)"
                  min-width="125"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span class="actions" v-bind="attrs" v-on="on">
                      <v-icon small>fas fa-ellipsis-h</v-icon>
                    </span>
                  </template>
                  <v-list class="menu">
                    <div v-for="action in col.actions" :key="action">
                      <v-list-item
                        v-if="
                          !action.isDivider && action.conditionFn(props.item)
                        "
                        @click="action.clickFn(props.item)"
                      >
                        <v-list-item-title class="menu-item-wrapper">
                          <v-icon x-small :key="props.item.status">
                            {{ action.iconFn(props.item) }}
                          </v-icon>
                          <span class="label">
                            {{ action.labelFn(props.item) }}
                          </span>
                        </v-list-item-title>
                      </v-list-item>
                      <div
                        class="divider"
                        v-if="action.isDivider && canShowDivider(props.item)"
                      ></div>
                    </div>
                  </v-list>
                </v-menu>
              </div>
            </td>
          </tr>
          <tr v-else :key="getItemId(item)">
            <td>
              <v-checkbox
                :input-value="props.isSelected"
                style="margin: 0; padding: 0"
                hide-details
                @click="props.select(!props.isSelected)"
              >
              </v-checkbox>
              <ul class="flex-content">
                <li class="flex-item" v-for="col in tableColumns" :key="col">
                  <div v-if="!col.isActionColumn">
                    <span class="mobile-label" v-if="col.dataLabel">
                      {{ col.dataLabel }}:
                    </span>
                    <span
                      :class="col.classFn(props.item)"
                      @click="col.clickFn ? col.clickFn(props.item) : () => {}"
                    >
                      {{ col.valueFn(props.item) }}
                    </span>
                  </div>
                  <div v-else class="actions-container">
                    <v-bottom-sheet v-if="canPerformActions(props.item)">
                      <template v-slot:activator="{ on, attrs }">
                        <span class="actions" v-bind="attrs" v-on="on">
                          <v-icon small>fas fa-ellipsis-h</v-icon>
                        </span>
                      </template>
                      <v-sheet height="auto">
                        <v-list class="sheet">
                          <div v-for="action in col.actions" :key="action">
                            <v-list-item
                              v-if="
                                !action.isDivider &&
                                action.conditionFn(props.item)
                              "
                              @click="action.clickFn(props.item)"
                            >
                              <v-list-item-title class="menu-item-wrapper">
                                <v-icon x-small :key="props.item.status">
                                  {{ action.iconFn(props.item) }}
                                </v-icon>
                                <span class="label">
                                  {{ action.labelFn(props.item) }}
                                </span>
                              </v-list-item-title>
                            </v-list-item>
                            <div
                              class="divider"
                              v-if="
                                action.isDivider && canShowDivider(props.item)
                              "
                            ></div>
                          </div>
                          <div class="bottom-spacer"></div>
                        </v-list>
                      </v-sheet>
                    </v-bottom-sheet>
                  </div>
                </li>
              </ul>
            </td>
          </tr>
        </template>
        <div class="no-results" slot="no-results" :value="true">
          Your search/filter found no results.
        </div>
        <div class="no-data" slot="no-data" :value="true">
          No clients found.
          <a v-if="canAddClient" @click="addNewClient()">Add a new client</a>.
        </div>
      </v-data-table>
    </v-layout>
  </div>
</template>

<script>
import Themable from '@/common/mixins/themable.mixin';
import {
  MENUS,
  CLIENT_STATUSES,
  CLIENT_ONBORD_STATUSES,
  FETCH_CLIENT_STATUSES,
} from '@/common/constants';
import {
  GET_CLIENTS,
  GET_CLIENT,
  EDIT_CLIENT,
  DELETE_CLIENT,
  DELETE_CLIENT_BATCH,
  RESTORE_CLIENT,
  ARCHIVE_CLIENT,
  SEND_CLIENT_BATCH,
  SET_WORKING_CLIENT,
  IMPERSONATE_CLIENT,
  GET_REVIEW_ENVELOPE_URL,
} from '@/stores/actions.type';
import {
  NOTIFICATION_TYPES,
  NotificationService,
} from '@/services/notification.service';
import AdvisorClientMixin from '@/common/mixins/advisor.client.mixin';
import DataTableMixin from '@/common/mixins/datatable.mixin';
import { PermissionService, PERMISSIONS } from '@/services/permission.service';
import { dom } from '@fortawesome/fontawesome-svg-core';
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  data: () => ({
    selected: [],
    search: '',
    searchRep: '',
    filterStatus: null,
    filterOnbord: null,
    isMobile: false,
    clients: [],
    tableLoading: false,
    fetchMode: FETCH_CLIENT_STATUSES.ACTIVE,
  }),

  mixins: [Themable, AdvisorClientMixin, DataTableMixin],

  computed: {
    ...mapGetters(['currentCompany']),
    /* Table Header Definitions */
    headers() {
      const cols = [
        {
          text: 'Client',
          value: 'name',
          filter: (value) => {
            if (!this.search || this.search.length === 0) return true;
            return value.match(new RegExp(this.search, 'i'));
          },
        },
        {
          text: 'Primary Advisor',
          value: 'rep',
          filter: (value) => {
            if (!this.searchRep || this.searchRep.length === 0) return true;
            return value.match(new RegExp(this.searchRep, 'i'));
          },
        },
        {
          text: 'Status',
          value: 'status',
          filter: (value, search, item) => {
            if (!this.filterStatus) return true;

            if (this.filterStatus === CLIENT_STATUSES.SENT) {
              return value === CLIENT_STATUSES.SENT && !item.completed;
            }

            if (this.filterStatus === CLIENT_STATUSES.ARCHIVED) {
              return item.isArchived;
            }

            if (this.filterStatus === CLIENT_STATUSES.CLIENT_COMPLETE) {
              return value === CLIENT_STATUSES.CLIENT_COMPLETE;
            }

            return value === this.filterStatus && !item.isArchived;
          },
        },
        {
          text: 'Interview Updated',
          value: 'lastContactActivity',
          sortable: true,
        },
        {
          text: 'OnBord',
          value: 'completionPercent',
          filter: (value) => {
            if (!this.filterOnbord) return true;
            if (this.filterOnbord === 'Complete') {
              return value === 100;
            }
            return value < 100;
          },
        },
        {
          text: 'Actions',
          sortable: false,
          align: 'end',
        },
      ];

      return cols;
    },

    /* Table Column Definitions */
    tableColumns() {
      const cols = [
        {
          width: '18%',
          valueFn: (item) => {
            return item.name;
          },
          clickFn: (item) => {
            this.editClient(item);
          },
          classFn: (item) => {
            return `edit-client-link faux-anchor ${
              !this.canEditClient(item) ? 'disabled' : ''
            }`;
          },
        },
        {
          width: '20%',
          dataLabel: 'Primary Advisor',
          valueFn: (item) => {
            return item.rep;
          },
          classFn: () => {
            return '';
          },
        },
        {
          width: '20%',
          dataLabel: 'Status',
          valueFn: (item) => {
            if (item.isArchived) {
              return 'Archived';
            }

            if (
              item.isOnbordComplete &&
              item.status === CLIENT_STATUSES.SIGNED
            ) {
              return 'Signed';
            }

            if (!item.signatureRequested && item.isOnbordComplete) {
              return 'Complete';
            }

            return item.status === CLIENT_STATUSES.PENDING_DOCUSIGN_REVIEW
              ? 'Review Envelope'
              : item.status;
          },
          classFn: (item) => {
            let clz = item.status;
            switch (item.status) {
              case CLIENT_STATUSES.CLIENT_COMPLETE:
                clz = 'ClientComplete';
                break;
              case CLIENT_STATUSES.PENDING_DOCUSIGN_REVIEW:
                clz = 'btn WaitingReview';
                break;
              case CLIENT_STATUSES.WAITING_FOR_SIGNATURE:
                clz = 'WaitingSignature';
                break;
            }

            if (item.isArchived) return 'status-pill Archived';

            if (!item.signatureRequested && item.isOnbordComplete)
              clz = 'btn ClientComplete';

            return `status-pill ${clz}`;
          },
          clickFn: (item) => {
            if (item.status === CLIENT_STATUSES.PENDING_DOCUSIGN_REVIEW) {
              return this.reviewEnvelope(item);
            }
            return null;
          },
        },
        {
          width: '18%',
          dataLabel: 'Interview Updated',
          valueFn: (item) => {
            return `${item.lastContactActivityAt || '-'}`;
          },
          classFn: () => {},
        },
        {
          width: '10%',
          dataLabel: 'OnBord',
          valueFn: (item) => {
            return `${item.completionPercent}%`;
          },
          classFn: () => {
            return '';
          },
        },
        {
          width: '10%',
          isActionColumn: true,
          actions: [
            {
              conditionFn: (item) => {
                return this.canImpersonate(item);
              },
              clickFn: (item) => {
                this.impersonateClient(item);
              },
              labelFn: () => {
                return 'Impersonate';
              },
              iconFn: () => {
                return 'fas fa-glasses';
              },
            },
            {
              conditionFn: (item) => {
                return this.canSendText(item);
              },
              clickFn: (item) => {
                this.sendText(item);
              },
              labelFn: (item) => {
                return this.sendTextVerbiage(item);
              },
              iconFn: () => {
                return 'fas fa-redo';
              },
            },
            {
              conditionFn: (item) => {
                return this.canEditClient(item);
              },
              clickFn: (item) => {
                this.editClient(item);
              },
              labelFn: (item) => {
                return this.editTextVerbiage(item);
              },
              iconFn: (item) => {
                return this.editIcon(item);
              },
            },
            {
              conditionFn: (item) => {
                return this.canReviewEnvelope(item);
              },
              clickFn: (item) => {
                this.reviewEnvelope(item);
              },
              labelFn: () => {
                return 'Review Envelope';
              },
              iconFn: () => {
                return 'fas fa-envelope';
              },
            },
            {
              conditionFn: (item) => {
                return this.canRestore(item);
              },
              clickFn: (item) => {
                this.restoreClient(item);
              },
              labelFn: () => {
                return 'Restore';
              },
              iconFn: () => {
                return 'fas fa-trash-restore';
              },
            },
            {
              isDivider: true,
            },
            {
              conditionFn: (item) => {
                return this.canDeleteClient(item);
              },
              clickFn: (item) => {
                this.deleteClient(item);
              },
              labelFn: (item) => {
                return this.archiveTextVerbiage(item);
              },
              iconFn: () => {
                return 'fas fa-trash';
              },
            },
          ],
        },
      ];

      return cols;
    },

    canAddClient() {
      return PermissionService.hasPermission(PERMISSIONS.CLIENT.ADD_CLIENT);
    },
  },

  methods: {
    getItemId(item) {
      if (!item) return;
      return `${item.id}_${item.contactType}`;
    },
    transitionClients() {
      this.$router
        .push({ name: MENUS.TRANSITIONS.TRANSITION_TYPE.id })
        .catch(() => {});
    },
    customSort(items, [index], isDesc) {
      if (index === 'lastContactActivity') {
        return items.sort((a, b) => {
          let momentA = this.convertToMoment(a.lastContactActivityAt);
          let momentB = this.convertToMoment(b.lastContactActivityAt);

          return isDesc[0] ? momentB - momentA : momentA - momentB;
        });
      } else {
        // Default sorting for other columns
        return items.sort((a, b) => {
          if (isDesc[0]) {
            return b[index] < a[index] ? -1 : 1;
          } else {
            return a[index] < b[index] ? -1 : 1;
          }
        });
      }
    },
    /* 
      Reset Items on Search Clear - forces the table to re-draw
      Safari Mobile seems to have issues with not re-rendering icons
      when repainting the table rows. This is a hack to force the entire
      table to be re-drawn
    */
    resetItems() {
      this.tableLoading = true;
      this.$nextTick(() => setTimeout(() => (this.tableLoading = false)), 500);
    },

    /* Determine Mobile vs Desktop view */
    onResize() {
      this.isMobile = window.innerWidth < 769;
    },

    /* Is Client In a locked State - cannot be altered */
    isClientLocked(client) {
      if (!client) {
        return false;
      }

      const { status } = client;
      const signatureRequested =
        status === CLIENT_STATUSES.WAITING_FOR_SIGNATURE ||
        status === CLIENT_STATUSES.PENDING_DOCUSIGN_REVIEW;
      const completed = status === CLIENT_STATUSES.CLIENT_COMPLETE;

      return signatureRequested || completed;
    },

    /* Clear the User Status filter */
    clearStatusFilter() {
      this.filterStatus = null;
      this.$refs.statusFilter.blur();
    },

    /* Clear the OnBord Status filter */
    clearOnBordFilter() {
      this.filterOnbord = null;
      this.$refs.onbordFilter.blur();
    },

    /* Clear the Fetch filter */
    clearFetchFilter() {
      this.fetchStatus = null;
      this.$refs.fetchFilter.blur();
      this.getClients();
    },

    /* Initiate New Client Flow */
    addNewClient() {
      // Wipe any working client - we are starting fresh
      this.$store.dispatch(SET_WORKING_CLIENT, null).then(() => {
        // Route to initial screen
        this.$router
          .push({ name: MENUS.ADVISOR.CLIENT.CLIENT_SELECTION.id })
          .catch(() => {});
      });
    },
    convertToMoment(dateStr) {
      if (!dateStr) {
        return moment(new Date(0));
      }
      // Parse the date using the custom format
      return moment(dateStr, 'MM/DD/YY - HH:mm');
    },
    addNewBatch() {
      this.$router.push({ name: MENUS.ADD_CLIENT_BATCH.id }).catch(() => {});
    },

    /* Archived clients can be restored */
    canRestore(client) {
      return client.isArchived;
    },

    /* Sent/Incomplete clients can be impersonated */
    canImpersonate(client) {
      const { status, isArchived } = client;
      const isSent = status === CLIENT_STATUSES.SENT;
      const isLocked = this.isClientLocked(client);

      return [isSent, !isLocked, !isArchived].reduce(
        (acc, curr) => acc && curr,
        true,
      );
    },

    /* Incomplete clients not in DRAFT status can have their welcome text (re)sent */
    canSendText(client) {
      const { canSend, status, isArchived } = client;
      const isDraft = status === CLIENT_STATUSES.DRAFT;
      const isSigned = status === CLIENT_STATUSES.SIGNED;
      const isLocked = this.isClientLocked(client);

      return [canSend, !isDraft, !isSigned, !isLocked, !isArchived].reduce(
        (acc, curr) => acc && curr,
        true,
      );
    },

    /* 
      Depending on what permissions the current user has, a client's
      initial can be edited as long as they are not in SENT status.
    */
    canEditClient(client) {
      const { EDIT_OWN_CLIENT, EDIT_ANY_CLIENT } = PERMISSIONS.CLIENT;

      const hasPermission = [EDIT_OWN_CLIENT, EDIT_ANY_CLIENT].some(
        (permission) => PermissionService.hasPermission(permission),
      );
      const { isArchived, status } = client;
      const isClientLocked = this.isClientLocked(client);
      const isSent = status === CLIENT_STATUSES.SENT;
      const isSigned = status === CLIENT_STATUSES.SIGNED;

      const canEdit = [
        hasPermission,
        !isArchived,
        !isClientLocked,
        !isSent,
        !isSigned,
      ].reduce((acc, curr) => acc && curr, true);

      return canEdit;
    },

    /* 
      Clients in PENDING_DOCUSIGN_REVIEW can have their envelope viewed
    */
    canReviewEnvelope(client) {
      const { status, isArchived } = client;
      const pendingReview = status === CLIENT_STATUSES.PENDING_DOCUSIGN_REVIEW;

      return pendingReview && !isArchived;
    },

    /* 
      Depending on what permission the current user has, a client can be
      Deleted as long as it is not in OnBord Complete status
    */
    canDeleteClient(client) {
      const { DELETE_OWN_CLIENT, DELETE_ANY_CLIENT } = PERMISSIONS.CLIENT;
      const hasPermission = [DELETE_OWN_CLIENT, DELETE_ANY_CLIENT].some(
        (permission) => PermissionService.hasPermission(permission),
      );

      return hasPermission && !client.isArchived;
    },

    /* Helper method to determine whether to show a divider in the actions context menu */
    canShowDivider(client) {
      return (
        this.canDeleteClient(client) &&
        (this.canImpersonate(client) ||
          this.canSendText(client) ||
          this.canEditClient(client) ||
          this.canReviewEnvelope(client))
      );
    },

    /* If any action is still applicable for the client we can show the actions context menu */
    canPerformActions(client) {
      return (
        this.canImpersonate(client) ||
        this.canSendText(client) ||
        this.canEditClient(client) ||
        this.canReviewEnvelope(client) ||
        this.canDeleteClient(client) ||
        this.canRestore(client)
      );
    },

    /* Get send/resend verbiage based on client status */
    sendTextVerbiage(client) {
      return client.status === CLIENT_STATUSES.SENT
        ? 'Resend Text'
        : 'Send Text';
    },

    /* Get finish/edit verbiage based on client status */
    editTextVerbiage(client) {
      return client.status === CLIENT_STATUSES.DRAFT ? 'Finish' : 'Edit';
    },

    /* Get delete/archive verbiage based on client status */
    archiveTextVerbiage(client) {
      return client.isOnbordComplete ? 'Archive' : 'Delete';
    },

    /* Get edit icon based on client status */
    editIcon(client) {
      return client.status === CLIENT_STATUSES.DRAFT
        ? 'fas fa-external-link-alt'
        : 'fas fa-pencil';
    },

    /* Allow a user to impersonate a client and enter that client's interview */
    impersonateClient(client) {
      const payload = {
        id: client.id,
        relatesTo: client.contactType,
      };
      this.$store
        .dispatch(IMPERSONATE_CLIENT, payload)
        .then(({ token }) => {
          const routeData = this.$router.resolve({
            name: MENUS.INTERVIEW.AUTHENTICATE.id,
            params: { token },
          });

          // Open the URL in a new tab
          window.open(routeData.href, '_blank');
        })
        .catch((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: `Failed to impersonate client!`,
              okTitle: 'Ok',
              okMethod: () => {
                this.getClients();
              },
            },
            response,
          );
        })
        .finally(() => {});
    },
    // Send or resend a batch of welcome texts
    sendBatch() {
      // We use a set here so we don't send duplicate primary ids
      let clientIds = new Set();
      for (var client of this.selected) {
        if (this.canSendText(client)) {
          clientIds.add(client.id);
        }
      }
      if (clientIds.size === 0) {
        NotificationService.alert({
          type: NOTIFICATION_TYPES.ERROR,
          title: 'Error',
          message: `None of the selected clients are ready to send!`,
          okTitle: 'Ok',
        });
      } else {
        this.$store
          .dispatch(SEND_CLIENT_BATCH, Array.from(clientIds))
          .then((response) => {
            // Clear the selected items on successful batch operation
            this.selected.splice(0);
            NotificationService.alert({
              type: NOTIFICATION_TYPES.SUCEESS,
              title: 'Success',
              message: `${response.totalClientsCommunicated} of ${response.batchSize} Clients / Households Communicated Successfully!`,
              okTitle: 'Ok',
            });
          })
          .catch((response) => {
            NotificationService.alert(
              {
                type: NOTIFICATION_TYPES.ERROR,
                title: 'Error',
                message: `Failed to send text batch!`,
                okTitle: 'Ok',
              },
              response,
            );
          })
          .finally(() => {
            this.getClients();
          });
      }
    },
    // Delete/Archive a set of OnBords
    deleteBatch() {
      // We use a set here so we don't send duplicate primary ids
      let ids = new Set();
      for (var client of this.selected) {
        if (this.canDeleteClient(client)) {
          ids.add(client.id);
        }
      }
      if (ids.size === 0) {
        NotificationService.alert({
          type: NOTIFICATION_TYPES.ERROR,
          title: 'Error',
          message: `None of the selected clients can be deleted!`,
          okTitle: 'Ok',
        });
      } else {
        this.$store
          .dispatch(DELETE_CLIENT_BATCH, Array.from(ids))
          .then((response) => {
            // Clear the selected items on successful batch operation
            this.selected.splice(0);
            NotificationService.alert({
              type: NOTIFICATION_TYPES.SUCEESS,
              title: 'Success',
              message: `${response.clientsModified} of ${response.batchSize} Clients / Households Archived Successfully!`,
              okTitle: 'Ok',
            });
          })
          .catch((response) => {
            NotificationService.alert(
              {
                type: NOTIFICATION_TYPES.ERROR,
                title: 'Error',
                message: 'Failed to archive the selected clients!',
                okTitle: 'Ok',
              },
              response,
            );
          })
          .finally(() => {
            this.getClients();
          });
      }
    },
    /* Send/Resend Welcome text to client */
    sendText(client) {
      const payload = {
        id: client.id,
        sendNow: true,
      };

      this.$store
        .dispatch(EDIT_CLIENT, payload)
        .then(() => {
          NotificationService.alert({
            type: NOTIFICATION_TYPES.SUCEESS,
            title: 'Success',
            message: `${this.sendTextVerbiage(client)} Successful!`,
            okTitle: 'Ok',
          });
        })
        .catch((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: `Failed to ${this.sendTextVerbiage(client)}!`,
              okTitle: 'Ok',
            },
            response,
          );
        })
        .finally(() => {
          this.getClients();
        });
    },

    /* Edit a Draft client */
    editClient(client) {
      if (!this.canEditClient(client)) {
        return;
      }

      const id = !client.primaryInterviewId
        ? client.id
        : client.primaryInterviewId;

      this.$store
        .dispatch(GET_CLIENT, id)
        .then((response) => {
          // Reconstitute the working client from backend data
          const workingClient = this.reconstituteClient({
            ...response,
            id: response._id,
          });

          this.$store.dispatch(SET_WORKING_CLIENT, workingClient).then(() => {
            this.$router.push({
              name: MENUS.ADVISOR.CLIENT.REVIEW_AND_FINISH.id,
            });
          });
        })
        .catch((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: `Error getting client ${client.name} !`,
              okTitle: 'Ok',
              okMethod: () => {
                this.getClients();
              },
            },
            response,
          );
        })
        .finally(() => {});
    },

    /* Get the DocuSign URL and redirect so that user can review envelope */
    reviewEnvelope(client) {
      const clientId = client.id;

      this.$store
        .dispatch(GET_REVIEW_ENVELOPE_URL, clientId)
        .then((url) => {
          window.open(url, '_blank');
        })
        .catch((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: 'Error accessing DocuSign Envelope!',
              okTitle: 'Ok',
            },
            response,
          );
        });
    },

    /* Actually Delete Client */
    doDelete(client) {
      const action = this.archiveTextVerbiage(client);
      const actionPast = action === 'Delete' ? 'Deleted' : 'Archived';
      const actionPlural = action === 'Delete' ? 'Deleting' : 'Archiving';

      const id = client.id;

      const actionFn = action === 'Delete' ? DELETE_CLIENT : ARCHIVE_CLIENT;

      this.$store
        .dispatch(actionFn, id)
        .then(() => {
          NotificationService.alert({
            type: NOTIFICATION_TYPES.SUCEESS,
            title: 'Success',
            message: `Client Successfully ${actionPast}!`,
            okTitle: 'Ok',
          });
        })
        .catch((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: `Error Deleting ${actionPlural}!`,
              okTitle: 'Ok',
            },
            response,
          );
        })
        .finally(() => {
          this.getClients();
        });
    },

    /* Delete Client requested. Confirm/Warn before deletion */
    deleteClient(client) {
      const action = this.archiveTextVerbiage(client);
      const actionPlural = action === 'Delete' ? 'Deleting' : 'Archiving';
      let msg = `Are you sure you want to ${action} this client?`;

      // Clients that are part of a household should be warned that
      // the linked client will also be deleted.
      if (client.belongsToHousehold) {
        msg =
          `This client is part of a household. ${actionPlural} this client will ${action} <strong>the household</strong>.<br><br>` +
          msg;
      }

      NotificationService.confirm({
        type: NOTIFICATION_TYPES.WARNING,
        message: msg,
        okTitle: "Yes I'm sure",
        okMethod: () => {
          this.doDelete(client);
        },
      });
    },

    /* Actually Restore Client */
    doRestore(client) {
      const id = client.primaryInterviewId
        ? client.primaryInterviewId
        : client.id;

      this.$store
        .dispatch(RESTORE_CLIENT, id)
        .then(() => {
          NotificationService.alert({
            type: NOTIFICATION_TYPES.SUCEESS,
            title: 'Success',
            message: 'Client Successfully Restored!',
            okTitle: 'Ok',
          });
        })
        .catch((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: 'Error Restoring client!',
              okTitle: 'Ok',
            },
            response,
          );
        })
        .finally(() => {
          this.getClients();
        });
    },

    /* Restore Client requested. Confirm/Warn before deletion */
    restoreClient(client) {
      let msg = 'Are you sure you want to Restore this client?';

      // Clients that are part of a household should be warned that
      // the linked client will also be restored.
      if (client.primaryInterviewId || client.secondaryInterviewId) {
        msg =
          `This client is part of a household. Restoring this client will Restore <strong>${client.familyName}</strong>.<br><br>` +
          msg;
      }

      NotificationService.confirm({
        type: NOTIFICATION_TYPES.WARNING,
        message: msg,
        okTitle: "Yes I'm sure",
        okMethod: () => {
          this.doRestore(client);
        },
      });
    },

    /* Get the list of clients */
    getClients() {
      // Again we hide the table while loading to force
      // it to be redrawn as Safari on mobile seems to have issues
      // compiling icons
      this.tableLoading = true;
      this.clients = [];
      const payload = {
        include: this.fetchMode,
        enrich: false,
      };

      this.$store
        .dispatch(GET_CLIENTS, payload)
        .then((response) => {
          this.clients = response || [];
          this.clients.forEach((c) => {
            c.name = `${c.lastName}, ${c.firstName}`;
            c.rep = c.repLastName ? `${c.repLastName}, ${c.repFirstName}` : '-';
          });
        })
        .catch((response) => {
          this.clients = [];

          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: 'Error getting list of clients!',
              okTitle: 'Ok',
            },
            response,
          );
        })
        .finally(() => {
          // Regardless of outcome, force re-draw
          this.tableLoading = false;
          // This is needed to watch the DOM for any additional icons being added or modified.
          dom.watch();
        });
    },
  },

  /* Lifecycle Hook */
  created() {
    this.pagination.sortBy = 'name';

    // Set initial static status lists
    this.statuses = [];
    Object.keys(CLIENT_STATUSES).map((key) => {
      this.statuses.push(CLIENT_STATUSES[key]);
    });
    this.onbordStatuses = [];
    Object.keys(CLIENT_ONBORD_STATUSES).map((key) => {
      this.onbordStatuses.push(CLIENT_ONBORD_STATUSES[key]);
    });
    this.fetchStatuses = FETCH_CLIENT_STATUSES;

    this.MENUS = MENUS;
    // Get client list
    this.getClients();
  },
};
</script>

<style lang="scss" scoped>
.mobile {
  color: var(--color-text-dark);
}

.prepend {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--secondary-color);
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.search-input {
  ::v-deep {
    .v-input__icon--clear {
      height: 16px;
    }

    .fa-times-circle {
      font-size: 16px;
      margin-right: 8px;
    }
  }
}

.actions {
  padding: 2px 4px;
  line-height: 16px;

  &[aria-expanded='true'] {
    background-color: var(--secondary-color);
    border-radius: 4px;

    ::v-deep .v-icon {
      color: var(--primary-color);
    }
  }
}

.menu {
  background-color: var(--color-white);
  min-width: 120px;

  ::v-deep {
    .v-list-item {
      min-height: 24px !important;
      padding: 0;
    }
  }
}

.menu-item-wrapper {
  padding-left: 10px;
  color: var(--color-text) !important;
  font-size: 14px !important;
  font-weight: 700;
  margin-bottom: 0;
  padding: 8px 12px;
  margin: 0 4px;

  ::v-deep {
    .v-icon {
      font-weight: 700;
      color: var(--color-text) !important;
      //margin-right: 8px;
      margin-top: -2px;
    }
  }

  &:hover {
    color: var(--primary-color) !important;
    background-color: var(--input-bg-color);
    text-decoration: underline;

    ::v-deep .v-icon {
      color: var(--primary-color) !important;
    }
  }

  .label {
    padding-left: 8px;
  }
}

.sheet {
  background-color: var(--color-white);

  ::v-deep {
    .v-list-item {
      min-height: 24px !important;
      padding: 0;
    }
  }

  .menu-item-wrapper {
    font-size: 16px !important;
    padding: 12px;
  }

  .bottom-spacer {
    height: 25px;
  }
}

.status-col {
  max-width: 275px;
}

.o-status-col {
  max-width: 175px;
}

.action-column {
  text-align: end !important;
}

button.theme--light.v-btn--has-bg.batch-activator {
  background-color: var(--primary-color);
  color: var(--color-white);
  padding: 22px 20px;
  margin-right: 5px;
  text-transform: none;
  font-size: 16px;
}

.edit-client-link {
  color: var(--color-text);
  font-weight: 700;

  &:hover {
    color: var(--primary-color);
  }
}

.flex-content {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;

  .actions-container {
    position: absolute;
    top: 0;
    right: 0;
    line-height: normal;
  }

  .flex-item {
    padding: 5px;
    line-height: 24px;
    font-size: 16px;

    .edit-client-link {
      padding-right: 30px;
      display: inline-block;
      line-height: normal;
    }

    .mobile-label {
      font-weight: 400;
      margin-right: 10px;
    }
  }
}

.search {
  margin-right: 12px;
}

.divider {
  height: 1px;
  margin: 8px;
  background-color: var(--secondary-color);
}

.client-table {
  font-weight: 500;
  color: var(--color-text);

  .disposition-switch {
    ::v-deep label {
      font-size: 14px !important;
    }
  }

  .status-pill {
    padding: 4px 15px;
    border-radius: 30px;
    line-height: 24px;
    font-size: 14px;

    &.Draft,
    &.Ready {
      background-color: hsla(0, 0%, 72.7%, 0.16);
      color: var(--label-text);
    }

    &.Sent {
      background-color: rgba(30, 59, 112, 0.15);
      color: var(--primary-color);
    }

    &.WaitingSignature {
      background-color: rgba(252, 180, 0, 0.25);
      color: var(--primary-color);
    }

    &.WaitingReview {
      display: inline-block;
      background-color: transparent;
      color: var(--color-text);
      font-weight: 700;

      &:hover {
        background-color: var(--input-bg-color);
        text-decoration: underline;
        color: var(--primary-color);
      }
    }

    &.DownloadDocuments {
      display: inline-block;
      background-color: transparent;
      color: var(--color-text);
      font-weight: 700;

      &:hover {
        background-color: var(--input-bg-color);
        text-decoration: underline;
        color: var(--primary-color);
      }
    }

    &.ClientComplete,
    &.Signed {
      background-color: rgba(52, 182, 56, 0.25);
      color: var(--primary-color);
    }

    &.Rejected,
    &.Archived {
      background-color: rgba(232, 44, 44, 0.25);
      color: var(--primary-color);
    }
  }

  .filter-action-row {
    padding-bottom: 12px;
  }

  .weight-300 {
    font-weight: 300;
  }

  .font-fam-ui-serif {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }

  .edit-client-link.disabled {
    text-decoration: none;
    color: var(--color-text);

    &:hover {
      color: var(--color-text);
      cursor: text;
    }
  }
}

@media screen and (max-width: 769px) {
  .filter-action-row {
    flex-direction: column;

    .status-col,
    .o-status-col {
      max-width: 100%;
      padding: 4px 12px;
    }

    .button-area {
      justify-content: flex-start;
    }
  }
}
</style>
