<template>
  <div class="step-container advisor">
    <v-form name="ClientName" class="form">
      <v-container class="client-step-container">
        <v-row class="mb-8">
          <v-col class="instructions">
            <h1>
              <span class="headerlight"
                >What's your {{ clientLabel }} <strong>name</strong>?</span
              >
            </h1>
          </v-col>
        </v-row>
        <v-row class="client-name-container">
          <v-col class="step-form-container">
            <label for="firstName" class="required">First Name</label>
            <v-text-field
              outlined
              type="text"
              maxlength="256"
              name="firstName"
              aria-required="true"
              v-model.trim="firstName"
              :backgroundColor="inputBackgroundColor"
              @input="$v.firstName.$touch()"
              @blur="$v.firstName.$touch()"
              :error-messages="firstNameErrors"
              hide-details="auto"
            />
          </v-col>
          <v-col class="step-form-container">
            <label for="lastName" class="required">Last Name</label>
            <v-text-field
              outlined
              type="text"
              maxlength="256"
              name="lastName"
              aria-required="true"
              v-model.trim="lastName"
              :backgroundColor="inputBackgroundColor"
              @input="$v.lastName.$touch()"
              @blur="$v.lastName.$touch()"
              :error-messages="lastNameErrors"
              hide-details="auto"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <bottom-nav v-on:go-prev="prev()" v-on:go-next="next()"></bottom-nav>
  </div>
</template>

<script>
import BottomNav from '@/components/common/BottomNav';
import { validationMixin } from 'vuelidate';
import { MENUS } from '@/common/constants';
import { required } from 'vuelidate/lib/validators';
import { ADD_TRANSITION_NAME } from '@/stores/actions.type';
import AdvisorTransitionMixin from '@/common/mixins/advisor.transition.mixin';
import { mapGetters } from 'vuex';

export default {
  name: 'TransitionManualClientName',

  mixins: [validationMixin, AdvisorTransitionMixin],

  data: () => ({
    firstName: null,
    lastName: null,
  }),

  props: {
    second: {
      type: Boolean,
      default: false,
    },
  },

  components: { BottomNav },

  validations() {
    return {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      validationGroup: ['firstName', 'lastName'],
    };
  },

  computed: {
    ...mapGetters(['currentUser', 'currentCompany', 'transitioningHousehold']),

    transitionType() {
      return this.$store.getters.selectedTransitionType;
    },
    isProtocol() {
      return this.transitionType === 'Protocol';
    },
    // Dynamic First Name errors */
    firstNameErrors() {
      const errors = [];
      if (!this.$v.firstName.$dirty) return errors;
      !this.$v.firstName.required && errors.push('First Name is required');
      return errors;
    },

    // Dynamic Last Name errors */
    lastNameErrors() {
      const errors = [];
      if (!this.$v.lastName.$dirty) return errors;
      !this.$v.lastName.required && errors.push('Last Name is required');
      return errors;
    },
    clientLabel() {
      return this.second ? '2nd client' : "client's";
    },
  },

  created() {
    if (this.transitioningHousehold) {
      const contact = this.second
        ? this.transitioningHousehold.secondaryContact
        : this.transitioningHousehold.primaryContact;

      this.firstName = contact.firstName;
      this.lastName = contact.lastName;
    }
  },

  methods: {
    /* Nav to Next Page in flow */
    next() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      const protocolKey = this.isProtocol ? 'protocol' : 'notProtocol';
      const secondKey = this.second ? 'second' : 'notSecond';

      const routeConfig = {
        protocol: {
          second: MENUS.TRANSITIONS.ENTER_MANUALLY.CLIENT_TWO_EMAIL.id,
          notSecond: MENUS.TRANSITIONS.ENTER_MANUALLY.CLIENT_ONE_EMAIL.id,
        },
        notProtocol: {
          second: MENUS.TRANSITIONS.ENTER_MANUALLY.CLIENT_TWO_PHONE.id,
          notSecond: MENUS.TRANSITIONS.ENTER_MANUALLY.CLIENT_ONE_PHONE.id,
        },
      };
      const to = routeConfig[protocolKey][secondKey];

      const payload = {
        firstName: this.firstName,
        lastName: this.lastName,
        key: this.second ? 1 : 0,
      };

      this.$store.dispatch(ADD_TRANSITION_NAME, payload).then(() => {
        this.$router.push({ name: to });
      });
    },

    /* Nav to Previous Page in flow */
    prev() {
      this.$router.push({
        name: MENUS.TRANSITIONS.TRANSITION_FORM_SELECTION.id,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.client-name-container {
  justify-content: center;
  align-content: align-center;
  flex-direction: column;
}
</style>
