<template>
  <div
    class="allocation-area d-flex justify-center"
    v-if="beneficiaries.length > 0"
  >
    <div class="d-flex action-container align-center justify-center">
      <span class="totals">{{ allocationTotals }}</span>
      <span>
        <v-tooltip top content-class="breakdown-tip-container" nudge-top="10">
          <template v-slot:activator="{ on, attrs }">
            <div v-on="on" v-bind="attrs" class="breakdown-tip">
              <span class="breakdown"> Breakdown </span>
              <span>
                <v-icon small color="#fff"> fas fa-list </v-icon>
              </span>
            </div>
          </template>
          <div>
            <div
              class="breakdown-row"
              v-for="beneficiary in beneficiaries"
              :key="beneficiary"
            >
              <span class="name"> {{ beneficiary.name }} </span>
              <span> {{ beneficiary.allocationPercent }}% </span>
            </div>
          </div>
        </v-tooltip>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    beneficiaries: {
      type: Object,
      default: null,
    },
  },
  name: 'AllocationTracker',
  computed: {
    allocationTotals() {
      let tots = 0;
      const benes = this.beneficiaries || [];
      benes.forEach((b) => {
        tots += b.allocationPercent ? parseInt(b.allocationPercent) : 0;
      });

      return `${tots}/100%`;
    },
  },
};
</script>

<style lang="scss" scoped>
.allocation-area {
  position: fixed;
  bottom: 100px;
  left: 0;
  height: 60px;
  width: 100%;
  background-color: var(--cb-color);

  @media screen and (max-width: 960px) {
    bottom: 100px;
    top: auto;
    width: 100vw;
    position: absolute;
  }

  .action-container {
    max-width: 1600px;
    width: 90vw;
    color: var(--color-white);

    .breakdown-top {
      cursor: default;
    }
    .totals {
      font-size: 28px;
    }
    .breakdown {
      padding: 0 8px 0 16px;
      font-size: 12px;
    }
  }
}
</style>
>
