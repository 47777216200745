var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-layout',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],attrs:{"column":""}},[_c('v-data-table',{staticClass:"responsive-table asset-table",class:{ mobile: _vm.isMobile },attrs:{"headers":_vm.headers,"items":_vm.assets,"items-per-page":5,"hide-default-header":_vm.isMobile,"loading":_vm.tableLoading,"page":_vm.pagination.page},on:{"update:page":function($event){return _vm.$set(_vm.pagination, "page", $event)},"pagination":_vm.updatePage},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',[_c('v-col',{staticClass:"button-area btn-margin"},[_c('a',{staticClass:"btn",on:{"click":function($event){return _vm.addDocument()}}},[_vm._v(" Add Document "),_c('span',{staticClass:"fa-icon"},[_c('i',{staticClass:"fas fa-plus-circle"})])])])],1)]},proxy:true},{key:"item",fn:function(props){return [(!_vm.isMobile)?_c('tr',_vm._l((_vm.tableColumns),function(col){return _c('td',{key:col,attrs:{"width":col.width}},[(!col.isActionColumn)?_c('div',[_c('span',{class:col.classFn(props.item),on:{"click":function($event){col.clickFn ? col.clickFn(props.item) : function () {}}}},[_vm._v(" "+_vm._s(col.valueFn(props.item))+" ")])]):_c('div',{staticClass:"action-column"},[(_vm.canPerformActions(props.item))?_c('v-menu',{attrs:{"left":"","nudge-left":"15","nudge-bottom":"25","min-width":"125"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"actions"},'span',attrs,false),on),[_c('font-awesome-icon',{attrs:{"icon":"ellipsis-h"}})],1)]}}],null,true)},[_c('v-list',{staticClass:"menu"},_vm._l((col.actions),function(action){return _c('div',{key:action},[(!action.isDivider)?_c('v-list-item',{on:{"click":function($event){return action.clickFn(props.item)}}},[_c('v-list-item-title',{staticClass:"menu-item-wrapper"},[_c('v-icon',{key:props.item.status,attrs:{"x-small":""}},[_vm._v(" fas fa-file-upload ")]),_c('input',{staticClass:"label",attrs:{"type":"file"},on:{"change":function($event){return action.fileChangeFn($event)}}})],1)],1):_vm._e(),(action.isDivider && _vm.canShowDivider(props.item))?_c('div',{staticClass:"divider"}):_vm._e()],1)}),0)],1):_vm._e()],1)])}),0):_c('tr',[_c('td',[_c('ul',{staticClass:"flex-content"},_vm._l((_vm.tableColumns),function(col){return _c('li',{key:col,staticClass:"flex-item"},[(!col.isActionColumn)?_c('div',[(col.dataLabel)?_c('span',{staticClass:"mobile-label"},[_vm._v(" "+_vm._s(col.dataLabel)+": ")]):_vm._e(),_c('span',{class:col.classFn(props.item),on:{"click":function($event){col.clickFn ? col.clickFn(props.item) : function () {}}}},[_vm._v(" "+_vm._s(col.valueFn(props.item))+" ")])]):_c('div',{staticClass:"actions-container"},[(_vm.canPerformActions(props.item))?_c('v-bottom-sheet',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"actions"},'span',attrs,false),on),[_c('font-awesome-icon',{attrs:{"icon":"ellipsis-h"}})],1)]}}],null,true)},[_c('v-sheet',{attrs:{"height":"auto"}},[_c('v-list',{staticClass:"sheet"},[_vm._l((col.actions),function(action){return _c('div',{key:action},[(!action.isDivider)?_c('v-list-item',{on:{"click":function($event){return action.clickFn(props.item)}}},[_c('v-list-item-title',{staticClass:"menu-item-wrapper"})],1):_vm._e(),(
                              action.isDivider && _vm.canShowDivider(props.item)
                            )?_c('div',{staticClass:"divider"}):_vm._e()],1)}),_c('div',{staticClass:"bottom-spacer"})],2)],1)],1):_vm._e()],1)])}),0)])])]}}])},[_c('div',{staticClass:"no-results",attrs:{"slot":"no-results","value":true},slot:"no-results"},[_vm._v(" Your search/filter found no results. ")]),_c('div',{staticClass:"no-data",attrs:{"slot":"no-data","value":true},slot:"no-data"},[_vm._v(" No assets found. "),_c('a',{on:{"click":function($event){return _vm.addDocument()}}},[_vm._v(" Add a new asset")]),_vm._v(". ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }