import Vue from 'vue';
import Vuetify, { VIcon, VTooltip } from 'vuetify/lib';

/* Configure Vuetify Plugin */
Vue.use(Vuetify, {
  components: {
    VIcon,
  },
  directives: {
    VTooltip,
  },
});

export default new Vuetify({
  theme: { disable: true },
  icons: {
    iconfont: 'faSvg',
  },
});
