<template>
  <div class="template-container">
    <v-form ref="form">
      <v-container>
        <v-card-title>Template Details</v-card-title>
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              v-model="template.templateName"
              :rules="nameRules"
              placeholder="Template Name..."
              required
              single-line
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-card-title>Template Questions</v-card-title>
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              type="text"
              maxlength="256"
              placeholder="Search Questions..."
              v-model.trim="search"
              class="search-input mb-2"
              single-line
              @input="debounceFetch"
              @click:clear="resetSearch"
            >
              <template v-slot:append>
                <span class="search mr-5">
                  <v-icon small>fas fa-search</v-icon>
                </span>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <div class="panel-header">Available Questions</div>
            <div
              class="scrollable-list"
              ref="scrollableList"
              @scroll="handleScroll"
            >
              <draggable
                :list="questions"
                :group="{ name: 'questions', pull: 'clone', put: false }"
                item-key="element._id"
                class="drag-area"
              >
                <div
                  v-for="item in questions"
                  :key="item._id"
                  class="drag-item"
                >
                  {{ item.question }}
                </div>
              </draggable>
            </div>
          </v-col>
          <v-col cols="6">
            <div class="panel-header">Selected Questions</div>
            <div class="scrollable-list">
              <draggable
                handle=".handle"
                v-model="selectedQuestions"
                @change="refresh()"
                group="questions"
                item-key="_id"
                class="drop-area"
              >
                <div
                  v-for="(item, index) in selectedQuestionsA"
                  :key="item._id"
                  class="drop-item"
                >
                  <v-icon class="handle">fas fa-grip-lines</v-icon>
                  {{ item.question }}
                  <a @click="removeQuestion(index)">
                    <v-icon class="remove-icon">fas fa-times</v-icon>
                  </a>
                </div>
              </draggable>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <div class="fixed-action-bar">
        <a class="btn secondary" text @click="cancel">Cancel</a>
        <a class="btn" color="darken-1" text @click="save">Save</a>
      </div>
    </v-form>
  </div>
</template>

<script>
import {
  NotificationService,
  NOTIFICATION_TYPES,
} from '@/services/notification.service';
import {
  GET_CUSTOM_QUESTIONS,
  ADD_CUSTOM_QUESTION_TEMPLATE,
  UPDATE_CUSTOM_QUESTION_TEMPLATE,
  DELETE_CUSTOM_QUESTION_TEMPLATE,
  GET_CUSTOM_QUESTIONS_TEMPLATE,
} from '@/stores/actions.type';
import draggable from 'vuedraggable';
import EventListener from '@/common/mixins/eventlistener.mixin';
import { EventService } from '@/services/event.service';
import { MENUS } from '@/common/constants';

export default {
  name: 'AddTemplate',
  mixins: [EventListener],
  components: {
    draggable,
  },
  props: {
    id: String,
    dialog: {
      type: Boolean,
      default: false,
    },
    availableQuestions: Array,
  },
  destroyed() {
    const scrollableList = this.$refs.scrollableList;
    if (scrollableList) {
      scrollableList.removeEventListener('scroll', this.handleScroll);
    }
  },
  created() {
    if (this.id !== 'new') {
      this.loadTemplateData(this.id); // Load existing data if editing
    } else {
      this.initNewTemplate(); // Initialize for a new template
    }
  },
  data() {
    return {
      localTemplate: {
        templateName: '',
        questionIds: [],
      },
      search: '',
      template: {
        name: '',
      },
      questions: [],
      selectedQuestions: [],
      loading: false,
      allQuestionsLoaded: false,
      page: 1,
      limit: 10,
      templateId: null,
      nameRules: [
        (v) => !!v || 'Template name is required',
        (v) =>
          (v && v.length <= 256) ||
          'Template name must be less than 256 characters',
      ],
    };
  },
  computed: {
    selectedQuestionsA() {
      return Array.from(new Set(this.selectedQuestions));
    },
    isEditing() {
      return !!this.id;
    },
  },
  methods: {
    initNewTemplate() {
      this.template = this.defaultTemplate();
      this.selectedQuestions = [];
      this.fetchQuestions();
      this.$refs.form.resetValidation(); // Reset form validation state
    },
    refresh() {
      this.$nextTick(() => {
        this.$forceUpdate();
        this.selectedQuestions = [...this.selectedQuestions];
      });
    },
    init() {
      this.resetForm();
      this.fetchQuestions();
    },
    cancel() {
      this.$router.go(-1);
    },
    removeQuestion(index) {
      this.selectedQuestions.splice(index, 1);
    },
    resetSearch() {
      this.questions = [];
      this.page = 1;
      this.allQuestionsLoaded = false;
      this.fetchQuestions();
    },
    handleScroll() {
      const target = this.$refs.scrollableList;
      if (!target || this.loading || this.allQuestionsLoaded) return;

      const { scrollTop, scrollHeight, clientHeight } = target;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        this.fetchQuestions();
      }
    },
    debounceFetch() {
      clearTimeout(this.debouncer);
      this.debouncer = setTimeout(() => {
        this.resetSearch();
        this.fetchQuestions();
      }, 200);
    },

    resetForm() {
      this.localTemplate = { templateName: '', questionIds: [] };
      this.selectedQuestions = this.isEditing
        ? this.getCustomQuestionsFromTemplate(this.template)
        : [];
      this.questions = [];
      this.page = 1;
      this.allQuestionsLoaded = false;
    },
    defaultTemplate() {
      return {
        templateName: '',
        questionIds: [],
      };
    },
    save() {
      if (this.$refs.form.validate()) {
        if (this.selectedQuestions.length === 0) {
          NotificationService.alert({
            type: NOTIFICATION_TYPES.WARNING,
            title: 'Validation Error',
            message: 'Please select at least one question.',
          });
          return;
        }
        // The form is valid, proceed with the save operation
        if (this.id === 'new') {
          return this.createTemplate();
        }
        return this.updateTemplate();
      } else {
        // The form is invalid, do not proceed
        NotificationService.alert({
          type: NOTIFICATION_TYPES.WARNING,
          title: 'Validation Error',
          message: 'Please correct the errors before saving.',
        });
      }
    },
    fetchQuestions() {
      if (this.loading) return;

      const template = this.template;
      this.loading = true;

      const paginationData = {
        page: this.page,
        limit: this.limit,
        search: this.search.trim(),
      };

      this.$store
        .dispatch(GET_CUSTOM_QUESTIONS, paginationData)
        .then((response) => {
          const fetchedQuestions = response.result;
          // Check if in edit mode and merge selected questions
          if (this.isEditing) {
            this.selectedQuestions =
              this.selectedQuestions.length === 0
                ? this.getCustomQuestionsFromTemplate(template)
                : this.selectedQuestions;
          }

          this.questions = [...this.questions, ...fetchedQuestions];
          this.questions = this.questions.filter(
            (question) =>
              !this.selectedQuestions.some(
                (selectedQuestion) => selectedQuestion._id === question._id,
              ),
          );
          if (fetchedQuestions.length < this.limit) {
            this.allQuestionsLoaded = true;
          }

          this.$nextTick(() => {
            this.loading = false;
          });
          this.page += 1;

          EventService.emit('reload-custom-questions-template-table');
        })
        .catch(() => {
          this.loading = false;
        });
    },

    updatelimit(newlimit) {
      this.limit = newlimit;
      this.fetchQuestions();
    },

    updatePage(newPage) {
      this.page = newPage;
      this.fetchQuestions();
    },

    createTemplate() {
      const payload = {
        templateName: this.template.templateName,
        customQuestionIds: Array.from(
          new Set(this.selectedQuestions.map((q) => q._id)),
        ),
      };

      this.$store
        .dispatch(ADD_CUSTOM_QUESTION_TEMPLATE, payload)
        .then(() => {
          NotificationService.alert({
            type: NOTIFICATION_TYPES.SUCCESS,
            title: 'Success',
            message: 'Template created successfully',
            okTitle: 'Ok',
            okMethod: () => {
              this.$router.push({ name: MENUS.CUSTOM_QUESTIONS.id });
            },
          });
        })
        .catch((error) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: 'Error creating template',
              okTitle: 'Ok',
              okMethod: () => {},
            },
            error,
          );
        });
    },

    updateTemplate() {
      const payload = {
        id: this.id,
        templateName: this.template.templateName,
        customQuestionIds: Array.from(
          new Set(this.selectedQuestions.map((q) => q._id)),
        ),
      };

      this.$store
        .dispatch(UPDATE_CUSTOM_QUESTION_TEMPLATE, payload)
        .then(() => {
          NotificationService.alert({
            type: NOTIFICATION_TYPES.SUCCESS,
            title: 'Success',
            message: 'Template updated successfully',
            okTitle: 'Ok',
            okMethod: () => {
              this.$router.push({ name: MENUS.CUSTOM_QUESTIONS.id });
            },
          });
        })
        .catch((error) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: 'Error updating template',
              okTitle: 'Ok',
              okMethod: () => {},
            },
            error,
          );
        });
    },

    deleteTemplate(templateId) {
      this.$store
        .dispatch(DELETE_CUSTOM_QUESTION_TEMPLATE, templateId)
        .then(() => {
          this.$emit('notification', 'Template deleted successfully');
          // Additional logic after deletion
        })
        .catch(() => {
          this.$emit('notification', 'Error deleting template');
        });
    },
    loadTemplateData(templateId) {
      this.$store
        .dispatch(GET_CUSTOM_QUESTIONS_TEMPLATE, templateId)
        .then((response) => {
          this.template = response;
          this.selectedQuestions = this.getCustomQuestionsFromTemplate(
            this.template,
          );
          this.fetchQuestions();
        })
        .catch(() => {
          this.$emit('notification', 'Error loading template');
        });
    },
    getCustomQuestionsFromTemplate(template) {
      if (!template || !template.pages) {
        return [];
      }
      const customQuestions = template.pages.map((page) => {
        return page.data.question;
      });
      return customQuestions;
    },
  },
};
</script>
<style scoped lang="scss">
.v-card-title {
  background-color: blue;
}

.scrollable-list {
  overflow-y: auto;
  max-height: 30vh;
  border-right: 1px solid #e0e0e0; // A subtle border to visually separate the list from the drop area
}

.drag-area,
.drop-area {
  min-height: 30vh; // Make sure both areas are of equal height
  padding: 1rem;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Soft shadow for depth
}

.drag-item,
.drop-item {
  background-color: #fafafa; // Slightly off-white for contrast
  border: 1px solid #e0e0e0; // Light border for each item
  border-radius: 4px; // Rounded corners for modern feel
  margin-bottom: 0.5rem;
  padding: 0.75rem;
  transition: background-color 0.3s ease; // Smooth transition for hover effect

  &:hover {
    background-color: #f5f5f5; // Slightly darker when hovered
  }
}

/* For browsers that support it, let's use a more modern scrollbar appearance */
.scrollable-list::-webkit-scrollbar {
  width: 3px;
}

.scrollable-list::-webkit-scrollbar-thumb {
  background: #ccc; // Neutral color for scrollbar thumb
  border-radius: 4px;
}

.scrollable-list::-webkit-scrollbar-track {
  background: transparent; // Less visible scrollbar track
}

.scrollable-list {
  overflow-y: auto;
  max-height: 30vh;
  border: 1px solid #e0e0e0;
}

.panel-header {
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 1.1em;
}

.drag-area,
.drop-area {
  min-height: 30vh;
  padding: 1rem;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.drag-item,
.drop-item {
  background-color: #fafafa;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin-bottom: 0.5rem;
  padding: 0.75rem;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f5f5f5;
  }
}

.v-divider {
  margin: 20px 0;
}

.template-container {
  padding-bottom: 60px;
}

.fixed-action-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  padding: 10px 20px;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: flex-end;
}

.remove-icon {
  float: right;
  cursor: pointer;
  margin-top: 2px;
  margin-right: 5px;
  font-size: 20px;
}

.handle {
  float: left;
  margin-right: 15px;
  margin-top: 5px;
  font-size: 15px;
  cursor: grab;
}
</style>
