<template>
  <v-data-table
    :headers="headers"
    :items="households"
    :expanded.sync="expanded"
    item-key="householdName"
    show-expand
    class="responsive-table household-container"
  >
    <template v-slot:item.advisor="{ item }">
      <v-select
        hide-details="auto"
        :items="advisorsList"
        :value="item.firmRoles.primaryAdvisor"
        item-value="id"
        item-text="name"
        label="Select Advisor"
        :menu-props="{ offsetY: true }"
        outlined
        @input="handleAdvisorChange($event, item)"
      ></v-select>
    </template>
    <template v-slot:item.csa="{ item }">
      <v-select
        hide-details="auto"
        :items="csaList"
        :value="item.firmRoles.primaryCSA"
        item-text="name"
        item-value="id"
        label="Select CSA"
        :menu-props="{ offsetY: true }"
        outlined
        @input="handleCSAChange($event, item)"
      ></v-select>
    </template>

    <template v-slot:item.assetsValue="{ item }">
      <v-text-field
        outlined
        type="text"
        maxlength="256"
        name="assetsValue"
        aria-required="true"
        v-model.trim="item.assetsValue"
        :backgroundColor="inputBackgroundColor"
        @keyup.enter="updateHouseholdValue(item)"
        placeholder="Assets Value"
        hide-details="auto"
      />
    </template>

    <template v-slot:expanded-item="{ item }">
      <td :colspan="headers.length + 1">
        <v-data-table
          :headers="headersHouseholdMembers"
          :items="
            [item.primaryContact, item.secondaryContact].filter(
              (contact) => contact,
            )
          "
          hide-default-footer
          :hide-default-header="isMobile"
          loading
          class="clients-table v-data-table-header"
        >
          <template v-slot:item.firstName="{ item }">
            <v-text-field
              outlined
              type="text"
              maxlength="256"
              name="firstName"
              aria-required="true"
              v-model.trim="item.firstName"
              :backgroundColor="inputBackgroundColor"
              @keyup.enter="updateHouseholdName(item)"
              placeholder="First Name"
              hide-details="auto"
            />
          </template>
          <template v-slot:item.lastName="{ item }">
            <v-text-field
              outlined
              type="text"
              maxlength="256"
              name="name"
              aria-required="true"
              v-model.trim="item.lastName"
              :backgroundColor="inputBackgroundColor"
              @keyup.enter="updateHouseholdName(item)"
              placeholder="Last Name"
              hide-details="auto"
            />
          </template>
          <template v-slot:item.phone="{ item }">
            <v-text-field
              outlined
              type="phone"
              maxlength="256"
              name="phoneNumber"
              aria-required="true"
              v-model.trim="item.phone"
              :backgroundColor="inputBackgroundColor"
              @blur="$v.users.$each[props.index].phoneNumber.$touch()"
              @keyup.enter="updateHouseholdName(item)"
              placeholder="Phone Number"
              hide-details="auto"
            />
          </template>

          <template v-slot:item.email="{ item }">
            <v-text-field
              outlined
              type="email"
              maxlength="256"
              name="email"
              aria-required="true"
              v-model.trim="item.email"
              :backgroundColor="inputBackgroundColor"
              @blur="$v.users.$each[props.index].email.$touch()"
              @keyup.enter="updateHouseholdName(item)"
              placeholder="email"
              hide-details="auto"
            />
          </template>
        </v-data-table>
      </td>
    </template>
  </v-data-table>
</template>

<script>
import {
  NOTIFICATION_TYPES,
  NotificationService,
} from '@/services/notification.service';
import { GET_TEAM_USERS } from '@/stores/actions.type';
import { isEmpty } from 'lodash';

export default {
  props: {
    dataSource: {
      type: String,
      default: 'file',
    },
  },
  data: () => ({
    teamUsers: [],
    households: [],
    headers: [
      { text: 'Household Name', value: 'householdName', width: '25%' },
      { text: 'Advisor', value: 'advisor', width: '25%' },
      { text: 'CSA', value: 'csa', width: '25%' },
      { text: 'Household Value', value: 'assetsValue', width: '20%' },
    ],
    expanded: [],
    decisionDialog: false,
    smsDialog: false,
    isReadyToTransition: null,
    smsTemplate: null,
  }),
  computed: {
    transitionType() {
      return this.$store.getters.selectedTransitionType;
    },
    isProtocol() {
      return this.transitionType === 'Protocol';
    },
    advisorsList() {
      return this.teamUsers.filter(
        (user) => user.setupComplete && user.hasADV2B,
      );
    },
    csaList() {
      return this.teamUsers.filter((user) => user.setupComplete);
    },
    headersHouseholdMembers() {
      const baseHeaders = [
        { text: 'First Name', value: 'firstName' },
        { text: 'Last Name', value: 'lastName' },
        { text: 'Mobile', value: 'phone' },
      ];

      const protocolHeaders = [{ text: 'Email', value: 'email' }];

      return this.isProtocol
        ? baseHeaders.concat(protocolHeaders)
        : baseHeaders;
    },
  },

  created() {
    this.initializeFirmRoles();
    if (this.dataSource === 'csvFile') {
      this.fetchFromCsv();
    } else if (this.dataSource === 'root') {
      this.fetchFromRoot();
    }
  },

  methods: {
    handleAdvisorChange(selectedAdvisorId, item) {
      const selectedAdv = this.advisorsList.find(
        (adv) => adv.id === selectedAdvisorId,
      );
      if (selectedAdv)
        item.firmRoles.primaryAdvisor = {
          canSend: selectedAdv.canSend,
          name: selectedAdv.name,
          id: selectedAdv.id,
        };
    },
    handleCSAChange(selectedCSAId, item) {
      const selectedCSA = this.csaList.find((csa) => csa.id === selectedCSAId);
      if (selectedCSA)
        item.firmRoles.primaryCSA = {
          canSend: selectedCSA.canSend,
          name: selectedCSA.name,
          id: selectedCSA.id,
        };
    },
    fetchFromRoot() {
      this.households = this.$store.getters.transitionHouseholds;
      this.households = this.households.map((household) => {
        // Ensure primaryContact exists
        if (!household.primaryContact) {
          household.primaryContact = { firstName: '', lastName: '' };
        }
        // Remove secondaryContact if the firstName is empty
        if (
          isEmpty(household.secondaryContact) ||
          isEmpty(household.secondaryContact.firstName)
        ) {
          delete household.secondaryContact;
        }
        // Update the household name
        household.householdName = this.updateHouseholdName(household);

        return household;
      });
    },

    fetchFromCsv() {
      // Fetch and process the data when dataSource is 'csvFile'
      this.households = this.$store.getters.transitionHouseholds; // or however you fetch the data
      this.households = this.households.map((household) => ({
        ...household,
        householdName: this.updateHouseholdName(household),
      }));
    },

    updateHouseholdName(household) {
      const nameParts = [];
      if (
        household.primaryContact &&
        household.primaryContact.firstName &&
        household.primaryContact.firstName.trim() !== ''
      ) {
        nameParts.push(household.primaryContact.firstName);
      }
      if (
        household.secondaryContact &&
        household.secondaryContact.firstName &&
        household.secondaryContact.firstName.trim() !== ''
      ) {
        nameParts.push(household.secondaryContact.firstName);
      }

      // Assuming lastName is in primaryContact, adjust if your data structure is different
      return (
        nameParts.join(' & ') +
        (household.primaryContact.lastName
          ? ' ' + household.primaryContact.lastName
          : '')
      );
    },
    updateHouseholdValue(household) {
      household.assetsValue = Number(household.assetsValue);
      return household;
    },

    formatNumber(value) {
      return new Intl.NumberFormat().format(value);
    },

    async initializeFirmRoles() {
      this.$store
        .dispatch(GET_TEAM_USERS)
        .then((firmRoleUsers) => {
          firmRoleUsers.forEach((u) => {
            u.name = `${u.firstName} ${u.lastName}`;
          });
          this.teamUsers = firmRoleUsers;
                  })
        .catch((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: `Error getting users for team selection.`,
              okTitle: 'Ok',
            },
            response,
          );
        });
    },
  },
  components: {},
};
</script>

<style lang="scss" scoped>
.household-container {
  width: 100%;
  margin: 5% 0 20% 0;
  overflow: auto !important;

  &::deep {
    overflow: auto !important;
  }
}

.responsive-table::v-deep .clients-table {
  width: 95%;
  margin: 0 auto;

  thead {
    border-spacing: 0;
    background-color: var(--primary-color) !important;
    padding-bottom: 0 !important;
  }

  tbody {
    &:before {
      content: '-';
      display: block;
      line-height: 0.5em;
      color: transparent;
    }

    td {
      padding: 6px !important;
    }
  }
}

.title-section {
  display: flex;
  justify-content: center;
  font-family: Roboto;
  font-size: 40px;
  font-weight: 400;
  line-height: 47px;
  letter-spacing: 0em;
}
</style>
