/*
    VUEX Client Module
*/
import Storage from '@/services/storage.service';
import { replaceEndpointSlugsFromState } from '@/utils';
import { SESSION_KEYS, MENUS, DB_ACCOUNT_TYPES } from '@/common/constants';
import {
  UPDATE_INTERVIEW,
  UPLOAD_CLIENT_DOCUMENT,
  FINISH_INTERVIEW,
  STATEMENT_UPLOAD,
} from '@/stores/actions.type';
import { SET_CLIENT_PROGRESS } from '@/stores/mutations.type';
import ApiService from '../../services/api.service';
import { ActionApiRouteMapper } from '../actions.type';

// State Data
const state = {
  clientProgress: Storage.get(SESSION_KEYS.CLIENT_PROGRESS) || null,
};

// Getters
const getters = {
  clientProgress(state) {
    return state.clientProgress;
  },
  clientData(state) {
    return state.clientProgress
      ? state.clientProgress.clientData
      : { accounts: [] };
  },
};

const actions = {
  [UPDATE_INTERVIEW]({ rootState }, payload) {
    const url = replaceEndpointSlugsFromState(
      ActionApiRouteMapper.UPDATE_INTERVIEW,
      rootState,
      'interview',
    );

    return new Promise((resolve, reject) => {
      ApiService.patch(url, payload, UPDATE_INTERVIEW)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [UPLOAD_CLIENT_DOCUMENT]({ rootState }, payload) {
    const url = replaceEndpointSlugsFromState(
      ActionApiRouteMapper.UPLOAD_CLIENT_DOCUMENT,
      rootState,
      'interview',
    );

    let formData = new FormData();
    formData.append('files', payload.file);
    formData.append('documentName', payload.documentName);
    if (payload.accountId) {
      formData.append('accountId', payload.accountId);
    }
    if (payload.feature) {
      formData.append('feature', payload.feature);
    }

    return new Promise((resolve, reject) => {
      ApiService.put(url, formData)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [FINISH_INTERVIEW]({ rootState }) {
    const url = replaceEndpointSlugsFromState(
      ActionApiRouteMapper.FINISH_INTERVIEW,
      rootState,
      'interview',
    );

    return new Promise((resolve, reject) => {
      ApiService.post(url, undefined, FINISH_INTERVIEW)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [STATEMENT_UPLOAD](_, payload) {
    const url = ActionApiRouteMapper.STATEMENT_UPLOAD.replace(
      ':transitionsId',
      payload.transitionId,
    );

    let formData = new FormData();
    formData.append('statement', payload.file);
    return new Promise((resolve, reject) => {
      ApiService.post(`${url}?relatesTo=${payload.relatesTo}`, formData)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  /* Set the current user and store in session */
  [SET_CLIENT_PROGRESS](state, progress) {
    if (!state.clientProgress || !progress) {
      state.clientProgress = progress;
    } else {
      const { progress: oldInterviewPages } = state.clientProgress;
      const { progress: newInterviewPages } = progress;
      // Filter out the pages that were removed in the frontend flow
      state.clientProgress = {
        ...progress,
        progress: oldInterviewPages
          ? newInterviewPages.filter((p) =>
              oldInterviewPages.find((op) => op.pageName === p.pageName),
            )
          : newInterviewPages,
      };
    }
    if (progress === null) {
      state.clientData = null;
      Storage.remove(SESSION_KEYS.CLIENT_PROGRESS);
    } else {
      if (!state.clientProgress.clientData) {
        state.clientProgress.clientData = {
          applicantName: state.clientProgress.applicantName,
          coapplicantFirstName: state.clientProgress.coapplicantFirstName,
          coapplicantLastName: state.clientProgress.coapplicantLastName,
          primaryPhone: state.clientProgress.mobile,
          accounts: [],
        };
        const iraPages = state.clientProgress.progress.filter(
          (p) =>
            p.pageName === MENUS.CLIENT.ACCOUNT_PRIMARY_BENEFICIARIES.id &&
            p.instance === DB_ACCOUNT_TYPES.IRA,
        );
        iraPages.forEach((p) => {
          state.clientProgress.clientData.accounts.push({
            instance: {
              name: p.instance,
              label: p.label,
            },
          });
        });
        const rothIraPages = state.clientProgress.progress.filter(
          (p) =>
            p.pageName === MENUS.CLIENT.ACCOUNT_PRIMARY_BENEFICIARIES.id &&
            p.instance === DB_ACCOUNT_TYPES.ROTH_IRA,
        );
        rothIraPages.forEach((p) => {
          state.clientProgress.clientData.accounts.push({
            instance: {
              name: p.instance,
              label: p.label,
            },
          });
        });
      }
      Storage.set(SESSION_KEYS.CLIENT_PROGRESS, state.clientProgress);
    }
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
