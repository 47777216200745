<template>
  <div class="success-page">
    <h1>All clients ({{ transitionCount }}) added successfully</h1>
    <v-btn
      @click="goToDashboard"
      style="width: 64px; margin-top: 20px; align-self: center"
      >Done</v-btn
    >
  </div>
</template>

<script>
import { MENUS } from '../../../common/constants';

export default {
  props: {
    transitionCount: null,
  },
  methods: {
    goToDashboard() {
      this.$router.push({ name: MENUS.CLIENTS.id }).catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.success-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

h1 {
  font-family: Roboto;
  color: #5c5c5c;
  font-weight: 300;
  font-size: 38px;
  line-height: 46px;
  text-align: center;
}
</style>
