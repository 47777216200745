/*
  Auth VUEX Module
*/
import ApiService from '@/services/api.service';
import Storage from '@/services/storage.service';
import { SESSION_KEYS, LOCAL_KEYS, AUTH_TYPES } from '@/common/constants';
import { replaceEndpointSlugsFromState, replaceEndpointSlugs } from '@/utils';
import {
  LOGIN,
  VERIFY_CODE,
  LOGOUT,
  PING,
  GET_CURRENT_USER,
  SEND_PASSWORD_RESET_EMAIL,
  RESET_PASSWORD,
  SESSION_TIMEOUT,
  CHANGE_LOGIN_EMAIL,
  CONFIRM_LOGIN_EMAIL_CHANGE,
  INTERVIEW,
  AUTHENTICATE_INTERVIEW,
  GET_INTERVIEW_PROGRESS,
  SEND_DESKTOP_LINK,
  PURGE_AUTHENTICATED_INTERVIEW,
  ASSET,
  COMPANY_ASSETS,
  DOWNLOAD_COMPANY_ASSET,
  UPLOAD_COMPANY_ASSET,
  UPLOAD_LOGO,
  GET_ASSET,
  SET_NEW_PASSWORD,
  CHANGE_PASSWORD,
} from '@/stores/actions.type';
import {
  SET_CURRENT_USER,
  SET_AUTH,
  SET_CLIENT_AUTH,
  SET_TIMEOUT_FLAG,
  PURGE_AUTH,
  SET_SESSION_TOKEN,
  SET_CLIENT_PROGRESS,
  SET_EMAIL,
  SET_USER_ID,
} from '@/stores/mutations.type';
import { ActionApiRouteMapper } from '../actions.type';

// State Data
const state = {
  isClientAuthenticated: Storage.get(SESSION_KEYS.IS_CLIENT_AUTHENTICATED),
  isAuthenticated: Storage.get(SESSION_KEYS.IS_AUTHENTICATED),
  currentUser: Storage.get(SESSION_KEYS.CURRENT_USER),
  sessionToken: Storage.get(SESSION_KEYS.SESSION_TOKEN),
  phone: Storage.get(SESSION_KEYS.CURRENT_PHONE),
  sessionTimedOut: false,
};

// Getters
const getters = {
  isClientAuthenticated(state) {
    return state.isClientAuthenticated;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  currentUser(state) {
    return state.currentUser;
  },
  currentUserRole(state) {
    return state.currentUser && state.currentUser.roles
      ? state.currentUser.roles[0]
      : null;
  },
  phone(state) {
    return state.phone;
  },
  sessionToken(state) {
    return state.sessionToken;
  },
  sessionTimedOut(state) {
    return state.sessionTimedOut;
  },
};

const actions = {
  [AUTHENTICATE_INTERVIEW](context, token) {
    return new Promise((resolve, reject) => {
      // If authentication with a token (unauthenticated client)
      // Purge logged in user data
      if (token && token.length > 0) {
        context.commit(SET_CLIENT_PROGRESS, null, { root: true });
        context.commit(SET_TIMEOUT_FLAG, false);
        context.commit(PURGE_AUTH);
        Storage.setLocal(
          LOCAL_KEYS.AUTH_TYPE,
          AUTH_TYPES.UNAUTHENTICATED_CLIENT,
        );
      } else {
        // Impersonating Client
        // Only purge client data
        context.commit(SET_CLIENT_AUTH, false);
        context.commit(SET_CLIENT_PROGRESS, null, { root: true });
      }
      context.commit(SET_SESSION_TOKEN, token);

      ApiService.get(`${ActionApiRouteMapper.INTERVIEW}/${token}`, INTERVIEW)
        .then((data) => {
          context.commit(SET_CLIENT_AUTH, true);
          context.commit(SET_CLIENT_PROGRESS, data, { root: true });
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_INTERVIEW_PROGRESS]({ commit, rootState }) {
    const url = replaceEndpointSlugsFromState(
      ActionApiRouteMapper.GET_INTERVIEW_PROGRESS,
      rootState,
      'interview',
    );

    return new Promise((resolve, reject) => {
      ApiService.get(url, GET_INTERVIEW_PROGRESS)
        .then((data) => {
          commit(SET_CLIENT_PROGRESS, data, { root: true });
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [SEND_DESKTOP_LINK](context, token) {
    // Do we care about success false?
    return new Promise((resolve) => {
      ApiService.query(SEND_DESKTOP_LINK, { access_token: token })
        .catch(() => {})
        .finally(() => {
          resolve();
        });
    });
  },
  [PURGE_AUTHENTICATED_INTERVIEW](context) {
    context.commit(SET_CLIENT_AUTH, false);
    context.commit(SET_CLIENT_PROGRESS, null, { root: true });
  },

  /* LOGIN */
  [LOGIN]({ dispatch, commit }, credentials) {
    return new Promise((resolve, reject) => {
      const url = ActionApiRouteMapper.LOGIN;
      const { email, password } = credentials || {};

      if (!email || !password) {
        reject('Invalid Credentials');
      }
      // Force ping to reset potentially expired XSRF cookie
      dispatch(PING)
        .catch(() => {})
        .finally(() => {
          // Now perform login
          commit(SET_TIMEOUT_FLAG, false);
          commit(PURGE_AUTH);
          Storage.setLocal(LOCAL_KEYS.AUTH_TYPE, AUTH_TYPES.USER);

          ApiService.post(url, {
            email,
            password,
          })
            .then((data) => {
              const { session, mfaRequired, newPasswordRequired } = data || {};

              // it's a new user who needs to setup a new password
              if (newPasswordRequired && session) {
                resolve(data);
              }

              const { advisorId, organisationId } = data;

              if (mfaRequired) {
                commit(SET_SESSION_TOKEN, session);
              } else {
                commit(SET_USER_ID, { advisorId, organisationId });
                commit(SET_AUTH);
              }

              commit(SET_EMAIL, email);

              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        });
    });
  },

  /* VERIFY Access Code */
  [VERIFY_CODE]({ state, commit }, code) {
    return new Promise((resolve, reject) => {
      const url = ActionApiRouteMapper.VERIFY_CODE;
      const { sessionToken: session, email } = state;

      ApiService.post(url, {
        mfaCode: code,
        session,
        email,
      })
        .then((response) => {
          const { advisorId, organisationId } = response || {};

          if (!advisorId || !organisationId) {
            reject('Invalid Response');
          }

          commit(SET_USER_ID, { advisorId, organisationId });
          commit(SET_AUTH);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /* Logout */
  [LOGOUT]({ commit, state }) {
    return new Promise((resolve, reject) => {
      const url = ActionApiRouteMapper.LOGOUT;
      const { email } = state.currentUser.user || {};

      if (!email) {
        reject('Missing email');
      }

      ApiService.post(url, { email })
        .then(() => {
          commit(PURGE_AUTH);
          resolve();
        })
        .catch((error) => {
          commit(PURGE_AUTH);
          reject(error);
        });
    });
  },

  /* GET Current User */
  [GET_CURRENT_USER]({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      const url = replaceEndpointSlugsFromState(
        ActionApiRouteMapper.GET_CURRENT_USER,
        rootState,
      );

      ApiService.get(url, GET_CURRENT_USER)
        .then((data) => {
          commit(SET_CURRENT_USER, data);
          resolve(data);
        })
        .catch((error) => {
          commit(PURGE_AUTH);
          reject(error);
        });
    });
  },

  /* Ping for session alive */
  /* eslint-disable */
  [PING](context, timeoutOnFail = false) {
    return new Promise((resolve) => {
      const url = ActionApiRouteMapper.PING;
      let pingFail = false;

      ApiService.get(url)
        .then((response) => (pingFail = response && response.status === 401))
        .catch(() => (pingFail = true))
        .finally((r) => {
          // If Ping Failure and PING failure requires a timeout
          if (pingFail && timeoutOnFail) {
            context.commit(PURGE_AUTH);
            context.commit(SET_TIMEOUT_FLAG, true);
          }
          resolve();
        });
    });
  },

  [SEND_PASSWORD_RESET_EMAIL](context, payload) {
    return new Promise((resolve, reject) => {
      const url = ActionApiRouteMapper.SEND_PASSWORD_RESET_EMAIL;

      ApiService.post(url, payload)
        .then((response) => {
          context.commit(SET_EMAIL, payload.email);
          resolve(response);
        })
        .catch((error) => {
          reject(
            error.response
              ? error.response.data && error.response.data.exceptionMessage
              : error
          );
        });
    });
  },

  [RESET_PASSWORD](context, payload) {
    return new Promise((resolve, reject) => {
      const url = ActionApiRouteMapper.RESET_PASSWORD;
      const { email } = context.state;

      ApiService.post(url, { ...payload, email })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [SET_NEW_PASSWORD](context, payload) {
    return new Promise((resolve, reject) => {
      const url = ActionApiRouteMapper.SET_NEW_PASSWORD;
      const { email } = context.state;

      ApiService.post(url, {
        ...payload,
        email,
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [CHANGE_PASSWORD](context, payload) {
    return ApiService.post(ActionApiRouteMapper.CHANGE_PASSWORD, payload);
  },

  [CHANGE_LOGIN_EMAIL](context, payload) {
    return new Promise((resolve, reject) => {
      const { advisorId, organisationId } = payload;
      const url = replaceEndpointSlugs(
        ActionApiRouteMapper.CHANGE_LOGIN_EMAIL,
        { advisorId, organisationId }
      );

      ApiService.put(url, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [CONFIRM_LOGIN_EMAIL_CHANGE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(CONFIRM_LOGIN_EMAIL_CHANGE, payload)
        .then(({ data }) => {
          if (data.success) {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // Session Timeout
  [SESSION_TIMEOUT]({ state, commit }, timeoutFlag = true) {
    if (!state.isAuthenticated) {
      // If the user is already logged out, there's no need to continue
      return Promise.resolve();
    }

    return new Promise((resolve) => {
      if (timeoutFlag === false) {
        // Reset timeout
        commit(SET_TIMEOUT_FLAG, timeoutFlag);
        resolve();
      } else {
        const url = ActionApiRouteMapper.LOGOUT;
        ApiService.post(url).finally(() => {
          commit(PURGE_AUTH);
          commit(SET_TIMEOUT_FLAG, timeoutFlag);
          resolve();
        });
      }
    });
  },
  /* Get all Organization Assets */
  [COMPANY_ASSETS](_, payload) {
    const url = replaceEndpointSlugs(
      ActionApiRouteMapper.COMPANY_ASSETS,
      {
        organisationId: payload.organisationId,
      }
    );
    return ApiService.get(url, COMPANY_ASSETS);
  },

  /* Get asset */
  [GET_ASSET](context, assetKey) {
    return ApiService.get(`${ASSET}/${assetKey}`);
  },

  /* Download Asset */
  [DOWNLOAD_COMPANY_ASSET](_, payload) {
    const url = replaceEndpointSlugs(ActionApiRouteMapper.DOWNLOAD_COMPANY_ASSET, {
      organisationId: payload.organisationId,
    });
    return ApiService.get(`${url}?assetId=${payload.assetId}`);
  },

  /* Upload asset */
  [UPLOAD_COMPANY_ASSET](_, payload) {
    const url = replaceEndpointSlugs(ActionApiRouteMapper.UPLOAD_COMPANY_ASSET, {
      organisationId: payload.organisationId,
    });

    let formData = new FormData();
    formData.append('file', payload.file);
    formData.append('assetType', payload.assetKey);
    return ApiService.upload(url, formData);
  },

  [UPLOAD_LOGO](_, payload) {
    const url = replaceEndpointSlugs(ActionApiRouteMapper.UPLOAD_LOGO, {
      organisationId: payload.organisationId,
    });

    let formData = new FormData();
    formData.append('logo', payload.file);
    return ApiService.upload(url, formData);
  },
};

const mutations = {
  /* Set isClientAuthenticated flag */
  [SET_CLIENT_AUTH](state, val) {
    state.isClientAuthenticated = val;
    Storage.set(
      SESSION_KEYS.IS_CLIENT_AUTHENTICATED,
      state.isClientAuthenticated
    );
  },

  /* Set isAuthenticated flag */
  [SET_AUTH](state) {
    state.isAuthenticated = true;
    Storage.set(SESSION_KEYS.IS_AUTHENTICATED, state.isAuthenticated);
  },

  /* Set Session Token */
  [SET_SESSION_TOKEN](state, tok) {
    state.sessionToken = tok;
    Storage.set(SESSION_KEYS.SESSION_TOKEN, state.sessionToken);
  },

  [SET_EMAIL](state, email) {
    state.email = email;
  },

  /* Set current user id and organisation id for authentication purposes */
  [SET_USER_ID](state, { advisorId, organisationId }) {
    state.currentUser = {
      ...state.currentUser,
      user: {
        ...state.currentUser.user,
        id: advisorId,
      },
      organisation: {
        ...state.currentUser.organisation,
        id: organisationId,
      },
    };
    Storage.set(SESSION_KEYS.USER_ID, state.currentUser.user.id);
  },

  /* Clear isAuthenticated flag and current user/session storage  */
  [PURGE_AUTH](state) {
      state.isAuthenticated = false;
      state.isClientAuthenticated = false;
      state.currentUser = {};
  
      // Clear session Storage
      Storage.purge();
  
      // Clear all cookies
      const cookies = document.cookie.split(";");
  
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
      }
  },

  /* Set session timed out flag */
  [SET_TIMEOUT_FLAG](state, timeout) {
    state.sessionTimedOut = timeout;
  },

  /* Set the current user and store in session */
  [SET_CURRENT_USER](state, user) {
    state.currentUser = {
      ...user,
    };
    Storage.set(SESSION_KEYS.CURRENT_USER, state.currentUser);
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
