<template>
  <div class="auth-container">
    <div class="inner-container">
      <div class="logo-container">
        <img
          :src="companyLogo"
          width="140"
          loading="lazy"
          alt="OnBord"
          class="logo"
          v-if="companyLogo"
        />
      </div>
      <div v-if="flag === CLIENT_EXIT_KEYS.EXIT_COMPLETE">
        <h1>
          Congratulations!
          <span class="headerlight">
            You've completed all of the questions from your advisor.
          </span>
        </h1>
        <p class="paragraph">
          Now that you're done, you will no longer be able to access the
          questions that have been submitted. If you would like to change any of
          your previous answers, we recommend that you contact your financial
          advisor to have the changes made manually.
        </p>
      </div>
      <div v-if="flag === CLIENT_EXIT_KEYS.EXIT_INCOMPLETE_SAVE">
        <h1>
          Thanks
          <span class="headerlight">
            for working through the questions for your advisor!
          </span>
        </h1>
        <p class="paragraph">
          You’ve skipped a few that your advisor still needs. We will remind you
          tomorrow in case you forget to come back. If you would like to change
          any of your previous answers you can do that, too.
        </p>
      </div>
      <div v-if="flag === CLIENT_EXIT_KEYS.EXIT_INCOMPLETE_EXIT">
        <h1>
          You’re not quite done yet,
          <span class="headerlight">
            please come back when you’re ready to finish.
          </span>
        </h1>
        <p class="paragraph">
          We will remind you tomorrow in case you forget to come back. If you
          would like to change any of your previous answers you can do that,
          too.
        </p>
      </div>
      <div v-if="flag === CLIENT_EXIT_KEYS.EXIT_SESSION_TIMEOUT">
        <h1>
          You’ve been timed out due to inactivity
          <span class="headerlight">
            you can click the link in your original message to jump back in and
            finish up.
          </span>
        </h1>
        <p class="paragraph">
          If you would like to change any of your previous answers you can do
          that, too.
        </p>
      </div>
      <div v-if="flag === CLIENT_EXIT_KEYS.EXIT_CANT_PROGRESS">
        <h1>
          If you are not a US Citizen or Legal Resident,
          <span class="headerlight">
            you cannot complete this process online.
          </span>
        </h1>
        <p class="paragraph center">
          Your advisor will contact you to finish up.
        </p>
      </div>
      <div v-if="flag === CLIENT_EXIT_KEYS.EXIT_NOT_AUTHORIZED">
        <h1>
          You must be authenticated
          <span class="headerlight"> to access the questions. </span>
        </h1>
        <p class="paragraph">
          Please click on the link provided in the email sent to you by your
          advisor, or contact your advisor if you are having issues accessing
          the questions.<br /><br />If you have already completed all your
          questions, you will no longer be able to access the questions that
          have been submitted. If you would like to change any of your previous
          answers, we recommend that you contact your financial advisor to have
          the changes made manually.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import PublicAssets from '@/common/mixins/publicasset.mixin';
import { CLIENT_EXIT_KEYS, LOCAL_KEYS } from '@/common/constants';
import Storage from '@/services/storage.service';

export default {
  name: 'Unauthenticated',
  data: () => ({
    companyLogo: null,
  }),
  props: {
    flag: String,
    default: CLIENT_EXIT_KEYS.EXIT_NOT_AUTHORIZED,
  },
  mixins: [PublicAssets],
  created() {
    this.CLIENT_EXIT_KEYS = CLIENT_EXIT_KEYS;
    this.companyLogo = Storage.getLocal(LOCAL_KEYS.COMPANY_LOGO) || null;
  },
};
</script>
<style lang="scss" scoped>
.auth-container {
  align-items: flex-start;

  .inner-container {
    padding-top: 100px;
    max-width: 1024px;

    .paragraph {
      padding-top: 20px;

      &.center {
        text-align: center;
      }
    }

    @media screen and (max-width: 960px) {
      padding-top: 0px;
    }
  }
}
</style>
