import { FORMATTERS } from '../../../common/formats';
import { CLIENT_STATUSES } from '@/common/constants';

export function clientsMapper(response) {
  return response.map(
    ({
      _id,
      firstName,
      lastName,
      contactType,
      primaryContact,
      secondaryContact,
      primaryAdvisor,
      readyToSend,
      status,
      belongsToHousehold,
      fileUploadsNo,
      completionPercentage,
      archivedAt,
      secondaryAdvisor,
    }) => {
      const {
        id: repId,
        firstName: repFirstName,
        lastName: repLastName,
      } = primaryAdvisor || {};
      const contact =
        contactType === 'Primary' ? primaryContact : secondaryContact;

      const isCompletedStatus =
        contact.interviewComplete &&
        status !== 'Sent' &&
        status !== 'PendingReview' &&
        status !== 'WaitingSignature';

      const isComplete = ['Complete', 'Signed'].some((k) => k === status);

      let contactStatus = {
        Sent: CLIENT_STATUSES.SENT,
        Draft: CLIENT_STATUSES.DRAFT,
        Ready: CLIENT_STATUSES.READY,
        Complete: CLIENT_STATUSES.CLIENT_COMPLETE,
        PendingReview: CLIENT_STATUSES.PENDING_DOCUSIGN_REVIEW,
        WaitingSignature: CLIENT_STATUSES.WAITING_FOR_SIGNATURE,
        Signed: CLIENT_STATUSES.SIGNED,
        Rejected: CLIENT_STATUSES.REJECTED,
        Archived: CLIENT_STATUSES.ARCHIVED,
      }[status];

      if (archivedAt) {
        contactStatus = CLIENT_STATUSES.ARCHIVED;
      }

      if (
        !contact.interviewComplete &&
        status !== 'Draft' &&
        status !== 'Ready'
      ) {
        contactStatus = CLIENT_STATUSES.SENT;
      }

      const client = {
        id: _id,
        contactType,
        firstName,
        lastName,
        familyName: primaryContact.firstName,
        repFirstName,
        repLastName,
        repId,
        canSend: readyToSend,
        status: contactStatus,
        isArchived: archivedAt ? true : false,
        signatureRequested: status === 'WaitingSignature',
        isOnbordComplete: isComplete,
        uploads: fileUploadsNo || 0,
        completed: isCompletedStatus,
        interviewCompleted: 39, // TODO: Add this field to the response
        interviewTotal: 39, // TODO: Add this field to the response
        completionPercent: completionPercentage,
        provideAdvice: secondaryAdvisor,
        belongsToHousehold,
        lastContactActivityAt: FORMATTERS.dateTime(contact.lastActivityAt),
      };

      return client;
    },
  );
}
