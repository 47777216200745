<template>
  <company-table></company-table>
</template>

<script>
import CompanyTable from '@/components/advisor/tables/CompanyTable';

export default {
  name: 'CompanyList',

  components: {
    CompanyTable,
  },

  data: () => ({}),

  methods: {},
  created() {},
};
</script>
<style lang="scss" scoped></style>
