<template>
  <v-container class="client-step-container">
    <v-row class="mb-8">
      <v-col class="instructions">
        <h1>
          <span class="headerlight">{{ question }}</span>
        </h1>
      </v-col>
    </v-row>
    <v-row class="mb-8">
      <v-col>
        <v-checkbox
          v-for="(option, index) in options"
          :key="index"  
          multiple 
          v-model="answer"
          hide-details
          :color="primaryColor"
          :ripple="false"
          :value="option"
          @change="updateAnswer"
        >
          <template v-slot:label>
            <span class="option-label">{{option}}</span>
          </template>
        </v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <div v-if="answerErrors.length" class="error-message">
          <span v-for="error in answerErrors" :key="error" class="error-message">{{ error }}</span> <br />
      </div>
    </v-row>
  </v-container>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';

import InterviewClientMixin from '@/common/mixins/interview.client.mixin';
import Themable from '@/common/mixins/themable.mixin';
import { EventService } from '@/services/event.service';

export default {
  name: 'CheckboxGroup',
  props: {
    question: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => []
    },
  },
  mixins: [InterviewClientMixin, Themable],
  validations() {
    const rules = {
      answer: {}
    };
    if (this.required) {
      rules.answer.required = required;
      rules.answer.minLength = minLength(1)
    }
    return rules;
  },
  data() {
    return {
      answer: []
    }
  },
  methods: {
    updateAnswer() {
      this.$v.answer.$touch();
      EventService.emit('update-answer', this.answer.join(","));
    },
    validate() {
      this.$v.$touch();
      if (!this.required && this.answer.length === 0) {
        return true;
      }
      return !this.$v.$invalid;
    },
  },
  computed: {
    answerErrors() {
      const errors = [];
      if (!this.$v.answer.$dirty) return errors;
      if (this.required && !this.$v.answer.required) errors.push('Selection is required')
      if (this.required && !this.$v.answer.minLength) errors.push('At least one option must be selected');
      return errors;
    },
  
  }
}
</script>

<style>

.option-label {
  padding-top: 5px;
  text-transform: capitalize;
  font-size: 18px !important;
}

.error-message {
  color: var(--error-color);
  font-size: 14px;;
}
</style>
