<template>
  <div class="step-container advisor">
    <help-drawer :title="helpTitle">
      <div>
        <p>
        <ul class="help-list">
          <li><strong>Primary Advisor</strong> &mdash;
            The primary advisor that formulates investment advice for the client/s and has direct client contact. Only one
            advisor may be given this role on this screen. The ADV part 2B for the selected advisor will be sent to the
            client/s.
          </li>
          <li><strong>Provides Advice</strong> &mdash;
            Any other advisor who formulates investment advice for the client/s and has direct client contact. Multiple
            advisors may be given this role on this screen. The ADV part 2B for all advisors selected will be sent to the
            client/s.
          </li>
          <li><strong>Primary CSA</strong> &mdash;
            The primary client service advisor who will handle communications with the client/s but will not be providing
            financial advice. Only one CSA may be given this role on this screen.
          </li>
          <li><strong>Secondary CSA</strong> &mdash;
            Any other client service advisors with access to this client/s.
          </li>
        </ul>
        </p>
      </div>
    </help-drawer>
    <v-form name="FirmRoles" class="form">
      <v-container class="teams-step-container">
        <v-row class="mb-8">
          <v-col class="instructions">
            <h1>
              <span class="headerlight">
                Select <strong>Firm Roles</strong>
                <span v-if="!missingClients">
                  for <strong>{{ clientNames }}</strong>
                </span>
              </span>
            </h1>

            <div class="help-link">
              <a @click="toggleHelp()"> {{ helpTitle }} </a>
            </div>
          </v-col>
        </v-row>
        <!-- No Firm Roles -->
        <div class="alert" v-if="missingClients">
          <div class="pr-2">
            <span class="fa-icon left">
              <i class="fas fa-exclamation-triangle"></i>
            </span>
          </div>
          <div>
            {{ missingClientsText }}
          </div>
        </div>
        <div class="justify-center teams-table" v-if="!missingClients">
          <v-row class="header-row">
            <v-col class="col-4 first">
              <h4> OnBord User </h4>
            </v-col>
            <v-col class="text-center">
              <h4>Role</h4>
              <span class="small">(Exactly ONE Primary Advisor and ONE Primary CSA MUST be selected)</span>
            </v-col>
          </v-row>
          <v-row v-for="(user, idx) in teamUsers" :key="user.id">
            <v-col class="col-4 first name">
              {{ user.name }}
            </v-col>
            <v-col>
              <v-row>
                <v-col>
                  <v-btn-toggle v-model="user.advisorRole" @change="advisorChanged(idx, $event)">

                    <v-btn small class="text-capitalize" v-if="user.hasADV2B && user.setupComplete"
                      :value="FIRM_ROLE_TYPES.PRIMARY_ADVISOR">
                      <font-awesome-icon size="2x" class="role-icon" icon="comments-dollar"></font-awesome-icon>
                      Primary Advisor
                    </v-btn>
                    <!-- We create a fake button as the else instead of making a disabled button so we can add a working tooltip -->
                    <div v-else class="v-btn v-btn--flat theme--light v-size--small text-capitalize"
                      style="color: rgba(0, 0, 0, 0.26)">
                      <v-tooltip top transition="none">
                        <template v-slot:activator="{ on }">
                          <div v-on="on">
                            <font-awesome-icon size="lg" class="role-icon" :style="{ color: errorColor }"
                              icon="exclamation-triangle"></font-awesome-icon>
                          </div>
                        </template>
                        <span v-html="getTooltipText(user, FIRM_ROLE_TYPES.PRIMARY_ADVISOR)"></span>
                      </v-tooltip>
                      Primary Advisor
                    </div>

                    <v-btn small class="text-capitalize" v-if="user.hasADV2B && user.setupComplete"
                      :value="FIRM_ROLE_TYPES.PROVIDES_ADVICE">
                      <font-awesome-icon size="2x" class="role-icon" style="color: rgb(64, 64, 96);"
                        icon="comments-dollar"></font-awesome-icon>
                      Provides Advice
                    </v-btn>
                    <!-- We create a fake button as the else instead of making a disabled button so we can add a working tooltip -->
                    <div v-else class="v-btn v-btn--flat theme--light v-size--small text-capitalize"
                      style="color: rgba(0, 0, 0, 0.26)">
                      <v-tooltip top transition="none">
                        <template v-slot:activator="{ on }">
                          <div v-on="on">
                            <font-awesome-icon size="lg" class="role-icon" :style="{ color: errorColor }"
                              icon="exclamation-triangle"></font-awesome-icon>
                          </div>
                        </template>
                        <span v-html="getTooltipText(user, FIRM_ROLE_TYPES.PROVIDES_ADVICE)"></span>
                      </v-tooltip>
                      Provides Advice
                    </div>
                  </v-btn-toggle>
                </v-col>

                <v-col>
                  <v-btn-toggle v-model="user.csaRole" @change="csaChanged(idx, $event)">
                    <v-btn small class="text-capitalize" v-if="user.setupComplete" :value="FIRM_ROLE_TYPES.PRIMARY_CSA">
                      <font-awesome-icon size="2x" class="role-icon" icon="user-headset"></font-awesome-icon>
                      Primary CSA
                    </v-btn>
                    <!-- We create a fake button as the else instead of making a disabled button so we can add a working tooltip -->
                    <div v-else class="v-btn v-btn--flat theme--light v-size--small text-capitalize"
                      style="color: rgba(0, 0, 0, 0.26)">
                      <v-tooltip top transition="none">
                        <template v-slot:activator="{ on }">
                          <div v-on="on">
                            <font-awesome-icon size="lg" class="role-icon" :style="{ color: errorColor }"
                              icon="exclamation-triangle"></font-awesome-icon>
                          </div>
                        </template>
                        <span v-html="getTooltipText(user, FIRM_ROLE_TYPES.PRIMARY_CSA)"></span>
                      </v-tooltip>
                      Primary CSA
                    </div>

                    <v-btn small class="text-capitalize" v-if="user.setupComplete" :value="FIRM_ROLE_TYPES.SECONDARY_CSA">
                      <font-awesome-icon size="2x" class="role-icon" icon="user-headset"
                        style="color: rgb(64, 64, 96);"></font-awesome-icon>
                      Secondary CSA
                    </v-btn>
                    <!-- We create a fake button as the else instead of making a disabled button so we can add a working tooltip -->
                    <div v-else class="v-btn v-btn--flat theme--light v-size--small text-capitalize"
                      style="color: rgba(0, 0, 0, 0.26)">
                      <v-tooltip top transition="none">
                        <template v-slot:activator="{ on }">
                          <div v-on="on">
                            <font-awesome-icon size="lg" class="role-icon" :style="{ color: errorColor }"
                              icon="exclamation-triangle"></font-awesome-icon>
                          </div>
                        </template>
                        <span v-html="getTooltipText(user, FIRM_ROLE_TYPES.SECONDARY_CSA)"></span>
                      </v-tooltip>
                      Secondary CSA
                    </div>
                  </v-btn-toggle>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <div v-if="selectionErrors.length > 0">
          <v-row class="step-form-container mobile align-center " v-for="error in selectionErrors" :key="error">
            <v-col class="error-area">
              {{ error }}
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-form>
    <bottom-nav v-on:go-prev="prev()" v-on:go-next="next()"></bottom-nav>
  </div>
</template>
<script>
import BottomNav from "@/components/common/BottomNav";
import HelpDrawer from "@/components/HelpDrawerComponent";
import AdvisorClientMixin from "@/common/mixins/advisor.client.mixin";
import { MENUS, FIRM_ROLE_TYPES } from "@/common/constants";
import { GET_TEAM_USERS } from "@/stores/actions.type";
import {
  NOTIFICATION_TYPES,
  NotificationService,
} from "@/services/notification.service";

const primaryRequired = (value, vm) => {
  if (vm.missingFirstClient || vm.missingSecondClient) return true;
  const primary = vm.teamUsers.find((u) => u.advisorRole === FIRM_ROLE_TYPES.PRIMARY_ADVISOR);
  return primary !== undefined;
};

const csaRequired = (value, vm) => {
  if (vm.missingFirstClient || vm.missingSecondClient) return true;
  const csa = vm.teamUsers.find((u) => u.csaRole === FIRM_ROLE_TYPES.PRIMARY_CSA);
  return csa !== undefined;
};

export default {
  name: "FirmRoles",

  mixins: [AdvisorClientMixin],
  components: { BottomNav, HelpDrawer },

  data: () => ({
    teamUsers: []
  }),

  validations() {
    return {
      selections: {
        primaryRequired,
        csaRequired,
      },
    };
  },

  computed: {
    /* Dynamic errors */
    selectionErrors() {
      let errors = [];
      if (!this.$v.selections.$dirty) return errors;
      if (!this.$v.selections.primaryRequired)
        errors.push("A Primary Advisor is required");
      if (!this.$v.selections.csaRequired)
        errors.push("A Primary CSA is required");
      return errors;
    },
    missingFirstClient() {
      return this.clientOneName.length === 0;
    },
    missingSecondClient() {
      return this.addedSecondClient && !this.isClientTwoNameComplete;
    },
    missingClients() {
      return this.missingFirstClient || this.missingSecondClient;
    },
    missingClientsText() {
      if (this.missingFirstClient && this.missingSecondClient) {
        return "You must complete the first and second client name before choosing firm roles.";
      } else if (this.missingFirstClient) {
        return "You must complete the first client name before choosing firm roles.";
      } else if (this.missingSecondClient) {
        return "You must complete the second client name before choosing firm roles."
      }

      return "";
    },
    clientNames() {
      return this.isClientTwoNameComplete
        ? `${this.clientOneName}, ${this.clientTwoName}`
        : this.clientOneName;
    },
    helpTitle() {
      return "What does this mean?";
    },
  },

  methods: {
    advisorChanged(newAdvisorIndex, newRoleValue) {
      // Marking the form as touched for validation purposes
      this.$v.$touch();

      // Change the role to null for others if the new role is PRIMARY_ADVISOR
      if (newRoleValue === FIRM_ROLE_TYPES.PRIMARY_ADVISOR) {
        this.teamUsers.forEach((teamMember, index) => {
          if (newAdvisorIndex !== index && teamMember.advisorRole === FIRM_ROLE_TYPES.PRIMARY_ADVISOR) {
            teamMember.advisorRole = null;
          }
        });
      }
    },

    csaChanged(newCsaIndex, newRoleValue) {
      // Again, marking the form as touched
      this.$v.$touch();

      // Change the role to null for others if the new role is PRIMARY_CSA
      if (newRoleValue === FIRM_ROLE_TYPES.PRIMARY_CSA) {
        this.teamUsers.forEach((teamMember, index) => {
          if (newCsaIndex !== index && teamMember.csaRole === FIRM_ROLE_TYPES.PRIMARY_CSA) {
            teamMember.csaRole = null;
          }
        });
      }
    },
    adv2bChanged() {
      this.$v.$touch();
    },
    getTooltipText(user, type) {
      let text = "";
      if (type === FIRM_ROLE_TYPES.PRIMARY_CSA || type === FIRM_ROLE_TYPES.SECONDARY_CSA) {
        if (!user.setupComplete) {
          text = "This user cannot be designated a CSA<br>until they have finished completing their profile.";
        }
      } else if (type === FIRM_ROLE_TYPES.PRIMARY_ADVISOR) {
        if (!user.setupComplete) {
          text =
            "This user cannot be designated a Primary Advisor<br>until they have finished completing their profile.";
        } else if (!user.hasADV2B) {
          text =
            "This user cannot be designated a Primary Advisor<br>until your Company Adminstrator uploads their ADV 2B form.";
        }
      } else if (type === FIRM_ROLE_TYPES.PROVIDES_ADVICE) {
        if (!user.setupComplete) {
          text =
            "This user cannot be designated an Advisor providing advice<br>until they have finished completing their profile.";
        } else if (!user.hasADV2B) {
          text =
            "This user cannot be designated an Advisor providing advice<br>until your Company Adminstrator uploads their ADV 2B form.";
        }
      }

      return text;
    },

    /* Initialize Firm Roles*/
    initializeFirmRoles() {
      this.$store
        .dispatch(GET_TEAM_USERS)
        .then((response) => {
          response.forEach((u) => {
            u.name = `${u.firstName} ${u.lastName}`;
            if (
              this.currentClient.firmRoles &&
              this.currentClient.firmRoles.primaryAdvisor &&
              this.currentClient.firmRoles.primaryAdvisor.id === u.id
            )
              u.advisorRole = FIRM_ROLE_TYPES.PRIMARY_ADVISOR;
            else if (
              this.currentClient.firmRoles &&
              this.currentClient.firmRoles.provideAdvice &&
              this.currentClient.firmRoles.provideAdvice.find(
                (p) => p.id === u.id
              ) !== undefined
            )
              u.advisorRole = FIRM_ROLE_TYPES.PROVIDES_ADVICE;
            if (
              this.currentClient.firmRoles &&
              this.currentClient.firmRoles.primaryCSA &&
              this.currentClient.firmRoles.primaryCSA.id === u.id
            )
              u.csaRole = FIRM_ROLE_TYPES.PRIMARY_CSA;
            else if (
              this.currentClient.firmRoles &&
              this.currentClient.firmRoles.secondaryCSA &&
              this.currentClient.firmRoles.secondaryCSA.find(
                (p) => p.id === u.id
              ) !== undefined
            )
              u.csaRole = FIRM_ROLE_TYPES.SECONDARY_CSA;
          });
          this.teamUsers = response;
        })
        .catch((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.SUCCESS,
              title: "Error",
              message: `Error getting users for team selection.`,
              okTitle: "Ok",
            },
            response
          );
        });
    },

    /* Set the Firm Roles */
    setFirmRoles() {
      // Initialize firm roles if they don't exist
      if (!this.currentClient.firmRoles) {
        this.currentClient.firmRoles = {
          primaryAdvisor: null,
          primaryCSA: null,
          provideAdvice: [],
          secondaryCSA: [],
        };
      }

      // Set the primary advisor
      const primaryAdvisor = this.teamUsers.find(user => user.advisorRole === FIRM_ROLE_TYPES.PRIMARY_ADVISOR);
      this.currentClient.firmRoles.primaryAdvisor = primaryAdvisor
        ? { id: primaryAdvisor.id, name: primaryAdvisor.name, canSend: primaryAdvisor.canSend }
        : null;

      // Set the primary CSA
      const primaryCSA = this.teamUsers.find(user => user.csaRole === FIRM_ROLE_TYPES.PRIMARY_CSA);
      this.currentClient.firmRoles.primaryCSA = primaryCSA
        ? { id: primaryCSA.id, name: primaryCSA.name, canSend: primaryCSA.canSend }
        : null;

      // Collect all team members who provide advice
      this.currentClient.firmRoles.provideAdvice = this.teamUsers
        .filter(user => user.advisorRole === FIRM_ROLE_TYPES.PROVIDES_ADVICE)
        .map(user => ({ id: user.id, name: user.name, canSend: user.canSend }));

      // Collect all secondary CSAs
      this.currentClient.firmRoles.secondaryCSA = this.teamUsers
        .filter(user => user.csaRole === FIRM_ROLE_TYPES.SECONDARY_CSA)
        .map(user => ({ id: user.id, name: user.name, canSend: user.canSend }));
    },


    /* Map form data to working client */
    mapData() {
      this.setFirmRoles();
    },

    /* Nav to Next Page in flow */
    next(route = null) {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.mapData();
      let to = route ? route.name : MENUS.ADVISOR.CLIENT.REVIEW_AND_FINISH.id;
      if (this.canAddCustomTemplates) {
        return this.goNext(MENUS.ADVISOR.CLIENT.CUSTOM_QUESTIONS.id);
      }
      this.goNext(to);
    },

    /* Nav to Previous Page in flow */
    prev() {
      // If accounts can be added, prev is the advisory rate page
      // Otherwise, if there is a second client
      // prev is the second client phone page (last page in second client flow)
      //
      // Otherwise, prev is Second Client Question page.
      // Determine previous page based on what makes sense
      //  If the first client name is not populated
      //  Go to the first client name page (first page in the first client flow)
      //  
      //  Otherwise if a second client has been chosen and the name has not been filled out
      //  Go to the second client name page (first page in the second client flow).
      //
      //  Otherwise if added second client 
      //  Go to the second client phone page (last page in second client flow).
      //  Otherwise go to the Add Second Client question page
      let route = this.canAddAccounts
        ? MENUS.ADVISOR.CLIENT.ADVISORY_RATE.id
        : this.hasSecondClient
          ? MENUS.ADVISOR.CLIENT.CLIENT_TWO_PHONE.id
          //: MENUS.ADVISOR.CLIENT.CLIENT_ADD_SECOND.id;
          : MENUS.ADVISOR.CLIENT.CLIENT_ONE_PHONE.id;
      if (this.missingFirstClient) {
        route = MENUS.ADVISOR.CLIENT.CLIENT_ONE_NAME.id
      } else if (this.addedSecondClient) {
        if (this.missingSecondClient) {
          route = MENUS.ADVISOR.CLIENT.CLIENT_TWO_NAME.id;
        }
      }
      this.goPrev(
        route
      );

      /*
        // Non-intelligent nav
        // If added second client 
        //  Go to the second client phone page (last page in second client flow).
        //  Otherwise go to the Add Second Client question page
        this.goPrev(this.addedSecondClient ? MENUS.ADVISOR.CLIENT.CLIENT_TWO_PHONE.id : MENUS.ADVISOR.CLIENT.CLIENT_ADD_SECOND.id);
      
      const routeId = this.canAddAccounts
        ? MENUS.ADVISOR.CLIENT.ADVISORY_RATE.id
        : this.hasSecondClient
        ? MENUS.ADVISOR.CLIENT.CLIENT_TWO_PHONE.id
        : MENUS.ADVISOR.CLIENT.CLIENT_ADD_SECOND.id;
      this.goPrev(routeId);*/
    },
  },

  created() {
    this.FIRM_ROLE_TYPES = FIRM_ROLE_TYPES;

    // Initialize the current firm roles
    this.initializeFirmRoles();
  },
};
</script>
<style lang="scss" scoped>
.help-list li {
  display: list-item;
}

.teams-step-container {
  max-width: 1024px;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.teams-table {
  width: 100%;
  max-width: 100%;
  border: 2px solid var(--secondary-color);
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  .header-row {
    font-weight: 500;

    .col {
      flex-direction: column;
      justify-content: start !important;
      align-items: flex-start !important;
    }

    .col-2,
    .col-3 {
      @media screen and (max-width: 960px) {
        display: none !important;
      }
    }
  }

  >div:not(:first-of-type) {
    border-top: 2px solid var(--secondary-color);
  }

  >.row>div:not(:last-of-type) {
    border-right: 2px solid var(--secondary-color);

    @media screen and (max-width: 960px) {
      border-right: none;
    }
  }

  >.row {
    margin: 0;

    .cb-label {
      color: var(--color-text);
      font-size: 16px;
      font-weight: 500;
      margin-bottom: -5px;
    }

    .col {
      padding: 20px 14px;
      border-top: none;

      &.first {
        background-color: var(--color-light-grey);
      }

      &.name {
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
      }

      .warn {
        width: 24px;
        height: 24px;

        @media screen and (max-width: 960px) {
          width: auto;

          svg {
            width: 24px;
            height: 24px;
          }
        }

        .mobile-label {
          padding-left: 10px;
        }
      }

      @media screen and (max-width: 960px) {
        min-width: 100%;
      }

      .mobile-label {
        display: none;

        @media screen and (max-width: 960px) {
          display: inline-block;
        }
      }

      .v-btn {
        /* Fix a ~2px difference between buttons and divs styled as buttons */
        letter-spacing: normal;
        text-indent: 0;
      }

      .role-icon {
        /* keep button icons a fixed width so disabled buttons with warnings are equal in width */
        width: 36px;
      }

      .small {
        font-size: 0.75em;
      }

      ::v-deep .v-input--checkbox {
        margin-top: 0px;
      }
    }

    .col-2,
    .col-3 {
      justify-content: center;
      display: flex;

      @media screen and (max-width: 960px) {
        justify-content: start;
      }
    }

  }
}
</style>
