import moment from 'moment';

export const FORMATTERS = {
  // Expects an object
  query: (q) => {
    // Pessimistically checking types to be sure because of no type support
    const queryFormat =
      typeof q === 'object' && !Array.isArray(q)
        ? Object.keys(q)
            .filter((k) => !!q[k])
            .map((k) => `${k}=${q[k]}`)
        : [];
    return `?${queryFormat.join('&')}`;
  },
  dateTime: (d) => {
    if (!d) return;
    return moment(d).format('MM/DD/YY - HH:mm');
  },
};
