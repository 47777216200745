<template>
  <div class="step-container client">
    <help-drawer :title="helpTitle">
      <div>
        <p>
          We are required to gather this information for any account holder,
          based on The Patriot Act
        </p>
      </div>
    </help-drawer>
    <v-form name="CompanyAddress" class="form" @submit.prevent>
      <v-container class="client-step-container">
        <v-row class="mb-8">
          <v-col class="instructions">
            <h1>
              <span class="headerlight">
                Tell us a bit about your <strong>company.</strong>
              </span>
            </h1>
            <div class="help-link">
              <a @click="toggleHelp()">{{ helpTitle }}</a>
            </div>
          </v-col>
        </v-row>
        <data-submitted-message
          :submitted="isCompanyAddressComplete"
          v-on:hide-form="toggleFormState"
        />
        <div v-if="!formHidden">
          <v-row class="justify-center">
            <v-col class="step-form-container large">
              <v-row class="mobile">
                <v-col class="full">
                  <label for="searchCompanyAddress" class="required">
                    Search Company/Address
                  </label>
                  <v-text-field
                    outlined
                    type="text"
                    maxlength="256"
                    name="searchCompanyAddress"
                    id="searchCompanyAddress"
                    aria-required="true"
                    v-model.trim="searchCompanyAddress"
                    :backgroundColor="inputBackgroundColor"
                    placeHolder="Search"
                    @input="$v.searchCompanyAddress.$touch()"
                    @blur="$v.searchCompanyAddress.$touch()"
                    :error-messages="searchCompanyAddressErrors"
                    hide-details="auto"
                    class="mb-2"
                  />
                </v-col>
              </v-row>
              <v-row v-if="company.autoFilled">
                <v-col class="full">
                  <label for="companyName" class="required">Company Name</label>
                  <v-text-field
                    outlined
                    type="text"
                    maxlength="256"
                    name="companyName"
                    aria-required="true"
                    v-model.trim="company.name"
                    :backgroundColor="inputBackgroundColor"
                    @input="$v.company.name.$touch()"
                    @blur="$v.company.name.$touch()"
                    :error-messages="companyNameErrors"
                    hide-details="auto"
                    class="mb-2"
                  />
                </v-col>
              </v-row>
              <v-row class="mobile" v-if="company.autoFilled">
                <v-col>
                  <label for="address1" class="required">Address 1</label>
                  <v-text-field
                    outlined
                    type="text"
                    maxlength="256"
                    name="address1"
                    aria-required="true"
                    v-model.trim="company.address1"
                    :backgroundColor="inputBackgroundColor"
                    @input="$v.company.address1.$touch()"
                    @blur="$v.company.address1.$touch()"
                    :error-messages="companyAddress1Errors"
                    hide-details="auto"
                    class="mb-2"
                  />
                </v-col>
                <v-col>
                  <label for="address2">Address 2</label>
                  <v-text-field
                    outlined
                    type="text"
                    maxlength="256"
                    name="address2"
                    v-model.trim="company.address2"
                    :backgroundColor="inputBackgroundColor"
                    @input="$v.company.address2.$touch()"
                    @blur="$v.company.address2.$touch()"
                    hide-details="auto"
                    class="mb-2"
                  />
                </v-col>
              </v-row>
              <v-row class="mobile" v-if="company.autoFilled">
                <v-col class="col-6">
                  <label for="city" class="required">City</label>
                  <v-text-field
                    outlined
                    type="text"
                    maxlength="256"
                    name="city"
                    aria-required="true"
                    v-model.trim="company.city"
                    :backgroundColor="inputBackgroundColor"
                    @input="$v.company.city.$touch()"
                    @blur="$v.company.city.$touch()"
                    :error-messages="companyCityErrors"
                    hide-details="auto"
                  />
                </v-col>
                <v-col>
                  <v-row class="state-zip">
                    <v-col>
                      <label for="state" class="required">State</label>
                      <v-select
                        v-model="company.state"
                        ref="state"
                        :items="states"
                        :backgroundColor="inputBackgroundColor"
                        outlined
                        placeholder="Choose"
                        dense
                        hide-details="auto"
                        @change="$v.company.state.$touch()"
                        :error-messages="companyStateErrors"
                      />
                    </v-col>
                    <v-col
                      ><label for="zip" class="required">Zip Code</label>
                      <v-text-field
                        outlined
                        type="text"
                        maxlength="10"
                        name="zip"
                        aria-required="true"
                        v-model.trim="company.zip"
                        :backgroundColor="inputBackgroundColor"
                        @input="$v.company.zip.$touch()"
                        @blur="$v.company.zip.$touch()"
                        :error-messages="companyZipErrors"
                        hide-details="auto"
                        inputmode="numeric"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <div class="skip-for-now">
            <a @click="skip()">Skip for now</a>
          </div>
        </div>
      </v-container>
    </v-form>
    <bottom-nav v-on:go-prev="prev()" v-on:go-next="next()"></bottom-nav>
  </div>
</template>

<script>
import HelpDrawer from '@/components/HelpDrawerComponent';
import DataSubmittedMessage from '@/components/client/DataSubmittedMessage';
import BottomNav from '@/components/common/BottomNav';
import InterviewClientMixin from '@/common/mixins/interview.client.mixin';
import { MENUS, STATES, validateZip } from '@/common/constants';
import { required } from 'vuelidate/lib/validators';

/* Custom validator */
const mustSelectResult = (value, vm) => {
  return value !== null && vm.company.autoFilled;
};

export default {
  name: 'CompanyAddress',

  components: { BottomNav, DataSubmittedMessage, HelpDrawer },
  mixins: [InterviewClientMixin],

  data: () => ({
    searchCompanyAddress: null,
    company: {
      autoFilled: false,
      name: null,
      address1: null,
      address2: null,
      city: null,
      state: null,
      zip: null,
    },
  }),
  validations() {
    return {
      searchCompanyAddress: {
        //required,
        mustSelectResult,
      },
      company: {
        name: {
          required,
        },
        address1: {
          required,
        },
        address2: {},
        city: {
          required,
        },
        state: {
          required,
        },
        zip: {
          required,
          validateZip,
        },
      },
      validationGroup: [
        'searchCompanyAddress',
        'company.name',
        'company.address1',
        'company.address2',
        'company.city',
        'company.state',
        'company.zip',
      ],
    };
  },
  computed: {
    // Dynamic Search errors */
    searchCompanyAddressErrors() {
      const errors = [];
      if (!this.$v.searchCompanyAddress.$dirty) return errors;
      //!this.$v.searchCompanyAddress.required && errors.push("You must search/select a Company/Address");
      !this.$v.searchCompanyAddress.mustSelectResult &&
        errors.push('You must search/select a Company/Address');
      return errors;
    },

    // Dynamic Name errors */
    companyNameErrors() {
      const errors = [];
      if (!this.$v.company.name.$dirty) return errors;
      !this.$v.company.name.required && errors.push('Company Name is required');
      return errors;
    },

    // Dynamic Address1 errors */
    companyAddress1Errors() {
      const errors = [];
      if (!this.$v.company.address1.$dirty) return errors;
      !this.$v.company.address1.required &&
        errors.push('Address 1 is required');
      return errors;
    },

    // Dynamic City errors */
    companyCityErrors() {
      const errors = [];
      if (!this.$v.company.city.$dirty) return errors;
      !this.$v.company.city.required && errors.push('City is required');
      return errors;
    },

    // Dynamic State errors */
    companyStateErrors() {
      const errors = [];
      if (!this.$v.company.state.$dirty) return errors;
      !this.$v.company.state.required && errors.push('State is required');
      return errors;
    },

    // Dynamic Zip errors */
    companyZipErrors() {
      const errors = [];
      if (!this.$v.company.zip.$dirty) return errors;
      !this.$v.company.zip.required && errors.push('Zip Code is required');
      !this.$v.company.zip.validateZip &&
        errors.push('Zip Code must be NNNNN or NNNNN-NNNN');
      return errors;
    },
    helpTitle() {
      return 'Why do you need this?';
    },
  },
  methods: {
    /* 
      Override toggle form state from hidden/shown.
      If hidden, reset form errors, else init autocomplete
    */
    toggleFormState(hide) {
      this.formHidden = hide;
      if (hide) {
        this.$v.$reset();
      } else {
        this.$nextTick(() => this.initAutoComplete());
      }
    },

    /* Nav to Next Page in flow */
    next(route = null) {
      const to = route || MENUS.CLIENT.JOB_DESCRIPTION.id;

      if (!this.formHidden) {
        this.$v.$touch();
        if (this.$v.$invalid) return;
      } else {
        this.goNext(to);
        return;
      }

      const data = {
        name: this.company.name,
        address: {
          line1: this.company.address1,
          line2: this.company.address2,
          city: this.company.city,
          state: this.company.state,
          zip: this.company.zip,
        },
      };

      this.completeStep(MENUS.CLIENT.COMPANY_ADDRESS.id, data)
        .then(() => {
          this.goNext(to);
        })
        .catch(() => {});
    },

    /* Nav to Previous Page in flow */
    prev() {
      this.goPrev(MENUS.CLIENT.EMPLOYMENT_STATUS.id);
    },

    /* Skip to next Page in flow */
    skip() {
      this.goPrev(MENUS.CLIENT.JOB_DESCRIPTION.id);
    },

    /* Fill in Address Fields from Autocomplete */
    fillInAddress() {
      // Get the place details from the autocomplete object.
      const place = this.autocomplete.getPlace();
      const addressObj = this.company;
      let addressField = document.querySelector('#searchCompanyAddress');
      let address1 = '';
      let postcode = '';
      addressObj.autoFilled = true;

      // Get each component of the address from the place details,
      // and then fill-in the corresponding field on the form.
      // place.address_components are google.maps.GeocoderAddressComponent objects
      // which are documented at http://goo.gle/3l5i5Mr
      for (const component of place.address_components) {
        const componentType = component.types[0];

        switch (componentType) {
          case 'street_number': {
            address1 = `${component.long_name} ${address1}`;
            break;
          }

          case 'route': {
            address1 += component.short_name;
            break;
          }

          case 'postal_code': {
            postcode = `${component.long_name}${postcode}`;
            break;
          }

          case 'postal_code_suffix': {
            postcode = `${postcode}-${component.long_name}`;
            break;
          }
          case 'locality': {
            addressObj.city = component.long_name;
            break;
          }
          case 'administrative_area_level_1': {
            addressObj.state = component.short_name;
            break;
          }
        }
      }
      this.$nextTick(() => (addressField.value = place.formatted_address));
      addressObj.name = place.name;
      addressObj.address1 = address1;
      addressObj.zip = postcode;

      // Clone Company for read-only detection
      this.origCompanyAddress = { ...this.company };
    },

    /* Initialize the Autocomplete widget */
    initAutoComplete() {
      let addressField = document.querySelector('#searchCompanyAddress');

      this.autocomplete = new window.google.maps.places.Autocomplete(
        addressField,
        {
          componentRestrictions: { country: ['us'] },
          fields: ['address_components', 'name', 'formatted_address'],
          types: [],
        },
      );

      // When the user selects an address from the drop-down, populate the
      // address fields in the form.
      this.autocomplete.addListener('place_changed', this.fillInAddress);
    },
  },
  mounted() {
    // Initial Autocomplete on mount if form is not hidden
    if (!this.formHidden) this.initAutoComplete();
  },

  created() {
    // Initialize form data for current interview
    this.states = STATES;
    this.formHidden = this.isCompanyAddressComplete;
  },
};
</script>
<style lang="scss" scoped>
.state-zip {
  @media screen and (max-width: 960px) {
    padding-top: 8px !important;
  }
}
.alternate-address {
  margin-top: 30px;
  border-top: 1px solid var(--secondary-color);
}
.cb-label {
  margin-bottom: -5px;
}
.col.full {
  max-width: 100% !important;
}
.col.instructions {
  @media screen and (max-width: 960px) {
    strong {
      display: block;
    }
  }
}
</style>
