/* VUEX Dispatchable Mutations */

export const PURGE_AUTH = 'logOut';
export const SET_AUTH = 'setAuthentication';
export const SET_CLIENT_AUTH = 'setClientAuthentication';
export const SET_THEME_DATA = 'setThemeData';
export const SET_TIMEOUT_FLAG = 'setTimeoutFlag';
export const SET_SESSION_TOKEN = 'setSessionToken';
export const SET_CURRENT_USER = 'setCurrentUser';
export const SET_CURRENT_COMPANY = 'setCurrentCompany';
export const SET_CURRENT_CLIENT = 'setWorkingClient';
export const SET_CLIENT_PROGRESS = 'setClientProgress';
export const SET_EMAIL = 'setEmail';
export const SET_USER_ID = 'setUserId';
export const SET_HOUSEHOLD_VALUE = 'setHouseholdValue';
export const SET_TRANSITION_NAME = 'setTransitionName';
export const SET_TRANSITION_PHONE = 'setTransitionPhone';
export const SET_TRANSITION_EMAIL = 'setTransitionEmail';
export const SET_ADD_SECOND = 'setAddSecond';
export const SET_FIRM_ROLES = 'setFirmRoles';
export const SET_TRANSITION_HOUSEHOLDS = 'setTransitionHousehold';
export const RESET_TRANSITION_HOUSEHOLDS = 'resetTransitionHousehold';
export const RESET_TRANSITIONING_HOUSEHOLD = 'resetTransitioningHousehold';
export const SET_TRANSITION = 'setTransition';
