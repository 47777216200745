<template>
  <div class="step-container client">
    <v-form name="JobDescription" class="form">
      <v-container class="client-step-container">
        <v-row class="mb-8">
          <v-col class="instructions">
            <h1>
              <span class="headerlight">
                What best describes your <strong>job?</strong>
              </span>
            </h1>
          </v-col>
        </v-row>
        <data-submitted-message
          :submitted="isJobDescriptionComplete"
          v-on:hide-form="toggleFormState"
        />
        <div v-if="!formHidden">
          <v-row class="justify-center">
            <v-col class="step-form-container radio-container mb-4">
              <v-radio-group
                v-model="jobDescription"
                :error-messages="jobDescriptionErrors"
                hide-details="auto"
                @change="$v.jobDescription.$touch()"
              >
                <v-radio
                  :ripple="false"
                  class="v-faux-radio mb-4"
                  :label="JOB_DESCRIPTIONS.PROFESSIONAL"
                  :value="JOB_DESCRIPTIONS.PROFESSIONAL"
                ></v-radio>
                <div class="expander" v-show="isProfessionalJobType">
                  <div class="faux-label">Job Type</div>
                  <div>
                    <v-select
                      v-model="professionalJobType"
                      :items="jobTypes.professional"
                      :backgroundColor="inputBackgroundColor"
                      outlined
                      placeholder="Choose"
                      dense
                      hide-details="auto"
                      @change="$v.professionalJobType.$touch()"
                      :error-messages="professionalJobTypeErrors"
                      class="mb-2"
                    >
                    </v-select>
                  </div>
                </div>
                <v-radio
                  :ripple="false"
                  class="v-faux-radio"
                  :label="JOB_DESCRIPTIONS.TRADE_OR_SERVICE"
                  :value="JOB_DESCRIPTIONS.TRADE_OR_SERVICE"
                ></v-radio>
                <v-radio
                  :ripple="false"
                  class="v-faux-radio mb-4"
                  :label="JOB_DESCRIPTIONS.GOVERNMENT"
                  :value="JOB_DESCRIPTIONS.GOVERNMENT"
                ></v-radio>
                <div class="expander" v-show="isGovernmentJobType">
                  <div class="faux-label">Job Type</div>
                  <div>
                    <v-select
                      v-model="governmentJobType"
                      :items="jobTypes.government"
                      :backgroundColor="inputBackgroundColor"
                      outlined
                      placeholder="Choose"
                      dense
                      hide-details="auto"
                      @change="$v.governmentJobType.$touch()"
                      :error-messages="governmentJobTypeErrors"
                      class="mb-2"
                    >
                    </v-select>
                  </div>
                </div>
                <v-radio
                  :ripple="false"
                  class="v-faux-radio mb-4"
                  :label="JOB_DESCRIPTIONS.OTHER"
                  :value="JOB_DESCRIPTIONS.OTHER"
                ></v-radio>
                <div class="expander empty" v-show="isOtherJobType">
                  <label for="other">What's your job?</label>
                  <v-text-field
                    outlined
                    type="text"
                    maxlength="256"
                    name="other"
                    v-model.trim="otherJobType"
                    :backgroundColor="inputBackgroundColor"
                    @input="$v.otherJobType.$touch()"
                    @blur="$v.otherJobType.$touch()"
                    :error-messages="otherJobTypeErrors"
                    hide-details="auto"
                  />
                </div>
              </v-radio-group>
            </v-col>
          </v-row>

          <div class="skip-for-now">
            <a @click="skip()">Skip for now</a>
          </div>
        </div>
      </v-container>
    </v-form>
    <bottom-nav v-on:go-prev="prev()" v-on:go-next="next()"></bottom-nav>
  </div>
</template>

<script>
import DataSubmittedMessage from '@/components/client/DataSubmittedMessage';
import BottomNav from '@/components/common/BottomNav';
import InterviewClientMixin from '@/common/mixins/interview.client.mixin';
import { required, requiredIf } from 'vuelidate/lib/validators';
import {
  MENUS,
  JOB_DESCRIPTIONS,
  JOB_TYPES,
  JOB_TYPE_TRADE,
} from '@/common/constants';

export default {
  name: 'JobDescription',

  components: { BottomNav, DataSubmittedMessage },
  mixins: [InterviewClientMixin],

  data: () => ({
    jobDescription: null,
    professionalJobType: null,
    governmentJobType: null,
    otherJobType: null,
  }),

  validations() {
    return {
      jobDescription: {
        required,
      },
      professionalJobType: {
        required: requiredIf(() => this.isProfessionalJobType),
      },
      governmentJobType: {
        required: requiredIf(() => this.isGovernmentJobType),
      },
      otherJobType: {
        required: requiredIf(() => this.isOtherJobType),
      },
      validationGroup: [
        'jobDescription',
        'professionalJobType',
        'governmentJobType',
        'otherJobType',
      ],
    };
  },

  computed: {
    /* Dynamic errors */
    jobDescriptionErrors() {
      const errors = [];
      if (!this.$v.jobDescription.$dirty) return errors;
      !this.$v.jobDescription.required &&
        errors.push('A selection is required');
      return errors;
    },

    professionalJobTypeErrors() {
      const errors = [];
      if (!this.$v.professionalJobType.$dirty) return errors;
      !this.$v.professionalJobType.required &&
        errors.push('A selection is required');
      return errors;
    },

    governmentJobTypeErrors() {
      const errors = [];
      if (!this.$v.governmentJobType.$dirty) return errors;
      !this.$v.governmentJobType.required &&
        errors.push('A selection is required');
      return errors;
    },

    otherJobTypeErrors() {
      const errors = [];
      if (!this.$v.otherJobType.$dirty) return errors;
      !this.$v.otherJobType.required && errors.push('This field is required');
      return errors;
    },

    isProfessionalJobType() {
      return this.jobDescription === this.JOB_DESCRIPTIONS.PROFESSIONAL;
    },
    isGovernmentJobType() {
      return this.jobDescription === this.JOB_DESCRIPTIONS.GOVERNMENT;
    },
    isOtherJobType() {
      return this.jobDescription === this.JOB_DESCRIPTIONS.OTHER;
    },
  },

  methods: {
    /* Nav to Next Page in flow */
    next(route = null) {
      const to = route || MENUS.CLIENT.COMPANY_OFFICIAL.id;

      if (!this.formHidden) {
        this.$v.$touch();
        if (this.$v.$invalid) return;
      } else {
        this.goNext(to);
        return;
      }

      let type = null;
      switch (this.jobDescription) {
        case this.JOB_DESCRIPTIONS.PROFESSIONAL:
          type = this.professionalJobType;
          break;
        case this.JOB_DESCRIPTIONS.GOVERNMENT:
          type = this.governmentJobType;
          break;
        case this.JOB_DESCRIPTIONS.OTHER:
          type = this.otherJobType;
          break;
        case this.JOB_DESCRIPTIONS.TRADE_OR_SERVICE:
          type = JOB_TYPE_TRADE;
      }

      const data = {
        description: this.jobDescription,
        type: type,
      };

      this.completeStep(MENUS.CLIENT.JOB_DESCRIPTION.id, data)
        .then(() => {
          this.goNext(to);
        })
        .catch(() => {});
    },

    /* Nav to Previous Page in flow */
    prev() {
      this.goPrev(MENUS.CLIENT.COMPANY_ADDRESS.id);
    },

    /* Skip to next Page in flow */
    skip() {
      this.goPrev(MENUS.CLIENT.COMPANY_OFFICIAL.id);
    },
  },

  created() {
    // Initialize form data for current interview
    this.formHidden = this.isJobDescriptionComplete;
    this.jobTypes = JOB_TYPES;
    this.JOB_DESCRIPTIONS = JOB_DESCRIPTIONS;
  },
};
</script>
<style lang="scss" scoped>
.expander {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--secondary-color);

  &.empty {
    border-bottom: none;
  }
}
</style>
