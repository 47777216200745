<template>
  <v-container>
    <v-dialog
      v-model="smsDialog"
      persistent
      width="700"
      :overlay-opacity="0.8"
      :overlay-color="overlayColor"
      content-class="plain-dialog"
    >
      <v-card flat>
        <v-card-text class="pa-7">
          <div class="modal-title">Edit Message</div>
          <div class="modal-body">
            <v-row>
              <v-col>
                <v-textarea
                  class="textarea"
                  outlined
                  name="smsTemplate"
                  aria-required="true"
                  auto-grow
                  v-model="smsTemplate"
                  :backgroundColor="inputBackgroundColor"
                  @input="$v.smsTemplate.$touch()"
                  @blur="$v.smsTemplate.$touch()"
                  hide-details="auto"
                />
              </v-col>
            </v-row>
          </div>
          <div class="modal-actions">
            <a @click="saveSMSModal()" class="btn mt-3">Send Message</a>
            <a @click="cancelSMSModal()" class="btn secondary mt-3">Cancel</a>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="decisionDialog"
      persistent
      width="430"
      :overlay-opacity="0.8"
      :overlay-color="overlayColor"
      content-class="plain-dialog"
    >
      <v-card flat>
        <v-card-text class="pa-7">
          <div
            class="modal-title"
            style="font-size: 18px; text-align: center; line-height: 30px"
          >
            Are you ready to notify your clients to start transitioning?
          </div>
          <div class="modal-actions">
            <v-row class="justify-center">
              <v-col class="step-form-container radio-container">
                <v-radio-group
                  v-model="isReadyToTransition"
                  hide-details="auto"
                  class="pb-7"
                >
                  <div>
                    <div
                      v-if="this.currentCompany.transitionNotificationsDisabled"
                      style="
                        position: relative;
                        left: 0.5rem;
                        top: 2.5rem;
                        z-index: 999;
                      "
                    >
                      <v-tooltip top transition="none">
                        <template v-slot:activator="{ on }">
                          <div v-on="on">
                            <font-awesome-icon
                              size="lg"
                              class="role-icon"
                              :style="{ color: 'red' }"
                              icon="exclamation-triangle"
                            ></font-awesome-icon>
                          </div>
                        </template>
                        <span
                          v-html="
                            'Transition announcement are disabled at this time. Please contact your company administrator.'
                          "
                        ></span>
                      </v-tooltip>
                    </div>
                    <v-radio
                      class="v-faux-radio mb-4"
                      label="Yes"
                      :disabled="
                        this.currentCompany.transitionNotificationsDisabled
                      "
                      :value="true"
                      :ripple="false"
                    ></v-radio>
                  </div>
                  <v-radio
                    :ripple="false"
                    class="v-faux-radio"
                    label="No, I'll do it later"
                    :value="false"
                  ></v-radio>
                </v-radio-group>
                <a
                  @click="saveDecisionModal()"
                  v-if="isReadyToTransition !== null"
                  class="btn mt-3"
                  >Proceed</a
                >
                <a @click="cancelDecisionModal()" class="btn secondary mt-3"
                  >Cancel</a
                >
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <h1>
      <span class="headerlight">Edit Transitioning Clients</span>
    </h1>
    <TransitionHouseholdTable dataSource="csvFile"></TransitionHouseholdTable>
    <bottom-nav :hasPrevious="false" v-on:go-next="proceedTransition()">
    </bottom-nav>
  </v-container>
</template>

<script>
import BottomNav from '@/components/common/BottomNav';
import { mapGetters } from 'vuex';
import {
  TRANSITION_CLIENTS,
  EMPTY_TRANSITION_HOUSEHOLDS,
} from '@/stores/actions.type';
import { MENUS, formatPhone } from '@/common/constants';
import TransitionHouseholdTable from '@/components/advisor/tables/TransitionHouseholdTable';
import {
  NOTIFICATION_TYPES,
  NotificationService,
} from '@/services/notification.service';
import { isEmpty } from 'lodash';

export default {
  data: () => ({
    decisionDialog: false,
    smsDialog: false,
    isReadyToTransition: null,
    smsTemplate:
      'Dear valued client, \nWe would like to inform you that your account is in the process of transitioning. If you wish to continue your association with us, please upload your statement at [statementLink]. We value your association and are here to assist you through this process.\nWarm regards,[Your Company Name]',
  }),
  computed: {
    ...mapGetters([
      'currentCompany',
      'transitionHouseholds',
      'selectedTransitionType',
    ]),
  },
  created() {
    if (!this.currentCompany.notificationTemplates) {
      NotificationService.alert(
        {
          type: NOTIFICATION_TYPES.ERROR,
          title: 'Error',
          message: 'Error getting sms template',
          okTitle: 'Ok',
        },
        'No sms template found',
      );
    }

    const notificationTemplates = this.currentCompany.notificationTemplates;
    const foundTemplate = notificationTemplates.find((template) => {
      if (this.selectedTransitionType === 'Protocol') {
        return template.templateName === 'TransitionStatement';
      }
      return template.templateName === 'NonProtocolTransitionRequestEmail';
    });
    if (!foundTemplate) {
      NotificationService.alert(
        {
          type: NOTIFICATION_TYPES.WARNING,
          title: 'Warning',
          message: 'Error getting sms template',
          okTitle: 'Ok',
        },
        'No sms template found',
      );
    } else {
      this.smsTemplate = foundTemplate.template;
    }
  },
  components: { BottomNav, TransitionHouseholdTable },
  methods: {
    saveDecisionModal() {
      if (!this.isReadyToTransition) {
        const payload = {
          households: this.transitionHouseholds,
          sendMessageNow: false,
        };
        this.transitionClients(payload);
      } else {
        this.smsDialog = true;
      }
    },

    async transitionClients(payload) {
      const transitionObjTobeSent = payload.households.map((obj) => {
        const { primaryContact, secondaryContact } = obj;
        const transitionObj = {
          primaryContact: {
            firstName: primaryContact.firstName,
            lastName: primaryContact.lastName,
            mobile: formatPhone(primaryContact.phone),
            email: isEmpty(primaryContact.email) ? null : primaryContact.email,
          },
          secondaryContact: secondaryContact && {
            firstName: secondaryContact.firstName,
            lastName: secondaryContact.lastName,
            mobile: formatPhone(secondaryContact.phone),
            email: isEmpty(secondaryContact.email)
              ? null
              : secondaryContact.email,
          },
          primaryAdvisor:
            obj.firmRoles.primaryAdvisor.id || obj.firmRoles.primaryAdvisor,
          primaryCSA: obj.firmRoles.primaryCSA.id || obj.firmRoles.primaryCSA,
          transitionType: this.selectedTransitionType,
          assetsValue: Number(obj.assetsValue),
        };
        return transitionObj;
      });
      this.$store
        .dispatch(TRANSITION_CLIENTS, {
          transitions: transitionObjTobeSent,
          sendNow: payload.sendMessageNow,
          smsPayload: payload.smsPayload,
        })
        .then((response) => {
          this.$router.push({
            name: MENUS.TRANSITIONS.SUCCESS_TRANSITION.id,
            params: { transitionCount: response.length },
          });
        })
        .catch((error) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: error.message,
              okTitle: 'Ok',
            },
            error,
          );
        })
        .finally(() => {
          this.$store.dispatch(EMPTY_TRANSITION_HOUSEHOLDS);
        });
    },

    proceedTransition() {
      this.decisionDialog = true;
    },

    saveSMSModal() {
      this.transitionClients({
        households: this.transitionHouseholds,
        sendMessageNow: true,
        smsPayload: this.smsTemplate,
      });
    },

    cancelDecisionModal() {
      this.decisionDialog = false;
    },

    cancelSMSModal() {
      this.smsDialog = false;
      this.decisionDialog = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
