<template>
  <v-app v-cloak>
    <!-- Content Area -->
    <v-main class="app-container">
      <!-- Loading Modal -->
      <v-overlay id="loading-modal" opacity=".6" z-index="10000" v-if="loading">
        <div class="loader-container">
          <lottie-animation
            data-animation-type="lottie"
            :path="lottiePath"
            :loop="true"
            :autoPlay="true"
            class="themed"
          >
          </lottie-animation>
        </div>
      </v-overlay>
      <v-container fluid class="pa-0">
        <router-view :key="$route.fullPath"></router-view>
      </v-container>
    </v-main>

    <!-- Notification Area -->
    <notification-component></notification-component>
    <leave-guard-component></leave-guard-component>
    <idle-overlay v-if="isLoggedInIdle"></idle-overlay>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import EventListener from '@/common/mixins/eventlistener.mixin';
import PublicAssets from '@/common/mixins/publicasset.mixin';
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue';
import {
  MENUS,
  LOCAL_KEYS,
  AUTH_TYPES,
  CLIENT_EXIT_KEYS,
} from '@/common/constants';
import { SESSION_TIMEOUT } from '@/stores/actions.type';
import NotificationComponent from '@/components/NotificationComponent';
import LeaveGuardComponent from '@/components/LeaveGuardComponent';
import IdleOverlay from '@/components/IdleOverlayComponent';
import cssVars from 'css-vars-ponyfill';
import Storage from '@/services/storage.service';

export default {
  name: 'App',

  mixins: [PublicAssets, EventListener],

  components: {
    LottieAnimation,
    NotificationComponent,
    LeaveGuardComponent,
    IdleOverlay,
  },

  data: () => ({
    loading: false,
    xhrCount: 0,
  }),

  computed: {
    ...mapGetters(['isAuthenticated', 'isClientAuthenticated', 'sessionToken']),

    isLoggedInIdle() {
      return (
        (this.isAuthenticated || this.isClientAuthenticated) && this.isAppIdle
      );
    },
  },

  methods: {
    /* Update outstanding XHR Counts */
    updateXhrCount(up) {
      if (up) {
        this.xhrCount++;
      } else {
        this.xhrCount--;
      }
      if (this.xhrCount < 0) this.xhrCount = 0;

      this.loading = this.xhrCount > 0;
    },

    /* Handle Session Timeouts */
    sessionTimeout(flag) {
      const isClient =
        Storage.getLocal(LOCAL_KEYS.AUTH_TYPE) ===
        AUTH_TYPES.UNAUTHENTICATED_CLIENT;
      const route = isClient
        ? { name: MENUS.INTERVIEW.UNAUTHENTICATED.id, params: { flag: flag } }
        : { name: MENUS.LOGIN.id };

      this.$store.dispatch(SESSION_TIMEOUT).finally(() => {
        this.$router.push(route);
      });
    },
  },

  /* Lifecycle Hooks */
  created() {
    // Initialize CSS vars ponyfill
    // Document
    cssVars({
      rootElement: document, // default
    });

    // Listen for events
    this.listen('xhr-event', (started) => {
      this.updateXhrCount(started);
    });

    this.listen('toggle-loading', (value) => {
      this.loading = value;
    });

    this.listen(
      'session-timeout',
      (flag = CLIENT_EXIT_KEYS.EXIT_SESSION_TIMEOUT) =>
        this.sessionTimeout(flag),
    );
  },
};
</script>
<style lang="scss">
.app-container {
  //align-items: center;
  //padding: 0px !important;
  padding-top: 69px !important;

  @media screen and (max-width: 960px) {
    align-items: flex-start;
    padding: 0px !important;
  }
}

.loader-container {
  width: 25vw;
}
.themed {
  stroke: var(--primary-color);
  path {
    fill: var(--primary-color);
  }
}
</style>
