<template>
  <div class="step-container advisor">
    <v-form name="ClientEmail" class="form">
      <v-container class="client-step-container">
        <v-row class="mb-8">
          <v-col class="instructions">
            <h1>
              <span class="headerlight">
                What's {{ computedFirstName }}'s <strong>email address</strong>?
              </span>
            </h1>
          </v-col>
        </v-row>
        <v-row class="client-email-container">
          <v-col class="step-form-container">
            <v-text-field
              outlined
              type="email"
              maxlength="256"
              name="email"
              aria-required="true"
              v-model.trim="email"
              :backgroundColor="inputBackgroundColor"
              @blur="$v.email.$touch()"
              :error-messages="emailErrors"
              hide-details="auto"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <bottom-nav v-on:go-prev="prev()" v-on:go-next="next()"></bottom-nav>
  </div>
</template>

<script>
import BottomNav from '@/components/common/BottomNav';
import { validationMixin } from 'vuelidate';
import { MENUS } from '@/common/constants';
import { required, email } from 'vuelidate/lib/validators';
import { ADD_TRANSITION_EMAIL } from '@/stores/actions.type';
import AdvisorTransitionMixin from '@/common/mixins/advisor.transition.mixin';
import { mapGetters } from 'vuex';

export default {
  name: 'TransitionManualClientEmail',

  mixins: [validationMixin, AdvisorTransitionMixin],

  components: { BottomNav },

  props: {
    second: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    email: null,
  }),

  validations() {
    return {
      email: {
        required,
        email,
      },
      validationGroup: ['email'],
    };
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'currentCompany',
      'transitioningHousehold',
      'selectedTransitionType',
    ]),

    transitionType() {
      return this.selectedTransitionType;
    },
    // Dynamic Email errors */
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.required && errors.push('Email is required');
      !this.$v.email.email && errors.push('Invalid email address');
      return errors;
    },
    computedFirstName() {
      return this.second
        ? this.transitioningHousehold.secondaryContact.firstName ||
            'the Secondary Client'
        : this.transitioningHousehold.primaryContact.firstName ||
            'the Primary Client';
    },
  },

  methods: {
    mapData() {},

    /* Nav to Next Page in flow */
    next() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      // Next route passed in or next in flow for current client
      const to = !this.second
        ? MENUS.TRANSITIONS.ENTER_MANUALLY.CLIENT_ONE_PHONE.id
        : MENUS.TRANSITIONS.ENTER_MANUALLY.CLIENT_TWO_PHONE.id;

      const payload = {
        email: this.email,
        key: this.second ? 1 : 0,
      };

      this.$store.dispatch(ADD_TRANSITION_EMAIL, payload).then(() => {
        this.$router.push({
          name: to,
        });
      });
    },

    /* Nav to Previous Page in flow */
    prev() {
      const to = !this.second
        ? MENUS.TRANSITIONS.ENTER_MANUALLY.CLIENT_ONE_NAME.id
        : MENUS.TRANSITIONS.ENTER_MANUALLY.CLIENT_TWO_NAME.id;

      this.$router.push({
        name: to,
      });
    },
  },

  created() {
    // Initialize form data for current client
    if (this.transitioningHousehold) {
      const contact = this.second
        ? this.transitioningHousehold.secondaryContact
        : this.transitioningHousehold.primaryContact;

      this.email = contact.email;
    }
  },
};
</script>
<style lang="scss" scoped>
.help {
  margin-bottom: -4px;
}

.tooltip {
  max-width: 200px;
}

.client-email-container {
  justify-content: center;
  align-content: align-center;
  flex-direction: column;
}
</style>
