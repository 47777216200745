<template>
  <div class="step-container client">
    <div>
      <img
        :src="logo"
        width="240"
        loading="lazy"
        alt="OnBord"
        class="logo"
        id="companyLogo"
      />
    </div>

    <h2>
      <span v-if="!emailConfirmed">Please Confirm Your Emails</span>
      <span v-if="emailConfirmed">Upload Your Latest Statement</span>
    </h2>
    <v-form name="AddStatement" class="form">
      <v-container v-if="!emailConfirmed" class="steps-step-container">
        <div class="email-read">
          <v-text-field
            v-model="email"
            label="Email"
            type="email"
            :readonly="!emailEditMode"
            required
          ></v-text-field>
          <a v-if="!emailEditMode" @click="toggleEmailEditMode">
            <v-icon small class="ml-2"> fas fa-pencil </v-icon>
          </a>
        </div>

        <!-- File Upload Section -->
      </v-container>
      <v-container>
        <div v-if="emailConfirmed" class="justify-center steps-table">
          <input
            type="file"
            ref="fileInput"
            @change="handleFileUpload"
            style="display: none"
            accept=".jpeg, .png, .pdf"
          />
          <v-row class="section-row">
            <v-col class="col-4 step-col first">Statement</v-col>
            <v-col class="data-col">
              <span :key="batchFile" v-if="!batchFile">
                <v-icon style="color: red">fas fa-exclamation-triangle</v-icon>
                <span style="color: red"> missing</span>
              </span>
              <span v-else>
                <span> {{ batchFile.name }}</span>
              </span>

              <v-file-input
                v-model="batchFile"
                prepend-icon=""
                class="faux-upload"
                hide-details="auto"
                accept="text/csv"
                ref="File"
                :clearable="false"
              >
                <template v-slot:prepend-inner>
                  <a
                    class="btn upload-batch"
                    style="display: block"
                    @click="triggerFileInput"
                  >
                    {{ editMode ? 'Change File' : 'Choose File' }}
                  </a>
                </template>
              </v-file-input>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-form>
    <bottom-nav
      :hasPrevious="false"
      :nextText="nextText"
      :nextDisabled="!batchFile && emailConfirmed"
      v-on:go-prev="prev()"
      v-on:go-next="next()"
    ></bottom-nav>
  </div>
</template>

<script>
import BottomNav from '@/components/common/BottomNav';
import AdvisorClientMixin from '@/common/mixins/advisor.client.mixin';
import {
  REGISTER_EMAIL,
  STATEMENT_UPLOAD,
  GET_TRANSITION_PUBLIC_INFO,
} from '@/stores/actions.type';
import { MENUS } from '@/common/constants';
import {
  NOTIFICATION_TYPES,
  NotificationService,
} from '@/services/notification.service';
// import { LOCAL_KEYS } from '@/common/constants';
// import StorageService from '@/services/storage.service';

export default {
  name: 'StatementUpload',
  mixins: [AdvisorClientMixin],
  components: { BottomNav },
  props: {
    transitionId: {
      type: String,
      required: true,
    },
    relatesTo: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    documents: ['Statement'],
    batchFile: null,
    submitButton: 'Submit',
    uploadedAt: null,
    editMode: false,
    emailEditMode: false,
    email: '',
    emailPrepopulated: false,
    emailConfirmed: false,
    logo: null,
    // logo: StorageService.getLocal(LOCAL_KEYS.COMPANY_LOGO) || null,
  }),
  computed: {
    nextText() {
      return this.emailConfirmed ? 'Submit' : 'Confirm Email';
    },
  },
  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileUpload() {
      this.batchFile = this.$refs.fileInput.files[0];
      this.editMode = true;
    },
    confirmEmail() {
      this.registerEmail();
      this.emailConfirmed = true;
    },
    registerEmail() {
      const payload = {
        email: this.email,
        transitionId: this.transitionId,
        relatesTo: this.relatesTo,
      };
      this.$store
        .dispatch(REGISTER_EMAIL, payload)
        .then(() => {
          this.emailPrepopulated = true;
          this.emailEditMode = false;
          this.emailConfirmed = true;
        })
        .catch(() => {
          this.emailConfirmed = false;
          NotificationService.alert({
            type: NOTIFICATION_TYPES.ERROR,
            title: 'Error',
            message: 'Error registering email!',
            okTitle: 'Ok',
          });
        });
    },
    toggleEmailEditMode() {
      this.emailEditMode = !this.emailEditMode;
      if (this.emailEditMode) {
        this.email = '';
      }
    },
    next() {
      if (this.emailEditMode) {
        this.confirmEmail();
        return;
      }
      if (this.batchFile) {
        const payload = {
          transitionId: this.transitionId,
          relatesTo: this.relatesTo,
          file: this.batchFile,
          email: this.email,
        };
        this.$store
          .dispatch(STATEMENT_UPLOAD, payload)
          .then((response) => {
            this.batchResponse = response;
            this.$router.push({
              name: MENUS.TRANSITIONS.STATEMENT_SUBMITTED.id,
            });
          })
          .catch((response) => {
            let message = 'Unexpected Error';
            if (response.message) message = response.message;
            NotificationService.alert(
              {
                type: NOTIFICATION_TYPES.ERROR,
                title: 'Error',
                message: message,
                okTitle: 'Ok',
              },
              response,
            );
          });
        return;
      }
      this.emailConfirmed = true;
    },
    prev() {
      if (this.emailConfirmed) {
        this.emailConfirmed = false; // Allow users to go back to email confirmation
      }
      const prev = MENUS.CLIENT.HOME.id;
      this.goPrev(prev);
    },
  },
  created() {
    this.$store
      .dispatch(GET_TRANSITION_PUBLIC_INFO, { transitionId: this.transitionId })
      .then((response) => {
        if (response && response.status !== 'StatementPending') {
          NotificationService.alert({
            type: NOTIFICATION_TYPES.INFO,
            title: 'Info',
            message: 'Statement already uploaded!',
            okTitle: 'Ok',
          });
          this.$router.push({ name: MENUS.TRANSITIONS.STATEMENT_SUBMITTED.id });
        }
        if (response) {
          const contact =
            this.relatesTo === 'Primary'
              ? response.primaryContact
              : response.secondaryContact;
          this.email = contact.email;
          this.editMode = !this.email;
          this.emailEditMode = !this.email;
          if (response.organisationLogo) {
            this.logo = response.organisationLogo;
          }
        }
      })
      .catch(() => {
        NotificationService.alert({
          type: NOTIFICATION_TYPES.ERROR,
          title: 'Error',
          message: 'Invalid parameters!',
          okTitle: 'Ok',
        });
        this.$router.push('/');
      });
  },
};
</script>

<style lang="scss" scoped>
.email-read {
  display: flex;
  margin: 0 auto;
  max-width: 50%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.steps-step-container {
  max-width: 50%;
  margin-left: auto;
  align-items: center;
  flex-direction: column;
}

.steps-table {
  width: 100%;
  max-width: 100%;
  border: 2px solid var(--secondary-color);
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  .row {
    margin: 0;

    &.section-row {
      border-top: 2px solid var(--secondary-color);
    }

    .col {
      padding: 20px 26px;
      border-top: none;

      &.data-col {
        border-left: 2px solid var(--secondary-color);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: 960px) {
          border-left: none;
        }

        .faux-upload {
          display: inline-block;
          padding: 0px;
          max-width: 160px;

          ::v-deep .v-input__slot {
            &:before,
            &:after {
              border-color: transparent !important;
              text-decoration-color: transparent !important;
              background-color: transparent;
            }

            .v-input__append-inner {
              width: 0px;
            }

            .v-text-field__slot {
              display: none;
            }

            .pdf {
              margin-right: 8px;
              color: #fff;
            }
          }
        }
      }

      &.step-col {
        background-color: var(--color-light-grey);
        align-items: center;
        display: flex;
        flex-direction: row;

        @media screen and (max-width: 960px) {
          background-color: transparent;
          font-size: 18px;
          font-weight: 500;
          padding-bottom: 0px;
        }
      }

      @media screen and (max-width: 960px) {
        min-width: 100%;
      }

      .mobile-label {
        display: none;

        @media screen and (max-width: 960px) {
          display: inline-block;
        }
      }
    }
  }
}

.step-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 20px;
  text-align: center;
}

.headerlight {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.email-display {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.btn {
  padding: 10px 15px;
  margin-top: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &.primary {
    background-color: #0056b3;
    color: white;
  }

  &.secondary {
    background-color: #f8f9fa;
    color: #333;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
  }
}

// Responsive design adjustments
@media (max-width: 768px) {
  .step-container {
    padding: 10px;
  }

  .btn {
    width: 100%; // Full-width buttons on mobile for easier interaction
    padding: 12px 0; // Larger tap area
  }
}
</style>
