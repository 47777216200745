<template>
  <div class="step-container client">
    <help-drawer :title="helpTitle">
      <div>
        <p>
          The rules under Section 16 of the Exchange Act require these
          "insiders" to report most of their transactions involving the
          company's equity securities to the
          <a href="https://www.sec.gov/" target="_blank">SEC</a>.
        </p>
      </div>
    </help-drawer>
    <v-form name="CompanyOfficial" class="form">
      <v-container class="client-step-container">
        <v-row class="mb-8">
          <v-col class="instructions">
            <h1>
              <span class="headerlight">
                Are you a
                <strong>
                  member of the board of directors, 10% shareholder
                </strong>
                or <strong>policy-making officer</strong>
                of a publicly held company?
              </span>
            </h1>
            <div class="help-link">
              <a @click="toggleHelp()">{{ helpTitle }}</a>
            </div>
          </v-col>
        </v-row>
        <data-submitted-message
          :submitted="isCompanyOfficialComplete"
          v-on:hide-form="toggleFormState"
        />
        <div v-if="!formHidden">
          <v-row class="justify-center">
            <v-col class="step-form-container radio-container mb-4">
              <v-radio-group
                v-model="companyOfficial"
                :error-messages="companyOfficialErrors"
                hide-details="auto"
                @change="$v.companyOfficial.$touch()"
              >
                <v-radio
                  :ripple="false"
                  class="v-faux-radio mb-4"
                  label="Yes"
                  :value="true"
                ></v-radio>
                <div class="expander" v-show="companyOfficial">
                  <label for="companyName">Company Name</label>
                  <v-text-field
                    outlined
                    type="text"
                    maxlength="256"
                    name="companyName"
                    v-model.trim="companyName"
                    :backgroundColor="inputBackgroundColor"
                    @input="$v.companyName.$touch()"
                    @blur="$v.companyName.$touch()"
                    :error-messages="companyNameErrors"
                    hide-details="auto"
                  />
                  <label for="tickerSymbol">Ticker Symbol</label>
                  <v-text-field
                    outlined
                    type="text"
                    maxlength="5"
                    name="tickerSymbol"
                    v-model.trim="tickerSymbol"
                    :backgroundColor="inputBackgroundColor"
                    @input="$v.tickerSymbol.$touch()"
                    @blur="$v.tickerSymbol.$touch()"
                    :error-messages="tickerSymbolErrors"
                    hide-details="auto"
                    class="ticker"
                  />
                </div>
                <v-radio
                  :ripple="false"
                  class="v-faux-radio"
                  label="No"
                  :value="false"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <div class="skip-for-now">
            <a @click="skip()">Skip for now</a>
          </div>
        </div>
      </v-container>
    </v-form>
    <bottom-nav v-on:go-prev="prev()" v-on:go-next="next()"></bottom-nav>
  </div>
</template>

<script>
import HelpDrawer from '@/components/HelpDrawerComponent';
import BottomNav from '@/components/common/BottomNav';
import DataSubmittedMessage from '@/components/client/DataSubmittedMessage';
import InterviewClientMixin from '@/common/mixins/interview.client.mixin';
import { required, requiredIf } from 'vuelidate/lib/validators';
import { MENUS } from '@/common/constants';

export default {
  name: 'CompanyOfficial',

  components: { BottomNav, HelpDrawer, DataSubmittedMessage },
  mixins: [InterviewClientMixin],

  data: () => ({
    companyOfficial: null,
    companyName: null,
    tickerSymbol: null,
  }),

  validations() {
    return {
      companyOfficial: {
        required,
      },
      companyName: {
        required: requiredIf(() => this.companyOfficial),
      },
      tickerSymbol: {
        required: requiredIf(() => this.companyOfficial),
      },
      validationGroup: ['companyOfficial', 'companyName', 'tickerSymbol'],
    };
  },

  computed: {
    /* Dynamic errors */
    companyOfficialErrors() {
      const errors = [];
      if (!this.$v.companyOfficial.$dirty) return errors;
      !this.$v.companyOfficial.required &&
        errors.push('A selection is required');
      return errors;
    },
    companyNameErrors() {
      const errors = [];
      if (!this.$v.companyName.$dirty) return errors;
      !this.$v.companyName.required && errors.push('Company Name is required');
      return errors;
    },
    tickerSymbolErrors() {
      const errors = [];
      if (!this.$v.tickerSymbol.$dirty) return errors;
      !this.$v.tickerSymbol.required &&
        errors.push('Ticker Symbol is required');
      return errors;
    },
    helpTitle() {
      return 'Why do you need this?';
    },
  },

  methods: {
    /* Nav to Next Page in flow */
    next(route = null) {
      const to = route || MENUS.CLIENT.BENEFICIAL_INTEREST.id;

      if (!this.formHidden) {
        this.$v.$touch();
        if (this.$v.$invalid) return;
      } else {
        this.goNext(to);
        return;
      }

      const data = this.companyOfficial
        ? {
            companyName: this.companyName,
            tickerSymbol: this.tickerSymbol,
          }
        : {};

      this.completeStep(MENUS.CLIENT.COMPANY_OFFICIAL.id, data)
        .then(() => {
          this.goNext(to);
        })
        .catch(() => {});
    },

    /* Nav to Previous Page in flow */
    prev() {
      // If we have a job description page previous page is that
      // Otherwise, previous page is the employment status page
      const hasJobPage =
        this.getPage(MENUS.CLIENT.JOB_DESCRIPTION.id) !== undefined;
      const prevPage = hasJobPage
        ? MENUS.CLIENT.JOB_DESCRIPTION.id
        : MENUS.CLIENT.EMPLOYMENT_STATUS.id;
      this.goPrev(prevPage);
    },

    /* Skip to next Page in flow */
    skip() {
      this.goPrev(MENUS.CLIENT.BENEFICIAL_INTEREST.id);
    },
  },

  created() {
    // Initialize form data for current interview
    this.formHidden = this.isCompanyOfficialComplete;
  },
};
</script>
<style lang="scss" scoped>
.expander {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--secondary-color);

  .ticker {
    ::v-deep .v-input__slot {
      max-width: 90px !important;
    }
  }
}
</style>
