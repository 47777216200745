<template>
  <div class="advisor">
    <v-form v-if="!batchResponse" name="FirmRoles" class="form">
      <v-container class="teams-step-container">
        <v-row class="mb-8">
          <v-col class="instructions">
            <h1>
              <span class="headerlight">
                Select a <strong>CSV Batch File</strong> with Client Information
                for Transitions
              </span>
            </h1>
          </v-col>
        </v-row>
        <v-row class="mb-15">
          <v-file-input
            v-model="batchFile"
            prepend-icon=""
            class="faux-upload"
            hide-details="auto"
            accept="text/csv"
            ref="upload-batch"
            :clearable="false"
          >
            <template v-slot:prepend-inner>
              <a
                class="btn upload-batch"
                style="display: block"
                @click="() => $refs['upload-batch'].$refs.input.click()"
              >
                <v-icon small class="csv"> fas fa-file-csv </v-icon>
                Upload Client List
              </a>
            </template>
          </v-file-input>
        </v-row>
        <v-row class="mb-8 d-flex flex-column align-center">
          <span class="mb-2">
            Download the <strong>CSV Template</strong> for
            {{ selectedTransitionType }} Transitions
          </span>
          <a class="upload-batch" @click="downloadTemplate()">
            <v-icon small class="csv"> fas fa-file-csv </v-icon>
            Download Template
          </a>
        </v-row>
      </v-container>
    </v-form>
    <bottom-nav
      :nextDisabled="!batchFile ? true : false"
      v-on:go-prev="prev()"
      v-on:go-next="continueAction()"
    ></bottom-nav>
  </div>
</template>
<script>
import BottomNav from '@/components/common/BottomNav';
import { validationMixin } from 'vuelidate';
import { MENUS, TRANSITION_TYPES, formatPhoneLocal } from '@/common/constants';
import {
  TRANSITION_BATCH,
  DOWNLOAD_CSV_NONPROTOCOL_TRANSITIONS_TEMPLATE,
  DOWNLOAD_CSV_PROTOCOL_TRANSITIONS_TEMPLATE,
} from '@/stores/actions.type';
import {
  NOTIFICATION_TYPES,
  NotificationService,
} from '@/services/notification.service';
import { mapGetters } from 'vuex';
import { SET_TRANSITION_HOUSEHOLDS } from '../../../stores/mutations.type';

export default {
  components: { BottomNav },
  mixins: [validationMixin],

  data: () => ({
    batchFile: null,
    batchResponse: null,
  }),

  computed: {
    ...mapGetters(['selectedTransitionType']),
  },

  methods: {
    continueAction() {
      this.sendBatchRequest();
    },

    prev() {
      this.$router
        .push({
          name: MENUS.TRANSITIONS.TRANSITION_CLIENTS.id,
        })
        .catch(() => {});
    },

    sendBatchRequest() {
      const payload = {
        file: this.batchFile,
        transitionType: this.selectedTransitionType,
      };

      this.$store
        .dispatch(TRANSITION_BATCH, payload)
        .then((response) => {
          const mappedHouseholds = response.map((household) => ({
            ...household,
            primaryContact: {
              ...household.primaryContact,
              phone: formatPhoneLocal(household.primaryContact.mobile),
            },
            secondaryContact: household.secondaryContact
              ? {
                  ...household.secondaryContact,
                  phone: formatPhoneLocal(household.secondaryContact.mobile),
                }
              : null,
            firmRoles: {
              primaryAdvisor: household.primaryAdvisor,
              primaryCSA: household.primaryCSA,
            },
          }));

          this.$store
            .dispatch(SET_TRANSITION_HOUSEHOLDS, mappedHouseholds)
            .then(() => {
              this.$router.push({
                name: MENUS.TRANSITIONS.TRANSITION_HOUSEHOLD.id,
              });
            });
        })
        .catch((response) => {
          const error = response.response.data;
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: error.message.includes('Invalid data' || 'empty file')
                ? 'Invalid or empty CSV file. Please download the template and try again.'
                : error.message,
              okTitle: 'Ok',
              okMethod: () => {
                this.$router.push({
                  name: MENUS.TRANSITIONS.TRANSITION_BATCH.id,
                });
              },
            },
            response,
          );
        });
    },

    downloadTemplate() {
      const action =
        this.selectedTransitionType === TRANSITION_TYPES.PROTOCOL
          ? DOWNLOAD_CSV_PROTOCOL_TRANSITIONS_TEMPLATE
          : DOWNLOAD_CSV_NONPROTOCOL_TRANSITIONS_TEMPLATE;
      this.$store.dispatch(action);
    },
  },

  created() {
    this.MENUS = MENUS;
  },
};
</script>
<style lang="scss" scoped>
.teams-step-container {
  max-width: 1024px;
  align-items: center;
  display: flex;
  flex-direction: column;
}

::v-deep {
  .faux-upload {
    display: inline-block;
    padding: 0px;
    .upload-batch {
      color: var(--color-white) !important;
      text-decoration: none;
      max-width: 260px;
    }
    .csv {
      margin-right: 8px;
      color: #fff;
    }
    .v-input__slot {
      &:before,
      &:after {
        border-color: transparent !important;
        text-decoration-color: transparent !important;
        background-color: transparent;
      }

      .v-input__append-inner {
        width: 0px;
      }
      .v-text-field__slot {
        font-size: 24px;
      }
    }
  }
}
</style>
