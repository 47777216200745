import Vue from 'vue';
import App from './App.vue';
import '@/scss/index.scss';
import vuetify from './plugins/vuetify';
import router from './routes/router';
import store from '@/stores';
import ApiService from '@/services/api.service';
import VuetifyToast from 'vuetify-toast-snackbar';
import IdleVue from 'idle-vue';
import { IDLE_TIMEOUT_SECONDS } from '@/common/constants';
import { EventBus } from '@/services/event.service';
import { PermissionService } from '@/services/permission.service';
import VueLodash from 'vue-lodash';
import lodash from 'lodash';

import { library, dom, config } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fas as fap } from '@fortawesome/pro-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// Add FontAwesome libraries
config.searchPseudoElements = true;
library.add(fas, far, fap);

// This is needed to watch the DOM for any additional icons being added or modified.
dom.watch();
Vue.component('font-awesome-icon', FontAwesomeIcon);

window.moment = require('moment');
Vue.use(VueLodash, { lodash: lodash });
const ignoreWarnMessage =
  'The .native modifier for v-on is only valid on components but it was used on';

// Configure Idle Timer
const idleOpts = {
  eventEmitter: EventBus,
  store,
  idleTime: IDLE_TIMEOUT_SECONDS * 1000,
  startAtIdle: false,
};
Vue.use(IdleVue, idleOpts);

// Add Toast Component
Vue.use(VuetifyToast);
Vue.config.productionTip = false;
ApiService.init();

/*eslint-disable*/
/* Hide vue message about modifier */
Vue.config.warnHandler = function (msg, vm, trace) {
  // `trace` is the component hierarchy trace
  if (msg.includes(ignoreWarnMessage)) {
    msg = null;
    vm = null;
    trace = null;
  }
};

// Guard routes based on current user permissions
router.beforeEach((to, from, next) => {
  PermissionService.guardAccess(to)
    .then(() => next())
    .catch((data) => next(data));
});

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
