import { organizationMapper } from './mappers/organizationMapper';
import { updateInterviewMapper } from './mappers/updateInterviewMapper';
import {
  ADD_CLIENT,
  UPDATE_COMPANY_INFO,
  UPDATE_INTERVIEW,
} from '../../stores/actions.type';
import { clientMapper } from './mappers/clientMapper';
export function requestMapper(action, request) {
  const mapper =
    {
      [UPDATE_COMPANY_INFO]: organizationMapper,
      [UPDATE_INTERVIEW]: updateInterviewMapper,
      [ADD_CLIENT]: clientMapper,
    }[action] || null;

  return mapper ? mapper(request) : request;
}
