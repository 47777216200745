import {
  SET_WORKING_CLIENT,
  ADD_CLIENT,
  EDIT_CLIENT,
} from '@/stores/actions.type';
import Themable from '@/common/mixins/themable.mixin';
import LeaveGuard from '@/common/mixins/leaveguard.mixin';
import { validationMixin } from 'vuelidate';
import ClientMixin from '@/common/mixins/client.mixin';
import EventListener from '@/common/mixins/eventlistener.mixin';
import { EventService } from '@/services/event.service';
import { mapGetters } from 'vuex';
import {
  JOINT_NAME_BROKERAGE_TYPES,
  DB_JOINT_NAME_BROKERAGE_TYPES,
  INDIVIDUAL_OWNERSHIP,
  ACCOUNT_TYPES,
  DB_ACCOUNT_TYPES,
  MENUS,
  CONTACT_TYPES,
  DB_FEATURES_TYPES,
} from '@/common/constants';
import {
  NOTIFICATION_TYPES,
  NotificationService,
} from '@/services/notification.service';
import { formatPhone } from '../constants';

const AdvisorClientMixin = {
  data: () => ({
    ignoreInProgessClient: false,
  }),

  mixins: [validationMixin, EventListener, LeaveGuard, Themable, ClientMixin],

  computed: {
    ...mapGetters(['currentUser', 'currentCompany']),
  },

  methods: {
    // Toggle Help Drawer
    toggleHelp() {
      EventService.emit('toggle-help');
    },

    /* Page is dirty as long as we are not ignoring an in progress client and the client has data (is in progress) */
    getAdditionalDirtyIndicator() {
      return (
        !this.ignoreInProgessClient && !this.lodash.isEmpty(this.currentClient)
      );
    },

    /* Save option shown if we are navigating within the advisor client pages */
    showSaveFunction(route) {
      return route.path.includes('/advisor/client');
    },

    /* 
      Go to the next route.
      Saves in memory (updated) working client data
    */
    goNext(route) {
      if (this.formErrors) this.formErrors.$reset();

      this.ignoreInProgessClient = true;
      this.$store.dispatch(SET_WORKING_CLIENT, this.currentClient).then(() => {
        this.$router.push({ name: route }).catch(() => {});
      });
    },

    /*
      Go to the previous route 
      Abandons any changes
    */
    goPrev(route) {
      if (this.formErrors) {
        this.formErrors.$reset();
      }
      this.ignoreInProgessClient = true;
      this.$router.push({ name: route }).catch(() => {});
    },

    /*
      Called before we exit the page
      If we are leaving the new client process, delete the working client
    */
    beforeExit(route) {
      const showSave = this.showSaveFunction(route);
      if (!showSave) {
        this.$store.dispatch(SET_WORKING_CLIENT, null);
      }
    },

    /* Reset Form */

    /*
      Map Account data from working client to 
      JSON needed for backend API
    */
    mapClientPayloadAccounts(isPrimary = true) {
      const clientObj = isPrimary
        ? this.currentClient.client1
        : this.currentClient.client2;

      const mapInternalOwnershipType = (internalType) =>
        ({
          [JOINT_NAME_BROKERAGE_TYPES.JTWROS]:
            DB_JOINT_NAME_BROKERAGE_TYPES.JTWROS,
          [JOINT_NAME_BROKERAGE_TYPES.TENANTS_IN_COMMON]:
            DB_JOINT_NAME_BROKERAGE_TYPES.TENANTS_IN_COMMON,
          [JOINT_NAME_BROKERAGE_TYPES.TENANTS_BY_ENTIRETY]:
            DB_JOINT_NAME_BROKERAGE_TYPES.TENANTS_BY_ENTIRETY,
        }[internalType] || INDIVIDUAL_OWNERSHIP);

      const mapInternalAccountType = (internalType) =>
        ({
          [ACCOUNT_TYPES.SINGLE_NAME_BROKERAGE]:
            DB_ACCOUNT_TYPES.SINGLE_NAME_BROKERAGE,
          [ACCOUNT_TYPES.JOINT_NAME_BROKERAGE]:
            DB_ACCOUNT_TYPES.JOINT_NAME_BROKERAGE,
          [ACCOUNT_TYPES.IRA]: DB_ACCOUNT_TYPES.IRA,
          [ACCOUNT_TYPES.ROTH_IRA]: DB_ACCOUNT_TYPES.ROTH_IRA,
          [ACCOUNT_TYPES.OFFLINE]: DB_ACCOUNT_TYPES.OFFLINE,
          [ACCOUNT_TYPES.OFFLINE_TRUST]: DB_ACCOUNT_TYPES.OFFLINE_TRUST,
          [ACCOUNT_TYPES.OFFLINE_INHERITED_IRA]:
            DB_ACCOUNT_TYPES.OFFLINE_INHERITED_IRA,
          [ACCOUNT_TYPES.OFFLINE_SIMPLE_IRA]:
            DB_ACCOUNT_TYPES.OFFLINE_SIMPLE_IRA,
          [ACCOUNT_TYPES.OFFLINE_CORPORATE]: DB_ACCOUNT_TYPES.OFFLINE_CORPORATE,
          [ACCOUNT_TYPES.OFFLINE_OTHER]: DB_ACCOUNT_TYPES.OFFLINE_OTHER,
        }[internalType] || internalType);

      const clientAccounts = clientObj.accounts;
      const accounts = [];

      if (clientAccounts) {
        clientAccounts.forEach((a) => {
          const account = {
            type: mapInternalAccountType(a.type),
            label: a.label,
            ownership:
              a.type === ACCOUNT_TYPES.JOINT_NAME_BROKERAGE
                ? mapInternalOwnershipType(a.ownership)
                : INDIVIDUAL_OWNERSHIP,
            masterAccountNumber: a.masterAccountNumber,
            advisoryRate: a.rate || null,
            features: [],
          };
          if (a.moveMoney) account.features.push(DB_FEATURES_TYPES.MONEY_LINK);
          if (a.acat) account.features.push(DB_FEATURES_TYPES.ACAT);
          if (a.iraDistribution)
            account.features.push(DB_FEATURES_TYPES.IRA_DISTRIBUTION);

          // JOINT NAME BROKERAGE ACCOUNTS DO NOT GET MAPPED TO THE SECONDARY CLIENT
          if (
            isPrimary ||
            (!isPrimary && a.type !== ACCOUNT_TYPES.JOINT_NAME_BROKERAGE)
          ) {
            accounts.push(account);
          }
        });
      }

      return accounts;
    },

    /*
      Map Data from working client to 
      JSON needed for backend API
    */
    mapClientPayload() {
      const payload = {
        id: this.currentClient.id || null,
        readyToSend: this.areAllStepsComplete,
        fixedRate: this.currentClient.rateType === this.RATE_TYPES.FLAT,
        customTemplates: this.currentClient.addCustomQuestionsSelected
          ? this.currentClient.selectedTemplates.map(
              (template) => template._id,
            ) || []
          : [],
        featuresSelected: this.currentClient.featuresVisited || false,
        docusignSelected: this.currentClient.esignEnabled || false,
        doClientProfiling: this.currentClient.doClientProfiling,
        addAccountsSelected: this.currentClient.addAccountsSelected || false, // TODO:  Non-DocuSign flow
        primaryContact: {
          firstName: this.currentClient.client1.firstName,
          lastName: this.currentClient.client1.lastName,
          email: this.currentClient.client1.email || null,
          mobile: this.currentClient.client1.phone
            ? formatPhone(this.currentClient.client1.phone)
            : null,
          skipContactInterview:
            this.currentClient.client1.preserveInterview || false,
        },
      };

      if (this.currentClient.client1.crmId) {
        payload.primaryContact.crmClientId = this.currentClient.client1.crmId;
      }

      if (this.addedSecondClient) {
        payload.secondaryContact = {
          firstName: this.currentClient.client2.firstName,
          lastName: this.currentClient.client2.lastName,
          email: this.currentClient.client2.email || null,
          mobile: this.currentClient.client2.phone
            ? formatPhone(this.currentClient.client2.phone)
            : null,
          clientId: this.currentClient.client2.clientId || null,
          skipContactInterview:
            this.currentClient.client2.preserveInterview || false,
        };

        if (this.currentClient.client2.crmId) {
          payload.secondaryContact.crmClientId =
            this.currentClient.client2.crmId;
        }
      }

      if (this.canAddAccounts) {
        payload.primaryContact.accounts = this.mapClientPayloadAccounts();
        if (this.addedSecondClient) {
          payload.secondaryContact.accounts =
            this.mapClientPayloadAccounts(false);
        }
      }

      if (this.currentClient.firmRoles) {
        payload.primaryAdvisor = this.currentClient.firmRoles.primaryAdvisor
          ? {
              id: this.currentClient.firmRoles.primaryAdvisor.id,
            }
          : null;
        payload.primaryCSA = this.currentClient.firmRoles.primaryCSA
          ? {
              id: this.currentClient.firmRoles.primaryCSA.id,
            }
          : null;
        payload.provideAdvice = [];
        if (this.currentClient.firmRoles.provideAdvice.length > 0) {
          this.currentClient.firmRoles.provideAdvice.forEach((p) =>
            payload.provideAdvice.push({ id: p.id }),
          );
        }
        payload.secondaryCSA = [];
        if (this.currentClient.firmRoles.secondaryCSA.length > 0) {
          this.currentClient.firmRoles.secondaryCSA.forEach((p) =>
            payload.secondaryCSA.push({ id: p.id }),
          );
        }
      }

      return payload;
    },

    /*
      True if payload indicates a fixed rate and any account has an advisory rate set.
    */
    hasFlatRatePercent(payload) {
      if (!payload.fixedRate) return false;

      const accounts = payload.primaryContact.accounts;
      for (var account of accounts) {
        if (account.advisoryRate !== null) return true;
      }
      return false;
    },

    /* BEGING RECONSTITUE CLIENT LOGIC */

    createClient(contact) {
      return {
        type: contact.crmClientId ? CONTACT_TYPES.EXISTING : CONTACT_TYPES.NEW,
        firstName: contact.firstName,
        lastName: contact.lastName,
        email: contact.email,
        phone: contact.mobile,
        clientId: contact.clientId,
        preserveInterview: contact.skipContactInterview,
        canSkipInterview: contact.canSkipInterview,
        crmClientId: contact.crmClientId,
      };
    },

    createRole(person) {
      return {
        id: person.id,
        name: `${person.firstName} ${person.lastName}`,
      };
    },

    mapAccount(dbAccount) {
      const ACCOUNT_TYPE_MAP = {
        [DB_ACCOUNT_TYPES.SINGLE_NAME_BROKERAGE]:
          ACCOUNT_TYPES.SINGLE_NAME_BROKERAGE,
        [DB_ACCOUNT_TYPES.JOINT_NAME_BROKERAGE]:
          ACCOUNT_TYPES.JOINT_NAME_BROKERAGE,
        [DB_ACCOUNT_TYPES.IRA]: ACCOUNT_TYPES.IRA,
        [DB_ACCOUNT_TYPES.ROTH_IRA]: ACCOUNT_TYPES.ROTH_IRA,
        [DB_ACCOUNT_TYPES.OFFLINE]: ACCOUNT_TYPES.OFFLINE,
      };

      const JOINT_OWNERSHIP_TYPE_MAP = {
        [DB_JOINT_NAME_BROKERAGE_TYPES.JTWROS]:
          JOINT_NAME_BROKERAGE_TYPES.JTWROS,
        [DB_JOINT_NAME_BROKERAGE_TYPES.TENANTS_IN_COMMON]:
          JOINT_NAME_BROKERAGE_TYPES.TENANTS_IN_COMMON,
        [DB_JOINT_NAME_BROKERAGE_TYPES.TENANTS_BY_ENTIRETY]:
          JOINT_NAME_BROKERAGE_TYPES.TENANTS_BY_ENTIRETY,
      };

      return {
        type: ACCOUNT_TYPE_MAP[dbAccount.type] || dbAccount.type,
        label: dbAccount.label,
        ownership: JOINT_OWNERSHIP_TYPE_MAP[dbAccount.ownership] || null,
        masterAccountNumber: dbAccount.masterAccountNumber,
        accountId: dbAccount.accountId,
        moveMoney:
          dbAccount.features &&
          dbAccount.features.includes(DB_FEATURES_TYPES.MONEY_LINK),
        acat:
          dbAccount.features &&
          dbAccount.features.includes(DB_FEATURES_TYPES.ACAT),
        iraDistribution:
          dbAccount.features &&
          dbAccount.features.includes(DB_FEATURES_TYPES.IRA_DISTRIBUTION),
        rate: dbAccount.advisoryRate || null,
      };
    },

    reconstituteAccounts(payload, secondaryContact = false) {
      const clientAccounts = secondaryContact
        ? payload.secondaryContact.accounts
        : payload.primaryContact.accounts;

      const primaryAccounts = payload.primaryContact.accounts;

      const accounts = clientAccounts.map(this.mapAccount);

      if (secondaryContact) {
        const jointAccounts = primaryAccounts
          .filter(
            (account) => account.type === DB_ACCOUNT_TYPES.JOINT_NAME_BROKERAGE,
          )
          .map(this.mapAccount);

        accounts.push(...jointAccounts);
      }

      return accounts;
    },

    reconstituteFirmRoles(payload) {
      const firmRoles = {
        primaryCSA: payload.primaryCSA
          ? this.createRole(payload.primaryCSA)
          : null,
        primaryAdvisor: payload.primaryAdvisor
          ? {
              ...this.createRole(payload.primaryAdvisor),
              canSend: payload.primaryAdvisor.canSend || true,
            }
          : null,
        provideAdvice: payload.provideAdvice
          ? payload.provideAdvice
              .filter(
                (p) =>
                  !payload.primaryAdvisor || payload.primaryAdvisor.id !== p.id,
              )
              .map((p) => this.createRole(p))
          : [],
        secondaryCSA: payload.secondaryCSA
          ? payload.secondaryCSA.map((p) => this.createRole(p))
          : [],
      };

      return this.lodash.isEmpty(firmRoles) ? null : firmRoles;
    },

    reconstituteClient(payload) {
      const clientObj = {
        id: payload.id,
        isExistingOnbord: true,
        client1: this.createClient(payload.primaryContact),
        addSecond: payload.secondaryContact ? 'Y' : 'N',
        client2: payload.secondaryContact
          ? this.createClient(payload.secondaryContact)
          : null,
        type: this.DATA_TYPES.TYPE_ONE,
        firmRoles: this.reconstituteFirmRoles(payload),
        addAccountsSelected: payload.addAccountsSelected,
      };

      if (!this.lodash.isEmpty(payload.primaryContact.accounts)) {
        clientObj.type = this.DATA_TYPES.TYPE_TWO;
        clientObj.client1.accounts = this.reconstituteAccounts(payload);
        clientObj.rateType = payload.fixedRate
          ? this.hasFlatRatePercent(payload)
            ? this.RATE_TYPES.FLAT
            : null
          : this.RATE_TYPES.MANUAL;
      }

      if (payload.secondaryContact) {
        clientObj.client2.accounts = this.reconstituteAccounts(payload, true);
      }

      if (
        clientObj.client1.preserveInterview &&
        (!payload.secondaryContact ||
          (clientObj.client2 && clientObj.client2.preserveInterview))
      ) {
        clientObj.type = this.DATA_TYPES.TYPE_THREE;
      }

      clientObj.featuresVisited = payload.featuresSelected;

      return clientObj;
    },

    /* END RECONSTITUE CLIENT LOGIC */

    /* Save the working client */
    saveClient(client) {
      // Edit if existing client, else add
      const action = client.id ? EDIT_CLIENT : ADD_CLIENT;

      this.$store
        .dispatch(action, client)
        .then(() => {
          NotificationService.alert({
            type: NOTIFICATION_TYPES.SUCCESS,
            title: 'Success',
            message: 'Client successfully saved!',
            okTitle: 'Ok',
            okMethod: () => {
              this.ignoreInProgessClient = true;
              this.formErrors.$reset();
              this.$store.dispatch(SET_WORKING_CLIENT, null).finally(() => {
                this.$router.push({ name: MENUS.CLIENTS.id });
              });
            },
          });
        })
        .catch((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: 'Error saving client!',
              okTitle: 'Ok',
            },
            response,
          );
        })
        .finally(() => {});
    },

    getSaveMessage() {
      const incompleteClientTwo =
        this.currentClient.addSecond === 'Y' && !this.isClientTwoNameComplete;
      const incompleteClientOne = !(
        this.clientOne.firstName && this.clientOne.lastName
      );
      let canSaveMsg =
        incompleteClientOne || incompleteClientTwo
          ? 'You must complete all client names before saving!'
          : null;
      return canSaveMsg;
    },

    // Toggle Exit Modal
    toggleExitModal() {
      let saveMsg = this.getSaveMessage();
      this.formErrors.$reset();
      EventService.emit('toggle-exit-modal', saveMsg);
    },
  },

  created() {
    /* 
      On navigate from menu
      Leave and close nav drawer
    */
    this.listen('navigate-to', (route) => {
      //this.ignoreInProgessClient = true;
      this.$router.push({ name: route }).catch(() => {
        EventService.emit('toggle-main-drawer');
      });
    });

    this.listen('cancel-and-exit', () => {
      this.ignoreInProgessClient = true;
      this.formErrors.$reset();
      this.$router.push({ name: MENUS.CLIENTS.id }).catch(() => {
        EventService.emit('toggle-main-drawer');
      });
    });

    this.listen('map-client-data', () => {
      this.formErrors.$touch();

      if (!this.formErrors.$invalid) {
        this.mapData();
      }
      this.toggleExitModal();
    });

    this.listen('save-and-exit', () => {
      // Simply Save
      const payload = this.mapClientPayload();
      payload.sendNow = false;

      this.saveClient(payload);
    });

    // Prompt leave via manual url change if dirty
    window.addEventListener('beforeunload', function (e) {
      // Cancel the event
      e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
      // Chrome requires returnValue to be set
      e.returnValue = '';
    });
  },
};

export default AdvisorClientMixin;
