import { isEmpty } from 'lodash';
import { checkSetupComplete } from '../../checkSetupComplete';

export function advisorMapper(response) {
  const { organisation, personalInfo, integrations, role, assets } =
    response || {};

  const [crmLinked, docusignLinked] = ['CRM', 'DocumentSigning'].map(
    (type) =>
      !!integrations &&
      integrations.some(
        ({ integrationType, integrationConfig }) =>
          integrationType === type && !isEmpty(integrationConfig),
      ),
  );

  const hasPhoneNumber = !!personalInfo.phone;
  const isUserSetupComplete = checkSetupComplete([crmLinked, hasPhoneNumber]);

  const { address } = organisation;

  return {
    isImpersonator: false,
    docusignEnabled: true,
    organisation: {
      id: organisation._id,
      name: organisation.name,
      phone: organisation.phone,
      state: address ? address.state : null,
      city: address ? address.city : null,
      zip: address ? address.zip : null,
      address1: address ? address.street : null,
      address2: null,
      endDate: null,
    },
    user: {
      id: response._id,
      organisationId: organisation._id,
      email: personalInfo.email,
      login: personalInfo.email,
      mobilePhone: personalInfo.phone,
      title: null,
      firstName: personalInfo.firstName,
      lastName: personalInfo.lastName,
      passwordLastFailed: null,
      passwordFails: 0,
      passwordExpires: null,
      preauthTokenExpires: null,
      useSmsNotifications: false,
      pendingLogin: null,
      canSend: true,
      setupComplete: isUserSetupComplete,
      crmLinked,
      docusignLinked,
      startDate: '',
      endDate: null,
      lockDate: null,
    },
    integrations,
    roles: [
      {
        description: '',
        id: role.name,
        disabled: false,
        name: role.name,
      },
    ],
    permissions: role.permissions,
    assets,
  };
}
