var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"responsive-table household-container",attrs:{"headers":_vm.headers,"items":_vm.households,"expanded":_vm.expanded,"item-key":"householdName","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.advisor",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"hide-details":"auto","items":_vm.advisorsList,"value":item.firmRoles.primaryAdvisor,"item-value":"id","item-text":"name","label":"Select Advisor","menu-props":{ offsetY: true },"outlined":""},on:{"input":function($event){return _vm.handleAdvisorChange($event, item)}}})]}},{key:"item.csa",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"hide-details":"auto","items":_vm.csaList,"value":item.firmRoles.primaryCSA,"item-text":"name","item-value":"id","label":"Select CSA","menu-props":{ offsetY: true },"outlined":""},on:{"input":function($event){return _vm.handleCSAChange($event, item)}}})]}},{key:"item.assetsValue",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"outlined":"","type":"text","maxlength":"256","name":"assetsValue","aria-required":"true","backgroundColor":_vm.inputBackgroundColor,"placeholder":"Assets Value","hide-details":"auto"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.updateHouseholdValue(item)}},model:{value:(item.assetsValue),callback:function ($$v) {_vm.$set(item, "assetsValue", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.assetsValue"}})]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"colspan":_vm.headers.length + 1}},[_c('v-data-table',{staticClass:"clients-table v-data-table-header",attrs:{"headers":_vm.headersHouseholdMembers,"items":[item.primaryContact, item.secondaryContact].filter(
            function (contact) { return contact; }
          ),"hide-default-footer":"","hide-default-header":_vm.isMobile,"loading":""},scopedSlots:_vm._u([{key:"item.firstName",fn:function(ref){
          var item = ref.item;
return [_c('v-text-field',{attrs:{"outlined":"","type":"text","maxlength":"256","name":"firstName","aria-required":"true","backgroundColor":_vm.inputBackgroundColor,"placeholder":"First Name","hide-details":"auto"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.updateHouseholdName(item)}},model:{value:(item.firstName),callback:function ($$v) {_vm.$set(item, "firstName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.firstName"}})]}},{key:"item.lastName",fn:function(ref){
          var item = ref.item;
return [_c('v-text-field',{attrs:{"outlined":"","type":"text","maxlength":"256","name":"name","aria-required":"true","backgroundColor":_vm.inputBackgroundColor,"placeholder":"Last Name","hide-details":"auto"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.updateHouseholdName(item)}},model:{value:(item.lastName),callback:function ($$v) {_vm.$set(item, "lastName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.lastName"}})]}},{key:"item.phone",fn:function(ref){
          var item = ref.item;
return [_c('v-text-field',{attrs:{"outlined":"","type":"phone","maxlength":"256","name":"phoneNumber","aria-required":"true","backgroundColor":_vm.inputBackgroundColor,"placeholder":"Phone Number","hide-details":"auto"},on:{"blur":function($event){_vm.$v.users.$each[_vm.props.index].phoneNumber.$touch()},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.updateHouseholdName(item)}},model:{value:(item.phone),callback:function ($$v) {_vm.$set(item, "phone", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.phone"}})]}},{key:"item.email",fn:function(ref){
          var item = ref.item;
return [_c('v-text-field',{attrs:{"outlined":"","type":"email","maxlength":"256","name":"email","aria-required":"true","backgroundColor":_vm.inputBackgroundColor,"placeholder":"email","hide-details":"auto"},on:{"blur":function($event){_vm.$v.users.$each[_vm.props.index].email.$touch()},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.updateHouseholdName(item)}},model:{value:(item.email),callback:function ($$v) {_vm.$set(item, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.email"}})]}}],null,true)})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }