<template>
  <div v-cloak class="client-container">
    <!-- Nav Drawer - Mobile -->
    <v-navigation-drawer
      v-model="drawer"
      right
      app
      clipped
      id="nav-drawer"
      overlay-opacity="0"
      overlay-color="#fff"
      class="hidden-lg-and-up"
      width="300"
    >
      <interview-menu />
    </v-navigation-drawer>

    <!-- App Bar -->
    <v-app-bar app flat class="app-top-bar" height="82">
      <div id="header">
        <a @click="exit()" class="btn secondary exit">
          <span class="exit-arrow">
            <i class="fas fa-sign-out-alt"></i>
          </span>
          <span class="ml-3 d-none d-sm-inline-flex">Exit</span>
        </a>
        <div class="home-logo-container">
          <img
            :src="logo"
            v-if="logo"
            width="120"
            loading="lazy"
            alt="OnBord"
            class="logo"
            id="companyLogo"
          />
        </div>
        <div v-if="!navDisabled" class="hidden-md-and-down">
          <interview-menu />
        </div>
        <div class="hidden-lg-and-up">
          <v-app-bar-nav-icon
            v-if="!navDisabled"
            @click.stop="toggleDrawer()"
          ></v-app-bar-nav-icon>
        </div>
      </div>
    </v-app-bar>
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import InterviewMenu from '@/components/client/InterviewMenu';
import {
  MENUS,
  LOCAL_KEYS,
  AUTH_TYPES,
  CLIENT_EXIT_KEYS,
} from '@/common/constants';
import EventListener from '@/common/mixins/eventlistener.mixin';
import { EventService } from '@/services/event.service';
import Storage from '@/services/storage.service';
import PublicAssets from '@/common/mixins/publicasset.mixin';
import StorageService from '@/services/storage.service';

export default {
  name: 'ClientHome',

  components: { InterviewMenu },
  mixins: [PublicAssets, EventListener],

  data: () => ({
    drawer: false,
    navDisabled: false,
    logo: StorageService.getLocal(LOCAL_KEYS.COMPANY_LOGO) || null,
  }),

  computed: {
    ...mapGetters(['isAuthenticated', 'isClientAuthenticated']),
  },
  methods: {
    /* Toggle Navigation Drawer */
    toggleDrawer() {
      if (this.navDisabled) return;

      this.drawer = !this.drawer;
    },

    /* Leave the Interview Flow */
    exit() {
      // Unauthenticated clients go to Exit Page
      // Users go to Clients page.
      const isClient =
        Storage.getLocal(LOCAL_KEYS.AUTH_TYPE) ===
        AUTH_TYPES.UNAUTHENTICATED_CLIENT;

      // If we are an unauthenticated client, the route is the exit interview page
      // Otherwise, the (you should be a user impersonating), route is the clients page
      const route = isClient
        ? {
            name: MENUS.INTERVIEW.UNAUTHENTICATED.id,
            params: { flag: CLIENT_EXIT_KEYS.EXIT_INCOMPLETE_EXIT },
          }
        : { name: MENUS.CLIENTS.id };

      EventService.emit('navigate-to', route);
    },
  },

  created() {
    // Listen for nav disabled event - show/hide drawer/top nav
    this.listen('client-nav-disabled', (val) => {
      this.navDisabled = val;
    });

    // Listen for drawer toggle event
    this.listen('toggle-main-drawer', this.toggleDrawer);
  },
};
</script>
<style lang="scss" scoped>
.client-container {
  // height: 100vh;
  width: 100vw;
  padding-right: 0px !important;

  @media screen and (max-width: 960px) {
    padding-top: 0px !important;
  }

  #nav-drawer {
    //border-left: 1px solid var(--secondary-color);
    background-color: var(--color-white);
    box-shadow: none;
    padding: 30px;
  }

  .app-top-bar {
    justify-content: center;
    display: flex;
    background-color: var(--color-white) !important;
    right: 0px !important;
    padding: 0px !important;

    @media screen and (max-width: 960px) {
      position: relative !important;
    }

    #header {
      width: 90vw;
      max-width: 1600px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .home-logo-container {
        position: absolute;
        //top: 13px;
        left: calc(50% - 60px);
        display: flex;
      }

      .exit {
        color: var(--color-text);
        line-height: 24px;
        font-weight: 700;
        cursor: pointer;

        @media screen and (max-width: 960px) {
          padding: 10px;
        }
      }
      .exit-arrow {
        display: inline-block;
        transform: rotate(180deg);
        font-size: 16px;

        ::v-deep .fa-sign-out-alt {
          vertical-align: middle;
        }
      }
    }
  }
}
</style>
