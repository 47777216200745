<template>
  <v-container>
    <v-dialog
      v-model="smsConfirmationDialog"
      persistent
      width="430"
      :overlay-opacity="0.8"
      :overlay-color="overlayColor"
      content-class="plain-dialog"
    >
      <v-card flat>
        <v-card-text class="pa-7">
          <div
            class="modal-title"
            style="font-size: 18px; text-align: center; line-height: 30px"
          >
            Are you sure you want to send the text?
            <b>This action is not reversible.</b>
          </div>
          <div class="modal-actions">
            <a @click="performSendText()" class="btn mt-3">Send Message</a>
            <a @click="cancelConfirmationModal()" class="btn secondary mt-3"
              >Cancel</a
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="smsDialog"
      persistent
      width="700"
      :overlay-opacity="0.8"
      :overlay-color="overlayColor"
      content-class="plain-dialog"
    >
      <v-card flat>
        <v-card-text class="pa-7">
          <div class="modal-title">
            <h4>Edit Message</h4>
          </div>
          <div class="modal-body">
            <v-row>
              <v-col>
                <div class="instructions">
                  <p>
                    Use the provided placeholders in your template to insert
                    client or advisor information. <br />
                  </p>
                </div>
                <v-textarea
                  class="textarea sms-template"
                  outlined
                  name="smsTemplate"
                  aria-required="true"
                  auto-grow
                  v-model="smsTemplate"
                  :backgroundColor="inputBackgroundColor"
                  @input="$v.smsTemplate.$touch()"
                  @blur="$v.smsTemplate.$touch()"
                  hide-details="auto"
                />
                <div class="instructions">
                  <p>
                    <b>Mandatory placeholders</b>:
                    <span
                      v-for="placeholder in mandatoryPlaceholders"
                      :key="placeholder"
                    >
                      <strong> {{ placeholder }} </strong>
                    </span>
                  </p>
                  <p>
                    <b>Available placeholders:</b>
                    <span
                      v-for="placeholder in generalPlaceholders"
                      :key="placeholder"
                    >
                      <strong> {{ placeholder }} </strong>
                    </span>
                  </p>
                </div>
              </v-col>
            </v-row>
          </div>
          <div class="modal-actions">
            <a @click="saveSMSModal()" class="btn mt-3">Send Message</a>
            <a @click="cancelSMSModal()" class="btn secondary mt-3">Cancel</a>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="decisionDialog"
      persistent
      width="430"
      :overlay-opacity="0.8"
      :overlay-color="overlayColor"
      content-class="plain-dialog"
    >
      <v-card flat>
        <v-card-text class="pa-7">
          <div
            class="modal-title"
            style="font-size: 18px; text-align: center; line-height: 30px"
          >
            Are you ready to notify your clients to start transitioning?
          </div>
          <div class="modal-actions">
            <v-row class="justify-center">
              <v-col class="step-form-container radio-container">
                <v-radio-group
                  v-model="isReadyToTransition"
                  hide-details="auto"
                  class="pb-7"
                >
                  <div>
                    <div
                      v-if="this.currentCompany.transitionNotificationsDisabled"
                      style="
                        position: relative;
                        left: 0.5rem;
                        top: 2.5rem;
                        z-index: 999;
                      "
                    >
                      <v-tooltip top transition="none">
                        <template v-slot:activator="{ on }">
                          <div v-on="on">
                            <font-awesome-icon
                              size="lg"
                              class="role-icon"
                              :style="{ color: 'red' }"
                              icon="exclamation-triangle"
                            ></font-awesome-icon>
                          </div>
                        </template>
                        <span
                          v-html="
                            'Transition announcement are disabled at this time. Please contact your company administrator.'
                          "
                        ></span>
                      </v-tooltip>
                    </div>
                    <v-radio
                      class="v-faux-radio mb-4"
                      label="Yes"
                      :disabled="
                        this.currentCompany.transitionNotificationsDisabled
                      "
                      :value="true"
                      :ripple="false"
                    ></v-radio>
                  </div>
                  <v-radio
                    :ripple="false"
                    class="v-faux-radio"
                    label="No, I'll do it later"
                    :value="false"
                  ></v-radio>
                </v-radio-group>
                <a
                  @click="saveDecisionModal()"
                  v-if="isReadyToTransition !== null"
                  class="btn mt-3"
                  >Proceed</a
                >
                <a @click="cancelDecisionModal()" class="btn secondary mt-3"
                  >Cancel</a
                >
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <h1>
      <span class="headerlight">Edit Transitioning Clients</span>
    </h1>
    <TransitionHouseholdTable dataSource="root"></TransitionHouseholdTable>
    <bottom-nav :hasPrevious="false" v-on:go-next="proceedTransition()">
    </bottom-nav>
  </v-container>
</template>

<script>
import BottomNav from '@/components/common/BottomNav';
import { mapGetters } from 'vuex';
import { TRANSITION_CLIENTS } from '@/stores/actions.type';
import { MENUS, formatPhone, MESSAGE_PLACEHOLDERS } from '@/common/constants';
import TransitionHouseholdTable from '@/components/advisor/tables/TransitionHouseholdTable';
import {
  NOTIFICATION_TYPES,
  NotificationService,
} from '@/services/notification.service';
import { EMPTY_TRANSITION_HOUSEHOLDS } from '@/stores/actions.type';
import { isEmpty } from 'lodash';

export default {
  data: () => ({
    decisionDialog: false,
    smsDialog: false,
    smsConfirmationDialog: false,
    isReadyToTransition: null,
    sendMessageNow: null,
    smsTemplate:
      'Dear valued client, \nWe would like to inform you that your account is in the process of transitioning. If you wish to continue your association with us, please upload your statement at [YourLinkHere]. We value your association and are here to assist you through this process.\nWarm regards,[Your Company Name]',
  }),

  computed: {
    ...mapGetters([
      'transitionHouseholds',
      'selectedTransitionType',
      'currentCompany',
    ]),

    generalPlaceholders() {
      return MESSAGE_PLACEHOLDERS.filter(
        (placeholder) =>
          !placeholder.includes('emailLink') &&
          !placeholder.includes('statementLink'),
      ).map((placeholder) =>
        placeholder.replace(/organisation/g, 'organization'),
      );
    },

    mandatoryPlaceholders() {
      return this.selectedTransitionType === 'Protocol'
        ? MESSAGE_PLACEHOLDERS.filter((placeholder) =>
            placeholder.includes('statementLink'),
          )
        : MESSAGE_PLACEHOLDERS.filter((placeholder) =>
            placeholder.includes('emailLink'),
          );
    },
  },

  created() {
    const notificationTemplates = this.currentCompany.notificationTemplates;
    const foundTemplate =
      notificationTemplates &&
      notificationTemplates.find((template) => {
        if (this.selectedTransitionType === 'Protocol') {
          return template.templateName === 'TransitionStatement';
        }
        return template.templateName === 'NonProtocolTransitionRequestEmail';
      });

    if (!foundTemplate) {
      NotificationService.alert(
        {
          type: NOTIFICATION_TYPES.WARNING,
          title: 'Warning',
          message: 'Error getting sms template',
          okTitle: 'Ok',
        },
        'No sms template found',
      );
    } else {
      this.smsTemplate = foundTemplate.template;
    }
  },

  components: { BottomNav, TransitionHouseholdTable },

  methods: {
    saveDecisionModal() {
      if (!this.isReadyToTransition) {
        const payload = {
          households: this.transitionHouseholds,
          sendMessageNow: false,
        };
        this.transitionClients(payload);
      } else {
        this.smsDialog = true;
      }
    },

    performSendText() {
      const payload = {
        households: this.transitionHouseholds,
        sendMessageNow: true,
        smsPayload: this.smsTemplate,
      };
      this.transitionClients(payload);
    },

    async transitionClients(payload) {
      const transitions = payload.households.map((household) => {
        const { primaryContact, secondaryContact } = household;
        return {
          primaryContact: {
            firstName: primaryContact.firstName,
            lastName: primaryContact.lastName,
            crmId: primaryContact.crmId,
            mobile: formatPhone(primaryContact.phone),
            email: isEmpty(primaryContact.email) ? null : primaryContact.email,
          },
          secondaryContact: secondaryContact && {
            crmId: secondaryContact.crmId,
            firstName: secondaryContact.firstName,
            lastName: secondaryContact.lastName,
            mobile: formatPhone(secondaryContact.phone),
            email: isEmpty(secondaryContact.email)
              ? null
              : secondaryContact.email,
          },
          primaryAdvisor: household.firmRoles.primaryAdvisor.id,
          primaryCSA: household.firmRoles.primaryCSA.id,
          transitionType: this.selectedTransitionType,
          assetsValue: Number(household.assetsValue),
        };
      });

      this.$store
        .dispatch(TRANSITION_CLIENTS, {
          sendNow: payload.sendMessageNow,
          smsPayload: payload.smsPayload,
          transitions,
        })
        .then((response) => {
          this.$router.push({
            name: MENUS.TRANSITIONS.SUCCESS_TRANSITION.id,
            params: { transitionCount: response.length },
          });
        })
        .catch((error) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: error.message,
              okTitle: 'Ok',
            },
            error,
          );
        })
        .finally(() => {
          this.$store.dispatch(EMPTY_TRANSITION_HOUSEHOLDS);
          this.decisionDialog = false;
          this.smsDialog = false;
          this.smsConfirmationDialog = false;
        });
    },

    proceedTransition() {
      this.decisionDialog = true;
    },

    saveSMSModal() {
      this.smsConfirmationDialog = true;
    },

    prev() {
      this.$router.push({
        name: MENUS.TRANSITIONS.ENTER_MANUALLY.HOUSEHOLD_VALUE.id,
      });
    },
    cancelDecisionModal() {
      this.decisionDialog = false;
    },
    cancelConfirmationModal() {
      this.smsConfirmationDialog = false;
    },

    cancelSMSModal() {
      this.smsDialog = false;
      this.decisionDialog = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.sms-template {
  ::v-deep .v-text-field__slot {
    padding: 16px;
  }
}

.instructions {
  margin-bottom: 10px;
  font-size: 0.9rem;
  color: #333;

  p {
    margin: 0 0 5px;
  }

  strong {
    color: var(--primary-color);
  }
}
</style>
