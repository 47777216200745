<template>
  <monitor></monitor>
</template>

<script>
import Monitor from '@/views/admin/Monitor';

export default {
  name: 'MonitorView',

  components: {
    Monitor,
  },

  data: () => ({}),

  methods: {},
  created() {},
};
</script>
<style lang="scss" scoped></style>
