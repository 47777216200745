export function teamAdvisorsMapper(response) {
  return response.result.map(({ _id, personalInfo, integrations, assets }) => ({
    id: _id,
    firstName: personalInfo.firstName,
    lastName: personalInfo.lastName,
    setupComplete:
      !!integrations.find(
        (integration) => integration.integrationType === 'CRM',
      ) &&
      personalInfo.phone &&
      personalInfo.phone.length > 0,
    canSend: true,
    hasADV2B:
      (assets && assets.some((asset) => asset.assetType === 'ADV2B')) || false,
  }));
}
