<template>
  <v-container class="client-step-container">
      <v-row class="mb-8">
        <v-col class="instructions">
          <h1>
            <span class="headerlight">
              {{ question }}
            </span>
          </h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-textarea
            id="textarea"
            outlined
            required
            auto-grow
            :rows="2"
            :cols="200"
            hide-details="auto"
            class="my-textarea"
            v-model.trim="answer"
            :error-messages="answerErrors"
            @change="updateAnswer"
            @blur="$v.answer.$touch()"
          ></v-textarea>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>

import { required } from 'vuelidate/lib/validators';

import InterviewClientMixin from '@/common/mixins/interview.client.mixin';
import Themable from '@/common/mixins/themable.mixin';
import { EventService } from '@/services/event.service';


export default {
  name: 'InputText',
  props: {
    question: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      required: true
    },
  },
  mixins: [InterviewClientMixin, Themable],
  validations: {
    answer: { required },
  },
  data() {
    return {
      answer: null
    }
  },
  methods: {
    updateAnswer() {
      EventService.emit('update-answer', this.answer);
    },
  },
  computed: {
    answerErrors() {
      const errors = [];
      if (!this.$v.answer.$dirty) return errors;
      !this.$v.answer.required && errors.push('Answer is required');
      return errors;
    },
  }
}
</script>

<style scoped>
::v-deep .my-textarea textarea {
  padding: 10px !important;
}
</style>