<template>
  <div class="step-container client">
    <help-drawer :title="helpTitle">
      <div>
        <p>
          The main difference between a mailing address and a physical address
          is that a physical address is where you are physically located,
          typically a home address, while a mailing address is where mail is
          delivered, such as a PO box or mailbox service.
        </p>
      </div>
    </help-drawer>
    <v-form name="LegalAddress" class="form" @submit.prevent>
      <v-container class="client-step-container">
        <v-row class="mb-8">
          <v-col class="instructions">
            <h1>
              <span class="headerlight">
                What's your <strong>legal address?</strong>
              </span>
            </h1>
            <div class="help-link">
              <a @click="toggleHelp()"> {{ helpTitle }} </a>
            </div>
          </v-col>
        </v-row>
        <data-submitted-message
          :submitted="isLegalAddressComplete"
          v-on:hide-form="toggleFormState"
        />
        <div v-if="!formHidden">
          <v-row class="justify-center">
            <v-col class="step-form-container large">
              <v-row class="mobile">
                <v-col class="full">
                  <label for="searchAddress1" class="required">
                    Search Legal Address
                  </label>
                  <v-text-field
                    outlined
                    type="text"
                    maxlength="256"
                    name="searchAddress1"
                    id="searchAddress1"
                    aria-required="true"
                    v-model.trim="searchAddress1"
                    :backgroundColor="inputBackgroundColor"
                    placeHolder="Search"
                    @input="$v.searchAddress1.$touch()"
                    @blur="$v.searchAddress1.$touch()"
                    :error-messages="searchAddress1Errors"
                    hide-details="auto"
                    class="mb-2"
                  />
                </v-col>
              </v-row>
              <v-row class="mobile" v-if="primary.autoFilled">
                <v-col>
                  <label for="address1" class="required">Address 1</label>
                  <v-text-field
                    outlined
                    type="text"
                    maxlength="256"
                    name="address1"
                    aria-required="true"
                    v-model.trim="primary.address1"
                    :backgroundColor="inputBackgroundColor"
                    @input="$v.primary.address1.$touch()"
                    @blur="$v.primary.address1.$touch()"
                    :error-messages="primaryAddress1Errors"
                    hide-details="auto"
                    class="mb-2"
                  />
                </v-col>
                <v-col>
                  <label for="address2">Address 2</label>
                  <v-text-field
                    outlined
                    type="text"
                    maxlength="256"
                    name="address2"
                    v-model.trim="primary.address2"
                    :backgroundColor="inputBackgroundColor"
                    @input="$v.primary.address2.$touch()"
                    @blur="$v.primary.address2.$touch()"
                    hide-details="auto"
                    class="mb-2"
                  />
                </v-col>
              </v-row>
              <v-row class="mobile" v-if="primary.autoFilled">
                <v-col class="col-6">
                  <label for="city" class="required">City</label>
                  <v-text-field
                    outlined
                    type="text"
                    maxlength="256"
                    name="city"
                    aria-required="true"
                    v-model.trim="primary.city"
                    :backgroundColor="inputBackgroundColor"
                    @input="$v.primary.city.$touch()"
                    @blur="$v.primary.city.$touch()"
                    :error-messages="primaryCityErrors"
                    hide-details="auto"
                  />
                </v-col>
                <v-col>
                  <v-row class="state-zip">
                    <v-col>
                      <label for="state" class="required">State</label>
                      <v-select
                        v-model="primary.state"
                        ref="state"
                        :items="states"
                        :backgroundColor="inputBackgroundColor"
                        outlined
                        placeholder="Choose"
                        dense
                        hide-details="auto"
                        @change="$v.primary.state.$touch()"
                        :error-messages="primaryStateErrors"
                      />
                    </v-col>
                    <v-col
                      ><label for="zip" class="required">Zip Code</label>
                      <v-text-field
                        outlined
                        type="text"
                        maxlength="10"
                        name="zip"
                        aria-required="true"
                        v-model.trim="primary.zip"
                        :backgroundColor="inputBackgroundColor"
                        @input="$v.primary.zip.$touch()"
                        @blur="$v.primary.zip.$touch()"
                        :error-messages="primaryZipErrors"
                        hide-details="auto"
                        inputmode="numeric"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="alternate-address">
                <v-col>
                  <v-checkbox
                    hide-details
                    :ripple="false"
                    v-model="hasAlternateAddress"
                    :color="primaryColor"
                    @change="hasAlternateAddressChanged()"
                  >
                    <template v-slot:label>
                      <span class="cb-label mobile-label"
                        >I'd like to receive mail at a different address</span
                      >
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="justify-center mt-4" v-if="hasAlternateAddress">
            <v-col class="step-form-container large">
              <v-row class="mobile">
                <v-col class="full">
                  <label for="searchAddress2" class="required">
                    Search Mailing Address
                  </label>
                  <v-text-field
                    outlined
                    type="text"
                    maxlength="256"
                    name="searchAddress2"
                    id="searchAddress2"
                    aria-required="true"
                    v-model.trim="searchAddress2"
                    :backgroundColor="inputBackgroundColor"
                    placeHolder="Search"
                    @input="$v.searchAddress2.$touch()"
                    @blur="$v.searchAddress2.$touch()"
                    :error-messages="searchAddress2Errors"
                    hide-details="auto"
                    class="mb-2"
                  />
                </v-col>
              </v-row>
              <v-row class="mobile" v-if="secondary.autoFilled">
                <v-col>
                  <label for="address1" class="required">Address 1</label>
                  <v-text-field
                    outlined
                    type="text"
                    maxlength="256"
                    name="address1"
                    aria-required="true"
                    v-model.trim="secondary.address1"
                    :backgroundColor="inputBackgroundColor"
                    @input="$v.secondary.address1.$touch()"
                    @blur="$v.secondary.address1.$touch()"
                    :error-messages="secondaryAddress1Errors"
                    hide-details="auto"
                    class="mb-2"
                  />
                </v-col>
                <v-col>
                  <label for="address2">Address 2</label>
                  <v-text-field
                    outlined
                    type="text"
                    maxlength="256"
                    name="address2"
                    v-model.trim="secondary.address2"
                    :backgroundColor="inputBackgroundColor"
                    @input="$v.secondary.address2.$touch()"
                    @blur="$v.secondary.address2.$touch()"
                    hide-details="auto"
                    class="mb-2"
                  />
                </v-col>
              </v-row>
              <v-row class="mobile" v-if="secondary.autoFilled">
                <v-col class="col-6">
                  <label for="city" class="required">City</label>
                  <v-text-field
                    outlined
                    type="text"
                    maxlength="256"
                    name="city"
                    aria-required="true"
                    v-model.trim="secondary.city"
                    :backgroundColor="inputBackgroundColor"
                    @input="$v.secondary.city.$touch()"
                    @blur="$v.secondary.city.$touch()"
                    :error-messages="secondaryCityErrors"
                    hide-details="auto"
                  />
                </v-col>
                <v-col>
                  <v-row class="state-zip">
                    <v-col>
                      <label for="state" class="required">State</label>
                      <v-select
                        v-model="secondary.state"
                        ref="state"
                        :items="states"
                        :backgroundColor="inputBackgroundColor"
                        outlined
                        placeholder="Choose"
                        dense
                        hide-details="auto"
                        @change="$v.secondary.state.$touch()"
                        :error-messages="secondaryStateErrors"
                      />
                    </v-col>
                    <v-col
                      ><label for="zip" class="required">Zip Code</label>
                      <v-text-field
                        outlined
                        type="text"
                        maxlength="10"
                        name="zip"
                        aria-required="true"
                        v-model.trim="secondary.zip"
                        :backgroundColor="inputBackgroundColor"
                        @input="$v.secondary.zip.$touch()"
                        @blur="$v.secondary.zip.$touch()"
                        :error-messages="secondaryZipErrors"
                        hide-details="auto"
                        inputmode="numeric"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <div class="skip-for-now">
            <a @click="skip()">Skip for now</a>
          </div>
        </div>
      </v-container>
    </v-form>
    <bottom-nav v-on:go-prev="prev()" v-on:go-next="next()"></bottom-nav>
  </div>
</template>

<script>
import HelpDrawer from '@/components/HelpDrawerComponent';
import BottomNav from '@/components/common/BottomNav';
import DataSubmittedMessage from '@/components/client/DataSubmittedMessage';
import InterviewClientMixin from '@/common/mixins/interview.client.mixin';
import { MENUS, STATES, validateZip } from '@/common/constants';
import { required, requiredIf } from 'vuelidate/lib/validators';

/* Custom validator */
const mustSelectLegalResult = (value, vm) => {
  return value !== null && vm.primary.autoFilled;
};

/* Custom validator */
const mustSelectMailingResult = (value, vm) => {
  if (!vm.hasAlternateAddress) return true;

  return value !== null && vm.secondary.autoFilled;
};

export default {
  name: 'LegalAddress',

  components: { BottomNav, HelpDrawer, DataSubmittedMessage },
  mixins: [InterviewClientMixin],

  data: () => ({
    searchAddress1: null,
    searchAddress2: null,
    primary: {
      autoFilled: false,
      address1: null,
      address2: null,
      city: null,
      state: null,
      zip: null,
    },
    secondary: {
      autoFilled: false,
      address1: null,
      address2: null,
      city: null,
      state: null,
      zip: null,
    },
    hasAlternateAddress: false,
  }),
  validations() {
    return {
      searchAddress1: {
        //required,
        mustSelectLegalResult,
      },
      primary: {
        address1: {
          required,
        },
        address2: {},
        city: {
          required,
        },
        state: {
          required,
        },
        zip: {
          required,
          validateZip,
        },
      },
      searchAddress2: {
        //required: requiredIf(() => this.hasAlternateAddress),
        mustSelectMailingResult,
      },
      secondary: {
        address1: {
          required: requiredIf(() => this.hasAlternateAddress),
        },
        address2: {},
        city: {
          required: requiredIf(() => this.hasAlternateAddress),
        },
        state: {
          required: requiredIf(() => this.hasAlternateAddress),
        },
        zip: {
          required: requiredIf(() => this.hasAlternateAddress),
          validateZip,
        },
      },
      hasAlternateAddress: {},
      validationGroup: [
        'searchAddress1',
        'primary.address1',
        'primary.address2',
        'primary.city',
        'primary.state',
        'primary.zip',
        'secondary.address1',
        'secondary.address2',
        'secondary.city',
        'secondary.state',
        'secondary.zip',
        'hasAlternateAddress',
      ],
    };
  },
  computed: {
    // Dynamic  errors */
    searchAddress1Errors() {
      const errors = [];
      if (!this.$v.searchAddress1.$dirty) return errors;
      //!this.$v.searchAddress1.required &&
      //  errors.push("You must search/select a Legal address");
      !this.$v.searchAddress1.mustSelectLegalResult &&
        errors.push('You must search/select a Legal address');
      return errors;
    },

    // Dynamic  errors */
    searchAddress2Errors() {
      const errors = [];
      if (!this.$v.searchAddress2.$dirty) return errors;
      //!this.$v.searchAddress2.required &&
      //  errors.push("You must search/select a Mailing address");
      !this.$v.searchAddress2.mustSelectMailingResult &&
        errors.push('You must search/select a Mailing address');
      return errors;
    },

    // Dynamic Address1 errors */
    primaryAddress1Errors() {
      const errors = [];
      if (!this.$v.primary.address1.$dirty) return errors;
      !this.$v.primary.address1.required &&
        errors.push('Address 1 is required');
      return errors;
    },

    // Dynamic City errors */
    primaryCityErrors() {
      const errors = [];
      if (!this.$v.primary.city.$dirty) return errors;
      !this.$v.primary.city.required && errors.push('City is required');
      return errors;
    },

    // Dynamic State errors */
    primaryStateErrors() {
      const errors = [];
      if (!this.$v.primary.state.$dirty) return errors;
      !this.$v.primary.state.required && errors.push('State is required');
      return errors;
    },

    // Dynamic Zip errors */
    primaryZipErrors() {
      const errors = [];
      if (!this.$v.primary.zip.$dirty) return errors;
      !this.$v.primary.zip.required && errors.push('Zip Code is required');
      !this.$v.primary.zip.validateZip &&
        errors.push('Zip Code must be NNNNN or NNNNN-NNNN');
      return errors;
    },

    // Dynamic Address1 errors */
    secondaryAddress1Errors() {
      const errors = [];
      if (!this.$v.secondary.address1.$dirty) return errors;
      !this.$v.secondary.address1.required &&
        errors.push('Address 1 is required');
      return errors;
    },

    // Dynamic City errors */
    secondaryCityErrors() {
      const errors = [];
      if (!this.$v.secondary.city.$dirty) return errors;
      !this.$v.secondary.city.required && errors.push('City is required');
      return errors;
    },

    // Dynamic State errors */
    secondaryStateErrors() {
      const errors = [];
      if (!this.$v.secondary.state.$dirty) return errors;
      !this.$v.secondary.state.required && errors.push('State is required');
      return errors;
    },

    // Dynamic Zip errors */
    secondaryZipErrors() {
      const errors = [];
      if (!this.$v.secondary.zip.$dirty) return errors;
      !this.$v.secondary.zip.required && errors.push('Zip Code is required');

      if (this.hasAlternateAddress) {
        !this.$v.primary.zip.validateZip &&
          errors.push('Zip Code must be NNNNN or NNNNN-NNNN');
      }
      return errors;
    },
    helpTitle() {
      return 'What does this mean?';
    },
  },
  methods: {
    /* 
      Override toggle form state from hidden/shown.
      If hidden, reset form errors, else init autocomplete
    */
    toggleFormState(hide) {
      this.formHidden = hide;
      if (hide) {
        this.$v.$reset();
      } else {
        this.$nextTick(() => this.initAutoComplete());
      }
    },

    // Mark optional alternate address field as dirty if changed
    hasAlternateAddressChanged() {
      this.$v.hasAlternateAddress.$touch();
      if (this.hasAlternateAddress) {
        this.$nextTick(() => this.initAutoComplete(true));
      }
    },

    /* Nav to Next Page in flow */
    next(route = null) {
      const to = route || MENUS.CLIENT.SSN.id;

      if (!this.formHidden) {
        this.$v.$touch();
        if (this.$v.$invalid) return;
      } else {
        this.goNext(to);
        return;
      }

      const data = {
        legalAddress: {
          line1: this.primary.address1,
          line2: this.primary.address2,
          city: this.primary.city,
          state: this.primary.state,
          zip: this.primary.zip,
        },
      };
      if (this.hasAlternateAddress) {
        data.mailingAddress = {
          line1: this.secondary.address1,
          line2: this.secondary.address2,
          city: this.secondary.city,
          state: this.secondary.state,
          zip: this.secondary.zip,
        };
      }

      this.completeStep(MENUS.CLIENT.LEGAL_ADDRESS.id, data)
        .then(() => {
          this.goNext(to);
        })
        .catch(() => {});
    },

    /* Nav to Previous Page in flow */
    prev() {
      this.goPrev(MENUS.CLIENT.LEGAL_NAME.id);
    },

    /* Skip to next Page in flow */
    skip() {
      this.goPrev(MENUS.CLIENT.SSN.id);
    },

    /* Fill in Address Fields from Autocomplete */
    fillInAddress2() {
      this.fillInAddress(true);
    },
    fillInAddress(secondary = false) {
      // Get the place details from the autocomplete object.
      const place = secondary
        ? this.autocomplete2.getPlace()
        : this.autocomplete.getPlace();
      const addressObj = secondary ? this.secondary : this.primary;
      let addressField = secondary
        ? document.querySelector('#searchAddress2')
        : document.querySelector('#searchAddress1');
      let address1 = '';
      let postcode = '';
      addressObj.autoFilled = true;

      // Get each component of the address from the place details,
      // and then fill-in the corresponding field on the form.
      // place.address_components are google.maps.GeocoderAddressComponent objects
      // which are documented at http://goo.gle/3l5i5Mr
      for (const component of place.address_components) {
        const componentType = component.types[0];

        switch (componentType) {
          case 'street_number': {
            address1 = `${component.long_name} ${address1}`;
            break;
          }

          case 'route': {
            address1 += component.short_name;
            break;
          }

          case 'postal_code': {
            postcode = `${component.long_name}${postcode}`;
            break;
          }

          case 'postal_code_suffix': {
            postcode = `${postcode}-${component.long_name}`;
            break;
          }
          case 'locality': {
            addressObj.city = component.long_name;
            break;
          }
          case 'administrative_area_level_1': {
            addressObj.state = component.short_name;
            break;
          }
        }
      }
      addressObj.address1 = address1;
      this.$nextTick(() => (addressField.value = place.formatted_address));
      addressObj.zip = postcode;
    },

    /* Initialize the Autocomplete widget */
    initAutoComplete(secondary = false) {
      let addressField = secondary
        ? document.querySelector('#searchAddress2')
        : document.querySelector('#searchAddress1');

      // Create the autocomplete object, restricting the search predictions to
      // addresses in the US
      if (secondary) {
        this.autocomplete2 = new window.google.maps.places.Autocomplete(
          addressField,
          {
            componentRestrictions: { country: ['us'] },
            fields: ['address_components', 'formatted_address'],
            types: ['address'],
          },
        );

        // When the user selects an address from the drop-down, populate the
        // address fields in the form.
        this.autocomplete2.addListener('place_changed', this.fillInAddress2);
      } else {
        this.autocomplete = new window.google.maps.places.Autocomplete(
          addressField,
          {
            componentRestrictions: { country: ['us'] },
            fields: ['address_components', 'formatted_address'],
            types: ['address'],
          },
        );

        // When the user selects an address from the drop-down, populate the
        // address fields in the form.
        this.autocomplete.addListener('place_changed', this.fillInAddress);
      }
    },
  },
  mounted() {
    // Initial Autocomplete on mount if form is not hidden
    if (!this.formHidden) this.initAutoComplete();
  },
  created() {
    // Initialize form data for current interview
    this.states = STATES;
    this.formHidden = this.isLegalAddressComplete;
  },
};
</script>
<style lang="scss" scoped>
.state-zip {
  @media screen and (max-width: 960px) {
    padding-top: 8px !important;
  }
}
.alternate-address {
  margin-top: 30px;
  border-top: 1px solid var(--secondary-color);
}
.cb-label {
  margin-bottom: -5px;
}
.col.instructions {
  @media screen and (max-width: 960px) {
    strong {
      display: block;
    }
  }
}
.col.full {
  max-width: 100%;
}
</style>
