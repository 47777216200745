<template>
  <div class="auth-container">
    <div class="inner-container">
      <div class="logo-container">
        <img
          :src="`${publicPath}images/defaultLogo.png`"
          width="140"
          loading="lazy"
          alt="OnBord"
          class="logo"
        />
      </div>
      <div v-if="sessionTimedOut" class="timed-out">
        Your session timed out.
      </div>
      <div class="main-title">Log in to your account</div>
      <div class="form-block w-form">
        <v-form
          name="loginForm"
          class="form"
          v-on:submit.prevent="doLogin(login, password)"
        >
          <v-row class="mb-1">
            <v-col>
              <label for="email-2">Email</label>
              <v-text-field
                outlined
                type="email"
                maxlength="256"
                name="email"
                aria-required="true"
                v-model.trim="login"
                :backgroundColor="inputBackgroundColor"
                @blur="$v.login.$touch()"
                :error-messages="loginErrors"
                hide-details="auto"
                inputmode="email"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <label for="Password">Password</label>
              <v-text-field
                outlined
                maxlength="256"
                name="password"
                aria-required="true"
                v-model.trim="password"
                :backgroundColor="inputBackgroundColor"
                @blur="$v.password.$touch()"
                :error-messages="passwordErrors"
                hide-details="auto"
                :type="masked ? 'password' : 'text'"
                class="pwd"
              >
                <template v-slot:append>
                  <span
                    @click="masked = !masked"
                    class="mask-eye"
                    :key="masked"
                  >
                    <v-icon
                      v-on="on"
                      small
                      :color="primaryColor"
                      data-testid="eye-icon"
                      >{{ iconClass }}</v-icon
                    >
                  </span>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-1 mb-7 align-end">
            <v-col class="pwd-col">
              <router-link :to="{ name: 'forgot-password' }"
                >Forgot password?</router-link
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <input type="submit" id="login" class="btn" value="Log In" />
            </v-col>
          </v-row>
        </v-form>
        <!--
        <v-row class="or-container">
          <v-col>
            <div class="or">or</div>
          </v-col>
        </v-row>
        <v-row class="mt-5">
          <v-col>
            <a class="btn secondary">Need an account? Contact Sales</a>
          </v-col>
        </v-row>-->
      </div>
    </div>
  </div>
</template>
<script>
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import LoginMixin from '@/common/mixins/login.mixin';
import Themable from '@/common/mixins/themable.mixin';
import {
  NOTIFICATION_TYPES,
  NotificationService,
} from '@/services/notification.service';
import { CONFIRM_LOGIN_EMAIL_CHANGE } from '@/stores/actions.type';
import { MENUS } from '@/common/constants';
import { mapGetters } from 'vuex';
import PublicAssets from '@/common/mixins/publicasset.mixin';

export default {
  name: 'Login',

  mixins: [PublicAssets, validationMixin, LoginMixin, Themable],

  data: () => ({
    login: null,
    password: null,
    masked: true,
  }),

  validations() {
    return {
      login: {
        required,
        email,
      },
      password: {
        required,
      },
      validationGroup: ['login', 'password'],
    };
  },

  computed: {
    ...mapGetters(['sessionTimedOut']),

    /* Dynamic Login errors */
    loginErrors() {
      const errors = [];
      if (!this.$v.login.$dirty) return errors;
      !this.$v.login.required && errors.push('Email is required');
      !this.$v.login.email && errors.push('Invalid email address');
      return errors;
    },

    /* Dynamic Login errors */
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push('Password is required');
      return errors;
    },
    iconClass() {
      return this.masked ? 'far fa-eye' : 'far fa-eye-slash';
    },
    confirmEmailChangeToken() {
      return this.$route.query.confirmLoginEmail;
    },
  },

  methods: {
    /* Perform Login */
  },
  mounted() {
    // If called with an email change token
    // validate that token and provide somewhat friendly message
    if (this.confirmEmailChangeToken) {
      const payload = {
        token: this.confirmEmailChangeToken,
      };
      const okMethod = () => this.$router.push({ name: MENUS.LOGIN.id });

      this.$store
        .dispatch(CONFIRM_LOGIN_EMAIL_CHANGE, payload)
        .then(() => {
          NotificationService.alert({
            type: NOTIFICATION_TYPES.SUCCESS,
            title: 'Success',
            message:
              'Email Change Confirmation Succeeded. Log in with your new email!',
            okTitle: 'Ok',
            okMethod: okMethod,
          });
        })
        .catch((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message:
                'Email Change Confirmation Failed. Try logging in with your original email!',
              okTitle: 'Ok',
              okMethod: okMethod,
            },
            response,
          );
        });
    }
  },
};
</script>
<style lang="scss" scoped>
.pwd-col {
  text-align: right;
}
.pwd {
  .mask-eye {
    margin-right: 16px;
    cursor: pointer;
    color: var(--primary-color);
  }
}
#login {
  width: 100%;
}
.timed-out {
  text-align: center;
  color: var(--error-color);
  font-weight: 700;
}
</style>
