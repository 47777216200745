import { isEmpty } from 'lodash';

export function checkSetupComplete(checklist) {
  return checklist.reduce((acc, curr) => acc && curr, true);
}

export function checkOrganisationSetupComplete(organisation) {
  const isAddressComplete =
    organisation.address &&
    Object.entries(organisation.address).reduce(
      (acc, [key, value]) =>
        acc && ['city', 'state', 'street', 'zip'].includes(key) && !!value,
      true,
    );

  const isMasterAccountNumbersComplete = !isEmpty(organisation.masterAccounts);

  let shouldUploadNonSolicit = false;
  if (
    organisation.transitions &&
    organisation.allowSendTransitionNotifications
  ) {
    shouldUploadNonSolicit = !organisation.assets.find(
      ({ assetType }) => assetType === 'nonSolicit',
    );
  }

  const isAssetsComplete =
    !isEmpty(organisation.assets) &&
    organisation.assets.some(({ assetType }) => {
      return ['ADV2A'].includes(assetType);
    }) &&
    !shouldUploadNonSolicit;

  return checkSetupComplete([
    !!organisation.name,
    !!organisation.riaName,
    !!organisation.phone,
    isAddressComplete,
    isMasterAccountNumbersComplete,
    isAssetsComplete,
  ]);
}
