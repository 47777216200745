/* 
    Notification Service
    Provide Mechanism for Confirm and Alerts
*/
import MessageService from '@/services/message.service';
import { EventService } from '@/services/event.service';
import { SESSION_TIMEOUT_ERROR } from '@/common/constants';
const noop = () => {};

// NOTIFICATION_TYPES
export const NOTIFICATION_TYPES = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
};

export const NotificationService = {
  options: {
    title: null,
    message: 'Message',
    okTitle: 'OK',
    okMethod: noop,
    cancelTitle: 'Cancel',
    cancelMethod: noop,
    type: NOTIFICATION_TYPES.INFO,
  },

  /**
   *  Configure Confirm options and trigger Dialog
   *
   * @param {Object} options
   * @param {Object} response
   */
  confirm(options, response) {
    // Ignore response for SUCESS TYPES
    if (options.type === NOTIFICATION_TYPES.SUCCESS) response = null;

    let opts = {
      ...NotificationService.options,
      ...options,
    };
    if (response)
      opts.message = MessageService.constructErrorMessage(
        opts.message,
        response,
      );
    EventService.emit('display-confirm', opts);
  },

  /* Configure Alert options and trigger Dialog */
  alert(options, response) {
    // Ignore response for SUCCESS TYPES
    if (options.type === NOTIFICATION_TYPES.SUCCESS) response = null;

    if (response === SESSION_TIMEOUT_ERROR) {
      return;
    }

    let opts = {
      ...NotificationService.options,
      ...options,
    };
    if (response)
      opts.message = MessageService.constructErrorMessage(
        opts.message,
        response,
      );
    EventService.emit('display-alert', opts);
  },

  /* Trigger Generic "Not Implemented" Alert */
  notImplemented() {
    let opts = {
      ...NotificationService.options,
      ...{
        title: 'Not Implemented',
        message: 'This behavior is not implemented yet!',
      },
    };
    EventService.emit('display-alert', opts);
  },

  /* Close the active notification */
  closeActive() {
    EventService.emit('close-notification');
  },
};
