import { advisorMapper } from './mappers/advisorMapper';
import { advisorsMapper } from './mappers/advisorsMapper';
import { redtailClientsMapper } from './mappers/redtailClientsMapper';
import { clientsMapper } from './mappers/clientsMapper';
import { organizationMapper } from './mappers/organizationMapper';
import { teamAdvisorsMapper } from './mappers/teamAdvisorsMapper';
import { interviewMapper } from './mappers/interviewMapper';

import {
  GET_CURRENT_USER,
  GET_USERS,
  GET_USER,
  SEARCH_CRM_CONTACTS,
  GET_CLIENTS,
  GET_CURRENT_COMPANY,
  GET_TEAM_USERS,
  INTERVIEW,
  GET_INTERVIEW_PROGRESS,
  GET_COMPANIES,
  GET_COMPANY,
} from '../../stores/actions.type';
import { organizationsMapper } from './mappers/organizationsMapper';

export function responseMapper(action, response) {
  if (!action) {
    return response;
  }

  const mapper =
    {
      [GET_CURRENT_USER]: advisorMapper,
      [GET_USER]: advisorMapper,
      [GET_USERS]: advisorsMapper,
      [SEARCH_CRM_CONTACTS]: redtailClientsMapper,
      [GET_CLIENTS]: clientsMapper,
      [SEARCH_CRM_CONTACTS]: redtailClientsMapper,
      [GET_CURRENT_COMPANY]: organizationMapper,
      [GET_COMPANY]: organizationMapper,
      [GET_COMPANIES]: organizationsMapper,
      [GET_TEAM_USERS]: teamAdvisorsMapper,
      [INTERVIEW]: interviewMapper,
      [GET_INTERVIEW_PROGRESS]: interviewMapper,
      // NOTE:  Add more mappers here
    }[action] || null;

  return mapper ? mapper(response) : response;
}
