<template>
  <div class="full pa-3 mt-3">
    <v-simple-table class="responsive-table">
      <thead>
        <tr>
          <th class="text-left">Selection</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <strong>Name:</strong> {{ contactName }} <br />
            <strong>Email:</strong> {{ selection.email }} <br />
            <strong>Phone:</strong> <span v-html="contactPhone"></span>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-row class="full pa-3 mt-3" v-show="contactExistsInOnbord">
      <v-col>
        <strong
          ><i>
            This user previously completed an interview in OnBord
          </i></strong
        ><br />
        <v-checkbox
          hide-details
          :ripple="false"
          v-model="selection.preserveInterview"
          :color="primaryColor"
          @change="selectPreserveInterview"
        >
          <template v-slot:label>
            <span class="cb-label mobile-label">
              Preserve OnBord Interview Answers
            </span>
          </template>
        </v-checkbox>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Themable from '@/common/mixins/themable.mixin';
import { EventService } from '@/services/event.service';
import { formatPhone } from '@/common/constants';

export default {
  name: 'ExistingClientSearch',
  props: ['selection', 'clientTwo'],
  mixins: [Themable],
  data: () => ({}),
  computed: {
    contactExistsInOnbord() {
      return this.selection && this.selection.canSkipInterview;
    },
    contactName() {
      return `${this.selection.firstName} ${this.selection.lastName}`;
    },
    contactPhone() {
      let formattedPhone = null;
      if (this.selection.phone) {
        formattedPhone = formatPhone(this.selection.phone);
      } else {
        formattedPhone = this.selection.dupPhone
          ? `<span class="dup">Cannot match Client 1 phone</span>`
          : '&lt;none&gt;';
      }

      return formattedPhone;
    },
  },
  methods: {
    selectPreserveInterview() {
      const data = {
        client: this.clientTwo ? 2 : 1,
        preserveInterview: this.selection.preserveInterview,
      };
      EventService.emit('contact-preserve-interview', data);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .dup {
  color: var(--error-color);
  font-size: 14px;

  svg {
    margin-right: 4px;
  }

  &:before {
    font-family: 'Font Awesome 5 Pro';
    font-weight: 900;
    content: '\f071';
    display: none;
  }
}
</style>
