<template>
  <div v-cloak class="client-container">
    <!-- Nav Drawer - Mobile -->
    <v-navigation-drawer
      v-model="drawer"
      right
      app
      clipped
      id="nav-drawer"
      overlay-opacity="0"
      overlay-color="#fff"
      class="hidden-lg-and-up"
      width="300"
    >
      <client-menu />
    </v-navigation-drawer>

    <!-- App Bar -->
    <v-app-bar app flat class="app-top-bar" height="69">
      <div id="header">
        <span @click="exit()" class="btn secondary exit">
          <span class="exit-arrow">
            <i class="fas fa-sign-out-alt"></i>
          </span>
          <span class="ml-3 d-none d-sm-inline-flex">Exit</span>
        </span>
        <div class="client-logo-container">
          <img
            :src="`${logo}`"
            width="120px"
            loading="lazy"
            alt="OnBord"
            class="logo"
          />
        </div>
        <div class="hidden-md-and-down">
          <client-menu />
        </div>
        <div class="hidden-lg-and-up">
          <v-app-bar-nav-icon @click.stop="toggleDrawer()"></v-app-bar-nav-icon>
        </div>
      </div>
    </v-app-bar>

    <!-- Routes -->
    <router-view :key="$route.fullPath"></router-view>
    <v-dialog
      v-model="exitDialog"
      persistent
      width="430"
      :overlay-opacity="0.8"
      :overlay-color="overlayColor"
      content-class="plain-dialog lg"
    >
      <v-card flat>
        <v-card-text class="pa-7">
          <div class="modal-title">
            You have unsaved changes. Are you sure you want to Leave?
          </div>
          <div class="modal-actions">
            <a @click="save()" v-if="showSave" class="btn mt-3">
              Save and Exit
            </a>
            <span v-else class="save-msg"> {{ noSaveMsg }} </span>
            <a
              @click="quit()"
              class="btn mt-3"
              :class="showSave ? 'secondary' : ''"
              >Quit without saving</a
            >
            <a @click="cancel()" class="btn secondary mt-3">Cancel</a>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ClientMenu from '@/components/advisor/AdvisorClientMenu';
import { mapGetters } from 'vuex';
import EventListener from '@/common/mixins/eventlistener.mixin';
import PublicAssets from '@/common/mixins/publicasset.mixin';
import ClientMixin from '@/common/mixins/client.mixin';
import { EventService } from '@/services/event.service';

export default {
  name: 'AddClient',
  mixins: [PublicAssets, EventListener, ClientMixin],

  components: { ClientMenu },

  data: () => ({
    drawer: false,
    exitDialog: false,
    showSave: false,
    logo: null,
  }),
  computed: {
    ...mapGetters(['currentUser', 'currentCompany']),
  },
  mounted() {
    const logo = this.currentCompany.assets
      ? this.currentCompany.assets.find((asset) => asset.assetType === 'logo')
      : null;
    this.logo = logo ? logo.assetLocation : null;
  },
  methods: {
    cancel() {
      this.exitDialog = false;
    },

    quit() {
      this.exitDialog = false;
      EventService.emit('cancel-and-exit');
    },
    save() {
      this.exitDialog = false;
      EventService.emit('save-and-exit');
    },

    /* Navigation Toggled */
    toggleDrawer() {
      this.drawer = !this.drawer;
    },

    /* Exit Add Client flow  - go to clients page */
    exit() {
      // this.$router.push({ name: MENUS.CLIENTS.id }).catch(() => {});
      EventService.emit('map-client-data');
    },

    /* Toggle Exit Modal */
    toggleExitModal(canSaveMsg) {
      this.showSave = canSaveMsg === null;
      this.noSaveMsg = canSaveMsg;
      this.exitDialog = true;
    },
  },

  created() {
    // Listen for event to toggle nav drawer
    this.listen('toggle-main-drawer', this.toggleDrawer);
    this.listen('toggle-exit-modal', this.toggleExitModal);
  },
};
</script>
<style lang="scss" scoped>
.modal-title {
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 28px;
  color: var(--color-text);
}
.btn {
  font-size: 16px;
}

.save-msg {
  color: var(--error-color);
}

.client-container {
  height: 100vh;
  width: 100vw;
  padding-right: 0px !important;

  @media screen and (max-width: 960px) {
    padding-top: 0px !important;
  }

  #nav-drawer {
    //border-left: 1px solid var(--secondary-color);
    background-color: var(--color-white);
    box-shadow: none;
    padding: 30px;
  }

  .app-top-bar {
    justify-content: center;
    display: flex;
    background-color: var(--color-white) !important;
    right: 0px !important;
    padding: 0px !important;

    @media screen and (max-width: 960px) {
      position: relative !important;
    }

    #header {
      width: 90vw;
      max-width: 1600px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .client-logo-container {
        position: absolute;
        //top: 8px;
        left: calc(50% - 60px);
        display: flex;
      }

      .exit {
        color: var(--color-text);
        line-height: normal;
        font-weight: 700;
        cursor: pointer;

        @media screen and (max-width: 960px) {
          padding: 10px;
        }
      }
      .exit-arrow {
        display: inline-block;
        transform: rotate(180deg);
        font-size: 16px;

        ::v-deep .fa-sign-out-alt {
          vertical-align: middle;
        }
      }
    }
  }
}
</style>
