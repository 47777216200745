<template>
  <div class="step-container client">
    <help-drawer :title="helpTitle">
      <div>
        <p>
          Collecting this information helps your financial professional to
          fulfill regulatory obligations.
        </p>
      </div>
    </help-drawer>
    <v-form name="EmploymentStatus" class="form">
      <v-container class="client-step-container">
        <v-row class="mb-8">
          <v-col class="instructions">
            <h1>
              <span class="headerlight">
                What's your <strong>employment status?</strong>
              </span>
            </h1>
            <div class="help-link">
              <a @click="toggleHelp()">{{ helpTitle }}</a>
            </div>
          </v-col>
        </v-row>
        <data-submitted-message
          :submitted="isEmploymentStatusComplete"
          v-on:hide-form="toggleFormState"
        />
        <div v-if="!formHidden">
          <v-row class="justify-center">
            <v-col class="step-form-container radio-container mb-4">
              <v-radio-group
                v-model="employmentStatus"
                :error-messages="employmentStatusErrors"
                hide-details="auto"
                @change="$v.employmentStatus.$touch()"
              >
                <v-radio
                  :ripple="false"
                  class="v-faux-radio mb-4"
                  :label="EMPLOYMENT_STATUSES.EMPLOYED"
                  :value="EMPLOYMENT_STATUSES.EMPLOYED"
                ></v-radio>
                <v-radio
                  :ripple="false"
                  class="v-faux-radio"
                  :label="EMPLOYMENT_STATUSES.SELF_EMPLOYED"
                  :value="EMPLOYMENT_STATUSES.SELF_EMPLOYED"
                ></v-radio>
                <v-radio
                  :ripple="false"
                  class="v-faux-radio mb-4"
                  :label="EMPLOYMENT_STATUSES.STUDENT"
                  :value="EMPLOYMENT_STATUSES.STUDENT"
                ></v-radio>
                <v-radio
                  :ripple="false"
                  class="v-faux-radio mb-4"
                  :label="EMPLOYMENT_STATUSES.HOMEMAKER"
                  :value="EMPLOYMENT_STATUSES.HOMEMAKER"
                ></v-radio>
                <v-radio
                  :ripple="false"
                  class="v-faux-radio mb-4"
                  :label="EMPLOYMENT_STATUSES.RETIRED"
                  :value="EMPLOYMENT_STATUSES.RETIRED"
                ></v-radio>
                <v-radio
                  :ripple="false"
                  class="v-faux-radio mb-4"
                  :label="EMPLOYMENT_STATUSES.NOT_EMPLOYED"
                  :value="EMPLOYMENT_STATUSES.NOT_EMPLOYED"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <div class="skip-for-now">
            <a @click="skip()">Skip for now</a>
          </div>
        </div>
      </v-container>
    </v-form>
    <bottom-nav v-on:go-prev="prev()" v-on:go-next="next()"></bottom-nav>
  </div>
</template>

<script>
import HelpDrawer from '@/components/HelpDrawerComponent';
import BottomNav from '@/components/common/BottomNav';
import DataSubmittedMessage from '@/components/client/DataSubmittedMessage';
import InterviewClientMixin from '@/common/mixins/interview.client.mixin';
import { required } from 'vuelidate/lib/validators';
import { MENUS, EMPLOYMENT_STATUSES } from '@/common/constants';

export default {
  name: 'EmploymentStatus',

  components: { BottomNav, HelpDrawer, DataSubmittedMessage },
  mixins: [InterviewClientMixin],

  data: () => ({
    employmentStatus: null,
  }),

  validations() {
    return {
      employmentStatus: {
        required,
      },
      validationGroup: ['employmentStatus'],
    };
  },

  computed: {
    /* Dynamic errors */
    employmentStatusErrors() {
      const errors = [];
      if (!this.$v.employmentStatus.$dirty) return errors;
      !this.$v.employmentStatus.required &&
        errors.push('A selection is required');
      return errors;
    },
    helpTitle() {
      return 'Why do you need this?';
    },
  },

  methods: {
    /* Get next screen */
    getNextScreen() {
      // If we have the company address page available - nnext is that
      // Otherwise, next is the company offical page
      const hasCompanyPage =
        this.getPage(MENUS.CLIENT.COMPANY_ADDRESS.id) !== undefined;
      return hasCompanyPage
        ? MENUS.CLIENT.COMPANY_ADDRESS.id
        : MENUS.CLIENT.COMPANY_OFFICIAL.id;
    },

    /* Update available screens based on form answer */
    updateScreens() {
      if (
        this.employmentStatus === this.EMPLOYMENT_STATUSES.EMPLOYED ||
        this.employmentStatus === this.EMPLOYMENT_STATUSES.SELF_EMPLOYED
      ) {
        // Add (Potentialy) any required pages
        this.addPage(MENUS.CLIENT.COMPANY_ADDRESS.id);
        this.addPage(MENUS.CLIENT.JOB_DESCRIPTION.id);
      } else {
        // Remove any pages no longer required
        this.removePage(MENUS.CLIENT.COMPANY_ADDRESS.id);
        this.removePage(MENUS.CLIENT.JOB_DESCRIPTION.id);
      }
    },

    /* Nav to Next Page in flow */
    next(route = null) {
      let to = route || this.getNextScreen();

      if (!this.formHidden) {
        this.$v.$touch();
        if (this.$v.$invalid) return;
      } else {
        this.goNext(to);
        return;
      }

      // Recalculate screens/next route based on new data
      this.updateScreens();
      to = route || this.getNextScreen();

      const data = {
        status: this.employmentStatus,
      };

      this.completeStep(MENUS.CLIENT.EMPLOYMENT_STATUS.id, data)
        .then(() => {
          this.goNext(to);
        })
        .catch(() => {});
    },

    /* Nav to Previous Page in flow */
    prev() {
      this.goPrev(MENUS.CLIENT.ALTERNATE_PHONE.id);
    },

    /* Skip to next Page in flow */
    skip() {
      this.goPrev(this.getNextScreen());
    },
  },
  created() {
    // Initialize form data for current interview
    this.formHidden = this.isEmploymentStatusComplete;
    this.EMPLOYMENT_STATUSES = EMPLOYMENT_STATUSES;
  },
};
</script>
<style lang="scss" scoped></style>
