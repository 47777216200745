<template>
  <div class="email-register-container">
    <div class="header-container">
      <div>
        <img
          :src="logo"
          width="240"
          loading="lazy"
          alt="OnBord"
          class="logo"
          id="companyLogo"
        />
      </div>

      <div class="d-flex">
        <h2>Register Your Email</h2>
        <div
          class="icon-container"
          @mouseover="showTooltip = true"
          @mouseout="showTooltip = false"
        >
          <i class="fas fa-question-circle"></i>
          <div v-if="showTooltip" class="tooltip">
            We need your email to send a Non Solicitation Agreement for
            signature to initiate the transition process.
          </div>
        </div>
      </div>
    </div>
    <v-form v-if="!registrationSuccess">
      <v-text-field
        v-model="email"
        :readonly="emailPrepopulated && !editMode"
        label="Email"
        type="email"
        required
      ></v-text-field>
      <a
        class="btn"
        @click="registerEmail"
        v-if="!emailPrepopulated || editMode"
        >Register</a
      >
      <a
        class="btn"
        @click="toggleEditMode"
        v-if="emailPrepopulated && !editMode"
        >Edit</a
      >
      <div v-if="emailPrepopulated && !editMode" class="prepopulated-message">
        Your email is already set.
      </div>
    </v-form>
    <div v-if="registrationSuccess" class="success-message">
      Please be on the lookout for an email containing the non-solicitation agreement from DocuSign. Thank you!
    </div>
  </div>
</template>

<script>
import {
  REGISTER_EMAIL,
  GET_TRANSITION_PUBLIC_INFO,
} from '@/stores/actions.type';
import {
  NOTIFICATION_TYPES,
  NotificationService,
} from '@/services/notification.service';
// import { LOCAL_KEYS } from '@/common/constants';
// import StorageService from '@/services/storage.service';

export default {
  name: 'TransitionEmailRegister',
  props: ['relatesTo', 'transitionId'],

  created: function () {
    if (!this.relatesTo || !this.transitionId) {
      NotificationService.alert({
        type: NOTIFICATION_TYPES.ERROR,
        title: 'Error',
        message: 'Invalid parameters!',
        okTitle: 'Ok',
      });
      this.$router.push('/');
    }
    this.$store
      .dispatch(GET_TRANSITION_PUBLIC_INFO, { transitionId: this.transitionId })
      .then((response) => {
        if (response) {
          if (this.relatesTo === 'Primary') {
            this.email = response.primaryContact.email;
          } else {
            this.email = response.secondaryContact.email;
          }
          this.emailPrepopulated = !!this.email;
          if (response.organisationLogo) {
            this.logo = response.organisationLogo;
          }
        }
      })
      .catch(() => {
        NotificationService.alert({
          type: NOTIFICATION_TYPES.ERROR,
          title: 'Error',
          message: 'Error getting transition info!',
          okTitle: 'Ok',
        });
      });
  },
  data() {
    return {
      email: '',
      showTooltip: false,
      registrationSuccess: false,
      emailPrepopulated: false,
      editMode: false,
      // logo: StorageService.getLocal(LOCAL_KEYS.COMPANY_LOGO) || null,
      logo: null,
    };
  },
  methods: {
    registerEmail() {
      const payload = {
        email: this.email,
        transitionId: this.transitionId,
        relatesTo: this.relatesTo,
      };
      this.$store
        .dispatch(REGISTER_EMAIL, payload)
        .then(() => {
          this.registrationSuccess = true;
          this.emailPrepopulated = true;
          this.editMode = false;
        })
        .catch(() => {
          NotificationService.alert({
            type: NOTIFICATION_TYPES.ERROR,
            title: 'Error',
            message: 'Error registering email!',
            okTitle: 'Ok',
          });
        });
    },
    toggleEditMode() {
      this.email = '';
      this.editMode = !this.editMode;
    },
  },
};
</script>

<style scoped>
.email-register-container {
  max-width: 500px;
  margin: auto;
  padding: 20px;
}

.header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

h1 {
  margin: 0;
  padding-right: 10px;
}

.icon-container {
  position: relative;
}

.tooltip {
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translate(0, -50%);
  background-color: #333;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 14px;
}

.success-message {
  color: green;
  margin-top: 20px;
  text-align: center;
}

.prepopulated-message {
  color: blue;
  margin-top: 10px;
}
</style>
