<template>
  <div class="advisor-menu-container">
    <div
      class="profile-container"
      @mouseover="showListMenu = true"
      @mouseout="showListMenu = false"
      @click="toggleMobileDrawer()"
    >
      <div class="dropdown-toggle">
        <div class="profile-text" :class="showListMenu ? ' hover' : ''">
          <span class="profile-name-label d-none d-sm-inline-flex">
            {{ profileName }}
          </span>
          <i class="profile-icon fas fa-user-circle"></i>
        </div>
      </div>
      <user-views
        v-if="!$vuetify.breakpoint.mdAndDown"
        :showHover="showListMenu"
      ></user-views>
    </div>
  </div>
</template>

<script>
import { EventService } from '@/services/event.service';
import UserViews from '@/components/advisor/AdvisorViews';
import { mapGetters } from 'vuex';

export default {
  name: 'MainMenu',

  components: { UserViews },

  data: () => ({
    showListMenu: false,
  }),

  computed: {
    ...mapGetters(['currentUser']),

    canShowHoverMenu() {
      return this.showListMenu && !this.$vuetify.breakpoint.mdAndDown;
    },
    canAddNewClient() {
      return true;
    },
    canShowNewClient() {
      return this.$vuetify.breakpoint.mdAndDown && this.canAddNewClient;
    },
    profileName() {
      try {
        return `${this.currentUser.user.firstName} ${this.currentUser.user.lastName}`;
      } catch {
        return '';
      }
    },
  },

  methods: {
    /* Toggle Mobile Menu Drawer (only for tablets and below) */
    toggleMobileDrawer() {
      if (this.$vuetify.breakpoint.mdAndDown)
        EventService.emit('toggle-main-drawer');
    },
  },
};
</script>
<style lang="scss" scoped>
.advisor-menu-container {
  justify-content: flex-end;
  align-items: center;
  display: flex;

  .profile-container {
    @media screen and (max-width: 1263px) {
      margin-top: 0px;
      margin-left: 0px;
    }

    @media screen and (max-width: 960px) {
      margin-left: 10px;
    }

    .dropdown-toggle {
      padding: 20px;
      transition: all 200ms ease;
      font-weight: 500;

      .profile-text {
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        color: var(--color-text);

        .profile-name-label {
          vertical-align: middle;
        }

        &.hover {
          color: var(--primary-color);
        }
      }

      .profile-icon {
        margin-left: 15px;
        font-size: 28px;
        line-height: 28px;
        vertical-align: middle;
      }

      @media screen and (max-width: 960px) {
        padding: 10px;
      }
    }
  }
}
</style>
