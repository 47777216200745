/*
  Event Service
  A simple event bus
*/
import Vue from 'vue';

// This is the Local EventBus
export const EventBus = new Vue();

export const EventService = {
  // Emit an event through the EventBus
  emit: (eventName, ...data) => {
    EventBus.$emit(eventName, ...data);
  },

  // Register an Event through the EventBus
  register: (eventName, fn) => {
    EventBus.$off(eventName);
    EventBus.$on(eventName, fn);
  },

  // Deregister an event in the EventBus
  deregister: (eventName, fn) => {
    EventBus.$off(eventName, fn);
  },
};
