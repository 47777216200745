<template>
  <v-dialog
    v-model="dialog"
    width="430"
    persistent
    :overlay-color="overlayColor"
    overlay-opacity="0.8"
    content-class="plain-dialog instructions-dialog"
  >
    <v-card flat>
      <v-card-title class="pa-0">
        <div class="modal-header d-flex">
          <div class="d-flex justify-start align-center close-container">
            <a class="close" @click="close()">
              <i class="fas fa-times"></i>
            </a>
          </div>
          <div class="modal-detail">How it works</div>
          <div class="d-flex justify-end next-container">
            <a class="next" @click="changePage()" v-if="showNext">
              <div>Next</div>
            </a>
          </div>
        </div>
      </v-card-title>
      <v-card-text>
        <v-carousel
          height="350"
          :continuous="true"
          :show-arrows="false"
          hide-delimiter-background
          light
          class="car"
          v-model="carouselPage"
          @change="pageChanged"
        >
          <v-carousel-item>
            <div class="slide">
              <div class="fad-container li-bullet">
                <v-icon v-if="paintIcon" color="#FFF" :key="pageKey"
                  >fas fa-tasks</v-icon
                >
              </div>
              <p class="paragraph-standard">
                Answer a few simple questions for your advisor.
              </p>
            </div>
          </v-carousel-item>
          <v-carousel-item>
            <div class="slide">
              <div class="fad-container li-bullet">
                <v-icon v-if="paintIcon" color="#FFF" :key="pageKey"
                  >fas fa-forward</v-icon
                >
              </div>
              <p class="paragraph-standard">
                You can jump around at any time. If you don't want to answer a
                question right away, skip it.
              </p>
            </div>
          </v-carousel-item>
          <v-carousel-item>
            <div class="slide">
              <div class="fad-container li-bullet">
                <v-icon v-if="paintIcon" color="#FFF" :key="pageKey"
                  >fas fa-exclamation-triangle</v-icon
                >
              </div>
              <p class="paragraph-standard">
                We do <span class="circle-sketch-highlight">NOT</span> save
                client data to a database. Once an answer is submitted, you will
                no longer be able to see your answer, but will be able to
                re-submit an answer at any time.
              </p>
              <a @click="close()" class="btn">
                <span>Dismiss</span>
              </a>
            </div>
          </v-carousel-item>
        </v-carousel>
        <v-card-actions class="justify-center">
          <v-checkbox
            v-model="preventShow"
            hide-details
            :color="primaryColor"
            :ripple="false"
          >
            <template v-slot:label>
              <span class="cb-label">Got it, don't show me again</span>
            </template>
          </v-checkbox>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Themable from '@/common/mixins/themable.mixin';
import StorageService from '@/services/storage.service';
import { LOCAL_KEYS } from '@/common/constants';

export default {
  name: 'ClientInstructions',

  mixins: [Themable],

  components: {},

  data: () => ({
    dialog:
      StorageService.getLocal(LOCAL_KEYS.DISABLE_CLIENT_INSTRUCTIONS) !== true,
    carouselPage: 0,
    preventShow: true,
    paintIcon: true,
  }),

  computed: {
    pageKey() {
      return `${this.carouselPage}-key`;
    },
    showNext() {
      return this.carouselPage !== 2;
    },
  },

  methods: {
    /* Close dialog and save show again state */
    close() {
      StorageService.setLocal(
        LOCAL_KEYS.DISABLE_CLIENT_INSTRUCTIONS,
        this.preventShow,
      );
      this.dialog = false;
    },

    /* Change Page */
    changePage() {
      const newPage = this.carouselPage + 1 > 2 ? 0 : this.carouselPage + 1;
      this.carouselPage = newPage;
      this.pageChanged();
    },

    /* On page change */
    pageChanged() {
      // Force icon to recompile.
      // v-icon seems to have issues painting when page changes
      this.paintIcon = false;
      setTimeout(() => (this.paintIcon = true), 100);
    },
  },

  created() {},
};
</script>

<style lang="scss" scoped>
.slide {
  display: flex;
  width: 100%;
  padding: 30px 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.next-container {
  width: 50%;
}

.close-container {
  width: 50%;
  height: 100%;
}
.modal-header {
  height: 55px;
  padding-right: 25px;
  padding-left: 25px;
  align-items: center;
  border-bottom: 1px solid var(--secondary-color);
  width: 100%;
}

.modal-detail {
  font-size: 20px;
  font-weight: 300;
  white-space: nowrap;
}

.next {
  padding: 4px 15px;
  font-size: 15px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
.cb-label {
  padding-top: 5px;
}
.li-bullet {
  background-color: var(--primary-color);
  color: var(--color-white);
  font-size: 36px;
  line-height: 34px;
  font-weight: 400;
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
  border-radius: 50%;
}
.circle-sketch-highlight {
  margin-right: 3px;
  margin-left: 10px;
  padding-right: 5px;
  padding-left: 0px;
  font-weight: 500;
  position: relative;
  font-size: 16px;

  &:before {
    content: '';
    z-index: -1;
    left: -0.5em;
    top: -0.1em;
    border-width: 2px;
    border-style: solid;
    border-color: var(--primary-color);
    position: absolute;
    border-right-color: transparent;
    width: 48px;
    height: 23px;
    transform: rotate(2deg);
    opacity: 0.7;
    border-radius: 50%;
    padding: 0.1em 0.25em;
  }
  &:after {
    content: '';
    z-index: -1;
    left: -0.5em;
    top: 0.15em;
    padding: 0.1em 0.25em;
    border-width: 2px;
    border-style: solid;
    border-color: var(--primary-color);
    border-left-color: transparent;
    border-top-color: transparent;
    position: absolute;
    width: 48px;
    height: 23px;
    transform: rotate(-1deg);
    opacity: 0.7;
    border-radius: 50%;
  }
}
</style>
