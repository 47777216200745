<template>
  <div>
    <v-layout v-resize="onResize" column style="">
      <v-data-table
        :headers="headers"
        :items="companies"
        :items-per-page="5"
        :hide-default-header="isMobile"
        class="responsive-table company-table"
        :class="{ mobile: isMobile }"
        :loading="tableLoading"
        :page.sync="pagination.page"
        @pagination="updatePage"
      >
        <template v-slot:top>
          <v-row>
            <v-col>
              <v-text-field
                outlined
                type="text"
                maxlength="256"
                placeholder="Search Companies..."
                v-model.trim="search"
                :backgroundColor="inputBackgroundColor"
                clearable
                class="search-input mb-2"
                single-line
                hide-details
                @click:clear="resetItems()"
              >
                <template v-slot:append>
                  <span
                    class="search"
                    v-if="!search || search.length === 0"
                    :key="tableLoading"
                  >
                    <font-awesome-icon icon="search"></font-awesome-icon>
                  </span>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="status-col">
              <v-select
                v-model="filterStatus"
                :items="statuses"
                :backgroundColor="inputBackgroundColor"
                outlined
                placeholder="Status"
                ref="statusFilter"
                dense
              >
                <template v-slot:prepend-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        class="prepend"
                        @click="clearStatusFilter()"
                      >
                        Clear Filter
                        <a class="close">
                          <div><i class="fas fa-times"></i></div>
                        </a>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <v-col class="button-area">
              <a @click="addCompany()" class="btn">
                Add Company
                <span class="fa-icon"><i class="fas fa-plus-circle"></i></span>
              </a>
            </v-col>
          </v-row>
        </template>
        <template slot="item" slot-scope="props">
          <tr v-if="!isMobile">
            <td v-for="col in tableColumns" :key="col" :width="col.width">
              <div v-if="!col.isActionColumn">
                <span
                  :class="col.classFn(props.item)"
                  @click="col.clickFn ? col.clickFn(props.item) : () => {}"
                >
                  {{ col.valueFn(props.item) }}
                </span>
              </div>
              <div v-else class="action-column">
                <v-menu
                  left
                  nudge-left="15"
                  nudge-bottom="25"
                  v-if="canPerformActions(props.item)"
                  min-width="125"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span class="actions" v-bind="attrs" v-on="on">
                      <font-awesome-icon icon="ellipsis-h"></font-awesome-icon>
                    </span>
                  </template>
                  <v-list class="menu">
                    <div v-for="action in col.actions" :key="action">
                      <v-list-item
                        v-if="
                          !action.isDivider && action.conditionFn(props.item)
                        "
                        @click="action.clickFn(props.item)"
                      >
                        <v-list-item-title class="menu-item-wrapper">
                          <v-icon x-small :key="props.item.status">
                            {{ action.iconFn(props.item) }}
                          </v-icon>
                          <span class="label">
                            {{ action.labelFn(props.item) }}
                          </span>
                        </v-list-item-title>
                      </v-list-item>
                      <div
                        class="divider"
                        v-if="action.isDivider && canShowDivider(props.item)"
                      ></div>
                    </div>
                  </v-list>
                </v-menu>
              </div>
            </td>
          </tr>
          <tr v-else>
            <td>
              <ul class="flex-content">
                <li class="flex-item" v-for="col in tableColumns" :key="col">
                  <div v-if="!col.isActionColumn">
                    <span class="mobile-label" v-if="col.dataLabel">
                      {{ col.dataLabel }}:
                    </span>
                    <span
                      :class="col.classFn(props.item)"
                      @click="col.clickFn ? col.clickFn(props.item) : () => {}"
                    >
                      {{ col.valueFn(props.item) }}
                    </span>
                  </div>
                  <div v-else class="actions-container">
                    <v-bottom-sheet v-if="canPerformActions(props.item)">
                      <template v-slot:activator="{ on, attrs }">
                        <span class="actions" v-bind="attrs" v-on="on">
                          <font-awesome-icon
                            icon="ellipsis-h"
                          ></font-awesome-icon>
                        </span>
                      </template>
                      <v-sheet height="auto">
                        <v-list class="sheet">
                          <div v-for="action in col.actions" :key="action">
                            <v-list-item
                              v-if="
                                !action.isDivider &&
                                action.conditionFn(props.item)
                              "
                              @click="action.clickFn(props.item)"
                            >
                              <v-list-item-title class="menu-item-wrapper">
                                <v-icon x-small :key="props.item.status">
                                  {{ action.iconFn(props.item) }}
                                </v-icon>
                                <span class="label">
                                  {{ action.labelFn(props.item) }}
                                </span>
                              </v-list-item-title>
                            </v-list-item>
                            <div
                              class="divider"
                              v-if="
                                action.isDivider && canShowDivider(props.item)
                              "
                            ></div>
                          </div>
                          <div class="bottom-spacer"></div>
                        </v-list>
                      </v-sheet>
                    </v-bottom-sheet>
                  </div>
                </li>
              </ul>
            </td>
          </tr>
        </template>
        <div class="no-results" slot="no-results" :value="true">
          Your search/filter found no results.
        </div>
        <div class="no-data" slot="no-data" :value="true">
          No companies found.
          <a @click="addCompany()"> Add a new company</a>.
        </div>
      </v-data-table>
    </v-layout>
  </div>
</template>

<script>
import Themable from '@/common/mixins/themable.mixin';
import { EventService } from '@/services/event.service';
import EventListener from '@/common/mixins/eventlistener.mixin';
import DataTableMixin from '@/common/mixins/datatable.mixin';
import {
  GET_COMPANIES,
  UPDATE_STATUS,
  TOGGLE_DOCUSIGN,
  TOGGLE_TRANSITIONS,
} from '@/stores/actions.type';
import {
  NOTIFICATION_TYPES,
  NotificationService,
} from '@/services/notification.service';
import { MENUS, COMPANY_STATUSES } from '@/common/constants';

export default {
  data: () => ({
    selected: [],
    search: '',
    filterStatus: null,
    isMobile: false,
    companies: [],
    tableLoading: false,
  }),

  mixins: [Themable, EventListener, DataTableMixin],
  computed: {
    /* Table Header Definitions */
    headers() {
      return [
        {
          text: 'Company',
          value: 'name',
          filter: (value) => {
            if (!this.search || this.search.length === 0) return true;
            return value.match(new RegExp(this.search, 'i'));
          },
        },
        {
          text: 'Status',
          value: 'status',
          filter: (value) => {
            if (!this.filterStatus) return true;

            return value === this.filterStatus;
          },
        },
        {
          text: 'DocuSign',
          value: 'status',
          filter: (value) => {
            if (!this.filterStatus) return true;

            return value === this.filterStatus;
          },
        },
        {
          text: 'Transitions',
          value: 'status',
          filter: (value) => {
            if (!this.filterStatus) return true;

            return value === this.filterStatus;
          },
        },
        {
          text: 'Seats',
          value: 'seats',
        },
        {
          text: 'Actions',
          align: 'end',
        },
      ];
    },

    /* Table Column Definitions */
    tableColumns() {
      const cols = [
        {
          width: '20%',
          valueFn: (item) => {
            return item.name;
          },
          clickFn: () => {},
          classFn: () => {
            return 'company-name';
          },
        },
        {
          width: '15%',
          dataLabel: 'Status',
          valueFn: (item) => {
            return item.status;
          },
          classFn: (item) => {
            return item.status;
          },
        },
        {
          width: '15%',
          dataLabel: 'DocuSign',
          valueFn: (item) => {
            return item.docusignDisabled ? 'Disabled' : 'Active';
          },
          classFn: (item) => {
            return item.docusignDisabled ? 'Disabled' : 'Active';
          },
        },
        {
          width: '20%',
          dataLabel: 'Transitions',
          valueFn: (item) => {
            return item.transitionsDisabled ? 'Disabled' : 'Active';
          },
          classFn: (item) => {
            return item.transitionsDisabled ? 'Disabled' : 'Active';
          },
        },
        {
          width: '15%',
          dataLabel: 'Seats',
          valueFn: (item) => {
            return item.totalAdvisors;
          },
          classFn: () => {
            return '';
          },
        },
        {
          width: '15%',
          isActionColumn: true,
          actions: [
            {
              conditionFn: (item) => {
                return !this.isCompanyDisabled(item);
              },
              clickFn: (item) => {
                this.editCompanyProfile(item);
              },
              labelFn: () => {
                return 'Edit Company';
              },
              iconFn: () => {
                return 'fas fa-edit';
              },
            },
            {
              conditionFn: (item) => {
                return !this.isCompanyDisabled(item);
              },
              clickFn: (item) => {
                this.toggleFeature(item, 'docusign');
              },
              labelFn: (item) => {
                return this.isFeatureDisabled(item, 'docusign')
                  ? 'Activate DocuSign'
                  : 'Disable DocuSign';
              },
              iconFn: (item) => {
                return this.isFeatureDisabled(item, 'docusign')
                  ? 'fas fa-toggle-on'
                  : 'fas fa-toggle-off';
              },
            },
            {
              conditionFn: (item) => {
                return !this.isCompanyDisabled(item);
              },
              clickFn: (item) => {
                this.toggleFeature(item, 'transitions');
              },
              labelFn: (item) => {
                return this.isFeatureDisabled(item, 'transitions')
                  ? 'Activate Transitions'
                  : 'Disable Transitions';
              },
              iconFn: (item) => {
                return this.isFeatureDisabled(item, 'transitions')
                  ? 'fas fa-toggle-on'
                  : 'fas fa-toggle-off';
              },
            },
            {
              conditionFn: () => {
                return true;
              },
              clickFn: (item) => {
                this.editCompanyStatus(item);
              },
              labelFn: (item) => {
                return this.isCompanyDisabled(item)
                  ? 'Activate Company'
                  : 'Disable Company';
              },
              iconFn: (item) => {
                return this.isCompanyDisabled(item)
                  ? 'fas fa-toggle-on'
                  : 'fas fa-toggle-off';
              },
            },
          ],
        },
      ];
      return cols;
    },
  },
  methods: {
    /* 
      Reset Items on Search Clear - forces the table to re-draw
      Safari Mobile seems to have issues with not re-rendering icons
      when repainting the table rows. This is a hack to force the entire
      table to be re-drawn
    */
    resetItems() {
      this.tableLoading = true;
      this.$nextTick(() => setTimeout(() => (this.tableLoading = false)), 500);
    },

    /* If any action is still applicable for the client we can show the actions context menu */
    // TODO:
    canPerformActions() {
      return true;
    },

    /* Can a divider be shown in the action context menu */
    canShowDivider() {
      return true;
    },

    /* Determine Mobile vs Desktop view */
    onResize() {
      this.isMobile = window.innerWidth < 769;
    },

    /* Clear the Company Status Filter */
    clearStatusFilter() {
      this.filterStatus = null;
      this.$refs.statusFilter.blur();
    },

    /* Display the Add Company Modal */
    addCompany() {
      EventService.emit('display-add-edit-company-modal');
    },

    performCompanyStatusChange(company) {
      const statusText = this.isCompanyDisabled(company)
        ? 'Enabled'
        : 'Disabled';

      const status = this.isCompanyDisabled(company)
        ? COMPANY_STATUSES.ACTIVE
        : COMPANY_STATUSES.DISABLED;

      this.$store
        .dispatch(UPDATE_STATUS, { organisationId: company.id, status })
        .then((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.SUCCESS,
              title: 'Success',
              message: `Company ${statusText}.`,
              okTitle: 'Ok',
              okMethod: () => {
                this.getCompanies();
              },
            },
            response,
          );
        })
        .catch((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: `Error changing company status.`,
              okTitle: 'Ok',
            },
            response,
          );
        })
        .finally(() => {
          this.getCompanies();
        });
    },

    performToggleFeature(company, feature) {
      const isDisabled = !this.isFeatureDisabled(company, feature);
      const status = isDisabled ? 'Disabled' : 'Enabled';

      const dict =
        {
          docusign: {
            action: TOGGLE_DOCUSIGN,
            verbiage: 'DocuSign',
          },
          transitions: {
            action: TOGGLE_TRANSITIONS,
            verbiage: 'Transitions',
          },
        }[feature] || null;

      this.$store
        .dispatch(dict.action, { companyId: company.id })
        .then((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.SUCCESS,
              title: 'Success',
              message: `${dict.verbiage} ${status}.`,
              okTitle: 'Ok',
            },
            response,
          );
        })
        .catch((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: `Error toggling ${dict.verbiage} feature.`,
              okTitle: 'Ok',
            },
            response,
          );
        })
        .finally(() => {
          this.getCompanies();
        });
    },

    editCompanyProfile(company) {
      this.$router.push({
        name: MENUS.COMPANY_PROFILE.id,
        params: { id: company.id },
      });
    },

    editCompanyStatus(company) {
      const action = this.isCompanyDisabled(company) ? 'enable' : 'archive';

      NotificationService.confirm({
        type: NOTIFICATION_TYPES.WARNING,
        message: `Are you sure you want to ${action} ${company.name}?`,
        okTitle: "Yes I'm sure",
        okMethod: () => {
          this.performCompanyStatusChange(company);
        },
      });
    },

    toggleFeature(company, feature) {
      const action = this.isFeatureDisabled(company, feature)
        ? 'enable'
        : 'disable';

      NotificationService.confirm({
        type: NOTIFICATION_TYPES.WARNING,
        message: `Are you sure you want to ${action} ${feature} for ${company.name}?`,
        okTitle: "Yes I'm sure",
        okMethod: () => {
          this.performToggleFeature(company, feature);
        },
      });
    },

    isCompanyDisabled(company) {
      return company.status === COMPANY_STATUSES.DISABLED;
    },

    isFeatureDisabled(company, feature) {
      return (
        {
          docusign: company.docusignDisabled,
          transitions: company.transitionsDisabled,
        }[feature] || false
      );
    },

    /* Get the list of companies */
    getCompanies() {
      // Again we hide the table while loading to force
      // it to be redrawn as Safari on mobile seems to have issues
      // compiling icons
      this.tableLoading = true;

      this.$store
        .dispatch(GET_COMPANIES, { limit: 1000 })
        .then((response) => {
          this.companies = response.result;
        })
        .catch((response) => {
          this.companies = [];
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: 'Error getting list of companies!',
              okTitle: 'Ok',
            },
            response,
          );
        })
        .finally(() => {
          // Regardless of outcome, force re-draw
          this.tableLoading = false;
        });
    },
  },

  /* Lifecycle Hook */
  created() {
    this.pagination.sortBy = 'name';

    // Set initial static status list
    this.statuses = [];
    Object.keys(COMPANY_STATUSES).map((key) => {
      this.statuses.push(COMPANY_STATUSES[key]);
    });

    // Get the list of companies
    this.getCompanies();

    // Listen for reload events
    this.listen('reload-company-list', () => {
      this.getCompanies();
    });
  },
};
</script>

<style lang="scss" scoped>
.mobile {
  color: var(--color-text-dark);
}
.prepend {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--secondary-color);
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.search-input {
  ::v-deep {
    .v-input__icon--clear {
      height: 16px;
    }
    .fa-times-circle {
      font-size: 16px;
      margin-right: 8px;
    }
  }
}

.action-column {
  text-align: end !important;
}

.actions {
  padding: 2px 4px;
  line-height: 16px;

  &[aria-expanded='true'] {
    background-color: var(--secondary-color);

    ::v-deep .v-icon {
      color: var(--primary-color);
    }
  }
}
.menu {
  background-color: var(--color-white);

  ::v-deep {
    .v-list-item {
      min-height: 24px !important;
      padding: 0;
    }
  }
}
.menu-item-wrapper {
  padding-left: 10px;
  color: var(--color-text) !important;
  font-size: 14px !important;
  font-weight: 700;
  margin-bottom: 0;
  padding: 8px 12px;
  margin: 0 4px;

  ::v-deep {
    .v-icon {
      font-weight: 700;
      color: var(--color-text) !important;
      //margin-right: 8px;
      margin-top: -2px;
    }
  }
  &:hover {
    color: var(--primary-color) !important;
    background-color: var(--input-bg-color);
    text-decoration: underline;
    ::v-deep .v-icon {
      color: var(--primary-color) !important;
    }
  }
  .label {
    padding-left: 8px;
  }
}
.sheet {
  background-color: var(--color-white);

  ::v-deep {
    .v-list-item {
      min-height: 24px !important;
      padding: 0;
    }
  }
  .menu-item-wrapper {
    font-size: 16px !important;
    padding: 12px;
  }
  .bottom-spacer {
    height: 25px;
  }
}
.status-col {
  max-width: 200px;
}
.flex-content {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;

  .actions-container {
    position: absolute;
    top: 0;
    right: 0;
    line-height: normal;
  }

  .edit-client-link {
    color: var(--color-text);
    font-weight: 700;
  }
  .flex-item {
    padding: 5px;
    line-height: 24px;
    font-size: 16px;

    .company-name {
      padding-right: 30px;
      display: inline-block;
      line-height: normal;
    }

    .mobile-label {
      font-weight: 400;
      margin-right: 10px;
    }
  }
}

.search {
  margin-right: 12px;
}
.divider {
  height: 1px;
  margin: 8px;
  background-color: var(--secondary-color);
}
.company-table {
  font-weight: 500;
  color: var(--color-text);

  .Disabled {
    padding: 2px 15px;
    border-radius: 30px;
    line-height: 24px;
    background-color: hsla(0, 0%, 72.7%, 0.16);
    color: var(--label-text);
    font-weight: 500;
  }
  .Active {
    padding: 2px 15px;
    border-radius: 30px;
    line-height: 24px;
    background-color: rgba(30, 59, 112, 0.15);
    color: var(--primary-color);
    font-weight: 500;
  }
}
</style>
