<template>
  <document-table></document-table>
</template>

<script>
import DocumentTable from '@/components/advisor/tables/DocumentTable';

export default {
  name: 'DocumentList',

  components: {
    DocumentTable,
  },

  data: () => ({}),

  methods: {},
  created() {},
};
</script>
<style lang="scss" scoped></style>
