<template>
  <div class="custom-questions-container">
    <v-form name="CustomQuestionsForm" class="form">
      <!-- Main container -->
      <v-container class="custom-questions-step-container">
        <!-- Heading -->
        <v-row class="mb-8">
          <v-col class="instructions">
            <h1>
              <span class="headerlight">
                Select <strong>custom question templates</strong> for the
                interview flow.
              </span>
            </h1>
          </v-col>
        </v-row>

        <!-- Multi-select for custom question templates -->
        <v-row>
          <v-col cols="12">
            <v-select
              v-model="selectedTemplates"
              :items="customQuestionTemplates"
              label="Custom Question Templates"
              multiple
              chips
              :loading="loadingTemplates"
              :no-data-text="'No templates available'"
              return-object
              item-text="templateName"
              item-value="_id"
              class="mb-5"
            >
            </v-select>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <!-- Navigation Buttons -->
    <bottom-nav @go-prev="prev" @go-next="next"></bottom-nav>
  </div>
</template>

<script>
import BottomNav from '@/components/common/BottomNav';
import AdvisorClientMixin from '@/common/mixins/advisor.client.mixin';
import { GET_CUSTOM_QUESTIONS_TEMPLATES } from '@/stores/actions.type';
import { mapGetters } from 'vuex';
import {
  NotificationService,
  NOTIFICATION_TYPES,
} from '@/services/notification.service';
import { MENUS } from '@/common/constants';
export default {
  name: 'CustomQuestionTemplates',
  components: { BottomNav },
  mixins: [AdvisorClientMixin],
  data: () => ({
    customQuestionTemplates: [],
    selectedTemplates: [],
    loadingTemplates: false,
  }),
  computed: {
    ...mapGetters(['currentUser', 'currentCompany']),
  },
  mounted() {
    this.selectedTemplates = this.currentClient.selectedTemplates || [];
    this.fetchTemplatesFromAPI();
  },
  methods: {
    fetchTemplatesFromAPI() {
      this.loadingTemplates = true;
      this.$store
        .dispatch(GET_CUSTOM_QUESTIONS_TEMPLATES, { page: 1, limit: 1000 })
        .then((response) => {
          this.customQuestionTemplates = response.result;
        })
        .catch(() => {
          NotificationService.alert({
            type: NOTIFICATION_TYPES.ERROR,
            title: 'Error',
            message: 'Error fetching question templates.',
          });
        })
        .finally(() => {
          this.loadingTemplates = false;
        });
    },
    next(route = null) {
      this.currentClient.selectedTemplates = this.selectedTemplates;
      const to = route ? route.name : MENUS.ADVISOR.CLIENT.REVIEW_AND_FINISH.id;
      this.goNext(to);
    },
    prev() {
      this.goPrev(MENUS.ADVISOR.CLIENT.FIRM_ROLES.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-questions-container {
  max-width: 1024px;
  margin: auto;
  max-height: 80vh;
  overflow-y: auto !important;
  font-family: var(--font-family);
  color: var(--color-text-dark);

  .instructions h1 {
    font-weight: 500;
  }

  .alert {
    border: 1px solid var(--secondary-color);
    border-radius: var(--border-radius);
    padding: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    .fa-icon {
      margin-right: 10px;
      color: var(--primary-color);
      font-size: 16px;
    }
  }

  .elevation-1 {
    width: 100%;
    max-width: 100%;
    border: 1px solid var(--secondary-color);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    display: flex;
    flex-direction: column;
    overflow-y: auto !important;

    .v-data-table-header {
      background-color: var(--secondary-color);
      color: var(--color-white);
      font-weight: 500;
      padding: 10px 26px;
    }

    .v-data-table__wrapper {
      overflow: auto !important;

      .row:not(:last-of-type) {
        border-bottom: 1px solid var(--secondary-color);
      }

      .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0;
        padding: 10px 26px;

        .v-data-table__checkbox {
          color: var(--color-text);
        }

        .col {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          border-top: none;
          padding: 10px 26px;

          &.first {
            background-color: var(--input-bg-color);
          }

          @media screen and (max-width: 960px) {
            padding: 10px;
          }
        }

        .col-2 {
          justify-content: center;
          padding: 10px 0;

          @media screen and (max-width: 960px) {
            justify-content: flex-start;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .custom-questions-container {
    .elevation-1 {
      .v-data-table__wrapper {
        .row {
          flex-direction: column;

          .col {
            width: 100%;
          }
        }
      }
    }
  }
}

.pagination-style {
  display: flex;
  justify-content: center;
  align-items: center;

  .v-pagination__navigation {
    padding: 0 15px !important; // Use !important to ensure the style is applied
  }

  .v-pagination__item {
    margin: 0 5px; // Adding a margin to space out individual pagination items
  }
}

#virtual-scroll-table {
  max-height: 400px;
  overflow: auto;
}

/* Add other styles as needed */
</style>
