<template>
  <div>
    <v-tabs v-model="tab" background-color="white" slider-color="primary">
      <v-tab value="custom-questions">Custom Questions</v-tab>
      <v-tab value="templates">Templates</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item key="custom-questions">
        <div>
          <v-layout v-resize="onResize" column>
            <v-toolbar flat color="white">
              <v-text-field
                outlined
                type="text"
                maxlength="256"
                placeholder="Search Questions..."
                v-model.trim="search"
                clearable
                class="search-input mb-2"
                single-line
                hide-details
                @click:clear="resetItems()"
              >
                <template v-slot:append>
                  <span class="search" v-if="!search || search.length === 0">
                    <v-icon small>fas fa-search</v-icon>
                  </span>
                </template>
              </v-text-field>
              <v-spacer></v-spacer>
              <a @click="addQuestion()" class="btn">
                Add Question
                <span class="fa-icon"><i class="fas fa-plus-circle"></i></span>
              </a>
            </v-toolbar>
            <v-data-table
              :headers="headers"
              :items="questions"
              :items-per-page="pagination.limit"
              :server-items-length="pagination.itemsLength"
              :hide-default-header="isMobile"
              class="responsive-table question-table"
              :class="{ mobile: isMobile }"
              :loading="tableLoading"
              :page.sync="pagination.page"
              @pagination="getQuestionList"
            >
              <template slot="item" slot-scope="props">
                <tr :key="props.item._id">
                  <td v-for="col in tableColumns" :key="col" :width="col.width">
                    <div v-if="!col.isActionColumn">
                      <span
                        :class="col.classFn(props.item)"
                        @click="
                          col.clickFn ? col.clickFn(props.item) : () => {}
                        "
                      >
                        {{ col.valueFn(props.item) }}
                      </span>
                    </div>
                    <div
                      v-else
                      class="action-column"
                      @click="deleteQuestion(props.item)"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            small
                            v-on="on"
                            @click="deleteQuestion(props.item)"
                          >
                            {{ col.iconFn(props.item) }}
                          </v-icon>
                        </template>
                        <span>{{ col.labelFn(props.item) }}</span>
                      </v-tooltip>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-slot:no-results>
                <div class="no-results">
                  No questions found.
                  <a @click="addQuestion()">Add a new question.</a>
                </div>
              </template>
              <template v-slot:no-data>
                <div class="no-data">
                  No questions found.
                  <a @click="addQuestion()">Add a new question.</a>
                </div>
              </template>
            </v-data-table>
          </v-layout>
          <question-dialog :dialog.sync="showDialog"></question-dialog>
        </div>
      </v-tab-item>

      <!-- Templates Tab -->
      <v-tab-item key="templates">
        <custom-questions-templates></custom-questions-templates>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
import QuestionDialog from '../dialog/AddEditQuestion.vue';
import CustomQuestionsTemplates from './CustomQuestionsTemplates.vue';
import { EventService } from '@/services/event.service';
import {
  GET_CUSTOM_QUESTIONS,
  DELETE_CUSTOM_QUESTION,
} from '@/stores/actions.type';
import EventListener from '@/common/mixins/eventlistener.mixin';
import {
  NOTIFICATION_TYPES,
  NotificationService,
} from '@/services/notification.service';

export default {
  mixins: [EventListener],
  components: {
    QuestionDialog,
    CustomQuestionsTemplates,
  },
  created() {
    this.listen('reload-questions-table', () => {
      this.getQuestionList();
    });
  },
  data() {
    return {
      tab: null,
      newTemplateDialog: false,
      newTemplateName: '',
      templates: [], // You'll need to populate this with your templates
      templateHeaders: [
        {
          text: 'Template Name',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      headers: [
        { text: 'Question', value: 'question', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      tableColumns: [
        {
          width: '80%',
          valueFn: (item) => item.question,
          clickFn: () => {},
          classFn: (item) => (item.isEditable ? 'clickable' : ''),
        },
        {
          width: '20%',
          valueFn: () => 'Delete',
          labelFn: () => {
            return 'Delete Question';
          },
          clickFn: (item) => {
            this.deleteQuestion(item);
          },
          iconFn: () => {
            return 'fas fa-trash';
          },
          isActionColumn: true,
        },
      ],
      questions: [],
      selectedQuestions: [],
      showDialog: false,
      tableLoading: false,
      search: '',
      pagination: {
        page: 1,
        limit: 5,
        pageCount: 0,
        totalItems: 0,
      },
      questionHeaders: [
        { text: 'Select', value: 'select', sortable: false },
        { text: 'Question', value: 'question', sortable: true },
      ],
    };
  },
  computed: {
    paginationPageCount() {
      return Math.ceil(this.questions.length / this.pagination.limit);
    },
    paginatedQuestions() {
      // Compute the questions to be shown on the current page
      const start = (this.pagination.page - 1) * this.pagination.limit;
      const end = start + this.pagination.limit;
      return this.questions.slice(start, end);
    },
  },
  watch: {
    search(newVal) {
      this.filterQuestions(newVal);
    },
  },
  methods: {
    toggleSelection(questionId) {
      const index = this.selectedQuestionIds.indexOf(questionId);
      if (index >= 0) {
        this.selectedQuestionIds.splice(index, 1); // remove the questionId
      } else {
        this.selectedQuestionIds.push(questionId); // add the questionId
      }
    },
    isSelected(questionId) {
      return this.selectedQuestionIds.includes(questionId);
    },
    updateSelected(value) {
      // This method will be called when the v-data-table selection changes
      this.selectedQuestionIds = value.map((item) => item._id);
    },
    close() {
      this.newTemplateDialog = false;
    },
    handlePagination(page) {
      this.pagination.page = page;
    },
    deleteQuestion(item) {
      this.tableLoading = true;
      NotificationService.confirm({
        type: NOTIFICATION_TYPES.WARNING,
        title: 'Delete Question',
        message: `Are you sure you want to delete the question "${item.question}"?`,
        okTitle: 'Yes',
        okMethod: () => {
          this.$store
            .dispatch(DELETE_CUSTOM_QUESTION, {
              id: item._id,
            })
            .catch((error) => {
              NotificationService.alert(
                {
                  type: NOTIFICATION_TYPES.ERROR,
                  title: 'Error',
                  message: 'Error deleting question',
                  okTitle: 'Ok',
                },
                error,
              );
            })
            .finally(() => {
              this.getQuestionList();
              this.tableLoading = false;
            });
        },
        cancelTitle: 'No',
      });
    },
    onResize() {
      this.isMobile = window.innerWidth < 769;
    },
    addQuestion() {
      EventService.emit('display-add-custom-question-modal');
    },
    resetItems() {
      this.search = '';
      this.getQuestionList();
    },
    filterQuestions(searchTerm) {
      if (!searchTerm) {
        this.getQuestionList();
      } else {
        this.questions = this.questions.filter((item) =>
          item.question.toLowerCase().includes(searchTerm.toLowerCase()),
        );
      }
    },
    getQuestionList(paginationData) {
      this.tableLoading = true;
      if (paginationData) {
        this.pagination.page = paginationData.page;
        this.pagination.limit = paginationData.limit;
      }
      const params = {
        search: this.search,
        page: this.pagination.page,
        limit: this.pagination.limit,
      };
      this.$store
        .dispatch(GET_CUSTOM_QUESTIONS, params)
        .then((result) => {
          this.questions = result.result;
          this.pagination.itemsLength = result.totalResults;
          // if (this.search) {
          //     this.filterQuestions(this.search);
          // }
        })
        .catch((error) => {
          this.questions = []; // Reset to questions
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: 'Error getting list of questions!',
              okTitle: 'Ok',
            },
            error,
          );
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile {
  color: var(--color-text-dark);
}

.search-input {
  ::v-deep {
    .v-input__icon--clear {
      height: 16px;
    }

    .fa-times-circle {
      font-size: 16px;
      margin-right: 8px;
    }
  }
}

.filter-col {
  max-width: 200px;

  &.role {
    max-width: 300px;
  }
}

.edit-profile-link {
  color: var(--color-text);
  font-weight: 700;

  &:hover {
    color: var(--primary-color);
  }
}

.flex-content {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;

  .actions-container {
    position: absolute;
    top: 0;
    right: 0;
    line-height: normal;
  }

  .flex-item {
    padding: 5px;
    line-height: 24px;
    font-size: 16px;

    .edit-profile-link {
      padding-right: 30px;
      display: inline-block;
      line-height: normal;
    }

    .mobile-label {
      font-weight: 400;
      margin-right: 10px;
    }
  }
}

.search {
  margin-right: 12px;
}

.admin-role {
  ::v-deep .v-icon {
    font-size: 12px;
    color: var(--color-warning);
    vertical-align: baseline;
  }
}

.name-item {
  height: 60px;
}

.user-email {
  color: var(--label-text);
  font-size: 11px;
  font-weight: 400;
  line-height: normal;
}

.actions {
  padding: 2px 4px;
  line-height: 16px;

  &[aria-expanded='true'] {
    background-color: var(--secondary-color);
    border-radius: 4px;

    ::v-deep .v-icon {
      color: var(--primary-color);
    }
  }
}

.menu {
  background-color: var(--color-white);

  ::v-deep {
    .v-list-item {
      min-height: 24px !important;
      padding: 0;
    }
  }
}

.menu-item-wrapper {
  padding-left: 10px;
  color: var(--color-text) !important;
  font-size: 14px !important;
  font-weight: 700;
  margin-bottom: 0;
  padding: 8px 12px;
  margin: 0 4px;
  cursor: pointer;

  ::v-deep {
    .v-icon {
      font-weight: 700;
      color: var(--color-text) !important;
      margin-top: -2px;
    }
  }

  &:hover {
    color: var(--primary-color) !important;
    background-color: var(--input-bg-color);

    text-decoration: underline;

    ::v-deep .v-icon {
      color: var(--primary-color) !important;
    }
  }

  .label {
    padding-left: 8px;
    cursor: pointer;
  }
}

.sheet {
  background-color: var(--color-white);

  ::v-deep {
    .v-list-item {
      min-height: 24px !important;
      padding: 0;
    }
  }

  .menu-item-wrapper {
    font-size: 16px !important;
    padding: 12px;
  }

  .bottom-spacer {
    height: 25px;
  }
}

.divider {
  height: 1px;
  margin: 8px;
  background-color: var(--secondary-color);
}
.v-toolbar {
  margin: 10px 0 5px 0;
}
.user-table {
  font-weight: 500;
  color: var(--color-text);

  .Pending,
  .Archived {
    padding: 2px 15px;
    border-radius: 30px;
    line-height: 24px;
    background-color: hsla(0, 0%, 72.7%, 0.16);
    color: var(--label-text);
  }

  .Active {
    padding: 2px 15px;
    border-radius: 30px;
    line-height: 24px;
    background-color: rgba(30, 59, 112, 0.15);
    color: var(--primary-color);
  }

  .filter-action-row {
    padding-bottom: 12px;
  }

  .edit-profile-link.disabled {
    text-decoration: none;
    color: var(--color-text);

    &:hover {
      color: var(--color-text);
      cursor: text;
    }
  }
}

@media screen and (max-width: 769px) {
  .filter-action-row {
    flex-direction: column;

    .filter-col {
      max-width: 100%;
      padding: 4px 12px;
    }

    .button-area {
      justify-content: flex-start;
    }
  }
}
</style>
