<template>
  <div class="auth-container">
    <div class="inner-container">
      <div class="logo-container">
        <img
          :src="`${publicPath}images/defaultLogo.png`"
          width="140"
          loading="lazy"
          alt="OnBord"
          class="logo"
        />
      </div>
      <div class="main-title">
        Enter your password for<br /><strong>{{ email }}</strong>
      </div>
      <div class="form-block w-form">
        <v-form name="setNewPasswordForm" class="form">
          <v-row class="mb-5">
            <v-col>
              <label for="oldPassword" class="required">Old Password</label>
              <v-text-field
                outlined
                type="password"
                maxlength="256"
                name="oldPassword"
                aria-required="true"
                v-model.trim="oldPassword"
                :backgroundColor="inputBackgroundColor"
                append-icon="far fa-eye"
                @blur="$v.oldPassword.$touch()"
                :error-messages="oldPasswordErrors"
                hide-details="auto"
              >
                <template v-slot:append>
                  <password-strength-meter
                    :value="password"
                  ></password-strength-meter>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="mb-1">
            <v-col>
              <label for="password" class="required">New Password</label>
              <v-text-field
                outlined
                type="password"
                maxlength="256"
                name="password"
                aria-required="true"
                v-model.trim="password"
                :backgroundColor="inputBackgroundColor"
                append-icon="far fa-eye"
                @blur="$v.password.$touch()"
                :error-messages="passwordErrors"
                hide-details="auto"
              >
                <template v-slot:append>
                  <password-strength-meter
                    :value="password"
                  ></password-strength-meter>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="mb-5">
            <v-col>
              <label for="confirmPassword" class="required"
                >Confirm New Password</label
              >
              <v-text-field
                outlined
                type="password"
                maxlength="256"
                name="confirmPassword"
                aria-required="true"
                v-model.trim="confirmPassword"
                :backgroundColor="inputBackgroundColor"
                append-icon="far fa-eye"
                @input="$v.confirmPassword.$touch()"
                @blur="$v.confirmPassword.$touch()"
                :error-messages="confirmPasswordErrors"
                hide-details="auto"
              >
                <template v-slot:append><span></span></template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <a class="btn" @click="setNewPassword(oldPassword, password)"
                >Save Password &amp; Log In</a
              >
            </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
  </div>
</template>
<script>
import { validationMixin } from 'vuelidate';
import { required, sameAs, minLength } from 'vuelidate/lib/validators';
import { SET_NEW_PASSWORD } from '@/stores/actions.type';
import LoginMixin from '@/common/mixins/login.mixin';
import Themable from '@/common/mixins/themable.mixin';
import {
  NOTIFICATION_TYPES,
  NotificationService,
} from '@/services/notification.service';
import PasswordStrengthMeter from '@/components/PasswordStrengthMeter';
import { MENUS, passwordComplexity } from '@/common/constants';
import PublicAssets from '@/common/mixins/publicasset.mixin';

export default {
  name: 'SetNewPassword',

  components: {
    PasswordStrengthMeter,
  },

  mixins: [PublicAssets, validationMixin, LoginMixin, Themable],

  data: () => ({
    password: null,
    confirmPassword: null,
    oldPassword: null,
  }),

  validations() {
    return {
      password: {
        required,
        minLength: minLength(6),
        passwordComplexity,
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs(() => this.password),
      },
      oldPassword: {
        required,
      },
      validationGroup: ['password', 'confirmPassword', 'oldPassword'],
    };
  },

  computed: {
    /* Dynamic Password errors */
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push('New Password is required');
      !this.$v.password.minLength &&
        errors.push('New Password must be at least 6 characters');
      !this.$v.password.passwordComplexity &&
        errors.push(
          'At least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character is required',
        );
      return errors;
    },

    /* Dynamic Confirm Password errors */
    confirmPasswordErrors() {
      const errors = [];
      if (!this.$v.confirmPassword.$dirty) return errors;
      !this.$v.confirmPassword.required &&
        errors.push('Confirm Password is required');
      !this.$v.confirmPassword.sameAsPassword &&
        errors.push('Password and Confirm Password do not match');
      return errors;
    },

    /* Dynamic Password errors */
    oldPasswordErrors() {
      const errors = [];
      if (!this.$v.oldPassword.$dirty) return errors;
      !this.$v.oldPassword.required && errors.push('Old Password is required');
      return errors;
    },

    email() {
      return this.$route.query.email;
    },
  },

  methods: {
    /* Perform Password Reset */
    setNewPassword(oldPassword, newPassword) {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.$store
        .dispatch(SET_NEW_PASSWORD, { oldPassword, newPassword })
        .then(() => {
          NotificationService.alert({
            type: NOTIFICATION_TYPES.SUCCESS,
            title: 'Success',
            message:
              'New password set. You can now use this password to login.',
            okTitle: 'Ok',
            okMethod: () => {
              this.$router.push({ name: MENUS.LOGIN.id });
            },
          });
        })
        .catch((error) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message:
                'Error setting new password! You may need to start the login again.',
              okTitle: 'Ok',
              okMethod: () => {
                this.$router.push({ name: MENUS.LOGIN.id });
              },
            },
            error,
          );
        });
    },
  },
};
</script>
<style lang="scss" scoped></style>
