<template>
  <div class="success-page">
    <div>
      <img
        :src="logo"
        width="240"
        loading="lazy"
        alt="OnBord"
        class="logo"
        id="companyLogo"
      />
    </div>

    <h2>Statement Submitted</h2>
    <h3>
      You will receive further communication from your advisor to transition
      your accounts
    </h3>
  </div>
</template>

<script>
import StorageService from '@/services/storage.service';
import { LOCAL_KEYS } from '@/common/constants';

export default {
  data: () => ({
    logo: StorageService.getLocal(LOCAL_KEYS.COMPANY_LOGO) || null,
  }),
  methods: {},
};
</script>

<style lang="scss" scoped>
.success-page {
  padding-top: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

h1 {
  font-family: Roboto;
  color: #5c5c5c;
  font-weight: 400;
  font-size: 40px;
  line-height: 46px;
  text-align: center;
  margin-bottom: 60px;

  @media screen and (max-width: 765px) {
    font-size: 30px;
  }
}

h2 {
  font-family: Roboto;
  color: #5c5c5c;
  font-weight: 400;
  font-size: 32px;
  line-height: 37.5px;
  align-items: center;
  width: 707px;
  margin-inline: auto;

  @media screen and (max-width: 765px) {
    font-size: 25px;
    width: auto;
  }
}
</style>
