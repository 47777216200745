<template>
  <div class="step-container client">
    <v-form name="Finish" class="form">
      <v-container class="steps-step-container">
        <v-row class="mb-8">
          <v-col class="instructions">
            <h1>
              <span class="headerlight" v-if="!isAllComplete">
                You're
                <strong>close to finishing!</strong>
                Here's what you've
                <strong>skipped:</strong>
              </span>
              <span v-else class="headerlight">
                Everything is <strong> Complete! </strong> Press
                <strong> Finish </strong> to finalize.
              </span>
            </h1>
          </v-col>
        </v-row>
        <div class="justify-center steps-table" v-if="!finishing">
          <v-row
            class="editable"
            v-for="(step, index) in incompleteSteps"
            :key="step.name"
            :class="index !== 0 ? 'section-row' : ''"
          >
            <v-col class="col-4 step-col" :class="index === 0 ? 'first' : ''">
              {{ step.name }}
            </v-col>
            <v-col class="col-8 data-col">
              <span v-if="!$vuetify.breakpoint.mdAndDown">—</span>
              <a class="edit" @click="next(step.route)">
                <span>Click to Complete</span>
                <span class="fa-icon">
                  <i class="fas fa-angle-right"></i>
                </span>
              </a>
            </v-col>
          </v-row>
          <v-row class="editable" v-if="areStepsComplete">
            <v-col class="col-12 all-complete"> No incomplete steps. </v-col>
          </v-row>
        </div>
        <div
          class="doc-container"
          v-if="!finishing && requiredDocuments.length > 0"
        >
          <v-row class="mb-8">
            <v-col class="instructions">
              <h1>Required Documents</h1>
            </v-col>
          </v-row>
          <div class="justify-center steps-table">
            <v-row
              class="editable"
              v-for="(doc, index) in requiredDocuments"
              :key="doc.name"
              :class="index !== 0 ? 'section-row' : ''"
            >
              <v-col class="col-4 step-col" :class="index === 0 ? 'first' : ''">
                {{ getDocumentLabel(doc) }}
              </v-col>

              <v-col class="col-8 data-col">
                <span v-if="doc.uploaded" class="uploaded">
                  <v-icon :color="primaryColor" :key="doc.id"
                    >fas fa-check</v-icon
                  >
                  uploaded<br />
                  <span class="small"> ({{ doc.uploaded }}) </span>
                </span>
                <span
                  v-if="!doc.uploaded && !isOptionalDoc(doc)"
                  class="not-uploaded"
                >
                  <v-icon :color="errorColor" key="not-uploaded">
                    fas fa-exclamation-triangle
                  </v-icon>
                  missing
                </span>
                <span v-if="!doc.uploaded && isOptionalDoc(doc)">
                  optional
                </span>

                <v-file-input
                  v-model="doc.file"
                  prepend-icon=""
                  class="faux-upload"
                  :ref="`upload-${index}`"
                  hide-details="auto"
                  :clearable="false"
                  accept="application/pdf, image/png, image/jpeg, image/svg+xml, image/gif"
                  @change="uploadDoc(doc)"
                >
                  <template v-slot:prepend-inner>
                    <a
                      class="btn mini"
                      style="display: block"
                      @click="
                        () => {
                          $refs[`upload-${index}`][0].$refs.input.click();
                        }
                      "
                    >
                      choose file
                    </a>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-container>
    </v-form>
    <bottom-nav
      :nextText="isAllComplete ? 'Finish' : 'Save'"
      v-on:go-prev="prev()"
      v-on:go-next="saveFinish()"
    ></bottom-nav>
  </div>
</template>

<script>
import BottomNav from '@/components/common/BottomNav';
import InterviewClientMixin from '@/common/mixins/interview.client.mixin';
import Storage from '@/services/storage.service';
import {
  MENUS,
  CLIENT_EXIT_KEYS,
  LOCAL_KEYS,
  AUTH_TYPES,
} from '@/common/constants';
import {
  UPLOAD_CLIENT_DOCUMENT,
  GET_INTERVIEW_PROGRESS,
  FINISH_INTERVIEW,
} from '@/stores/actions.type';
import {
  NOTIFICATION_TYPES,
  NotificationService,
} from '@/services/notification.service';

export default {
  name: 'Finish',

  components: { BottomNav },
  mixins: [InterviewClientMixin],

  data: () => ({
    finishing: false,
  }),

  validations() {
    return {};
  },

  computed: {
    areStepsComplete() {
      return this.incompleteSteps.length === 0;
    },

    isAllComplete() {
      return this.areStepsComplete && this.documentsComplete;
    },
    documentsComplete() {
      if (this.requiredDocuments.length === 0) {
        return true;
      }

      return (
        this.requiredDocuments.find(
          (d) => !this.isOptionalDoc(d) && d.uploaded === null,
        ) === undefined
      );
    },
  },

  methods: {
    getDocumentLabel(doc) {
      const docLabel = doc.label ? `(${doc.label})` : '';
      const docFeature = doc.feature ? `(${doc.feature})` : '';
      const docType = doc.type ? `for ${doc.type}` : '';
      return `${doc.name} ${docFeature} ${docType} ${docLabel}`;
    },
    isOptionalDoc(doc) {
      return ['CancelledCheck', 'BrokerageStatement'].includes(doc.id);
    },
    /* Upload a document */
    uploadDoc(doc) {
      const payload = {
        documentName: doc.id,
        accountId: doc.accountId,
        file: doc.file,
        feature: doc.feature,
      };
      this.finishing = true;

      this.$store
        .dispatch(UPLOAD_CLIENT_DOCUMENT, payload)
        .then(() => {
          NotificationService.alert({
            type: NOTIFICATION_TYPES.SUCCESS,
            title: 'Success',
            message: 'Document successfully uploaded!',
            okTitle: 'Ok',
          });
        })
        .catch((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: 'Error uploading document!',
              okTitle: 'Ok',
            },
            response,
          );
        })
        .finally(() => {
          this.$store
            .dispatch(GET_INTERVIEW_PROGRESS)
            .finally(() => (this.finishing = false));
        });
    },

    /* Nav to Next Page in flow */
    saveFinish() {
      const isClient =
        Storage.getLocal(LOCAL_KEYS.AUTH_TYPE) ===
        AUTH_TYPES.UNAUTHENTICATED_CLIENT;

      let route = { name: MENUS.INTERVIEW.UNAUTHENTICATED.id };
      if (isClient) {
        route.params = {
          flag: this.isAllComplete
            ? CLIENT_EXIT_KEYS.EXIT_COMPLETE
            : CLIENT_EXIT_KEYS.EXIT_INCOMPLETE_SAVE,
        };
      } else {
        route = { name: MENUS.CLIENTS.id };
      }

      // Only call finish when all steps are complete
      if (this.isAllComplete) {
        this.$store
          .dispatch(FINISH_INTERVIEW)
          .then(() => {
            this.finishing = true;
            this.goNext(route);
          })
          .catch((response) => {
            NotificationService.alert(
              {
                type: NOTIFICATION_TYPES.ERROR,
                title: 'Error',
                message: 'Error Finishing the interview process.',
                okTitle: 'Ok',
              },
              response,
            );
          })
          .finally(() => {});
      } else {
        this.finishing = true;
        this.goNext(route);
      }
    },

    /* Nav to route selected */
    next(route) {
      this.goNext(route);
    },

    /* Nav to Previous Page in flow */
    prev() {
      const prev =
        this.getPreviousCustomQuestionRoute() ||
        this.lastBeneficiaryRoute ||
        MENUS.CLIENT.BENEFICIAL_INTEREST.id;
      this.goPrev(prev);
    },
  },
};
</script>
<style lang="scss" scoped>
.steps-step-container {
  max-width: 1024px;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.doc-container {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.steps-table {
  width: 100%;
  max-width: 100%;
  border: 2px solid var(--secondary-color);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  .row {
    margin: 0;

    &.section-row {
      border-top: 2px solid var(--secondary-color);
    }

    .edit {
      border: none !important;
      cursor: pointer;
      color: var(--primary-color);
      font-weight: 700;
      opacity: 0.8;

      &:hover {
        color: var(--primary-color);
        opacity: 1;
      }
    }

    .col {
      padding: 20px 26px;
      border-top: none;

      &.all-complete {
        text-align: center;
        font-weight: 700;
      }

      &.data-col {
        border-left: 2px solid var(--secondary-color);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: 960px) {
          border-left: none;
        }

        .val {
          border: none !important;
          font-weight: 500;
        }

        .button-area {
          margin-top: 20px;
          margin-bottom: 5px;

          .btn {
            margin-right: 15px;
          }
        }

        .uploaded {
          font-weight: 500;
          color: var(--primary-color);

          .small {
            font-size: 0.75em;
            font-weight: normal;
            padding-left: 24px;
          }

          svg {
            padding-right: 8px;
          }
        }

        .not-uploaded {
          font-weight: 500;
          color: var(--error-color);

          svg {
            padding-right: 8px;
          }
        }

        .faux-upload {
          display: inline-block;
          padding: 0px;
          max-width: 120px;

          ::v-deep .v-input__slot {
            &:before,
            &:after {
              border-color: transparent !important;
              text-decoration-color: transparent !important;
              background-color: transparent;
            }

            .v-input__append-inner {
              width: 0px;
            }

            .v-text-field__slot {
              display: none;
            }

            .pdf {
              margin-right: 8px;
              color: #fff;
            }
          }
        }
      }

      &.step-col {
        background-color: var(--color-light-grey);
        align-items: center;
        display: flex;
        flex-direction: row;

        @media screen and (max-width: 960px) {
          background-color: transparent;
          font-size: 18px;
          font-weight: 500;
          padding-bottom: 0px;
        }
      }

      @media screen and (max-width: 960px) {
        min-width: 100%;
      }

      .mobile-label {
        display: none;

        @media screen and (max-width: 960px) {
          display: inline-block;
        }
      }
    }
  }
}
</style>
