<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="430"
    :overlay-opacity="0.8"
    :overlay-color="overlayColor"
    content-class="plain-dialog"
  >
    <v-card flat>
      <v-card-text class="pa-7">
        <div class="modal-title">
          <strong>Warning!</strong>You have not entered any beneficiaries.
        </div>
        <div class="modal-actions">
          <v-row class="justify-center">
            <v-col>
              <div class="modal-text">
                If you don’t name anyone it will default to your estate. Are you
                sure you want to continue?
              </div>
              <a @click="save()" v-if="saveDecision !== null" class="btn mt-3">
                Confirm and Continue
              </a>
              <a @click="skip()" class="btn secondary mt-3"> Skip for now </a>
              <a @click="cancel()" class="btn secondary mt-3"> Cancel </a>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { DIALOG_DECISIONS } from '@/common/constants';
import Themable from '@/common/mixins/themable.mixin';

export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  name: 'AllocationDialog',
  mixins: [Themable],

  data: () => ({}),

  methods: {
    /* Emit Save Decision */
    save() {
      return this.$emit('dialog-closed', DIALOG_DECISIONS.SAVE);
    },

    /* Emit Cancel Decision */
    cancel() {
      return this.$emit('dialog-closed', DIALOG_DECISIONS.CANCEL);
    },

    /* Emit Skip Decision */
    skip() {
      return this.$emit('dialog-closed', DIALOG_DECISIONS.SKIP);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-title {
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 28px;
  color: var(--color-text);
  text-align: center;
}
.modal-text {
  font-size: 16px;
  line-height: 1.5em;
  color: var(--color-text);
  padding-bottom: 10px;
}
</style>
