<template>
  <v-layout v-resize="onResize" column style="">
    <div class="change-area" v-if="!expand">
      <a class="faux-anchor" @click="expand = !expand"> Change Selection </a>
    </div>
    <v-expand-transition>
      <v-data-table
        :headers="headers"
        :items="searchResults"
        hide-default-footer
        disable-pagination
        :hide-default-header="isMobile"
        class="responsive-table contact-table"
        :class="{ mobile: isMobile }"
        :loading="tableLoading"
        ref="contactTable"
        v-show="expand"
      >
        <template v-slot:top>
          <v-row class="search-row mb-8 align-end justify-center">
            <v-col class="col-12 col-sm-6">
              <label for="searchName">Contact Name</label>
              <v-text-field
                outlined
                type="text"
                maxlength="256"
                name="searchName"
                v-model.trim="searchName"
                :backgroundColor="inputBackgroundColor"
                hide-details="auto"
              />
            </v-col>
            <v-col class="col-12 col-sm-4">
              <a id="searchOne" @click="search()" class="btn">
                <span>Search CRM</span>
                <span class="fa-icon">
                  <i class="fas fa-search"></i>
                </span>
              </a>
            </v-col>
          </v-row>
        </template>

        <template slot="item" slot-scope="props" v-if="!tableLoading">
          <tr v-if="!isMobile" :key="props.item.id">
            <td width="60">
              <v-checkbox
                hide-details
                :ripple="false"
                v-model="selectedContact"
                :color="primaryColor"
                :value="props.item"
                @change="selectContact(props.item)"
              >
              </v-checkbox>
            </td>
            <td v-for="col in tableColumns" :key="col" :width="col.width">
              <span
                :class="col.classFn(props.item)"
                @click="col.clickFn ? col.clickFn(props.item) : () => {}"
              >
                {{ col.valueFn(props.item) }}
              </span>
            </td>
          </tr>
          <tr v-else :key="props.item.id">
            <td>
              <ul class="flex-content">
                <li class="flex-item">
                  <v-checkbox
                    hide-details
                    :ripple="false"
                    v-model="selectedContact"
                    :color="primaryColor"
                    :value="props.item"
                    @change="selectContact(props.item)"
                  >
                  </v-checkbox>
                </li>
                <li class="flex-item" v-for="col in tableColumns" :key="col">
                  <span class="mobile-label" v-if="col.dataLabel">
                    {{ col.dataLabel }}:
                  </span>
                  <span
                    :class="col.classFn(props.item)"
                    @click="col.clickFn ? col.clickFn(props.item) : () => {}"
                  >
                    {{ col.valueFn(props.item) }}
                  </span>
                </li>
              </ul>
            </td>
          </tr>
        </template>
        <div class="no-results" slot="no-results" :value="true">
          Your search/filter found no results.
        </div>
        <div class="no-data" slot="no-data" :value="true">
          No contacts found.
        </div>
      </v-data-table>
    </v-expand-transition>
  </v-layout>
</template>
<script>
import Themable from '@/common/mixins/themable.mixin';
import { SEARCH_CRM_CONTACTS } from '@/stores/actions.type';
import {
  NOTIFICATION_TYPES,
  NotificationService,
} from '@/services/notification.service';
import { EventService } from '@/services/event.service';
import { formatPhone } from '@/common/constants';

export default {
  name: 'ExistingClientSearch',
  props: ['clientTwo', 'expanded'],
  mixins: [Themable],
  data: () => ({
    searchName: null,
    searchResults: [],
    isMobile: false,
    selectedContact: false,
    expand: true,
  }),
  computed: {
    /* Table Header Definitions */
    headers() {
      const cols = [
        {
          text: 'Select',
          value: 'selected',
          sortable: false,
        },
        {
          text: 'First Name',
          value: 'firstName',
          filter: (value) => {
            if (!this.search || this.search.length === 0) return true;
            return value.match(new RegExp(this.search, 'i'));
          },
        },
        {
          text: 'Last Name',
          value: 'lastName',
          filter: (value) => {
            if (!this.searchRep || this.searchRep.length === 0) return true;
            return value.match(new RegExp(this.searchRep, 'i'));
          },
        },
        {
          text: 'Email',
          value: 'email',
          filter: (value) => {
            if (!this.filterStatus) return true;

            return value === this.filterStatus;
          },
        },
        {
          text: 'Phone',
          value: 'phone',
          filter: (value) => {
            if (!this.filterStatus) return true;

            return value === this.filterStatus;
          },
        },
        {
          text: '',
          sortable: false,
          align: 'end',
        },
      ];

      return cols;
    },

    /* Table Column Definitions */
    tableColumns() {
      const cols = [
        {
          width: '15%',
          dataLabel: 'First Name',
          valueFn: (item) => {
            return item.firstName;
          },
          classFn: () => {
            return 'small';
          },
        },
        {
          width: '20%',
          dataLabel: 'Last Name',
          valueFn: (item) => {
            return item.lastName;
          },
          classFn: () => {
            return 'small';
          },
        },
        {
          width: '20%',
          dataLabel: 'Email',
          valueFn: (item) => {
            return item.email;
          },
          classFn: () => {
            return 'small';
          },
        },
        {
          width: '15%',
          dataLabel: 'Mobile',
          valueFn: (item) => {
            return formatPhone(item.phone);
          },
          classFn: () => {
            return 'small';
          },
        },
        {
          width: '15%',
          valueFn: () => {
            return 'View Contact Info';
          },
          classFn: () => {
            return 'small faux-anchor';
          },
          clickFn: (item) => {
            window.open(item.crmProfileUrl, 'redtailuser');
          },
        },
      ];

      return cols;
    },
  },
  methods: {
    /* Determine Mobile vs Desktop view */
    onResize() {
      this.isMobile = window.innerWidth < 769;
    },

    /* Reset the component */
    reset() {
      this.searchName = null;
      this.searchResults = [];
      this.selectedContact = false;
      this.expand = true;
    },

    /* Search CRM contacts */
    search() {
      this.searchResults = [];
      if (!this.searchName) return;

      const payload = {
        name: this.searchName,
      };
      this.$store
        .dispatch(SEARCH_CRM_CONTACTS, payload)
        .then((response) => {
          this.searchResults = response;
        })
        .catch((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: 'Error search CRM!',
              okTitle: 'Ok',
            },
            response,
          );
        });
    },

    /* Contact has been selected */
    selectContact(item) {
      if (this.selectedContact) {
        NotificationService.confirm({
          type: NOTIFICATION_TYPES.WARNING,
          title: 'Warning!',
          message:
            'Any data collected for this client will override existing CRM data! ',
          okMethod: () => {
            const data = {
              client: this.clientTwo ? 2 : 1,
              contact: this.selectedContact ? item : null,
            };
            if (this.selectContact) this.expand = false;
            EventService.emit('contact-selected', data);
          },
          cancelMethod: () => {
            this.selectedContact = null;
            const data = {
              client: this.clientTwo ? 2 : 1,
              contact: null,
            };
            EventService.emit('contact-selected', data);
          },
        });
      } else {
        const data = {
          client: this.clientTwo ? 2 : 1,
          contact: null,
        };
        EventService.emit('contact-selected', data);
      }
    },
  },

  created() {
    this.expand = this.expanded;
  },
};
</script>
<style lang="scss" scoped>
.change-area {
  text-align: center;
}
.search-row {
  width: 100%;

  ::v-deep .v-input--selection-controls {
    margin-top: 0px;
  }
}

.contact-table ::v-deep .small {
  font-size: 14px;
}

.flex-content {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;

  .flex-item {
    padding: 5px;
    line-height: 24px;
    font-size: 14px;

    .mobile-label {
      font-weight: 500;
      margin-right: 10px;
    }
  }
}
</style>
