/* Application Routes */
import Vue from 'vue';
import Router from 'vue-router';
import AuthRoutes from '@/routes/auth.routes';
import AdvisorRoutes from '@/routes/advisor.routes';
import ClientRoutes from '@/routes/client.routes';
import { MENUS } from '@/common/constants';
import { PermissionService } from '@/services/permission.service';
import store from '@/stores';

Vue.use(Router);

const getHome = () => {
  if (store.getters.isAuthenticated) {
    return {
      name: PermissionService.getHomePage(store.getters.currentUser.roles[0]),
    };
  }

  return { name: MENUS.LOGIN.id };
};

const routes = [
  ...AuthRoutes,
  ...AdvisorRoutes,
  ...ClientRoutes,

  // Handle Login or Home
  {
    path: '/',
    redirect: () => {
      return getHome();
    },
  },
  // Fallback route - if no match, redirect to home TODO: 404
  {
    path: '*',
    redirect: () => {
      return getHome();
    },
  },
];

// VUE navigation routes
export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
