<template>
  <div>
    <v-layout v-resize="onResize" column style="">
      <v-data-table
        :headers="headers"
        :items="assets"
        :items-per-page="5"
        :hide-default-header="isMobile"
        class="responsive-table asset-table"
        :class="{ mobile: isMobile }"
        :loading="tableLoading"
        :page.sync="pagination.page"
        @pagination="updatePage"
      >
        <template v-slot:top>
          <v-row>
            <v-col class="button-area btn-margin">
              <a @click="addDocument()" class="btn">
                Add Document
                <span class="fa-icon"><i class="fas fa-plus-circle"></i></span>
              </a>
            </v-col>
          </v-row>
        </template>
        <template slot="item" slot-scope="props">
          <tr v-if="!isMobile">
            <td v-for="col in tableColumns" :key="col" :width="col.width">
              <div v-if="!col.isActionColumn">
                <span
                  :class="col.classFn(props.item)"
                  @click="col.clickFn ? col.clickFn(props.item) : () => {}"
                >
                  {{ col.valueFn(props.item) }}
                </span>
              </div>
              <div v-else class="action-column">
                <v-menu
                  left
                  nudge-left="15"
                  nudge-bottom="25"
                  v-if="canPerformActions(props.item)"
                  min-width="125"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span class="actions" v-bind="attrs" v-on="on">
                      <font-awesome-icon icon="ellipsis-h"></font-awesome-icon>
                    </span>
                  </template>
                  <v-list class="menu">
                    <div v-for="action in col.actions" :key="action">
                      <v-list-item
                        v-if="!action.isDivider"
                        @click="action.clickFn(props.item)"
                      >
                        <v-list-item-title class="menu-item-wrapper">
                          <v-icon x-small :key="props.item.status">
                            fas fa-file-upload
                          </v-icon>
                          <input
                            class="label"
                            type="file"
                            @change="action.fileChangeFn($event)"
                          />
                        </v-list-item-title>
                      </v-list-item>
                      <div
                        class="divider"
                        v-if="action.isDivider && canShowDivider(props.item)"
                      ></div>
                    </div>
                  </v-list>
                </v-menu>
              </div>
            </td>
          </tr>
          <tr v-else>
            <td>
              <ul class="flex-content">
                <li class="flex-item" v-for="col in tableColumns" :key="col">
                  <div v-if="!col.isActionColumn">
                    <span class="mobile-label" v-if="col.dataLabel">
                      {{ col.dataLabel }}:
                    </span>
                    <span
                      :class="col.classFn(props.item)"
                      @click="col.clickFn ? col.clickFn(props.item) : () => {}"
                    >
                      {{ col.valueFn(props.item) }}
                    </span>
                  </div>
                  <div v-else class="actions-container">
                    <v-bottom-sheet v-if="canPerformActions(props.item)">
                      <template v-slot:activator="{ on, attrs }">
                        <span class="actions" v-bind="attrs" v-on="on">
                          <font-awesome-icon
                            icon="ellipsis-h"
                          ></font-awesome-icon>
                        </span>
                      </template>
                      <v-sheet height="auto">
                        <v-list class="sheet">
                          <div v-for="action in col.actions" :key="action">
                            <v-list-item
                              v-if="!action.isDivider"
                              @click="action.clickFn(props.item)"
                            >
                              <v-list-item-title class="menu-item-wrapper">
                              </v-list-item-title>
                            </v-list-item>
                            <div
                              class="divider"
                              v-if="
                                action.isDivider && canShowDivider(props.item)
                              "
                            ></div>
                          </div>
                          <div class="bottom-spacer"></div>
                        </v-list>
                      </v-sheet>
                    </v-bottom-sheet>
                  </div>
                </li>
              </ul>
            </td>
          </tr>
        </template>
        <div class="no-results" slot="no-results" :value="true">
          Your search/filter found no results.
        </div>
        <div class="no-data" slot="no-data" :value="true">
          No assets found.
          <a @click="addDocument()"> Add a new asset</a>.
        </div>
      </v-data-table>
    </v-layout>
  </div>
</template>

<script>
import Themable from '@/common/mixins/themable.mixin';
import { EventService } from '@/services/event.service';
import EventListener from '@/common/mixins/eventlistener.mixin';
import DataTableMixin from '@/common/mixins/datatable.mixin';
import {
  GET_DOCUSIGN_DOCUMENTS,
  UPSERT_DOCUSIGN_DOCUMENT,
} from '@/stores/actions.type';
import { parseDate } from '@/utils/parseDate';
import {
  NOTIFICATION_TYPES,
  NotificationService,
} from '@/services/notification.service';

export default {
  data: () => ({
    selected: [],
    search: '',
    filterStatus: null,
    isMobile: false,
    assets: [],
    tableLoading: false,
    asset: {
      file: null,
      fileName: '',
    },
  }),

  mixins: [Themable, EventListener, DataTableMixin],

  computed: {
    /* Table Header Definitions */
    headers() {
      return [
        {
          text: 'Document',
          value: 'name',
          filter: (value) => {
            if (!this.search || this.search.length === 0) return true;
            return value.match(new RegExp(this.search, 'i'));
          },
        },
        {
          text: 'Last Upload Date',
          value: 'updatedAt',
        },
        {
          text: 'Actions',
          align: 'end',
        },
      ];
    },

    /* Table Column Definitions */
    tableColumns() {
      const cols = [
        {
          width: '50%',
          valueFn: (item) => {
            return item.assetId.replace(
              /(?<=[a-z])(?=[A-Z])|(?<=[A-Z])(?=[A-Z][a-z])/g,
              ' ',
            );
          },
          clickFn: () => {},
          classFn: () => {
            return 'asset-name';
          },
        },
        {
          width: '30%',
          dataLabel: 'Last Upload Date',
          valueFn: (item) => {
            return parseDate(item.updatedAt);
          },
          classFn: (item) => {
            return parseDate(item.updatedAt);
          },
        },
        {
          width: '20%',
          isActionColumn: true,
          actions: [
            {
              clickFn: (asset) => {
                this.asset.fileName = asset.assetId;
              },
              fileChangeFn: (event) => {
                const file = event.target.files[0];
                if (file) {
                  this.asset.file = file;
                  this.upsertDocument(this.asset);
                }
              },
            },
          ],
        },
      ];
      return cols;
    },
  },

  methods: {
    resetItems() {
      this.tableLoading = true;
      this.$nextTick(() => setTimeout(() => (this.tableLoading = false)), 500);
    },

    canPerformActions() {
      return true;
    },

    /* Can a divider be shown in the action context menu */
    canShowDivider() {
      return true;
    },

    /* Determine Mobile vs Desktop view */
    onResize() {
      this.isMobile = window.innerWidth < 769;
    },

    /* Display the Add Document Modal */
    addDocument() {
      EventService.emit('display-add-document-modal');
    },

    /* Actually Perform the asset update */
    performDocumentUpsert(asset) {
      const action = UPSERT_DOCUSIGN_DOCUMENT;
      const payload = {
        file: asset.file,
        fileName: asset.fileName,
      };

      this.$store
        .dispatch(action, payload)
        .then((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.SUCCESS,
              title: 'Success',
              message: `File uploaded successfully.`,
              okTitle: 'Ok',
              okMethod: () => {
                this.getDocuments();
              },
            },
            response,
          );
        })
        .catch((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: `Error changing asset status.`,
              okTitle: 'Ok',
            },
            response,
          );
        })
        .finally(() => {
          this.getDocuments();
        });
    },

    /* Status change requested for asset, Confirm before processing */
    upsertDocument(asset) {
      NotificationService.confirm({
        type: NOTIFICATION_TYPES.WARNING,
        message: `Are you sure you want to update this file?`,
        okTitle: "Yes I'm sure",
        okMethod: () => {
          this.performDocumentUpsert(asset);
        },
      });
    },

    /* Get the list of assets */
    getDocuments() {
      this.tableLoading = true;

      this.$store
        .dispatch(GET_DOCUSIGN_DOCUMENTS)
        .then((response) => {
          this.assets = response;
        })
        .catch((response) => {
          this.assets = [];
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: 'Error getting list of assets!',
              okTitle: 'Ok',
            },
            response,
          );
        })
        .finally(() => {
          // Regardless of outcome, force re-draw
          this.tableLoading = false;
        });
    },
  },

  /* Lifecycle Hook */
  created() {
    this.pagination.sortBy = 'name';

    // Get the list of assets
    this.getDocuments();

    // Listen for reload events
    this.listen('reload-asset-list', () => {
      this.getDocuments();
    });
  },
};
</script>

<style lang="scss" scoped>
.mobile {
  color: var(--color-text-dark);
}
.prepend {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--secondary-color);
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.action-column {
  text-align: end !important;
}

.actions {
  padding: 2px 4px;
  line-height: 16px;

  &[aria-expanded='true'] {
    background-color: var(--secondary-color);

    ::v-deep .v-icon {
      color: var(--primary-color);
    }
  }
}
.menu {
  background-color: var(--color-white);

  ::v-deep {
    .v-list-item {
      min-height: 24px !important;
      padding: 0;
    }
  }
}
.menu-item-wrapper {
  padding-left: 10px;
  color: var(--color-text) !important;
  font-size: 14px !important;
  font-weight: 700;
  margin-bottom: 0;
  padding: 8px 12px;
  margin: 0 4px;

  ::v-deep {
    .v-icon {
      font-weight: 700;
      color: var(--color-text) !important;
      //margin-right: 8px;
      margin-top: -2px;
    }
  }
  &:hover {
    color: var(--primary-color) !important;
    background-color: var(--input-bg-color);
    text-decoration: underline;
    ::v-deep .v-icon {
      color: var(--primary-color) !important;
    }
  }
  .label {
    padding-left: 8px;
  }
}
.sheet {
  background-color: var(--color-white);

  ::v-deep {
    .v-list-item {
      min-height: 24px !important;
      padding: 0;
    }
  }
  .menu-item-wrapper {
    font-size: 16px !important;
    padding: 12px;
  }
  .bottom-spacer {
    height: 25px;
  }
}
.flex-content {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;

  .actions-container {
    position: absolute;
    top: 0;
    right: 0;
    line-height: normal;
  }

  .edit-client-link {
    color: var(--color-text);
    font-weight: 700;
  }
  .flex-item {
    padding: 5px;
    line-height: 24px;
    font-size: 16px;

    .asset-name {
      padding-right: 30px;
      display: inline-block;
      line-height: normal;
    }

    .mobile-label {
      font-weight: 400;
      margin-right: 10px;
    }
  }
}
.divider {
  height: 1px;
  margin: 8px;
  background-color: var(--secondary-color);
}
.asset-table {
  font-weight: 500;
  color: var(--color-text);

  .Disabled {
    padding: 2px 15px;
    border-radius: 30px;
    line-height: 24px;
    background-color: hsla(0, 0%, 72.7%, 0.16);
    color: var(--label-text);
    font-weight: 500;
  }
  .Active {
    padding: 2px 15px;
    border-radius: 30px;
    line-height: 24px;
    background-color: rgba(30, 59, 112, 0.15);
    color: var(--primary-color);
    font-weight: 500;
  }
}
.btn-margin {
  margin-bottom: 1rem;
}
</style>
