<template>
  <!-- Timeout Overlay -->
  <v-overlay
    id="session-expiring-modal"
    absolute="absolute"
    :color="overlayColor"
    opacity="0.8"
    z-index="10000"
  >
    <div class="idle-overlay">
      <div class="warning pb-4">
        <v-icon :color="errorColor">fas fa-exclamation-triangle</v-icon>
        Warning
      </div>
      <p>You have left this browser idle for {{ idleTime }}.</p>
      <p>If no activity is taken you will be logged out!</p>
      <p class="warning-time pt-4">{{ timeoutSeconds }} seconds left</p>
    </div>
  </v-overlay>
</template>
<script>
import { EventService } from '@/services/event.service';
import Themeable from '@/common/mixins/themable.mixin';
import {
  IDLE_TIMEOUT_SECONDS,
  IDLE_COUNTDOWN_SECONDS,
  formatDurationSeconds,
} from '@/common/constants';

export default {
  name: 'IdleOverlay',

  mixins: [Themeable],

  data: () => ({
    timeoutMilliseconds: IDLE_COUNTDOWN_SECONDS * 1000,
  }),

  computed: {
    timeoutSeconds() {
      return this.timeoutMilliseconds / 1000;
    },

    idleTime() {
      return formatDurationSeconds(IDLE_TIMEOUT_SECONDS);
    },
  },

  methods: {},

  /* Lifecycle Hook - copy payload from listened events */
  created() {
    let timerId = setInterval(() => {
      this.timeoutMilliseconds -= 1000;
      if (!this.isAppIdle) {
        clearInterval(timerId);
      }
      if (this.timeoutMilliseconds < 1) {
        clearInterval(timerId);
        EventService.emit('session-timeout');
      }
    }, 1000);
  },

  beforeDestroy() {},
};
</script>

<style lang="scss" scoped>
.idle-overlay {
  background-color: var(--color-white);
  padding: 32px;
  border-radius: 8px;

  .warning {
    color: var(--error-color);
    text-align: center;
    margin-bottom: 16px;
    font-weight: 500;
    border-bottom: 1px solid var(--secondary-color);
  }
  .warning-time {
    color: var(--error-color);
    text-align: center;
    margin-bottom: 16px;
    font-weight: 500;
  }

  p {
    line-height: 1.4em;
    color: var(--primary-color);
    margin: 0;
  }
}
</style>
