/* MIXIN to manage keeping track of listeners and deregistering them when component is destroyed */
import { EventService } from '@/services/event.service';

const EventListener = {
  data: () => ({
    _eventlisteners: [],
  }),
  methods: {
    listen(event, fn) {
      EventService.register(event, fn);
      this._eventlisteners.push({ event: event, fn: fn });
    },
  },
  beforeCreate() {
    this._eventlisteners = [];
  },
  beforeDestroy() {
    this._eventlisteners.forEach((l) => EventService.deregister(l.event, l.fn));
  },
};

export default EventListener;
