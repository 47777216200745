<template>
  <div class="step-container client">
    <help-drawer :title="helpTitle">
      <div class="small">
        <p>
          FINRA Rule 3210 requires a registered representative to receive prior
          written consent from the member firm with which they are registered
          (the employing member) before opening any account, in which securities
          transactions can be effected and in which the registered person has a
          beneficial interest, at any other member firm (the executing member)
          or other financial institution. The registered representative must
          also notify in writing the executing member, or other financial
          institution, of their association with the employing member. In turn,
          the executing member is required, upon written request by the
          employing member, to transmit duplicate copies of confirmations and
          statements to the registered representative's employer for their
          account(s) including any account they have a beneficial interest in. A
          registered representative is presumed to have a beneficial interest in
          any account that is held by:
        </p>
        <p>
          <ol>
            <li>
              the spouse of the registered representative;
            </li>
            <li>
              a child of the registered representative or of the
              registered representative's spouse; provided that the child resides in
              the same household as or is financially dependent upon the associated
              person;
            </li>
            <li>
              other related individuals over whose account the
              registered representative has control; or
            </li>
            <li>
              other individual over
              whose account the registered representative has control and to whose
              financial support the registered representative materially contributes
              (FINRA Rule 3210.02).
            </li>
          </ol>
        </p>
      </div>
    </help-drawer>
    <v-form name="CompanyOfficial" class="form">
      <v-container class="client-step-container">
        <v-row class="mb-8">
          <v-col class="instructions">
            <h1>
              <span class="headerlight">
                Are <strong>you</strong>, or
                <strong> anyone with beneficial interest </strong> in this
                account, <strong>employed by</strong> or
                <strong>associated with</strong> any of the following?
              </span>
            </h1>
            <div class="help-link">
              <a @click="toggleHelp()">{{ helpTitle }}</a>
            </div>
            <div class="list-container">
              <ul role="list">
                <li class="list-item">Registered Broker-Dealer</li>
                <li class="list-item">FINRA</li>
                <li class="list-item">Stock Exchange</li>
              </ul>
            </div>
          </v-col>
        </v-row>
        <data-submitted-message :submitted="isBeneficialInterestComplete" v-on:hide-form="toggleFormState" />

        <div v-if="!formHidden">
          <v-row class="justify-center">
            <v-col class="step-form-container radio-container mb-4">
              <v-radio-group v-model="beneficialInterest" :error-messages="beneficialInterestErrors" hide-details="auto"
                @change="$v.beneficialInterest.$touch()">
                <v-radio :ripple="false" class="v-faux-radio mb-4" label="Yes" :value="true"></v-radio>
                <div class="expander" v-show="beneficialInterest">
                  <label for="companyName">Company Name</label>
                  <v-text-field outlined type="text" maxlength="256" name="companyName" v-model.trim="companyName"
                    :backgroundColor="inputBackgroundColor" @input="$v.companyName.$touch()"
                    @blur="$v.companyName.$touch()" :error-messages="companyNameErrors" hide-details="auto" />
                  <div class="finra-text">
                    A member of <strong> {{ whiteLabelCompanyName }} </strong>
                    will email you for a letter from your employer or
                    affiliated broker-dealer approving the establishment
                    of your account. This is required when submitting
                    your application.
                  </div>
                  <!-- <v-file-input
                    v-model="letter"
                    prepend-icon=""
                    class="faux-upload"
                    ref="upload"
                  >
                    <template v-slot:prepend-inner>
                      <a
                        id="upload"
                        class="btn mini upload"
                        style="display: block"
                        @click="() => $refs['upload'].$refs.input.click()"
                        v-if="!letter"
                      >
                        Upload Letter
                        <span class="fa-icon">
                          <i class="fas fa-file-upload"></i>
                        </span>
                      </a>
                      <a
                        id="replace"
                        class="btn mini replace"
                        style="display: block"
                        @click="() => $refs['upload'].$refs.input.click()"
                        v-if="letter"
                      >
                        Replace Letter
                        <span class="fa-icon"><i class="fas fa-redo"></i></span>
                      </a>
                    </template>
                    <template v-slot:selection="{ text }">
                      <span
                        class="file-name"
                        @click="
                          (e) => {
                            e.stopImmediatePropagation();
                          }
                        "
                        >{{ text }}</span
                      >
                    </template>
                  </v-file-input>-->
                </div>
                <v-radio :ripple="false" class="v-faux-radio" label="No" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <div class="skip-for-now">
            <a @click="skip()">Skip for now</a>
          </div>
        </div>
      </v-container>
    </v-form>
    <bottom-nav v-on:go-prev="prev()" v-on:go-next="next()"></bottom-nav>
  </div>
</template>

<script>
import HelpDrawer from "@/components/HelpDrawerComponent";
import BottomNav from "@/components/common/BottomNav";
import DataSubmittedMessage from "@/components/client/DataSubmittedMessage";
import InterviewClientMixin from "@/common/mixins/interview.client.mixin";
import { required, requiredIf } from "vuelidate/lib/validators";
import { MENUS } from "@/common/constants";

export default {
  name: "BeneficialInterest",

  components: { BottomNav, HelpDrawer, DataSubmittedMessage },
  mixins: [InterviewClientMixin],

  data: () => ({
    beneficialInterest: null,
    companyName: null,
    letter: null,
  }),

  validations() {
    return {
      beneficialInterest: {
        required,
      },
      companyName: {
        required: requiredIf(() => this.beneficialInterest),
      },
      validationGroup: ["beneficialInterest", "companyName"],
    };
  },

  computed: {
    /* Dynamic errors */
    beneficialInterestErrors() {
      const errors = [];
      if (!this.$v.beneficialInterest.$dirty) return errors;
      !this.$v.beneficialInterest.required &&
        errors.push("A selection is required");
      return errors;
    },
    companyNameErrors() {
      const errors = [];
      if (!this.$v.companyName.$dirty) return errors;
      !this.$v.companyName.required && errors.push("Company Name is required");
      return errors;
    },
    helpTitle() {
      return "What does this mean?";
    },
    whiteLabelCompanyName() {
      return this.clientProgress.companyName
    }
  },

  methods: {
    /* Nav to Next Page in flow */
    next(route = null) {

      // Next is route or
      // If we have a beneficiary screen to complete, the first in that flow
      // Otherwise, the finish page
      const nextRoute = this.firstBeneficiaryRoute || this.getNextCustomQuestionRoute() || MENUS.CLIENT.FINISH.id;
      const to = route || nextRoute;

      if (!this.formHidden) {
        this.$v.$touch();
        if (this.$v.$invalid) return;
      } else {
        this.goNext(to);
        return;
      }

      const data = this.beneficialInterest
        ? {
          companyName: this.companyName,
        }
        : {};

      this.completeStep(MENUS.CLIENT.BENEFICIAL_INTEREST.id, data)
        .then(() => {
          this.goNext(to);
        })
        .catch(() => { });
    },

    /* Nav to Previous Page in flow */
    prev() {
      this.goPrev(MENUS.CLIENT.COMPANY_OFFICIAL.id);
    },

    /* Skip to next Page in flow */
    skip() {
      const nextRoute = this.firstBeneficiaryRoute || this.getNextCustomQuestionRoute() || MENUS.CLIENT.FINISH.id;
      this.goPrev(nextRoute);
    },
  },

  created() {
    // Initialize form data for current interview
    this.formHidden = this.isBeneficialInterestComplete;
  },
};
</script>
<style lang="scss" scoped>
.finra-text {
  color: var(--color-text);
  font-size: 14px;
  padding: 12px 0;
}

.expander {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--secondary-color);

  .faux-upload {
    ::v-deep .v-input__slot {

      &:before,
      &:after {
        border-color: transparent !important;
        text-decoration-color: transparent !important;
        background-color: transparent;
      }

      .v-input__icon--clear {
        margin-top: 7px;
      }
    }

    .file-name {
      font-weight: 500;
      color: var(--color-text);
      font-size: 16px;
      margin-left: 8px;
    }
  }
}

.list-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  ul {
    display: flex;
    align-items: center;
  }

  li {
    margin-top: 5px;
    font-size: 20px;
    font-weight: 500;
    display: list-item;

    &::marker {
      unicode-bidi: isolate;
      font-variant-numeric: tabular-nums;
      text-transform: none;
      text-indent: 0px !important;
      text-align: start !important;
      text-align-last: start !important;
    }
  }
}
</style>
