<template>
  <user-table></user-table>
</template>

<script>
import UserTable from '@/components/advisor/tables/UserTable';

export default {
  name: 'UserList',

  components: {
    UserTable,
  },

  data: () => ({}),

  methods: {},
  created() {},
};
</script>
<style lang="scss" scoped></style>
