import {
  SET_WORKING_TRANSITION,
  ADD_CLIENT,
  EDIT_CLIENT,
  EMPTY_TRANSITIONING_HOUSEHOLD,
  EMPTY_TRANSITION_HOUSEHOLDS,
} from '@/stores/actions.type';
import Themable from '@/common/mixins/themable.mixin';
import LeaveGuard from '@/common/mixins/leaveguard.mixin';
import { validationMixin } from 'vuelidate';
import ClientMixin from '@/common/mixins/client.mixin';
import EventListener from '@/common/mixins/eventlistener.mixin';
import { EventService } from '@/services/event.service';
import { mapGetters } from 'vuex';
import { MENUS, CONTACT_TYPES } from '@/common/constants';
import {
  NOTIFICATION_TYPES,
  NotificationService,
} from '@/services/notification.service';
import { formatPhone } from '../constants';

const AdvisorTransitionMixin = {
  data: () => ({
    ignoreInProgessClient: false,
  }),

  mixins: [validationMixin, EventListener, LeaveGuard, Themable, ClientMixin],

  computed: {
    ...mapGetters(['currentUser', 'currentCompany', 'transitioningHousehold']),
  },

  methods: {
    // Toggle Help Drawer
    toggleHelp() {
      EventService.emit('toggle-help');
    },

    /* Page is dirty as long as we are not ignoring an in progress client and the client has data (is in progress) */
    getAdditionalDirtyIndicator() {
      return (
        !this.ignoreInProgessClient &&
        !this.lodash.isEmpty(this.transitioningHousehold)
      );
    },

    /* Save option shown if we are navigating within the advisor client pages */
    // eslint-disable-next-line no-unused-vars
    showSaveFunction(_) {
      return false;
    },

    /* 
      Go to the next route.
      Saves in memory (updated) working client data
    */
    goNext(route) {
      if (this.formErrors) {
        this.formErrors.$reset();
      }

      this.ignoreInProgessClient = true;
      this.$store
        .dispatch(SET_WORKING_TRANSITION, this.transitioningHousehold)
        .then(() => {
          this.$router.push({ name: route }).catch(() => {});
        });
    },

    /*
      Go to the previous route 
      Abandons any changes
    */
    goPrev(route) {
      if (this.formErrors) {
        this.formErrors.$reset();
      }
      this.ignoreInProgessClient = true;
      this.$router.push({ name: route }).catch(() => {});
    },

    /*
      Called before we exit the page
      If we are leaving the new client process, delete the working client
    */
    beforeExit(route) {
      const showSave = this.showSaveFunction(route);
      if (!showSave) {
        this.$store.dispatch(EMPTY_TRANSITIONING_HOUSEHOLD);
      }
    },

    /*
      Map Data from working client to 
      JSON needed for backend API
    */
    mapTransitionPayload() {
      const payload = {
        id: this.transitioningHousehold.id || null,
        readyToSend: this.areAllStepsComplete,
        primaryContact: {
          firstName: this.transitioningHousehold.primaryContact.firstName,
          lastName: this.transitioningHousehold.primaryContact.lastName,
          email: this.transitioningHousehold.primaryContact.email || null,
          mobile: this.transitioningHousehold.primaryContact.phone
            ? formatPhone(this.transitioningHousehold.primaryContact.phone)
            : null,
        },
      };

      if (this.transitioningHousehold.primaryContact.crmId) {
        payload.primaryContact.crmClientId =
          this.transitioningHousehold.primaryContact.crmId;
      }

      if (
        this.transitioningHousehold.secondaryContact &&
        Object.keys(this.transitioningHousehold.secondaryContact).length > 0
      ) {
        payload.secondaryContact = {
          firstName: this.transitioningHousehold.secondaryContact.firstName,
          lastName: this.transitioningHousehold.secondaryContact.lastName,
          email: this.transitioningHousehold.secondaryContact.email || null,
          mobile: this.transitioningHousehold.secondaryContact.phone
            ? formatPhone(this.transitioningHousehold.secondaryContact.phone)
            : null,
        };

        if (this.transitioningHousehold.secondaryContact.crmId) {
          payload.secondaryContact.crmClientId =
            this.transitioningHousehold.secondaryContact.crmId;
        }
      }

      if (this.transitioningHousehold.firmRoles) {
        payload.primaryAdvisor = this.transitioningHousehold.firmRoles
          .primaryAdvisor
          ? {
              id: this.transitioningHousehold.firmRoles.primaryAdvisor.id,
            }
          : null;
        payload.primaryCSA = this.transitioningHousehold.firmRoles.primaryCSA
          ? {
              id: this.transitioningHousehold.firmRoles.primaryCSA.id,
            }
          : null;

        payload.provideAdvice = [];
        if (this.transitioningHousehold.firmRoles.provideAdvice.length > 0) {
          this.transitioningHousehold.firmRoles.provideAdvice.forEach((p) =>
            payload.provideAdvice.push({ id: p.id }),
          );
        }

        payload.secondaryCSA = [];
        if (this.transitioningHousehold.firmRoles.secondaryCSA.length > 0) {
          this.transitioningHousehold.firmRoles.secondaryCSA.forEach((p) =>
            payload.secondaryCSA.push({ id: p.id }),
          );
        }
      }

      return payload;
    },

    /* BEGING RECONSTITUE CLIENT LOGIC */

    createTransition(contact) {
      const transition = {
        type: contact.crmClientId ? CONTACT_TYPES.EXISTING : CONTACT_TYPES.NEW,
        firstName: contact.firstName,
        lastName: contact.lastName,
        email: contact.email,
        phone: contact.mobile,
        crmClientId: contact.crmClientId,
      };

      return transition;
    },

    createRole(person) {
      return {
        id: person.id,
        name: `${person.firstName} ${person.lastName}`,
      };
    },

    reconstituteFirmRoles(payload) {
      const firmRoles = {
        primaryCSA: payload.primaryCSA
          ? this.createRole(payload.primaryCSA)
          : null,
        primaryAdvisor: payload.primaryAdvisor
          ? {
              ...this.createRole(payload.primaryAdvisor),
              canSend: payload.primaryAdvisor.canSend || true,
            }
          : null,
        provideAdvice: payload.provideAdvice
          ? payload.provideAdvice
              .filter(
                (p) =>
                  !payload.primaryAdvisor || payload.primaryAdvisor.id !== p.id,
              )
              .map((p) => this.createRole(p))
          : [],
        secondaryCSA: payload.secondaryCSA
          ? payload.secondaryCSA.map((p) => this.createRole(p))
          : [],
      };

      return this.lodash.isEmpty(firmRoles) ? null : firmRoles;
    },

    reconstituteTransition(payload) {
      const transitionObj = {
        id: payload.id,
        isExistingOnbord: true,
        primaryContact: this.createTransition(payload.primaryContact),
        addSecond: payload.secondaryContact ? 'Y' : 'N',
        secondaryContact: payload.secondaryContact
          ? this.createTransition(payload.secondaryContact)
          : null,
        type: this.DATA_TYPES.TYPE_ONE,
        firmRoles: this.reconstituteFirmRoles(payload),
        addAccountsSelected: payload.addAccountsSelected,
      };

      if (payload.secondaryContact) {
        transitionObj.secondaryContact.accounts = this.reconstituteAccounts(
          payload,
          true,
        );
      }

      transitionObj.featuresVisited = payload.featuresSelected;

      return transitionObj;
    },

    saveTransition(transition) {
      // FIXME:  switch to proper actions
      const action = transition.id ? EDIT_CLIENT : ADD_CLIENT;

      this.$store
        .dispatch(action, transition)
        .then(() => {
          NotificationService.alert({
            type: NOTIFICATION_TYPES.SUCCESS,
            title: 'Success',
            message: 'Client successfully saved!',
            okTitle: 'Ok',
            okMethod: () => {
              this.ignoreInProgessClient = true;
              if (this.formErrors) {
                this.formErrors.$reset();
              }
              this.$store
                .dispatch(EMPTY_TRANSITIONING_HOUSEHOLD)
                .finally(() => {
                  this.$router.push({ name: MENUS.CLIENTS.id });
                });
            },
          });
        })
        .catch((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: 'Error saving client!',
              okTitle: 'Ok',
            },
            response,
          );
        });
    },

    getSaveMessage() {
      const incompleteClientTwo =
        this.transitioningHousehold.addSecond === 'Y' &&
        !this.isClientTwoNameComplete;
      const incompleteClientOne =
        !this.transitioningHousehold.primaryContact.firstName ||
        !this.transitioningHousehold.secondaryContact.lastName;
      let canSaveMsg =
        incompleteClientOne || incompleteClientTwo
          ? 'Any unsaved changes will be lost'
          : null;
      return canSaveMsg;
    },

    // Toggle Exit Modal
    toggleExitModal() {
      let saveMsg = this.getSaveMessage();
      this.formErrors.$reset();
      EventService.emit('toggle-exit-modal', saveMsg);
    },
  },

  created() {
    /* 
      On navigate from menu
      Leave and close nav drawer
    */
    this.listen('navigate-to', (route) => {
      this.$router.push({ name: route });
    });

    this.listen('cancel-and-exit', () => {
      this.ignoreInProgessClient = true;
      if (this.formErrors) {
        this.formErrors.$reset();
      }
      this.$store.dispatch(EMPTY_TRANSITIONING_HOUSEHOLD);
      this.$store.dispatch(EMPTY_TRANSITION_HOUSEHOLDS);
      this.$router.push({ name: MENUS.CLIENTS.id });
    });

    this.listen('map-transition-data', () => {
      if (this.formErrors) {
        this.formErrors.$touch();

        if (!this.formErrors.$invalid) {
          this.mapData();
        }
      }

      this.toggleExitModal();
    });

    this.listen('save-and-exit', () => {
      const payload = this.mapTransitionPayload();
      payload.sendNow = false;

      this.saveTransition(payload);
    });

    // Prompt leave via manual url change if dirty
    window.addEventListener('beforeunload', function (e) {
      // Cancel the event
      e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
      // Chrome requires returnValue to be set
      e.returnValue = '';
    });
  },
};

export default AdvisorTransitionMixin;
