<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="700"
    scrollable
    overlay-opacity="0.8"
    content-class="plain-dialog instructions-dialog"
  >
    <v-card flat>
      <v-card-text class="pa-7">
        <div class="modal-title">Add Custom Question</div>
        <div class="modal-body">
          <v-row class="mb-2">
            <v-col cols="8">
              <v-text-field
                outlined
                placeholder="Question"
                type="text"
                maxlength="256"
                name="customQuestion"
                aria-required="true"
                v-model.trim="questionText"
                :backgroundColor="inputBackgroundColor"
                @input="$v.questionText.$touch()"
                @blur="$v.questionText.$touch()"
                :error-messages="questionErrors"
                hide-details="auto"
              />
            </v-col>
            <v-col cols="4">
              <v-select
                :items="questionTypes"
                label="Question type"
                v-model="questionType"
                solo
                hide-details
                class="question-type-selector"
              />
            </v-col>
          </v-row>
          <v-row v-if="showText">
            <v-col cols="12">
              <p class="red--text text--darken-3">Please note: insert a single underscore as a delimiter where you wish to indicate a fill in the blank space.</p>
            </v-col>
          </v-row>
          <v-row v-if="showOptions">
            <v-col cols="12">
              <div v-for="(option, index) in options" :key="`option-${index}`">
                <v-text-field
                  :placeholder="`Option ${index + 1}`"
                  aria-required="true"
                  :value="option[index]"
                  v-model="options[index]"
                >
                <template v-slot:append>
                  <v-btn text fab  @click.stop="removeOption(index)">
                    <v-icon>
                      fas fa-times
                    </v-icon>
                  </v-btn>
                  
                </template>
                </v-text-field>
              </div>
            </v-col>
          </v-row>
          <v-btn text v-if="showOptions" class="text-decoration-none grey--text text--darken-1" @click="addOption">
            Add Option
          </v-btn>
          <v-row>
            <v-col class="d-flex align-center" cols="auto">
              <span class="bold">Required</span>
              <v-switch 
                v-model="required" 
                color="blue" 
                class="mx-2"
                >
              </v-switch>
            </v-col>
          </v-row>
          
        </div>
        <div class="modal-actions">
          <a @click="saveQuestion()" class="btn mt-3">Save</a>
          <a @click="cancel()" class="btn secondary mt-3">Cancel</a>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import EventListener from '@/common/mixins/eventlistener.mixin';
import { ADD_CUSTOM_QUESTION } from '@/stores/actions.type';
import {
  NOTIFICATION_TYPES,
  NotificationService,
} from '@/services/notification.service';
import { EventService } from '../../../services/event.service';


export default {
  mixins: [validationMixin, EventListener],

  data() {
    return {
      dialog: false,
      questionText: '',
      inputBackgroundColor: '#FFF',
      questionType: null,
      questionTypes: [
        { text: 'Multiple choice question', value: 'multipleChoice' },
        { text: 'Single choice question', value: 'singleChoice' },
        { text: 'Fill in the blank question', value: 'fillInBlank' },
        { text: 'Date question', value: 'date' },
        { text: 'Text question', value: 'text' }
      ],
      optionPlaceholder: 'Option 1',
      options: [''],
      optionIcon: null,
      required: false,
    };
  },

  validations: {
    questionText: {
      required,
    },
  },
  watch: {
    questionType(oldIcon, newIcon) {

      this.updateIcon(oldIcon, newIcon)
    }
  },
  computed: {
    questionErrors() {
      const errors = [];
      if (!this.$v.questionText.$dirty) return errors;
      if (!this.$v.questionText.required) errors.push('Question is required');
      return errors;
    },
    showOptions() {
      return this.questionType === 'multipleChoice' || this.questionType === 'singleChoice'
    },
    showText() {
      return this.questionType === 'fillInBlank'
    },
    saveOptions() {
      return this.showOptions && this.options[0] !== this.optionPlaceholder
    }
  
  },

  methods: {
    init() {
      this.$v.$reset();
      this.questionText = '';
      this.questionType = null;
      this.options = [''];
      this.required = false;
      this.optionIcon = null;
    },

    saveQuestion() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.addQuestion();

      // Logic to save the custom question...
      // Once saved successfully, reset form and close modal.
      this.init();
      this.dialog = false;
    },
    addQuestion() {
      this.$store
        .dispatch(ADD_CUSTOM_QUESTION, {
          question: this.questionText,
          type: this.questionType,
          ...(this.saveOptions && { options: this.options }),
          required: this.required,
        })
        .then(() => {
          this.init();
          this.dialog = false;
        })
        .catch((error) => {
          let errorMessage = 'Error adding question';
          if (error.response.data.statusCode === 400) {
            errorMessage = error.response.data.exceptionMessage;
          }
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: errorMessage,
              okTitle: 'Ok',
            },
            error,
          );
        })
        .finally(() => {
          EventService.emit('reload-questions-table');
        });
    
    },
    cancel() {
      this.dialog = false;
    },
    updateIcon() {
      switch (this.questionType) {
        case 'multipleChoice':
          this.optionIcon = 'far fa-square';
          break;
        case 'singleChoice':
          this.optionIcon = 'far fa-circle';
          break
        default:
          this.optionIcon = null;
          break;
      }
    },
    addOption() {
      this.options.push('')
    },
    removeOption(index) {
      this.options.splice(index, 1);
    },
  },

  created() {
    this.listen('display-add-custom-question-modal', () => {
      this.dialog = true;
      this.init();
    });
  },
};
</script>

<style lang="scss" scoped>
.modal-title {
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 28px;
  color: var(--color-text);
}

.cb-label {
  margin-top: 5px;
}

.expander {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--secondary-color);

  &.empty {
    border-bottom: none;
  }
}

.mx-2 {
  margin-left: 8px; /* Adjust the value to increase/decrease spacing */
  margin-right: 8px;
}

@media screen and (max-width: 960px) {
  .row.mobile {
    flex-direction: column;
  }
}
</style>
