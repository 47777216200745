/* 
  Permission Service
  Check for Permission Access to Functionality
*/
import Storage from '@/services/storage.service';
import store from '@/stores';
import {
  MENUS,
  USER_ROLES,
  LOCAL_KEYS,
  AUTH_TYPES,
  CLIENT_EXIT_KEYS,
  getClientPage,
  getPageNotFilled,
  interviewStarted,
} from '@/common/constants';
import {
  PING,
  LOGIN,
  INTERVIEW,
  ActionApiRouteMapper,
} from '@/stores/actions.type';
import { isEmpty } from 'lodash';

// PERMISSIONS
export const PERMISSIONS = {
  COMPANY: {
    ADD_COMPANY: 'onbord.addCompany',
    LIST_COMPANIES: 'onbord.listCompanies',
    MANAGE_COMPANY_STATUS: 'onbord.manageCompanyStatus',
    MANAGE_COMPANY_PROFILE: 'onbord.organization.config',
    MANAGE_PRODUCT: 'onbord.config',
    MONITOR_ACTIVITY: 'onbord.monitorActivity',
  },
  USER: {
    ADD_PRODUCT_ADMIN: 'onbord.addProductAdmin',
    LIST_USERS: 'onbord.organization.listUsers',
    ADD_USER: 'onbord.organization.addUser',
    MANAGE_USER_PROFILE: 'onbord.organization.manageUserProfile',
    DISABLE_USER: 'onbord.organization.disableUser',
    MANAGE_OWN_PROFILE: 'onbord.organization.updateSelf',
  },
  CLIENT: {
    ADD_CLIENT: 'onbord.organization.addClient',
    LIST_ANY_CLIENT: 'onbord.organization.listAnyClients',
    EDIT_ANY_CLIENT: 'onbord.organization.modifyAnyClients',
    DELETE_ANY_CLIENT: 'onbord.organization.deleteAnyClients',
    LIST_OWN_CLIENT: 'onbord.organization.listOwnClients',
    EDIT_OWN_CLIENT: 'onbord.organization.modifyOwnClients',
    DELETE_OWN_CLIENT: 'onbord.organization.deleteOwnClients',
  },
};

const ADVISOR_CLIENT_HOME = MENUS.ADVISOR.CLIENT.DATA_SELECTION.id;

export const PermissionService = {
  /* Allow 401 response for these apis - do not issue timeout */
  isAuthenticatedApi(apiUrl) {
    const [loginSlug, pingSlug, interviewSlug] = [LOGIN, PING, INTERVIEW].map(
      (key) => ActionApiRouteMapper[key],
    );

    if (
      apiUrl &&
      (apiUrl.includes(loginSlug) ||
        apiUrl.includes(pingSlug) ||
        apiUrl.includes(interviewSlug))
    )
      return false;
    return true;
  },

  /* Does the route require an authenticated user */
  isAuthenticatedRoute(route) {
    switch (route.name) {
      // These do not
      case MENUS.LOGIN.id:
      case MENUS.TRANSITIONS.EMAIL_REGISTER.id:
      case MENUS.TRANSITIONS.STATEMENT_UPLOAD.id:
      case MENUS.TRANSITIONS.STATEMENT_SUBMITTED.id:
      case MENUS.RESET_PASSWORD.id:
      case MENUS.FORGOT_PASSWORD.id:
      case MENUS.VERIFY_CODE.id:
      case MENUS.INTERVIEW.AUTHENTICATE.id:
      case MENUS.INTERVIEW.UNAUTHENTICATED.id:
      case MENUS.INTERVIEW.SEND_EMAIL_LINK.id:
      case MENUS.SET_NEW_PASSWORD.id:
        return false;
      default:
        // All others do
        return true;
    }
  },

  /* Is Current User Allowed Permission */
  isAllowed(permission, currentUser) {
    return (
      currentUser.permissions &&
      currentUser.permissions.find((p) => {
        return p.id === permission;
      }) !== undefined
    );
  },

  /* Does the current user have the requested permission */
  hasPermission(permission) {
    return PermissionService.isAllowed(permission, store.getters.currentUser);
  },

  /* Can the working client add account selections */
  canAddAccounts() {
    return store.getters.currentClient.addAccountsSelected;
  },

  /* Is there a second client designate to be added */
  addedSecondClient() {
    return store.getters.currentClient.addSecond === 'Y';
  },

  /* Get the Home (landing) page for an unauthenticated client */
  getClientHomePage() {
    const citizenPage = getClientPage(
      store.getters.clientProgress,
      MENUS.CLIENT.CITIZENSHIP.id,
    );

    if (!citizenPage && store.getters.clientProgress) {
      const pageNotFilled = getPageNotFilled(store.getters.clientProgress);
      return {
        route: !interviewStarted(store.getters.clientProgress)
          ? pageNotFilled.pageName
          : MENUS.CLIENT.FINISH.id,
        data: pageNotFilled.data,
      };
    }

    const pageComplete = citizenPage && citizenPage.lastCompleted !== null;
    return {
      route: pageComplete
        ? MENUS.CLIENT.FINISH.id
        : MENUS.CLIENT.CITIZENSHIP.id,
      data: undefined,
    };
  },

  /* Get the Home (landing) page for an authenticated user */
  getHomePage(role) {
    if (!role) return null;

    const page =
      {
        [USER_ROLES.PRODUCT_ADMIN.id]: MENUS.COMPANIES.id,
        [USER_ROLES.COMPANY_ADMIN.id]: MENUS.USERS.id,
        [USER_ROLES.REP.id]: MENUS.CLIENTS.id,
      }[role.id] || MENUS.LOGIN.id;

    return page;
  },

  /* 
    Is the user properly authenticated for the route 
    Assumes the route requires authentication.
  */
  isAuthenticatedForRoute(route) {
    /* Client Interview routes must be client (token) authenticated */
    if (route.path.includes('client-interview'))
      return store.getters.isClientAuthenticated;

    // All other routes must be user authenticated */
    return store.getters.isAuthenticated;
  },

  /* Should the user be allowed to complete the company profile */
  shouldCompleteCompanyProfile(currentUserData) {
    const hasPermission = this.isAllowed(
      PERMISSIONS.COMPANY.MANAGE_COMPANY_PROFILE,
      currentUserData,
    );
    const isSetupComplete =
      store.getters.currentCompany &&
      store.getters.currentCompany.setupComplete;
    const isProductAdmin =
      currentUserData.roles[0].id === USER_ROLES.PRODUCT_ADMIN.id;

    return [hasPermission, !isSetupComplete, !isProductAdmin].every(
      (pred) => pred,
    );
  },

  /*
    Redirect routes when access guard fails
  */
  getFallbackRoute(route, currentUserData) {
    if (isEmpty(currentUserData) || !route || !currentUserData.roles)
      return null;

    const { user, organisation, roles } = currentUserData;
    const { isAuthenticated, clientProgress } = store.getters;
    const { name: routeName, path: routePath, params: routeParams } = route;

    const homePage = this.getHomePage(roles[0]);
    const clientHomePage = this.getClientHomePage();

    const isSuperAdmin = roles[0].id === USER_ROLES.PRODUCT_ADMIN.id;
    const isUserProfileRoute = routeName === MENUS.USER_PROFILE.id;
    const isCompanyProfileRoute = routeName === MENUS.COMPANY_PROFILE.id;

    const shouldCompleteUserProfile = [
      isAuthenticated,
      !user.setupComplete,
      !isUserProfileRoute,
      !isSuperAdmin,
    ].every((pred) => pred);

    const shouldCompleteCompanyProfile = [
      isAuthenticated,
      this.shouldCompleteCompanyProfile(currentUserData),
      !isCompanyProfileRoute,
      !isSuperAdmin,
    ].every((pred) => pred);

    if (shouldCompleteCompanyProfile) {
      const companyProfileParams = {
        name: MENUS.COMPANY_PROFILE.id,
        params: { id: organisation.id },
      };

      return route.query.code
        ? { ...companyProfileParams, query: { code: route.query.code } }
        : companyProfileParams;
    }

    if (
      shouldCompleteUserProfile &&
      !this.shouldCompleteCompanyProfile(currentUserData)
    ) {
      return { name: MENUS.USER_PROFILE.id, params: { id: user.id } };
    }

    if (routePath.includes('/advisor/client')) {
      if (!this.isAllowed(PERMISSIONS.CLIENT.ADD_CLIENT, currentUserData)) {
        return homePage;
      }
    }

    if (
      routePath.includes('client-interview') &&
      !routePath.includes('citizenship')
    ) {
      const citizenPage = getClientPage(
        clientProgress,
        MENUS.CLIENT.CITIZENSHIP.id,
      );
      if (!(citizenPage && citizenPage.lastCompleted !== null))
        return MENUS.CLIENT.CITIZENSHIP.id;
    }

    const checkPermission = (permission, redirectRoute, negate = false) => {
      if (negate) {
        return !this.isAllowed(permission, currentUserData)
          ? null
          : redirectRoute;
      }

      return this.isAllowed(permission, currentUserData) ? null : redirectRoute;
    };

    const checkCompanyProfile = (id) =>
      !id || id === user.id
        ? PERMISSIONS.USER.MANAGE_OWN_PROFILE
        : PERMISSIONS.USER.MANAGE_USER_PROFILE;

    const getClientPageRoute = (menuId, routeParams = {}) =>
      getClientPage(clientProgress, menuId, routeParams) === undefined
        ? clientHomePage
        : null;

    const fallbackRoute =
      {
        [MENUS.COMPANIES.id]: checkPermission(
          PERMISSIONS.COMPANY.LIST_COMPANIES,
          homePage,
        ),
        [MENUS.MONITOR.id]: checkPermission(
          PERMISSIONS.COMPANY.MONITOR_ACTIVITY,
          homePage,
        ),
        [MENUS.DOCUMENTS.id]: checkPermission(
          PERMISSIONS.COMPANY.LIST_COMPANIES,
          homePage,
        ),
        [MENUS.COMPANY_PROFILE.id]: checkPermission(
          PERMISSIONS.COMPANY.MANAGE_COMPANY_PROFILE,
          homePage,
        ),
        [MENUS.USERS.id]: checkPermission(
          PERMISSIONS.USER.LIST_USERS,
          homePage,
        ),
        [MENUS.ADD_USERS.id]: checkPermission(
          PERMISSIONS.USER.ADD_USER,
          homePage,
        ),
        [MENUS.ADD_CLIENT_BATCH.id]: checkPermission(
          PERMISSIONS.CLIENT.ADD_CLIENT,
          homePage,
        ),
        [MENUS.USER_PROFILE.id]: checkPermission(
          checkCompanyProfile(routeParams.id),
          homePage,
        ),
        [MENUS.CLIENTS.id]: (() => {
          const canListOwnClients =
            checkPermission(PERMISSIONS.CLIENT.LIST_OWN_CLIENT, homePage) ===
            null;
          const canListAnyClients =
            checkPermission(PERMISSIONS.CLIENT.LIST_ANY_CLIENT, homePage) ===
            null;
          const canListClients = canListOwnClients || canListAnyClients;

          return canListClients ? null : homePage;
        })(),
        [MENUS.ADVISOR.CLIENT.ACCOUNT_SELECTION.id]: this.canAddAccounts()
          ? null
          : ADVISOR_CLIENT_HOME,
        [MENUS.ADVISOR.CLIENT.ACCOUNT_FEATURES.id]: this.canAddAccounts()
          ? null
          : ADVISOR_CLIENT_HOME,
        [MENUS.ADVISOR.CLIENT.ADVISORY_RATE.id]: this.canAddAccounts()
          ? null
          : ADVISOR_CLIENT_HOME,
        [MENUS.ADVISOR.CLIENT.CLIENT_TWO_NAME.id]: this.addedSecondClient()
          ? null
          : ADVISOR_CLIENT_HOME,
        [MENUS.ADVISOR.CLIENT.CLIENT_TWO_PHONE.id]: this.addedSecondClient()
          ? null
          : ADVISOR_CLIENT_HOME,
        [MENUS.ADVISOR.CLIENT.CLIENT_TWO_EMAIL.id]: this.addedSecondClient()
          ? null
          : ADVISOR_CLIENT_HOME,
        [MENUS.CLIENT.COMPANY_ADDRESS.id]: getClientPageRoute(
          MENUS.CLIENT.COMPANY_ADDRESS.id,
        ),
        [MENUS.CLIENT.JOB_DESCRIPTION.id]: getClientPageRoute(
          MENUS.CLIENT.JOB_DESCRIPTION.id,
        ),
        [MENUS.CLIENT.ACCOUNT_CONTINGENT_BENEFICIARIES.id]: getClientPageRoute(
          MENUS.CLIENT.ACCOUNT_CONTINGENT_BENEFICIARIES.id,
          routeParams,
        ),
        [MENUS.CLIENT.ACCOUNT_PRIMARY_BENEFICIARIES.id]: getClientPageRoute(
          MENUS.CLIENT.ACCOUNT_PRIMARY_BENEFICIARIES.id,
          routeParams,
        ),
      }[routeName] || null;

    return fallbackRoute;
  },

  /* Guard Access to routes */
  guardAccess(route) {
    const getRejectRoute = (isClient, store, currentUser) => {
      const { isAuthenticated } = store.getters || null;

      let rejectRoute = { replace: true, name: MENUS.LOGIN.id };
      if (isClient) {
        rejectRoute = {
          replace: true,
          name: MENUS.INTERVIEW.UNAUTHENTICATED.id,
          params: { flag: CLIENT_EXIT_KEYS.EXIT_NOT_AUTHORIZED },
        };
      } else if (isAuthenticated) {
        rejectRoute = { replace: true, name: this.getHomePage(currentUser) };
      }
      return rejectRoute;
    };

    const getMergedRoute = (newRoute) => {
      const replace = { replace: true };
      return typeof newRoute === 'object'
        ? { ...replace, ...newRoute }
        : { ...replace, ...{ name: newRoute } };
    };

    return new Promise((resolve, reject) => {
      const isClient =
        Storage.getLocal(LOCAL_KEYS.AUTH_TYPE) ===
        AUTH_TYPES.UNAUTHENTICATED_CLIENT;

      const { currentUser } = store.getters || {};

      if (!this.isAuthenticatedRoute(route)) {
        return resolve();
      }

      store.dispatch(PING, true).then(() => {
        if (!this.isAuthenticatedForRoute(route)) {
          reject(getRejectRoute(isClient, store, currentUser));
        } else {
          const newRoute = isClient
            ? null
            : this.getFallbackRoute(route, currentUser);

          if (!newRoute) {
            resolve();
          } else {
            reject(getMergedRoute(newRoute));
          }
        }
      });
    });
  },
};
