<template>
  <div class="step-container advisor" ref="scrollContainer">
    <add-client-instructions></add-client-instructions>
    <help-drawer :title="helpTitle">
      <div>
        <p>
          If the client will be adding a spouse to their household, please enter
          their information here.
        </p>
      </div>
    </help-drawer>
    <v-form name="ClientSelections" class="form">
      <v-container class="clients-step-container">
        <v-row>
          <v-col class="instructions">
            <h1>
              <span class="headerlight"> Client Selections </span>
            </h1>
          </v-col>
        </v-row>
      </v-container>

      <v-container class="clients-step-container" v-if="!isExistingOnbord">
        <!-- Client One Type -->
        <v-row class="">
          <v-col>
            <v-checkbox
              hide-details
              v-model="clientOneType"
              multiple="false"
              :ripple="false"
              :color="primaryColor"
              class="v-faux-checkbox"
              :value="CONTACT_TYPES.NEW"
              @change="clientSelectionChanged()"
            >
              <template v-slot:label>
                <span class="cb-label"> New Contact </span>
              </template>
            </v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox
              hide-details
              v-model="clientOneType"
              multiple="false"
              :ripple="false"
              :color="primaryColor"
              class="v-faux-checkbox"
              :value="CONTACT_TYPES.EXISTING"
              @change="clientSelectionChanged()"
            >
              <template v-slot:label>
                <span class="cb-label"> Existing Contact </span>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>

        <!-- Error Area -->
        <v-row
          v-if="clientOneTypeErrors"
          class="step-form-container mobile justify-center align-center"
        >
          <v-col class="error-area"> {{ clientOneTypeErrors }} </v-col>
        </v-row>

        <!-- Existing Client Search -->
        <div
          class="row full pa-3 mt-3"
          v-show="clientOneType === CONTACT_TYPES.EXISTING"
        >
          <existing-client-search
            ref="existing-search-1"
            :expanded="existingContactOne === null"
          ></existing-client-search>

          <existing-client-selection
            v-if="existingContactOne !== null"
            :selection="existingContactOne"
          ></existing-client-selection>

          <v-row
            v-if="existingClientOneErrors"
            class="full step-form-container mobile justify-center align-center"
          >
            <v-col class="error-area"> {{ existingClientOneErrors }} </v-col>
          </v-row>
        </div>

        <!-- Error Area -->
        <v-row class="mt-8" v-show="canShowSecondClient">
          <v-col class="instructions">
            <h3>
              <span class="headerlight">
                Would you like to <strong>add a 2nd client?</strong>
              </span>
            </h3>
            <div class="help-link">
              <a @click="toggleHelp()"> {{ helpTitle }} </a>
            </div>
          </v-col>
        </v-row>

        <!-- Add Second Client -->
        <v-row class="mt-3" v-show="canShowSecondClient">
          <v-col class="col-12 col-sm-6">
            <v-checkbox
              hide-details
              v-model="addSecond"
              multiple="false"
              :ripple="false"
              :color="primaryColor"
              class="v-faux-checkbox"
              value="Y"
              @change="secondClientSelectionChanged()"
            >
              <template v-slot:label>
                <span class="cb-label"> Yes </span>
              </template>
            </v-checkbox>
          </v-col>
          <v-col class="col-12 col-sm-6">
            <v-checkbox
              hide-details
              v-model="addSecond"
              multiple="false"
              :ripple="false"
              :color="primaryColor"
              class="v-faux-checkbox"
              value="N"
              @change="secondClientSelectionChanged()"
            >
              <template v-slot:label>
                <span class="cb-label"> No </span>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>

        <!-- Error Area -->
        <v-row
          v-if="addSecondErrors"
          class="step-form-container mobile justify-center align-center"
        >
          <v-col class="error-area"> {{ addSecondErrors }} </v-col>
        </v-row>

        <!-- Client Two Type -->
        <v-row class="mt-4" v-show="addSecond === 'Y'">
          <v-col class="col-12 col-sm-6">
            <v-checkbox
              hide-details
              v-model="clientTwoType"
              multiple="false"
              :ripple="false"
              :color="primaryColor"
              class="v-faux-checkbox"
              :value="CONTACT_TYPES.NEW"
              @change="clientSelectionChanged(true)"
            >
              <template v-slot:label>
                <span class="cb-label"> New Contact </span>
              </template>
            </v-checkbox>
          </v-col>
          <v-col class="col-12 col-sm-6">
            <v-checkbox
              hide-details
              v-model="clientTwoType"
              multiple="false"
              :ripple="false"
              :color="primaryColor"
              class="v-faux-checkbox"
              :value="CONTACT_TYPES.EXISTING"
              @change="clientSelectionChanged(true)"
            >
              <template v-slot:label>
                <span class="cb-label"> Existing Contact </span>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>

        <!-- Error Area -->
        <v-row
          v-if="clientTwoTypeErrors"
          class="step-form-container mobile justify-center align-center"
        >
          <v-col class="error-area"> {{ clientTwoTypeErrors }} </v-col>
        </v-row>

        <!-- Existing Client Search -->
        <div
          class="row full pa-3 mt-3"
          v-show="clientTwoType === CONTACT_TYPES.EXISTING"
        >
          <existing-client-search
            :clientTwo="true"
            :expanded="existingContactTwo === null"
            ref="existing-search-2"
          ></existing-client-search>

          <existing-client-selection
            :clientTwo="true"
            v-if="existingContactTwo !== null"
            :selection="existingContactTwo"
          ></existing-client-selection>

          <!-- Error Area -->
          <v-row
            v-if="existingClientTwoErrors"
            class="full step-form-container mobile justify-center align-center"
          >
            <v-col class="error-area"> {{ existingClientTwoErrors }} </v-col>
          </v-row>
        </div>
      </v-container>
      <v-container class="clients-step-container" v-else>
        <v-row>
          <v-col>
            <h2>
              <span class="headerlight small">
                Existing OnBord Client Selections cannot be modified once saved.
              </span>
            </h2>
          </v-col>
        </v-row>
        <v-row class="full">
          <existing-client-selection
            v-if="existingContactOne !== null"
            :selection="existingContactOne"
          ></existing-client-selection>
          <existing-client-selection
            :clientTwo="true"
            v-if="existingContactTwo !== null"
            :selection="existingContactTwo"
          ></existing-client-selection>
        </v-row>
      </v-container>
    </v-form>
    <bottom-nav :hasPrevious="false" v-on:go-next="next()"></bottom-nav>
  </div>
</template>
<script>
import AddClientInstructions from '@/components/advisor/dialog/AddClientInstructions';
import HelpDrawer from '@/components/HelpDrawerComponent';
import BottomNav from '@/components/common/BottomNav';
import ExistingClientSearch from '@/components/advisor/ExistingClientSearch';
import ExistingClientSelection from '@/components/advisor/ExistingClientSelection';
import AdvisorClientMixin from '@/common/mixins/advisor.client.mixin';
import { MENUS, CONTACT_TYPES, DATA_TYPES } from '@/common/constants';
//import { SET_WORKING_CLIENT } from "@/stores/actions.type";
import { required, requiredIf } from 'vuelidate/lib/validators';
import { EventService } from '@/services/event.service';

const differentContacts = (value, vm) => {
  if (!vm.existingContactOne || !vm.existingContactTwo) return true;
  return vm.existingContactOne.crmId !== vm.existingContactTwo.crmId;
};

export default {
  name: 'ClientSelection',

  mixins: [AdvisorClientMixin],
  components: {
    AddClientInstructions,
    BottomNav,
    HelpDrawer,
    ExistingClientSearch,
    ExistingClientSelection,
  },

  data: () => ({
    clientOneType: null,
    clientTwoType: null,
    addSecond: null,
    existingContactOne: null,
    preserveInterviewOne: false,
    existingContactTwo: null,
    preserveInterviewTwo: false,
    selectedSecondContact: null,
  }),

  validations() {
    return {
      clientOneType: {
        required,
      },
      addSecond: {
        required,
      },
      clientTwoType: {
        required: requiredIf(() => this.addSecond === 'Y'),
      },
      existingContactOne: {
        required: requiredIf(
          () => this.clientOneType === CONTACT_TYPES.EXISTING,
        ),
      },
      existingContactTwo: {
        required: requiredIf(
          () => this.clientTwoType === CONTACT_TYPES.EXISTING,
        ),
        differentContacts,
      },
      validationGroup: ['clientOneType', 'addSecond', 'clientTwoType'],
    };
  },

  computed: {
    /* Dynamic errors */
    clientOneTypeErrors() {
      const errors = [];
      if (!this.$v.clientOneType.$dirty) return null;
      !this.$v.clientOneType.required && errors.push('A selection is required');
      return errors.length > 0 ? errors[0] : null;
    },
    existingClientOneErrors() {
      const errors = [];
      if (!this.$v.existingContactOne.$dirty) return null;
      !this.$v.existingContactOne.required &&
        errors.push('An existing contact is required to be selected');
      return errors.length > 0 ? errors[0] : null;
    },
    addSecondErrors() {
      const errors = [];
      if (!this.$v.addSecond.$dirty) return null;
      !this.$v.addSecond.required && errors.push('A selection is required');
      return errors.length > 0 ? errors[0] : null;
    },
    clientTwoTypeErrors() {
      const errors = [];
      if (!this.$v.clientTwoType.$dirty) return null;
      !this.$v.clientTwoType.required && errors.push('A selection is required');
      return errors.length > 0 ? errors[0] : null;
    },
    existingClientTwoErrors() {
      const errors = [];
      if (!this.$v.existingContactTwo.$dirty) return null;
      !this.$v.existingContactTwo.required &&
        errors.push('An existing contact is required to be selected');

      !this.$v.existingContactTwo.differentContacts &&
        errors.push('This client cannot be the same as the first client');
      return errors.length > 0 ? errors[0] : null;
    },
    helpTitle() {
      return 'Who can I add?';
    },
    contactOneExistsInOnbord() {
      return (
        this.existingContactOne && this.existingContactOne.canSkipInterview
      );
    },
    contactTwoExistsInOnbord() {
      return (
        this.existingContactTwo && this.existingContactTwo.canSkipInterview
      );
    },
    canShowSecondClient() {
      return (
        this.addSecond ||
        this.clientOneType === CONTACT_TYPES.NEW ||
        (this.clientOneType === CONTACT_TYPES.EXISTING &&
          this.existingContactOne !== null)
      );
    },
  },

  methods: {
    secondClientSelectionChanged() {
      this.$v.addSecond.$touch();
      if (this.addSecond === 'N') this.clientTwoType = null;

      EventService.emit('add-second-client-changed', this.addSecond);
    },

    clientSelectionChanged(second = false) {
      if (second) {
        this.$v.clientTwoType.$touch();
        if (this.clientTwoType === CONTACT_TYPES.NEW) {
          this.existingContactTwo = null;
          this.$refs['existing-search-2'].reset();

          /* Reset client 2 info */
          if (this.clientTwo) {
            this.clientTwo.crmId = null;
            this.clientTwo.canSkipInterview = false;
            this.clientTwo.preserveInterview = false;
            this.clientTwo.firstName = null;
            this.clientTwo.lastName = null;
            this.clientTwo.email = null;
            this.clientTwo.phone = null;
          }
        }
      } else {
        this.$v.clientOneType.$touch();
        const origSecondPhone = this.selectedSecondContact
          ? this.selectedSecondContact.phone
          : null;

        if (this.clientOneType === CONTACT_TYPES.NEW) {
          this.existingContactOne = null;
          this.$refs['existing-search-1'].reset();

          /* Reset client 1 info */
          if (this.clientOne) {
            this.clientOne.crmId = null;
            this.clientOne.canSkipInterview = false;
            this.clientOne.preserveInterview = false;
            this.clientOne.firstName = null;
            this.clientOne.lastName = null;
            this.clientOne.email = null;
            this.clientOne.phone = null;
          }

          if (
            this.existingContactTwo &&
            this.selectedSecondContact &&
            this.existingContactTwo.phone === null
          ) {
            this.existingContactTwo.phone = origSecondPhone;
            this.existingContactTwo.dupPhone = false;
          }
        }
      }
    },

    mapSecondClientData() {
      this.currentClient.addSecond = this.addSecond;

      if (!this.addedSecondClient) {
        // If Client One was part of a Joint Name Brokerage
        // Remove that account from their list
        if (!this.lodash.isEmpty(this.currentClient.client1.accounts)) {
          this.currentClient.client1.accounts.jointNameBrokerage.selected = false;
          this.currentClient.client1.accounts.jointNameBrokerage.type = null;
        }

        if (!this.lodash.isEmpty(this.currentClient.client2)) {
          // Force user to revisit the Features and Advisory Rate pages again
          // THIS LOGIC IS LIKELY TO CHANGE
          if (!this.hasAccountsSelected(this.currentClient.client1)) {
            this.currentClient.rateType = null;
          }
        }
        this.currentClient.client2 = null;
      } else {
        this.clientTwo.type = this.clientTwoType;

        if (
          this.clientTwoType === CONTACT_TYPES.EXISTING &&
          this.existingContactTwo !== null
        ) {
          /* Map existing client data */
          this.clientTwo.crmId = this.existingContactTwo.crmId;
          this.clientTwo.canSkipInterview =
            this.existingContactTwo.canSkipInterview;
          this.clientTwo.preserveInterview = this.preserveInterviewTwo;
          this.clientTwo.firstName = this.existingContactTwo.firstName;
          this.clientTwo.lastName = this.existingContactTwo.lastName;
          this.clientTwo.email = this.existingContactTwo.email;
          this.clientTwo.phone = this.existingContactTwo.phone;
        }

        if (this.clientTwoType === CONTACT_TYPES.NEW) {
          /* Reset existing client specific data */
          this.clientTwo.crmId = null;
          this.clientTwo.canSkipInterview = false;
          this.clientTwo.preserveInterview = false;
        }

        if (this.clientTwo.phone === this.clientOne.phone) {
          this.clientTwo.phone = null;
        }
      }
    },

    /* Map form data to working client */
    mapData() {
      this.clientOne.type = this.clientOneType;
      if (
        this.clientOneType === CONTACT_TYPES.EXISTING &&
        this.existingContactOne !== null
      ) {
        /* Map existing client data */
        this.clientOne.crmId = this.existingContactOne.crmId;
        this.clientOne.canSkipInterview =
          this.existingContactOne.canSkipInterview;
        this.clientOne.preserveInterview = this.preserveInterviewOne;
        this.clientOne.firstName = this.existingContactOne.firstName;
        this.clientOne.lastName = this.existingContactOne.lastName;
        this.clientOne.email = this.existingContactOne.email;
        this.clientOne.phone = this.existingContactOne.phone;
      }
      if (this.clientOneType === CONTACT_TYPES.NEW) {
        /* Reset existing client specific data */
        this.clientOne.crmId = null;
        this.clientOne.canSkipInterview = false;
        this.clientOne.preserveInterview = false;
      }
      this.mapSecondClientData();

      // If DATA_TYPE.TYPE_THREE is no longer applicable, MOVE TO TYPE_TWO
      // If DATA_TYPE.TYPE_TWO is no longer applicable, MOVE TO TYPE_THREE
      if (this.currentClient.type === DATA_TYPES.TYPE_THREE) {
        if (
          !this.clientOne.preserveInterview ||
          (this.currentClient.client2 &&
            !this.currentClient.client2.preserveInterview)
        ) {
          this.currentClient.type = DATA_TYPES.TYPE_TWO;
        }
      } else if (this.currentClient.type === DATA_TYPES.TYPE_TWO) {
        if (
          this.clientOne.preserveInterview ||
          (this.currentClient.client2 &&
            this.currentClient.client2.preserveInterview)
        ) {
          this.currentClient.type = DATA_TYPES.TYPE_THREE;
        }
      }
    },

    /* Nav to Next Page in flow */
    next(route = null) {
      if(this.$v){
        this.$v.$touch();
      }

      // No need for error checking on existing onbord clients
      // as they cannot be modified.
      if (this.isExistingOnbord) this.$v.$reset();
      else if (this.$v.$invalid) {
        this.$nextTick(() => {
          // Scroll to first error
          const sc = this.$refs['scrollContainer'];
          const domEl = document.querySelector('.error-area');
          if (domEl) {
            // Scroll container and window (for mobile) to error
            const domRect = domEl.getBoundingClientRect();
            sc.scrollTo(
              domRect.left + document.documentElement.scrollLeft,
              domRect.top + document.documentElement.scrollTop,
            );
            window.scrollTo(
              domRect.left + document.documentElement.scrollLeft,
              domRect.top + document.documentElement.scrollTop,
            );
          }
        });
        return;
      }

      this.mapData();

      const to = route ? route.name : MENUS.ADVISOR.CLIENT.DATA_SELECTION.id;
      this.goNext(to);
    },

    /* Nav to Previous Page in flow */
    prev() {
      this.goPrev(MENUS.ADVISOR.CLIENT.ACCOUNT_SELECTION.id);
    },
  },

  created() {
    /* Initialize Data */
    this.isExistingOnbord = this.currentClient.isExistingOnbord;
    this.CONTACT_TYPES = CONTACT_TYPES;
    this.addSecond = this.currentClient.addSecond;
    this.clientOneType = this.clientOne.type || null;
    if (
      this.clientOneType === CONTACT_TYPES.EXISTING ||
      this.isExistingOnbord
    ) {
      this.existingContactOne = this.clientOne;
      this.preserveInterviewOne = this.clientOne.preserveInterview;
    }
    if (this.addSecond === 'Y') {
      this.clientTwoType = this.clientTwo.type || null;
      if (
        this.clientTwoType === CONTACT_TYPES.EXISTING ||
        this.isExistingOnbord
      ) {
        this.preserveInterviewTwo = this.clientTwo.preserveInterview;
        this.existingContactTwo = this.clientTwo;
      }
    }

    this.listen('contact-selected', (data) => {
      if (data.client == 1) {
        this.$v.existingContactOne.$touch();
        this.preserveInterviewOne = false;
        this.existingContactOne = data.contact;
      } else if (data.client == 2) {
        this.selectedSecondContact = data.contact;
        this.$v.existingContactTwo.$touch();
        this.preserveInterviewTwo = false;
        this.existingContactTwo = data.contact;
      }

      if (this.existingContactOne && this.existingContactTwo) {
        // Wipe Phone if matches client 1 phone
        if (
          this.existingContactOne.phone !== null &&
          this.existingContactTwo.phone !== null &&
          this.existingContactOne.phone === this.existingContactTwo.phone
        ) {
          this.existingContactTwo.phone = null;
          this.existingContactTwo.dupPhone = true;
        } else {
          // Restore original second phone
          this.existingContactTwo.phone = this.selectedSecondContact.phone;
          this.existingContactTwo.dupPhone = false;
        }
      }
    });

    this.listen('contact-preserve-interview', (data) => {
      if (data.client == 1) {
        this.$v.existingContactOne.$touch();
        this.preserveInterviewOne = data.preserveInterview;
      } else if (data.client == 2) {
        this.$v.existingContactTwo.$touch();
        this.preserveInterviewTwo = data.preserveInterview;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.clients-step-container {
  max-width: 1024px;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.v-faux-checkbox {
  min-width: 300px;
}
.full {
  width: 100%;
}
::v-deep .v-input--selection-controls {
  margin-top: 0px;

  .v-label {
    margin: 0px;
  }
}
.radio-container {
  max-width: 360px;
  width: 100%;
}
::v-deep .radio-choice .v-label {
  font-size: 16px !important;
  margin: 0px;
}
h3 {
  margin-bottom: 0px;
}
</style>
