<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="700"
    scrollable
    :overlay-color="overlayColor"
    overlay-opacity="0.8"
    content-class="plain-dialog instructions-dialog"
  >
    <!-- Add New Company -->
    <v-card flat v-if="isNewCompany">
      <v-card-text class="pa-7">
        <div class="modal-title">Add Company</div>
        <div class="modal-body">
          <v-row>
            <!-- Company Name -->
            <v-col>
              <label for="companyName" class="required"> Company Name </label>
              <v-text-field
                outlined
                type="text"
                maxlength="256"
                name="companyName"
                aria-required="true"
                v-model.trim="companyName"
                :backgroundColor="inputBackgroundColor"
                @input="$v.companyName.$touch()"
                @blur="$v.companyName.$touch()"
                :error-messages="companyNameErrors"
                hide-details="auto"
                class="mb-2"
              />
            </v-col>
          </v-row>

          <!-- Admin Name -->
          <v-row>
            <v-col>
              <label for="firstName" class="required"> Admin First Name </label>
              <v-text-field
                outlined
                type="text"
                maxlength="256"
                name="firstName"
                aria-required="true"
                v-model.trim="adminFirstName"
                :backgroundColor="inputBackgroundColor"
                @input="$v.adminFirstName.$touch()"
                @blur="$v.adminFirstName.$touch()"
                :error-messages="adminFirstNameErrors"
                hide-details="auto"
                class="mb-2"
              />
            </v-col>
            <v-col>
              <label for="lastName" class="required"> Admin Last Name </label>
              <v-text-field
                outlined
                type="text"
                maxlength="256"
                name="lastName"
                aria-required="true"
                v-model.trim="adminLastName"
                :backgroundColor="inputBackgroundColor"
                @input="$v.adminLastName.$touch()"
                @blur="$v.adminLastName.$touch()"
                :error-messages="adminLastNameErrors"
                hide-details="auto"
                class="mb-2"
              />
            </v-col>
          </v-row>

          <!-- Email -->
          <v-row>
            <v-col>
              <label for="emailAddress" class="required">
                Admin Email Address
              </label>
              <v-text-field
                outlined
                type="text"
                maxlength="256"
                name="emailAddress"
                aria-required="true"
                v-model.trim="adminEmailAddress"
                :backgroundColor="inputBackgroundColor"
                @blur="$v.adminEmailAddress.$touch()"
                :error-messages="adminEmailAddressErrors"
                hide-details="auto"
                class="mb-2"
              />
            </v-col>
          </v-row>

          <!-- Phone -->
          <v-row>
            <v-col>
              <label for="adminPhoneNumber" class="required">
                Admin Phone Number
              </label>
              <v-text-field
                outlined
                type="tel"
                maxlength="256"
                name="adminPhoneNumber"
                v-model.trim="adminPhoneNumber"
                :backgroundColor="inputBackgroundColor"
                @blur="$v.adminPhoneNumber.$touch()"
                hide-details="auto"
                :error-messages="adminPhoneNumberErrors"
                placeholder="e.g., 234-567-8900"
              />
            </v-col>
          </v-row>

          <!-- CRM -->
          <v-row>
            <v-col>
              <label for="crm" class="required"> CRM </label>
              <div class="expander">
                <div class="faux-label"></div>
                <div>
                  <v-select
                    name="crm"
                    v-model="selectedCRM"
                    :items="crms"
                    :backgroundColor="inputBackgroundColor"
                    outlined
                    placeholder="Choose"
                    dense
                    hide-details="auto"
                    @change="$v.selectedCRM.$touch()"
                    :error-messages="selectedCRMErrors"
                    class="mb-2"
                  >
                  </v-select>
                </div>
              </div>
            </v-col>
          </v-row>

          <!-- DocuSign && Transitions -->
          <v-row>
            <v-col>
              <v-checkbox
                v-model="docusignEnabled"
                hide-details
                :color="primaryColor"
                :ripple="false"
              >
                <template v-slot:label>
                  <span class="cb-label">Docusign Enabled</span>
                </template>
              </v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox
                v-model="transitionsEnabled"
                hide-details
                :color="primaryColor"
                :ripple="false"
              >
                <template v-slot:label>
                  <span class="cb-label">Transitions Enabled</span>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
        </div>

        <!-- Actions -->
        <div class="modal-actions">
          <a @click="save()" class="btn mt-3">Send Email Invite</a>
          <a @click="cancel()" class="btn secondary mt-3">Cancel</a>
        </div>
      </v-card-text>
    </v-card>

    <!-- Edit Company Info -->
    <v-card flat v-if="canEditBasicInfo">
      <v-card-text class="pa-7">
        <div class="modal-title">Edit Company Info</div>
        <div class="modal-body">
          <v-row>
            <v-col>
              <label for="companyName" class="required"> Company Name </label>
              <v-text-field
                outlined
                type="text"
                maxlength="256"
                name="companyName"
                aria-required="true"
                v-model.trim="companyName"
                :backgroundColor="inputBackgroundColor"
                @input="$v.companyName.$touch()"
                @blur="$v.companyName.$touch()"
                :error-messages="companyNameErrors"
                hide-details="auto"
                class="mb-2"
              />

              <label for="companyName" class="required"> RIA Name </label>
              <v-text-field
                outlined
                type="text"
                maxlength="256"
                name="iaFirmName"
                aria-required="true"
                v-model.trim="iaFirmName"
                :backgroundColor="inputBackgroundColor"
                @input="$v.iaFirmName.$touch()"
                @blur="$v.iaFirmName.$touch()"
                :error-messages="iaFirmNameErrors"
                hide-details="auto"
                class="mb-2"
              />

              <label for="companyPhone" class="required"> Company Phone </label>
              <v-text-field
                outlined
                type="text"
                maxlength="256"
                name="companyPhone"
                v-model.trim="companyPhone"
                :backgroundColor="inputBackgroundColor"
                @blur="$v.companyPhone.$touch()"
                :error-messages="companyPhoneErrors"
                hide-details="auto"
                inputmode="numeric"
                placeholder="e.g., 234-567-8900"
              />
            </v-col>
          </v-row>
        </div>

        <!-- Actions -->
        <div class="modal-actions">
          <a @click="saveChanges()" class="btn mt-3"> Save Changes </a>
          <a @click="cancel()" class="btn secondary mt-3"> Cancel </a>
        </div>
      </v-card-text>
    </v-card>

    <!-- Edit Address -->
    <v-card flat v-if="canEditAddress">
      <v-card-text class="pa-7">
        <div class="modal-title">Change Company Address</div>
        <div class="modal-body">
          <v-row class="mobile">
            <v-col class="full">
              <label for="searchCompanyAddress" class="required">
                Search Company/Address
              </label>
              <v-text-field
                outlined
                type="text"
                maxlength="256"
                name="searchCompanyAddress"
                id="searchCompanyAddress"
                aria-required="true"
                v-model.trim="searchCompanyAddress"
                :backgroundColor="inputBackgroundColor"
                placeHolder="Search"
                @input="$v.searchCompanyAddress.$touch()"
                @blur="$v.searchCompanyAddress.$touch()"
                :error-messages="searchCompanyAddressErrors"
                hide-details="auto"
                class="mb-2"
              />
            </v-col>
          </v-row>
          <v-row class="mobile" v-if="company.autoFilled">
            <v-col>
              <label for="address1" class="required">Address 1</label>
              <v-text-field
                outlined
                type="text"
                maxlength="256"
                name="address1"
                aria-required="true"
                v-model.trim="company.address1"
                :backgroundColor="inputBackgroundColor"
                @input="$v.company.address1.$touch()"
                @blur="$v.company.address1.$touch()"
                :error-messages="companyAddress1Errors"
                hide-details="auto"
                class="mb-2"
              />
            </v-col>
            <v-col>
              <label for="address2">Address 2</label>
              <v-text-field
                outlined
                type="text"
                maxlength="256"
                name="address2"
                v-model.trim="company.address2"
                :backgroundColor="inputBackgroundColor"
                @input="$v.company.address2.$touch()"
                @blur="$v.company.address2.$touch()"
                hide-details="auto"
                class="mb-2"
              />
            </v-col>
          </v-row>
          <v-row class="mobile" v-if="company.autoFilled">
            <v-col>
              <label for="city" class="required">City</label>
              <v-text-field
                outlined
                type="text"
                maxlength="256"
                name="city"
                aria-required="true"
                v-model.trim="company.city"
                :backgroundColor="inputBackgroundColor"
                @input="$v.company.city.$touch()"
                @blur="$v.company.city.$touch()"
                :error-messages="companyCityErrors"
                hide-details="auto"
              />
            </v-col>
          </v-row>
          <v-row class="mobile state-zip" v-if="company.autoFilled">
            <v-col>
              <label for="state" class="required">State</label>
              <v-select
                v-model="company.state"
                ref="state"
                :items="states"
                :backgroundColor="inputBackgroundColor"
                outlined
                placeholder="Choose"
                dense
                hide-details="auto"
                @change="$v.company.state.$touch()"
                :error-messages="companyStateErrors"
              />
            </v-col>
            <v-col
              ><label for="zip" class="required">Zip Code</label>
              <v-text-field
                outlined
                type="text"
                maxlength="10"
                name="zip"
                aria-required="true"
                v-model.trim="company.zip"
                :backgroundColor="inputBackgroundColor"
                @input="$v.company.zip.$touch()"
                @blur="$v.company.zip.$touch()"
                :error-messages="companyZipErrors"
                hide-details="auto"
                inputmode="numeric"
              />
            </v-col>
          </v-row>
        </div>

        <!-- Actions -->
        <div class="modal-actions">
          <a @click="saveAddressChanges()" class="btn mt-3"> Save Changes </a>
          <a @click="cancel()" class="btn secondary mt-3"> Cancel </a>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Themable from '@/common/mixins/themable.mixin';
import EventListener from '@/common/mixins/eventlistener.mixin';
import { validationMixin } from 'vuelidate';
import { requiredIf, email } from 'vuelidate/lib/validators';
import {
  ADD_COMPANY,
  UPDATE_COMPANY_INFO,
  GET_AVAILABLE_CRMS,
} from '@/stores/actions.type';
import {
  COMPANY_PROFILE_SECTION_TYPES,
  STATES,
  validatePhoneLocal,
  validateZip,
  formatPhone,
  formatPhoneLocal,
} from '@/common/constants';
import {
  NOTIFICATION_TYPES,
  NotificationService,
} from '@/services/notification.service';
import { EventService } from '@/services/event.service';
import { mapGetters } from 'vuex';
import { getErrorMessage } from '@/utils';
/* Custom validator */
const mustSelectResult = (value, vm) => {
  if (!vm.canEditAddress) return true;

  return value !== null && vm.company.autoFilled;
};

export default {
  name: 'AddCompany',

  mixins: [validationMixin, Themable, EventListener],

  components: {},

  data: () => ({
    crms: [],
    dialog: false,
    companyName: null,
    iaFirmName: null,
    companyPhone: null,
    searchCompanyAddress: null,
    selectedCRM: null,
    docusignEnabled: true,
    transitionsEnabled: true,
    company: {
      autoFilled: false,
      address1: null,
      address2: null,
      city: null,
      state: null,
      zip: null,
      id: null,
    },
    adminFirstName: null,
    adminLastName: null,
    adminEmailAddress: null,
    adminPhoneNumber: null,
    companyObj: null,
    section: null,
  }),
  validations() {
    return {
      selectedCRM: {
        required: true,
      },
      companyName: {
        required: requiredIf(() => this.isNewCompany || this.canEditBasicInfo),
      },
      iaFirmName: {
        required: requiredIf(() => this.canEditBasicInfo),
      },
      companyPhone: {
        required: requiredIf(() => this.canEditBasicInfo),
        validatePhoneLocal,
      },
      adminFirstName: {
        required: requiredIf(() => this.isNewCompany),
      },
      adminLastName: {
        required: requiredIf(() => this.isNewCompany),
      },
      adminEmailAddress: {
        required: requiredIf(() => this.isNewCompany),
        email,
      },
      adminPhoneNumber: {
        required: requiredIf(() => this.isNewCompany),
        validatePhoneLocal,
      },
      searchCompanyAddress: {
        required: requiredIf(() => this.canEditAddress),
        mustSelectResult,
      },
      company: {
        address1: {
          required: requiredIf(() => this.canEditAddress),
        },
        address2: {},
        city: {
          required: requiredIf(() => this.canEditAddress),
        },
        state: {
          required: requiredIf(() => this.canEditAddress),
        },
        zip: {
          required: requiredIf(() => this.canEditAddress),
          validateZip,
        },
      },
      validationGroup: [
        'companyName',
        'companyPhone',
        'adminFirstName',
        'adminLastName',
        'adminEmailAddress',
        'adminPhoneNumber',
        'searchCompanyAddress',
        'iaFirmName',
        'company.address1',
        'company.address2',
        'company.city',
        'company.state',
        'company.zip',
      ],
    };
  },

  computed: {
    ...mapGetters(['currentUser', 'currentCompany']),

    companyNameErrors() {
      const errors = [];
      if (!this.$v.companyName.$dirty) return errors;
      !this.$v.companyName.required && errors.push('Company Name is required');
      return errors;
    },

    iaFirmNameErrors() {
      const errors = [];
      if (!this.$v.iaFirmName.$dirty) return errors;
      !this.$v.iaFirmName.required && errors.push('IA Firm Name is required');
      return errors;
    },

    selectedCRMErrors() {
      const errors = [];

      if (!this.$v.selectedCRM.$dirty) {
        return errors;
      }

      if (!this.$v.selectedCRM.required) {
        errors.push('A selection is required');
      }

      return errors;
    },

    companyPhoneErrors() {
      const errors = [];
      if (!this.$v.companyPhone.$dirty) return errors;
      !this.$v.companyPhone.required && errors.push('Phone Number is required');
      !this.$v.companyPhone.validatePhoneLocal &&
        errors.push('Phone number must in US format: e.g., 123-456-7890');
      return errors;
    },

    adminFirstNameErrors() {
      const errors = [];
      if (!this.$v.adminFirstName.$dirty) return errors;
      !this.$v.adminFirstName.required && errors.push('First Name is required');
      return errors;
    },

    adminLastNameErrors() {
      const errors = [];
      if (!this.$v.adminLastName.$dirty) return errors;
      !this.$v.adminLastName.required && errors.push('Last Name is required');
      return errors;
    },

    adminEmailAddressErrors() {
      const errors = [];
      if (!this.$v.adminEmailAddress.$dirty) return errors;
      !this.$v.adminEmailAddress.required && errors.push('Email is required');
      !this.$v.adminEmailAddress.email && errors.push('Invalid email address');
      return errors;
    },

    adminPhoneNumberErrors() {
      const errors = [];
      if (!this.$v.adminPhoneNumber.$dirty) return errors;
      !this.$v.adminPhoneNumber.required &&
        errors.push('Admin Phone Number is required');
      !this.$v.adminPhoneNumber.validatePhoneLocal &&
        errors.push('Invalid phone number format. Must be NNN-NNN-NNNN');
      return errors;
    },

    searchCompanyAddressErrors() {
      const errors = [];
      if (!this.$v.searchCompanyAddress.$dirty) return errors;
      !this.$v.searchCompanyAddress.required &&
        errors.push('You must search/select a Company/Address');
      !this.$v.searchCompanyAddress.mustSelectResult &&
        errors.push('You must search/select a Company/Address');
      return errors;
    },

    companyAddress1Errors() {
      const errors = [];
      if (!this.$v.company.address1.$dirty) return errors;
      !this.$v.company.address1.required &&
        errors.push('Address 1 is required');
      return errors;
    },

    companyCityErrors() {
      const errors = [];
      if (!this.$v.company.city.$dirty) return errors;
      !this.$v.company.city.required && errors.push('City is required');
      return errors;
    },

    companyStateErrors() {
      const errors = [];
      if (!this.$v.company.state.$dirty) return errors;
      !this.$v.company.state.required && errors.push('State is required');
      return errors;
    },

    companyZipErrors() {
      const errors = [];
      if (!this.$v.company.zip.$dirty) return errors;
      !this.$v.company.zip.required && errors.push('Zip Code is required');
      !this.$v.company.zip.validateZip &&
        errors.push('Zip Code must be NNNNN or NNNNN-NNNN');
      return errors;
    },

    isNewCompany() {
      return this.section === COMPANY_PROFILE_SECTION_TYPES.NEW;
    },

    canEditBasicInfo() {
      return this.section === COMPANY_PROFILE_SECTION_TYPES.BASIC;
    },

    canEditAddress() {
      return this.section === COMPANY_PROFILE_SECTION_TYPES.ADDRESS;
    },
  },

  methods: {
    /* Initialize form data */
    init(company) {
      this.$v.$reset();
      this.adminFirstName = null;
      this.adminLastName = null;
      this.adminEmailAddress = null;
      this.adminPhoneNumber = null;
      this.company.id = company ? company.id : null;
      this.company.autoFilled = false;
      this.company.address1 = null;
      this.company.address2 = null;
      this.company.city = null;
      this.company.state = null;
      this.company.zip = null;
      this.searchCompanyAddress = null;
      this.crms = [];
      this.companyName = company ? company.name : null;
      this.iaFirmName = company ? company.iaFirmName : null;
      this.companyPhone = company ? formatPhoneLocal(company.phone) : null;

      this.getAvailableCrms();
    },

    /* Save the Company */
    save() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      const payload = {
        name: this.companyName,
        docusign: this.docusignEnabled,
        transitions: this.transitionsEnabled,
        email: this.adminEmailAddress,
        personalInfo: {
          firstName: this.adminFirstName,
          lastName: this.adminLastName,
          email: this.adminEmailAddress,
          phone: formatPhone(this.adminPhoneNumber),
        },
        selectedCRM: this.selectedCRM,
      };

      this.$store
        .dispatch(ADD_COMPANY, payload)
        .then(() => {
          NotificationService.alert({
            type: NOTIFICATION_TYPES.SUCCESS,
            title: 'Success',
            message:
              'Company and advisor added. The admin should receive an email at the address you entered.',
            okTitle: 'Ok',
            okMethod: () => {
              this.dialog = false;
              EventService.emit('reload-company-list');
            },
          });
        })
        .catch(({ response }) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: response.data
                ? getErrorMessage(response.data)
                : 'Company or Advisor creation failed.',
              okTitle: 'Ok',
            },
            response,
          );
        });
    },

    /* Save Company Changes */
    saveChanges() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      const payload = {
        name: this.companyName,
        iaFirmName: this.iaFirmName,
        phone: formatPhone(this.companyPhone),
        organisationId: this.company.id,
      };

      this.$store
        .dispatch(UPDATE_COMPANY_INFO, payload)
        .then(() => {
          NotificationService.alert({
            type: NOTIFICATION_TYPES.SUCCESS,
            title: 'Success',
            message: 'Company Information updated.',
            okTitle: 'Ok',
            okMethod: () => {
              EventService.emit('reload-company-profile');
              this.dialog = false;
            },
          });
        })
        .catch((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: 'Update Company Information failed.',
              okTitle: 'Ok',
            },
            response,
          );
        });
    },

    /* Save Company Address Changes */
    saveAddressChanges() {
      this.$v.$touch();
      if (this.$v.searchCompanyAddress.$invalid) return;

      const payload = {
        address1: this.company.address1,
        address2: this.company.address2,
        city: this.company.city,
        state: this.company.state,
        zip: this.company.zip,
        organisationId: this.company.id,
      };

      this.$store
        .dispatch(UPDATE_COMPANY_INFO, payload)
        .then(() => {
          NotificationService.alert({
            type: NOTIFICATION_TYPES.SUCCESS,
            title: 'Success',
            message: 'Company Address updated.',
            okTitle: 'Ok',
            okMethod: () => {
              this.dialog = false;
              EventService.emit('reload-company-profile');
            },
          });
        })
        .catch((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: 'Update Company Address failed.',
              okTitle: 'Ok',
            },
            response,
          );
        });
    },

    getAvailableCrms() {
      this.$store
        .dispatch(GET_AVAILABLE_CRMS, { companyId: this.currentCompany.id })
        .then((response) => {
          this.crms = response;
        })
        .catch((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: 'Error getting available CRMs.',
              okTitle: 'Ok',
            },
            response,
          );
        });
    },

    /* Dismiss with no changes */
    cancel() {
      this.dialog = false;
    },

    /* Fill in Address Fields from Autocomplete */
    fillInAddress() {
      // Get the place details from the autocomplete object.
      const place = this.autocomplete.getPlace();
      const addressObj = this.company;
      let addressField = document.querySelector('#searchCompanyAddress');
      let address1 = '';
      let postcode = '';
      addressObj.autoFilled = true;

      // Get each component of the address from the place details,
      // and then fill-in the corresponding field on the form.
      // place.address_components are google.maps.GeocoderAddressComponent objects
      // which are documented at http://goo.gle/3l5i5Mr
      for (const component of place.address_components) {
        const componentType = component.types[0];

        switch (componentType) {
          case 'street_number': {
            address1 = `${component.long_name} ${address1}`;
            break;
          }

          case 'route': {
            address1 += component.short_name;
            break;
          }

          case 'postal_code': {
            postcode = `${component.long_name}${postcode}`;
            break;
          }

          case 'postal_code_suffix': {
            postcode = `${postcode}-${component.long_name}`;
            break;
          }
          case 'locality': {
            addressObj.city = component.long_name;
            break;
          }
          case 'administrative_area_level_1': {
            addressObj.state = component.short_name;
            break;
          }
        }
      }
      this.$nextTick(() => (addressField.value = place.formatted_address));
      addressObj.name = place.name;
      addressObj.address1 = address1;
      addressObj.zip = postcode;
    },

    /* Initialize the Autocomplete widget */
    initAutoComplete() {
      let addressField = document.querySelector('#searchCompanyAddress');

      this.autocomplete = new window.google.maps.places.Autocomplete(
        addressField,
        {
          componentRestrictions: { country: ['us'] },
          fields: ['address_components', 'name', 'formatted_address'],
          types: [],
        },
      );

      // When the user selects an address from the drop-down, populate the
      // address fields in the form.
      this.autocomplete.addListener('place_changed', this.fillInAddress);
    },
  },

  /* Lifecycle Hooks - copy payload from listened events */
  async created() {
    this.states = STATES;

    this.listen(
      'display-add-edit-company-modal',
      (company = null, section = COMPANY_PROFILE_SECTION_TYPES.NEW) => {
        this.dialog = true;
        this.section = section;

        this.init(company);
        this.companyObj = company;

        if (section === COMPANY_PROFILE_SECTION_TYPES.ADDRESS) {
          this.$nextTick(() => this.initAutoComplete());
        }
      },
    );
  },
};
</script>

<style lang="scss" scoped>
.modal-title {
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 28px;
  color: var(--color-text);
}

.cb-label {
  margin-top: 5px;
}

@media screen and (max-width: 960px) {
  .row.mobile {
    flex-direction: column;
  }
}
</style>
