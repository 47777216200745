import {
  COMPANY_STATUSES,
  USER_STATUSES,
  CLIENT_STATUSES,
  USER_ROLES,
} from '@/common/constants';

const getRandomInt = function (min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const getIndexedProperty = function (obj, index, field = null) {
  var keys = Object.keys(obj);
  return field ? obj[keys[index]][field] : obj[keys[index]];
};

export const companiesReply = [...Array(20)].map((_, i) => {
  const s = getIndexedProperty(COMPANY_STATUSES, Math.round(Math.random()));
  return {
    name: `SOmewaht Large Named Large KInd Company ${i}`,
    seats: Math.floor(Math.random() * (50 - 1) + 1),
    status: s,
    disabled: s === 'Disabled',
  };
});
export const usersReply = [...Array(22)].map((_, i) => {
  return {
    id: i,
    firstName: 'User',
    lastName: `${i}`,
    clients: Math.floor(Math.random() * (50 - 1) + 1),
    status: getIndexedProperty(USER_STATUSES, getRandomInt(0, 2)),
    role: getIndexedProperty(USER_ROLES, getRandomInt(0, 2), 'id'),
    email: `user-${i}@.foobar.com`,
  };
});
export const clientsReply = [...Array(31)].map((_, i) => {
  const repId = getRandomInt(1, 4);
  const c = {
    id: i,
    firstName: 'Client',
    lastName: `${i}`,
    familyName: null,
    repFirstName: 'Rep',
    repLastName: `${repId}`,
    repId: repId,
    signatureRequested: false,
    isOnbordComplete: false,
    status: getIndexedProperty(CLIENT_STATUSES, getRandomInt(0, 2)),
    completionPercent: Math.floor(Math.random() * 100),
    primaryInterviewId: null,
  };
  return c;
});
