import Login from '@/views/advisor/Login';
import ForgotPassword from '@/views/advisor/ForgotPassword';
import ResetPassword from '@/views/advisor/ResetPassword';
import AccessCode from '@/views/advisor/AccessCode';
import SetNewPassword from '@/views/advisor/SetNewPassword';
import { MENUS } from '@/common/constants';

const routes = [
  {
    path: '/login',
    name: MENUS.LOGIN.id,
    component: Login,
    meta: { breadcrumb: '' },
  },
  {
    path: '/verify-code',
    name: MENUS.VERIFY_CODE.id,
    component: AccessCode,
    meta: { breadcrumb: '' },
    props: true,
  },
  {
    path: '/forgot-password',
    name: MENUS.FORGOT_PASSWORD.id,
    component: ForgotPassword,
    meta: { breadcrumb: '' },
  },
  {
    path: '/reset-password/:token',
    name: MENUS.RESET_PASSWORD.id,
    component: ResetPassword,
    meta: { breadcrumb: '' },
    props: true,
  },
  {
    path: '/set-new-password/',
    name: MENUS.SET_NEW_PASSWORD.id,
    component: SetNewPassword,
    meta: { breadcrumb: '' },
  },
];
export default routes;
