/* Mock Service Define any Mocked API's Here*/ /*eslint-disable*/
import {
  LOGIN,
  VERIFY_CODE,
  LOGOUT,
  PING,
  GET_CURRENT_USER,
  SEND_PASSWORD_RESET_EMAIL,
  SIGNUP,
  RESET_PASSWORD,
  GET_THEME_DATA,
  GET_CLIENT_PROGRESS,
  GET_COMPANIES,
  GET_USERS,
  GET_CLIENTS,
} from "@/stores/actions.type";
import MockAdapter from "axios-mock-adapter";
import {
  themeDataReply,
  loginReply,
  verifyReply,
  logoutReply,
  signupReply,
  pingReply,
  passwordResetReply,
  sendPasswordReply,
  currentUserReply,
} from "@/mocks/mock.auth";
import { companiesReply, usersReply, clientsReply } from "@/mocks/mock.advisor";
import { MENUS } from "@/common/constants";

const MockService = {
  /* Initialize the service */
  init: function (axios) {
    // COMMENT MOCKED API's WHEN READY ON THE BACKEND
    this.mock = new MockAdapter(axios, { delayResponse: 500 });

    // Login Home APIs
    //this.mock.onPost(LOGIN).reply(200, loginReply);
    //this.mock.onPost(VERIFY_CODE).reply(200, verifyReply);
    //this.mock.onPost(LOGOUT).reply(200, logoutReply);
    //this.mock.onPost(SIGNUP).reply(200, signupReply);
    //this.mock.onGet(PING).reply(200, pingReply);
    //this.mock.onPost(SEND_PASSWORD_RESET_EMAIL).reply(200, sendPasswordReply);
    //this.mock.onPost(RESET_PASSWORD).reply(200, passwordResetReply);
    //this.mock.onGet(GET_CURRENT_USER).reply(200, currentUserReply);

    // Client
    //this.mock.onGet(GET_CLIENT_PROGRESS).reply(() => {
    //  const data = {};
    /*data[MENUS.CLIENT.CITIZENSHIP.id] = false;
      data[MENUS.CLIENT.RESIDENT.id] = false;
      data[MENUS.CLIENT.LEGAL_NAME.id] = false;*/

    //  return [200, data];
    //});

    //this.mock.onGet(GET_COMPANIES).reply(200, companiesReply);
    //this.mock.onGet(GET_USERS).reply(200, usersReply);
    //this.mock.onGet(GET_CLIENTS).reply(200, clientsReply);
    // this.mock.onGet(GET_THEME_DATA).reply(200, themeDataReply);

    this.mock.onAny().passThrough();
  },
};

export default MockService;
