<template>
  <div>
    <v-layout v-resize="onResize" column style="">
      <v-data-table
        :headers="headers"
        :items="users"
        :items-per-page="pagination.itemsPerPage"
        :server-items-length="pagination.itemsLength"
        :hide-default-header="isMobile"
        class="responsive-table user-table"
        :class="{ mobile: isMobile }"
        :loading="tableLoading"
        :page.sync="pagination.page"
        @pagination="getUserList"
      >
        <template v-slot:top>
          <v-row>
            <v-col>
              <v-text-field
                outlined
                type="text"
                maxlength="256"
                placeholder="Search Users..."
                v-model.trim="search"
                :backgroundColor="inputBackgroundColor"
                clearable
                class="search-input mb-2"
                single-line
                hide-details
                @click:clear="resetItems()"
              >
                <template v-slot:append>
                  <span
                    class="search"
                    v-if="!search || search.length === 0"
                    :key="tableLoading"
                  >
                    <v-icon small>fas fa-search</v-icon>
                  </span>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="filter-action-row">
            <v-col class="filter-col role">
              <v-select
                v-model="filterRole"
                :items="roles"
                :backgroundColor="inputBackgroundColor"
                item-text="name"
                item-value="id"
                outlined
                placeholder="Role"
                ref="roleFilter"
                hide-details
                dense
              >
                <template v-slot:prepend-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        class="prepend"
                        @click="clearRoleFilter()"
                      >
                        Clear Filter
                        <a class="close">
                          <div><i class="fas fa-times"></i></div>
                        </a>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <v-col class="filter-col">
              <v-btn-toggle v-model="filterStatus" mandatory>
                <v-btn :value="statuses.ACTIVE">Active</v-btn>
                <v-btn :value="statuses.PENDING">Pending</v-btn>
                <v-btn :value="statuses.ARCHIVED">Archived</v-btn>
                <v-btn value="ALL">All</v-btn>
              </v-btn-toggle>
            </v-col>
            <v-col class="button-area" v-if="canAddUser">
              <a @click="addUser()" class="btn">
                Add Users
                <span class="fa-icon"><i class="fas fa-plus-circle"></i></span>
              </a>
            </v-col>
          </v-row>
        </template>
        <template slot="item" slot-scope="props">
          <tr v-if="!isMobile" :key="props.item.id">
            <td v-for="col in tableColumns" :key="col" :width="col.width">
              <div v-if="!col.isActionColumn">
                <span
                  :class="col.classFn(props.item)"
                  @click="col.clickFn ? col.clickFn(props.item) : () => {}"
                >
                  {{ col.valueFn(props.item) }}
                  <span v-if="col.hasAppendData">
                    <span class="admin-role">
                      <v-icon> {{ col.appendIconClassFn(props.item) }} </v-icon>
                    </span>
                  </span>
                </span>
                <div v-if="col.hasAppendData" class="user-email">
                  {{ col.appendEmail(props.item) }}
                </div>
              </div>
              <div v-else class="action-column">
                <v-menu
                  left
                  nudge-left="15"
                  nudge-bottom="25"
                  v-if="canPerformActions(props.item)"
                  min-width="125"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span class="actions" v-bind="attrs" v-on="on">
                      <v-icon small>fas fa-ellipsis-h</v-icon>
                    </span>
                  </template>
                  <v-list class="menu">
                    <div v-for="action in col.actions" :key="action">
                      <v-list-item
                        v-if="
                          !action.isDivider && action.conditionFn(props.item)
                        "
                        @click="action.clickFn(props.item)"
                      >
                        <v-list-item-title class="menu-item-wrapper">
                          <v-icon x-small :key="props.item.status">
                            {{ action.iconFn(props.item) }}
                          </v-icon>
                          <span class="label">
                            {{ action.labelFn(props.item) }}
                          </span>
                        </v-list-item-title>
                      </v-list-item>
                      <div
                        class="divider"
                        v-if="action.isDivider && canShowDivider(props.item)"
                      ></div>
                    </div>
                  </v-list>
                </v-menu>
              </div>
            </td>
          </tr>
          <tr v-else :key="props.item.id">
            <td>
              <ul class="flex-content">
                <li class="flex-item" v-for="col in tableColumns" :key="col">
                  <div v-if="!col.isActionColumn">
                    <span class="mobile-label" v-if="col.dataLabel">
                      {{ col.dataLabel }}:
                    </span>
                    <span
                      :class="col.classFn(props.item)"
                      @click="col.clickFn ? col.clickFn(props.item) : () => {}"
                    >
                      {{ col.valueFn(props.item) }}

                      <span v-if="col.hasAppendData">
                        <span class="admin-role">
                          <v-icon>
                            {{ col.appendIconClassFn(props.item) }}
                          </v-icon>
                        </span>
                      </span>
                    </span>
                    <div v-if="col.hasAppendData" class="user-email">
                      {{ col.appendEmail(props.item) }}
                    </div>
                  </div>
                  <div v-else class="actions-container">
                    <v-bottom-sheet v-if="canPerformActions(props.item)">
                      <template v-slot:activator="{ on, attrs }">
                        <span class="actions" v-bind="attrs" v-on="on">
                          <v-icon small>fas fa-ellipsis-h</v-icon>
                        </span>
                      </template>
                      <v-sheet height="auto">
                        <v-list class="sheet">
                          <div v-for="action in col.actions" :key="action">
                            <v-list-item
                              v-if="
                                !action.isDivider &&
                                action.conditionFn(props.item)
                              "
                              @click="action.clickFn(props.item)"
                            >
                              <v-list-item-title class="menu-item-wrapper">
                                <v-icon small :key="props.item.status">
                                  {{ action.iconFn(props.item) }}
                                </v-icon>
                                <span class="label">
                                  {{ action.labelFn(props.item) }}
                                </span>
                              </v-list-item-title>
                            </v-list-item>
                            <div
                              class="divider"
                              v-if="
                                action.isDivider && canShowDivider(props.item)
                              "
                            ></div>
                          </div>
                          <div class="bottom-spacer"></div>
                        </v-list>
                      </v-sheet>
                    </v-bottom-sheet>
                  </div>
                </li>
              </ul>
            </td>
          </tr>
        </template>
        <template v-slot:no-data>
          <span v-if="!tableLoading && !isAnyFilterApplied"
            >No users found.<a v-if="canAddUser" @click="addUser()"
              >Add new users from Redtail</a
            >.</span
          >
          <span v-if="!tableLoading && isAnyFilterApplied"
            >Your search/filter found no results.</span
          >
        </template>
      </v-data-table>
    </v-layout>
  </div>
</template>

<script>
import { EventService } from '@/services/event.service';
import EventListener from '@/common/mixins/eventlistener.mixin';
import DataTableMixin from '@/common/mixins/datatable.mixin';
import { GET_USERS, EDIT_USER } from '@/stores/actions.type';
import {
  MENUS,
  USER_STATUSES,
  USER_ROLES,
  formatDate,
  US_DATE_SHORT,
  NO_DATE,
} from '@/common/constants';
import {
  NOTIFICATION_TYPES,
  NotificationService,
} from '@/services/notification.service';
import { PermissionService, PERMISSIONS } from '@/services/permission.service';
import { mapGetters } from 'vuex';
import { USER_PROFILE_SECTION_TYPES } from '../../../common/constants';

export default {
  mixins: [EventListener, DataTableMixin],

  data: () => ({
    selected: [],
    search: '',
    filterStatus: 'ALL',
    filterRole: null,
    isMobile: false,
    users: [],
    debounceTimer: null,
    tableLoading: false,
    pagination: {
      page: NaN,
      itemsPerPage: NaN,
      itemsLength: NaN,
    },
  }),

  mounted() {},

  watch: {
    filterStatus(newValue, oldValue) {
      // Call getUserList whenever filterStatus changes
      if (newValue !== oldValue) {
        this.getUserList();
      }
    },
    filterRole(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getUserList();
      }
    },
    search(newValue, oldValue) {
      if (newValue !== oldValue) {
        clearTimeout(this.debounceTimer);
        this.debounceTimer = setTimeout(() => {
          this.getUserList();
        }, 300);
      }
    },
  },

  computed: {
    ...mapGetters(['currentUser']),

    /* Table Header Definitions */
    headers() {
      return [
        {
          text: 'User',
          align: 'left',
          value: 'name',
          filter: (value, search, item) => {
            if ((!this.search || this.search.length === 0) && !this.filterRole)
              return true;
            if (!this.filterRole)
              return value.match(new RegExp(this.search, 'i'));
            if (this.search || this.search.length > 0) {
              return (
                value.match(new RegExp(this.search, 'i')) &&
                item.roles.includes(this.filterRole)
              );
            }
            return item.roles.includes(this.filterRole);
          },
        },
        {
          text: 'Status',
          value: 'status',
          filter: (value) => {
            if (this.filterStatus === 'ALL') return true;

            return value === this.filterStatus;
          },
        },
        {
          text: 'Clients',
          value: 'clients',
        },
        {
          text: 'ADV 2B Upload Date',
          value: 'adv2bUploadedOn',
        },
        {
          text: 'Actions',
          sortable: false,
          align: 'end',
        },
      ];
    },

    /* Table Column Definitions */
    tableColumns() {
      const cols = [
        {
          width: '35%',
          valueFn: (item) => {
            return item.name;
          },
          clickFn: (item) => {
            this.editUserProfile(item.id); //, advisorId, organisationId);
          },
          classFn: () => {
            return `edit-profile-link faux-anchor ${
              !this.canManageUserProfile ? 'disabled' : ''
            }`;
          },
          hasAppendData: true,
          appendIconClassFn: (item) => {
            return this.getIcon(item);
          },
          appendEmail: (item) => {
            return item.login;
          },
        },
        {
          width: '20%',
          dataLabel: 'Status',
          valueFn: (item) => {
            return item.status;
          },
          classFn: (item) => {
            return item.status;
          },
        },
        {
          width: '10%',
          dataLabel: 'Clients',
          valueFn: (item) => {
            return item.clients;
          },
          classFn: () => {
            return '';
          },
        },
        {
          width: '20%',
          dataLabel: 'ADV 2B Upload Date',
          valueFn: (item) => {
            return item.adv2bUploadedOn
              ? formatDate(item.adv2bUploadedOn, US_DATE_SHORT)
              : NO_DATE;
          },
          classFn: () => {
            return '';
          },
        },
        {
          width: '15%',
          isActionColumn: true,
          actions: [
            {
              conditionFn: () => {
                return this.canManageUserProfile;
              },
              clickFn: (item) => {
                this.editUserProfile(item.id);
              },
              labelFn: () => {
                return 'Edit Profile';
              },
              iconFn: () => {
                return 'fas fa-user';
              },
            },
            {
              isDivider: true,
            },
            {
              conditionFn: (item) => {
                return this.canArchiveAndActivateUser(item);
              },
              clickFn: (item) => {
                this.editUserStatus(item);
              },
              labelFn: (item) => {
                return this.getUserStatusLabel(item);
              },
              iconFn: () => {
                return 'fas fa-archive';
              },
            },
          ],
        },
      ];
      return cols;
    },

    canAddUser() {
      return PermissionService.hasPermission(PERMISSIONS.USER.ADD_USER);
    },

    canManageUserProfile() {
      return PermissionService.hasPermission(
        PERMISSIONS.USER.MANAGE_USER_PROFILE,
      );
    },

    isAnyFilterApplied() {
      return this.search || this.roleFilter || this.filterStatus !== 'ALL';
    },
  },

  methods: {
    /*
      Reset Items on Search Clear - forces the table to re-draw
      Safari Mobile seems to have issues with not re-rendering icons
      when repainting the table rows. This is a hack to force the entire
      table to be re-drawn
    */
    resetItems() {
      this.tableLoading = true;
      this.$nextTick(() => setTimeout(() => (this.tableLoading = false)), 500);
    },

    /* Determine Mobile vs Desktop view */
    onResize() {
      this.isMobile = window.innerWidth < 769;
    },

    /* Clear the User Role Filter */
    clearRoleFilter() {
      this.filterRole = null;
      this.$refs.roleFilter.blur();
    },

    /* Get the role icon to display for the user */
    getIcon(user) {
      return this.isProductAdmin(user)
        ? 'fas fa-user-cog'
        : this.isCompanyAdmin(user)
        ? 'fas fa-crown'
        : '';
    },

    /* Is the user a Product Admin */
    isProductAdmin(user) {
      return user.roles[0] === USER_ROLES.PRODUCT_ADMIN.id;
    },

    /* Is the user a Company Admin */
    isCompanyAdmin(user) {
      return user.roles[0] === USER_ROLES.COMPANY_ADMIN.id;
    },

    /* Display the Add User Modal */
    addUser() {
      EventService.emit(
        'display-add-edit-user-modal',
        null,
        USER_PROFILE_SECTION_TYPES.NEW_USER,
      );
    },

    // /* Bulk add users */
    // addUsers() {
    //   this.$router.push({ name: MENUS.ADD_USERS.id });
    // },

    /* Is the user passed in the current logged in user (self) */
    isSelf(user) {
      try {
        return user.id === this.currentUser.user.id;
      } catch {
        return true;
      }
    },

    /*
        Based on the current user's role can the user being requested archived?
        (You can't archive yourself, regardless)
      */
    canArchiveAndActivateUser(user) {
      return (
        !this.isSelf(user) &&
        user.status !== USER_STATUSES.PENDING &&
        PermissionService.hasPermission(PERMISSIONS.USER.DISABLE_USER)
      );
    },

    /* Can a divider be shown in the action context menu */
    canShowDivider(user) {
      return this.canArchiveAndActivateUser(user) && this.canManageUserProfile;
    },

    /* Can the action context menu be shown based on actions allowed for the user */
    canPerformActions(user) {
      return this.canArchiveAndActivateUser(user) || this.canManageUserProfile;
    },

    /* Edit the passed in user */
    editUserProfile(id) {
      if (!this.canManageUserProfile) return;

      this.$router.push({
        name: MENUS.USER_PROFILE.id,
        params: { id: id },
      });
    },
    /* Is the user in ARCHIVED status */
    isArchived(user) {
      return user.status === USER_STATUSES.ARCHIVED;
    },
    /* Get the status change label (enable/archive) for the user based on their current status */
    getUserStatusLabel(user) {
      return this.isArchived(user) ? 'Enable' : 'Archive';
    },

    /* Actually Perform the status change for the user */
    performStatusChange(user) {
      const userPayload = {
        advisorId: user.id,
        organisationId: this.currentUser.organisation.id,
        status: this.isArchived(user)
          ? USER_STATUSES.ACTIVE
          : USER_STATUSES.ARCHIVED,
      };
      this.$store
        .dispatch(EDIT_USER, userPayload)
        .then((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.SUCCESS,
              title: 'Success',
              message: `User ${this.getUserStatusLabel(user)}d.`,
              okTitle: 'Ok',
            },
            response,
          );
        })
        .catch((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: `Error attempting to
                  ${this.getUserStatusLabel(user)}
                  user!`,
              okTitle: 'Ok',
            },
            response,
          );
        })
        .finally(() => {
          this.getUserList();
        });
    },

    /* Status change requested for user, Confirm before processing */
    editUserStatus(user) {
      const action = this.isArchived(user) ? 'enable' : 'archive';

      NotificationService.confirm({
        type: NOTIFICATION_TYPES.WARNING,
        message: `Are you sure you want to ${action} ${user.name}?`,
        okTitle: "Yes I'm sure",
        okMethod: () => {
          this.performStatusChange(user);
        },
      });
    },
    /* Get the list of users */
    getUserList(paginationOptions) {
      if (!this.currentUser) {
        return;
      }

      // Avoiding undefined values when rendering the page
      paginationOptions = paginationOptions || {
        page: 1,
        itemsPerPage: 10,
      };

      // Set a default value for itemsPerPage if not provided
      this.pagination.itemsPerPage = paginationOptions.itemsPerPage || 10;
      this.pagination.page = paginationOptions.page || 1;

      const { page, itemsPerPage, itemsLength } = this.pagination;

      this.tableLoading = true;

      // Flag if current user can add product admins
      // If not, we filter any users with that role from the user list
      const canAddProductAdmin = PermissionService.hasPermission(
        PERMISSIONS.USER.ADD_PRODUCT_ADMIN,
      );

      // The Vuetify library returns a limit = -1 when we select to show all users and, to avoid an error on the FE, we need to implement this following logic
      const query = {
        limit: itemsPerPage < 0 ? itemsLength : itemsPerPage,
        page,
        status: this.filterStatus === 'ALL' ? undefined : this.filterStatus,
        name: this.search,
        role: this.filterRole,
      };

      this.$store
        .dispatch(GET_USERS, query)
        .then(({ result, totalResults }) => {
          this.users = result.reduce((acc, user) => {
            // Filter Product Admins
            if (
              canAddProductAdmin ||
              user.roles[0].id !== USER_ROLES.PRODUCT_ADMIN.id
            ) {
              const { firstName, lastName } = user || '';
              user.name = `${lastName}, ${firstName}`;

              acc.push(user);
            }
            return acc;
          }, []);

          this.pagination.itemsLength = totalResults;
        })
        .catch((error) => {
          this.users = [];
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: 'Error getting list of users!',
              okTitle: 'Ok',
            },
            error,
          );
        })
        .finally(() => {
          // Regardless of outcome, force re-draw
          this.tableLoading = false;
        });
    },
  },

  /* Lifecycle Hook */
  created() {
    this.pagination.sortBy = 'name';

    // Set initial static status/role lists
    this.statuses = USER_STATUSES;
    const temp = [];
    Object.keys(USER_ROLES).map((key) => {
      temp.push(USER_ROLES[key]);
    });
    // Filter product admin role id user cannot see them
    const canAddProductAdmin = PermissionService.hasPermission(
      PERMISSIONS.USER.ADD_PRODUCT_ADMIN,
    );
    this.roles = temp.filter(
      (r) => canAddProductAdmin || r.id !== USER_ROLES.PRODUCT_ADMIN.id,
    );

    // Listen for reload events
    this.listen('reload-user-list', () => {
      this.getUserList();
    });
  },
};
</script>

<style lang="scss" scoped>
.mobile {
  color: var(--color-text-dark);
}

.prepend {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--secondary-color);
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.search-input {
  ::v-deep {
    .v-input__icon--clear {
      height: 16px;
    }

    .fa-times-circle {
      font-size: 16px;
      margin-right: 8px;
    }
  }
}

.filter-col {
  max-width: 200px;

  &.role {
    max-width: 300px;
  }
}

.action-column {
  text-align: end !important;
}

.edit-profile-link {
  color: var(--color-text);
  font-weight: 700;

  &:hover {
    color: var(--primary-color);
  }
}

.flex-content {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;

  .actions-container {
    position: absolute;
    top: 0;
    right: 0;
    line-height: normal;
  }

  .flex-item {
    padding: 5px;
    line-height: 24px;
    font-size: 16px;

    .edit-profile-link {
      padding-right: 30px;
      display: inline-block;
      line-height: normal;
    }

    .mobile-label {
      font-weight: 400;
      margin-right: 10px;
    }
  }
}

.search {
  margin-right: 12px;
}

.admin-role {
  ::v-deep .v-icon {
    font-size: 12px;
    color: var(--color-warning);
    vertical-align: baseline;
  }
}

.name-item {
  height: 60px;
}

.user-email {
  color: var(--label-text);
  font-size: 11px;
  font-weight: 400;
  line-height: normal;
}

.actions {
  padding: 2px 4px;
  line-height: 16px;

  &[aria-expanded='true'] {
    background-color: var(--secondary-color);
    border-radius: 4px;

    ::v-deep .v-icon {
      color: var(--primary-color);
    }
  }
}

.menu {
  background-color: var(--color-white);

  ::v-deep {
    .v-list-item {
      min-height: 24px !important;
      padding: 0;
    }
  }
}

.menu-item-wrapper {
  padding-left: 10px;
  color: var(--color-text) !important;
  font-size: 14px !important;
  font-weight: 700;
  margin-bottom: 0;
  padding: 8px 12px;
  margin: 0 4px;
  cursor: pointer;

  ::v-deep {
    .v-icon {
      font-weight: 700;
      color: var(--color-text) !important;
      margin-top: -2px;
    }
  }

  &:hover {
    color: var(--primary-color) !important;
    background-color: var(--input-bg-color);

    text-decoration: underline;

    ::v-deep .v-icon {
      color: var(--primary-color) !important;
    }
  }

  .label {
    padding-left: 8px;
    cursor: pointer;
  }
}

.sheet {
  background-color: var(--color-white);

  ::v-deep {
    .v-list-item {
      min-height: 24px !important;
      padding: 0;
    }
  }

  .menu-item-wrapper {
    font-size: 16px !important;
    padding: 12px;
  }

  .bottom-spacer {
    height: 25px;
  }
}

.divider {
  height: 1px;
  margin: 8px;
  background-color: var(--secondary-color);
}

.user-table {
  font-weight: 500;
  color: var(--color-text);

  .Pending,
  .Archived {
    padding: 2px 15px;
    border-radius: 30px;
    line-height: 24px;
    background-color: hsla(0, 0%, 72.7%, 0.16);
    color: var(--label-text);
  }

  .Active {
    padding: 2px 15px;
    border-radius: 30px;
    line-height: 24px;
    background-color: rgba(30, 59, 112, 0.15);
    color: var(--primary-color);
  }

  .filter-action-row {
    padding-bottom: 12px;
  }

  .edit-profile-link.disabled {
    text-decoration: none;
    color: var(--color-text);

    &:hover {
      color: var(--color-text);
      cursor: text;
    }
  }
}

@media screen and (max-width: 769px) {
  .filter-action-row {
    flex-direction: column;

    .filter-col {
      max-width: 100%;
      padding: 4px 12px;
    }

    .button-area {
      justify-content: flex-start;
    }
  }
}
</style>
