<template>
  <div class="step-container advisor">
    <v-form name="ClientPhone" class="form">
      <v-container class="client-step-container">
        <v-row class="mb-8">
          <v-col class="instructions">
            <h1>
              <span class="headerlight">
                What's {{ computedFirstName }}'s
                <strong>mobile phone number</strong>?
              </span>
            </h1>
            <div class="sub-header" v-if="showPhoneMatch">
              This number cannot be the same as
              <strong>
                {{ computedOtherName }} ({{ formattedOtherPhone }}) </strong
              >.
            </div>
          </v-col>
        </v-row>
        <v-row class="client-phone-container step-form-container">
          <v-col class="d-flex justify-center">
            <v-text-field
              class="npa"
              outlined
              maxlength="3"
              aria-required="true"
              v-model.trim="npa"
              :backgroundColor="inputBackgroundColor"
              @input="
                touchPhone();
                focusNxx();
              "
              @blur="touchPhone()"
              hide-details="auto"
              inputmode="numeric"
            />
            <div class="dash">
              <i class="fas fa-minus"></i>
            </div>
            <v-text-field
              outlined
              class="nxx"
              maxlength="3"
              name="nxx"
              ref="nxx"
              aria-required="true"
              v-model.trim="nxx"
              :backgroundColor="inputBackgroundColor"
              @input="
                touchPhone();
                focusLine();
              "
              @blur="touchPhone()"
              hide-details="auto"
              inputmode="numeric"
            />
            <div class="dash">
              <i class="fas fa-minus"></i>
            </div>
            <v-text-field
              class="line"
              outlined
              maxlength="4"
              name="line"
              ref="line"
              aria-required="true"
              v-model.trim="line"
              :backgroundColor="inputBackgroundColor"
              @input="touchPhone()"
              @blur="touchPhone()"
              hide-details="auto"
              inputmode="numeric"
            />
          </v-col>
          <v-row
            class="step-form-container error-container"
            v-if="hasPhoneErrors"
          >
            <v-col class="error-area">{{ phoneErrors }}</v-col>
          </v-row>
        </v-row>
      </v-container>
    </v-form>
    <bottom-nav v-on:go-prev="prev()" v-on:go-next="next()"></bottom-nav>
  </div>
</template>

<script>
import BottomNav from '@/components/common/BottomNav';
import { validationMixin } from 'vuelidate';
import { MENUS, TRANSITION_TYPES, formatPhone } from '@/common/constants';
import { EventService } from '@/services/event.service';
import { ADD_TRANSITION_PHONE } from '@/stores/actions.type';
import EventListener from '@/common/mixins/eventlistener.mixin';
import AdvisorTransitionMixin from '@/common/mixins/advisor.transition.mixin';
import { mapGetters } from 'vuex';

const validatePhone = (value) => {
  let v = value.match(/\d/g);
  let r = v && v.length === 10 ? true : false;
  return r;
};
const matchPhone = (value, clientOnePhone, clientTwoPhone, isSecondClient) => {
  if (isSecondClient) {
    return value !== clientOnePhone;
  } else {
    return value !== clientTwoPhone;
  }
};

export default {
  name: 'TransitionClientPhone',

  mixins: [validationMixin, AdvisorTransitionMixin, EventListener],

  components: { BottomNav },

  props: {
    second: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    npa: null,
    nxx: null,
    line: null,
    internalPhone: null,
    contactPhone: '',
  }),

  validations() {
    return {
      phone: {
        validatePhone,
        matchPhone: (value) =>
          matchPhone(
            value,
            this.transitioningHousehold.primaryContact.phone,
            this.transitioningHousehold.secondaryContact.phone,
            this.second,
          ),
      },
      validationGroup: ['phone'],
    };
  },
  computed: {
    ...mapGetters(['transitioningHousehold']),

    phone() {
      return this.review
        ? this.internalPhone
        : `${this.npa}${this.nxx}${this.line}`;
    },

    phoneErrors() {
      let errors = '';
      const firstName = !this.second
        ? this.transitioningHousehold.secondaryContact.phone
        : this.transitioningHousehold.primaryContact.phone;
      if (!this.$v.phone.$dirty) return errors;
      if (!this.$v.phone.validatePhone) errors = 'Phone number is invalid';
      if (!this.$v.phone.matchPhone)
        errors = `Phone cannot match number for ${firstName}`;
      return errors;
    },

    hasPhoneErrors() {
      return this.phoneErrors.length > 0;
    },

    showPhoneMatch() {
      return this.second && this.transitioningHousehold.primaryContact.phone;
    },

    computedFirstName() {
      return this.second
        ? this.transitioningHousehold.secondaryContact.firstName || '2nd Client'
        : this.transitioningHousehold.primaryContact.firstName || 'Client';
    },
    computedOtherName() {
      return !this.second
        ? this.transitioningHousehold.secondaryContact.firstName || '2nd Client'
        : this.transitioningHousehold.primaryContact.firstName || 'Client';
    },
    formattedOtherPhone() {
      return !this.second
        ? formatPhone(this.transitioningHousehold.secondaryContact.phone)
        : formatPhone(this.transitioningHousehold.primaryContact.phone);
    },
    transitionType() {
      return this.$store.getters.selectedTransitionType;
    },
  },

  methods: {
    /*
       Do nothing for now.
       We don't mark phone as dirty until leave
    */
    touchPhone() {},

    /* Focus on NXX field when NPA is complete */
    focusNxx() {
      if (this.npa.length === 3) {
        this.$refs.nxx.focus();
      }
    },

    /* Focus on Line field when NXX is complete */
    focusLine() {
      if (this.nxx.length === 3) {
        this.$refs.line.focus();
      }
    },

    next() {
      this.$v.phone.$touch();
      if (this.$v.$invalid) return;
      const payload = {
        phone: this.phone,
        key: this.second ? 1 : 0,
      };

      const to = this.second
        ? MENUS.TRANSITIONS.ENTER_MANUALLY.FIRM_ROLES.id
        : this.$store.getters.transitioningHousehold.addSecond === 'Y'
        ? MENUS.TRANSITIONS.ENTER_MANUALLY.CLIENT_TWO_NAME.id
        : MENUS.TRANSITIONS.ENTER_MANUALLY.FIRM_ROLES.id;

      this.$store.dispatch(ADD_TRANSITION_PHONE, payload).then(() => {
        this.$router.push({
          name: to,
        });
      });
    },

    prev() {
      const to =
        this.transitionType === TRANSITION_TYPES.PROTOCOL
          ? this.second
            ? MENUS.TRANSITIONS.ENTER_MANUALLY.CLIENT_TWO_EMAIL.id
            : MENUS.TRANSITIONS.ENTER_MANUALLY.CLIENT_ONE_EMAIL.id
          : this.second
          ? MENUS.TRANSITIONS.ENTER_MANUALLY.CLIENT_TWO_NAME.id
          : MENUS.TRANSITIONS.ENTER_MANUALLY.CLIENT_ONE_NAME.id;

      this.$router.push({
        name: to,
      });
    },
  },

  created() {
    if (this.transitioningHousehold) {
      const contact = this.second
        ? this.transitioningHousehold.secondaryContact
        : this.transitioningHousehold.primaryContact;

      this.contactPhone = contact.phone;
    }

    this.listen('navigate-to', (route) => {
      if (this.phone !== this.contactPhone) {
        this.$v.phone.$touch();
      }
      this.ignoreInProgessClient = true;
      this.$router.push({ name: route }).catch(() => {
        EventService.emit('toggle-main-drawer');
      });
    });

    // Initial form data for current client
    const mobileNum = this.contactPhone;
    const sanitizedMobileNum = mobileNum.startsWith('+1')
      ? mobileNum.slice(2)
      : mobileNum;
    this.npa = sanitizedMobileNum ? sanitizedMobileNum.slice(0, 3) : null;
    this.nxx = sanitizedMobileNum ? sanitizedMobileNum.slice(3, 6) : null;
    this.line = sanitizedMobileNum ? sanitizedMobileNum.slice(-4) : null;
    this.internalPhone = sanitizedMobileNum || null;
  },
};
</script>
<style lang="scss" scoped>
.client-phone-container {
  justify-content: center;
  align-content: center;
  flex-direction: column;

  .dash {
    display: inline-block;
    margin: auto 8px;
  }

  .npa,
  .nxx {
    max-width: 75px !important;
  }

  .line {
    max-width: 100px !important;
  }

  .error-container {
    justify-content: center;
    align-items: center;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
  }
}
</style>
