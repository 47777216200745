<template>
  <div class="step-container client">
    <help-drawer :title="helpTitle">
      <div>
        <p>
          We use your date of birthday to confirm identity and to ensure you are
          over age of 18.
        </p>
      </div>
    </help-drawer>
    <v-form name="DOB" class="form">
      <v-container class="client-step-container">
        <v-row class="mb-8">
          <v-col class="instructions">
            <h1>
              <span class="headerlight">
                What's your <strong>date of birth?</strong>
              </span>
            </h1>
            <div class="help-link">
              <a @click="toggleHelp()">{{ helpTitle }}</a>
            </div>
          </v-col>
        </v-row>
        <data-submitted-message
          :submitted="isDOBComplete"
          v-on:hide-form="toggleFormState"
        />
        <div v-if="!formHidden">
          <v-row class="justify-center">
            <v-col
              class="step-form-container d-flex justify-center flex-column"
            >
              <label for="dob" class="required">Date of Birth</label>
              <v-text-field
                v-model="dob"
                :placeholder="INPUT_DATE_FMT"
                outlined
                @input="$v.dob.$touch()"
                @blur="standardizeDate()"
                :error-messages="dobErrors"
                :backgroundColor="inputBackgroundColor"
                dense
                inputmode="numeric"
                maxlength="8"
              >
                <template v-slot:append>
                  <span class="cal-icon">
                    <v-icon small color="#9b9b9b">fas fa-calendar-alt</v-icon>
                  </span>
                </template>
              </v-text-field>
              <!--<v-menu
                v-model="activatorMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="dobString"
                    placeholder=""
                    readonly
                    outlined
                    @input="$v.dob.$touch()"
                    @blur="$v.dob.$touch()"
                    :error-messages="dobErrors"
                    :backgroundColor="inputBackgroundColor"
                    dense
                    v-on="on"
                    class="locked-date-input"
                  >
                    <template v-slot:append>
                      <span
                        class="cal-icon"
                        @click="activatorMenu = !activatorMenu"
                      >
                        <v-icon small color="#9b9b9b">fas fa-calendar-alt</v-icon>
                      </span>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="dob"
                  :max="maxDate"
                  @input="activatorMenu = false"
                  :color="primaryColor"
                ></v-date-picker>
              </v-menu>-->
            </v-col>
          </v-row>

          <div class="skip-for-now">
            <a @click="skip()">Skip for now</a>
          </div>
        </div>
      </v-container>
    </v-form>
    <bottom-nav v-on:go-prev="prev()" v-on:go-next="next()"></bottom-nav>
  </div>
</template>

<script>
import HelpDrawer from '@/components/HelpDrawerComponent';
import BottomNav from '@/components/common/BottomNav';
import DataSubmittedMessage from '@/components/client/DataSubmittedMessage';
import InterviewClientMixin from '@/common/mixins/interview.client.mixin';
import {
  MENUS,
  formatDate,
  ISO_DATE_SHORT,
  INPUT_DATE_FMT,
  validInputDate,
  US_DATE_SHORT,
} from '@/common/constants';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'DateOfBirth',

  components: { BottomNav, HelpDrawer, DataSubmittedMessage },
  mixins: [InterviewClientMixin],

  data: () => ({
    dob: null,
    activatorMenu: false,
    maxDate: formatDate(window.moment()),
  }),
  validations() {
    return {
      dob: {
        required,
        validInputDate,
      },
      validationGroup: ['dob'],
    };
  },
  computed: {
    // Dynamic  errors */
    dobErrors() {
      let errors = '';

      if (!this.$v.dob.$dirty) return errors;
      if (!this.$v.dob.required) errors = 'Date of Birth is required';
      if (!this.$v.dob.validInputDate)
        errors = 'Date of Birth must be a valid date (MMDDYYYY)';

      return errors;
    },

    dobString() {
      /*return this.dob ? formatDate(this.dob, US_DATE_SHORT) : "";*/
      return this.dob
        ? window.moment(this.dob, US_DATE_SHORT, true).format(ISO_DATE_SHORT)
        : null;
    },
    helpTitle() {
      return 'Why do you need this?';
    },
  },
  methods: {
    // Standardize a date from input format to US Readable
    standardizeDate() {
      this.$v.dob.$touch();
      if (validInputDate(this.dob)) {
        this.dob = this.dob
          ? window.moment(this.dob, INPUT_DATE_FMT, true).format(US_DATE_SHORT)
          : null;
      }
    },

    // Activate date picker menu
    activate() {
      this.activatorMenu = true;
    },

    /* Nav to Next Page in flow */
    next(route = null) {
      const to = route || MENUS.CLIENT.ALTERNATE_PHONE.id;

      if (!this.formHidden) {
        this.$v.$touch();
        if (this.$v.$invalid) return;
      } else {
        this.goNext(to);
        return;
      }

      // ISO Short date
      const data = {
        //dob: formatDate(this.dob),
        dob: this.dobString,
      };

      this.completeStep(MENUS.CLIENT.DOB.id, data)
        .then(() => {
          this.goNext(to);
        })
        .catch(() => {});
    },

    /* Nav to Previous Page in flow */
    prev() {
      this.goPrev(MENUS.CLIENT.SSN.id);
    },

    /* Skip to next Page in flow */
    skip() {
      this.goPrev(MENUS.CLIENT.ALTERNATE_PHONE.id);
    },
  },
  created() {
    // Initialize form data for current interview
    this.formHidden = this.isDOBComplete;
    this.INPUT_DATE_FMT = INPUT_DATE_FMT;
  },
};
</script>
<style lang="scss" scoped>
.col.instructions {
  @media screen and (max-width: 960px) {
    strong {
      display: block;
    }
  }
}
.cal-icon {
  margin-right: 6px;
  margin-top: 4px;
}
</style>
