/*
  MIXIN to for Login Functionality
 */
import {
  NOTIFICATION_TYPES,
  NotificationService,
} from '@/services/notification.service';
import {
  SESSION_TIMEOUT,
  LOGIN,
  VERIFY_CODE,
  GET_CURRENT_USER,
} from '@/stores/actions.type';
import { MENUS } from '@/common/constants';
import { PermissionService } from '@/services/permission.service';
import { mapGetters } from 'vuex';

const LoginMixin = {
  data: () => ({}),
  computed: {
    ...mapGetters(['currentUser']),
  },

  methods: {
    /* Login and get current user */
    doLogin(email, password) {
      // Reset timeout flag
      this.$store.dispatch(SESSION_TIMEOUT, false);

      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.$store
        .dispatch(LOGIN, { email, password })
        .then((data) => {
          if (data.newPasswordRequired) {
            this.$router.push({
              name: MENUS.SET_NEW_PASSWORD.id,
            });
          } else if (!data.mfaRequired) {
            this.$store
              .dispatch(GET_CURRENT_USER)
              .then(() => {
                const route = PermissionService.getHomePage(
                  this.currentUser.roles[0],
                );
                this.$router.push({ name: route });
              })
              .catch((error) => {
                NotificationService.alert(
                  {
                    type: NOTIFICATION_TYPES.ERROR,
                    title: 'Error',
                    message: 'Error getting logged in User!',
                    okTitle: 'Ok',
                  },
                  error,
                );
              });
            return;
          } else {
            // Login is successful - now verify code
            const route = MENUS.VERIFY_CODE.id;
            this.$router.push({
              name: route,
              params: { hint: data.notificationHint },
            });
          }
        })
        .catch((error) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              // No optional chaining support - Replace with optional chaining when available
              message:
                error &&
                error.response &&
                error.response.data &&
                error.response.data.message &&
                typeof error.response.data.message.error === 'string' &&
                error.response.data.message.error
                  .toLowerCase()
                  .includes('user is disabled')
                  ? 'This user is disabled, please contact your company admin!'
                  : 'Invalid Username or Password!',
              okTitle: 'Ok',
            },
            error,
          );
        });
    },

    /* Verify Access Code */
    doVerifyCode(code) {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.$store
        .dispatch(VERIFY_CODE, code)
        .then(() => {
          // Login is successful - nowfetch current user
          this.$store
            .dispatch(GET_CURRENT_USER)
            .then(() => {
              const route = PermissionService.getHomePage(
                this.currentUser.roles[0],
              );

              this.$router.push({ name: route });
            })
            .catch((error) => {
              NotificationService.alert(
                {
                  type: NOTIFICATION_TYPES.ERROR,
                  title: 'Error',
                  message: 'Error getting logged in User!',
                  okTitle: 'Ok',
                },
                error,
              );
            });
        })
        .catch((error) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: 'Invalid Access Code!',
              okTitle: 'Ok',
            },
            error,
          );
        });
    },
  },

  mounted() {},
};

export default LoginMixin;
