/*
  MIXIN for Theme
 */
const ThemeMixin = {
  computed: {
    /* Common Vars - Add as needed */
    primaryColor() {
      return this.getVariable('--primary-color');
    },
    inputBackgroundColor() {
      return this.getVariable('--input-bg-color');
    },
    darkTextColor() {
      return this.getVariable('--color-text-dark');
    },
    overlayColor() {
      return this.getVariable('--color-text-dark');
    },
    textColor() {
      return this.getVariable('--color-text');
    },
    errorColor() {
      return this.getVariable('--error-color');
    },
    warningColor() {
      return this.getVariable('--color-warning');
    },
  },

  methods: {
    /* Get a css variable by name */
    getVariable(name) {
      return window
        .getComputedStyle(document.documentElement)
        .getPropertyValue(name)
        .trim();
    },
  },
};

export default ThemeMixin;
