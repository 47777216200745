<template>
  <v-layout column style="">
    <h1><span class="headerlight">Add Users from Redtail</span></h1>
    <v-data-table
      :headers="headers"
      :items="users"
      :disable-pagination="true"
      hide-default-footer
      :footer-props="{ disablePagination: true, disableItemsPerPage: true }"
      class="responsive-table user-table"
      :loading="tableLoading"
    >
      <template slot="item.selected" slot-scope="props">
        <v-simple-checkbox
          v-model="props.item.selected"
          :color="primaryColor"
          :disabled="props.item.disabled"
        ></v-simple-checkbox>
      </template>
      <template slot="item.email" slot-scope="props">
        <v-text-field
          outlined
          type="email"
          maxlength="256"
          name="emailAddress"
          aria-required="true"
          v-model.trim="props.item.email"
          :backgroundColor="inputBackgroundColor"
          @blur="$v.users.$each[props.index].email.$touch()"
          :error-messages="emailAddressErrors(props.index)"
          placeholder="Email"
          hide-details="auto"
          class="mb-2"
          :disabled="props.item.disabled"
        />
      </template>
      <template slot="item.phone" slot-scope="props">
        <v-text-field
          outlined
          type="tel"
          maxlength="256"
          name="phoneNumber"
          aria-required="true"
          v-model.trim="props.item.phone"
          :backgroundColor="inputBackgroundColor"
          @blur="$v.users.$each[props.index].phone.$touch()"
          :error-messages="phoneNumberErrors(props.index)"
          placeholder="Phone Number"
          hide-details="auto"
          class="mb-2"
          :disabled="props.item.disabled"
        />
      </template>
      <template slot="item.role" slot-scope="props">
        <v-select
          v-model="props.item.role"
          :items="roles"
          item-value="id"
          item-text="name"
          :backgroundColor="inputBackgroundColor"
          outlined
          placeholder="Role"
          hide-details="auto"
          dense
          :disabled="props.item.disabled"
        >
        </v-select>
      </template>
      <template slot="item.adv2b" slot-scope="props">
        <v-file-input
          v-model="props.item.adv2b"
          prepend-icon=""
          class="faux-upload"
          :ref="`upload-2B-${props.index}`"
          hide-details="auto"
          accept="application/pdf"
          :clearable="false"
          @change="upload2B(props.item)"
          v-if="props.item.selected"
        >
          <template v-slot:prepend-inner>
            <a
              class="btn mini upload-2b"
              style="display: block"
              @click="
                () => $refs[`upload-2B-${props.index}`].$refs.input.click()
              "
            >
              <v-icon small class="pdf"> fas fa-file-pdf </v-icon>
              Upload ADV 2B
            </a>
          </template>
        </v-file-input>
        <div v-else>{{ NO_DATE }}</div>
      </template>
      <div class="no-results" slot="no-results" :value="true">
        Your search/filter found no results.
      </div>
      <div class="no-data" slot="no-data" :value="true">
        There are either no Redtail users to add, or all Redtail users have been
        added to OnBord.
      </div>
      <div class="no-results" slot="loading" :value="tableLoading">
        Finding Redtail Users...
      </div>
      <div
        slot="footer"
        :value="true"
        class="pt-3 d-flex flex-row justify-space-between align-center"
      >
        <div class="btn secondary" @click="cancel()">Cancel</div>
        <div
          class="btn mini"
          :class="hasSelectedUsers ? '' : 'not-allowed'"
          @click="addSelected()"
        >
          Add Selected
        </div>
      </div>
    </v-data-table>
  </v-layout>
</template>

<script>
import {
  MENUS,
  USER_ROLES,
  NO_DATE,
  validatePhoneLocal,
} from '@/common/constants';
import { validationMixin } from 'vuelidate';
import { requiredIf, email } from 'vuelidate/lib/validators';
import Themable from '@/common/mixins/themable.mixin';
import { PermissionService, PERMISSIONS } from '@/services/permission.service';
import { GET_CRM_USERS, ADD_USER } from '@/stores/actions.type';
import {
  NOTIFICATION_TYPES,
  NotificationService,
} from '@/services/notification.service';
import { formatPhone } from '../../../common/constants';

export default {
  name: 'AddUsers',

  components: {},
  mixins: [validationMixin, Themable],

  data: () => ({
    users: [],
    tableLoading: false,
  }),
  validations() {
    return {
      users: {
        $each: {
          email: {
            required: requiredIf((user) => user.selected),
            email,
          },
          phone: {
            required: requiredIf((user) => user.selected),
            validatePhoneLocal,
          },
        },
      },
      validationGroup: ['users'],
    };
  },
  computed: {
    headers() {
      return [
        {
          text: '',
          align: 'left',
          value: 'selected',
          width: '40px',
        },
        {
          text: 'User',
          align: 'left',
          value: 'name',
          width: '25%',
        },
        {
          text: 'Email',
          align: 'left',
          value: 'email',
          width: '25%',
        },
        {
          text: 'Mobile',
          align: 'left',
          value: 'phone',
          width: '25%',
        },
        {
          text: 'Role',
          align: 'left',
          value: 'role',
          width: '25%',
        },
        {
          text: 'ADV 2B',
          align: 'center',
          value: 'adv2b',
        },
      ];
    },
    hasSelectedUsers() {
      return this.users.find((u) => u.selected);
    },
  },
  methods: {
    emailAddressErrors(idx) {
      const errors = [];
      if (!this.$v.users.$each[idx].email.$dirty) return errors;
      !this.$v.users.$each[idx].email.required &&
        errors.push('Email is required');
      !this.$v.users.$each[idx].email.email &&
        errors.push('Invalid email address');
      return errors;
    },
    phoneNumberErrors(idx) {
      const errors = [];
      if (!this.$v.users.$each[idx].phone.$dirty) return errors;

      !this.$v.users.$each[idx].phone.required &&
        errors.push('Phone Number is required');
      !this.$v.users.$each[idx].phone.validatePhoneLocal &&
        errors.push('Phone number must in US format: e.g., 123-456-7890');
      return errors;
    },

    /* Upload 2B form */
    upload2B() {},

    cancel() {
      this.$router.push({ name: MENUS.USERS.id });
    },
    addSelected() {
      if (!this.hasSelectedUsers) return;

      this.$v.$reset();
      this.$v.$touch();
      if (this.$v.$invalid) return;

      let hasErroredUsers = false;

      const addPromises = [];
      this.users.forEach((u) => {
        if (u.selected) {
          u.phone = formatPhone(u.phone);
          addPromises.push(
            new Promise((resolve) => {
              this.$store
                .dispatch(ADD_USER, u)
                .catch(() => {
                  hasErroredUsers = true;
                })
                .finally(() => resolve());
            }),
          );
        }
      });

      Promise.all(addPromises).finally(() => {
        if (hasErroredUsers) {
          NotificationService.alert({
            type: NOTIFICATION_TYPES.ERROR,
            title: 'Error',
            message: `Some Users were unable to be created! It's possible a user with that email already exists in OnBord.<br>
                Any users created should receive an email invitation to log in.`,
            okTitle: 'Ok',
            okMethod: () => this.getRedtailUsers(),
          });
        } else {
          NotificationService.alert({
            type: NOTIFICATION_TYPES.SUCCESS,
            title: 'Success',
            message: `All selected Users successfully created!<br>
                All users should receive an email invitation to log in.`,
            okTitle: 'Ok',
            okMethod: () => this.getRedtailUsers(),
          });
        }
      });
    },
    getRedtailUsers() {
      this.tableLoading = true;
      this.$store
        .dispatch(GET_CRM_USERS)
        .then((response) => {
          response.forEach((u) => {
            (u.name = `${u.firstName} ${u.lastName}`),
              (u.selected = false),
              (u.role = USER_ROLES.REP.id),
              (u.disabled = u.isAlreadyOnbord);
          });
          this.users = response;
        })
        .catch((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: 'Error getting users from Redtail!',
              okTitle: 'Ok',
            },
            response,
          );
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
  },
  created() {
    this.NO_DATE = NO_DATE;
    const temp = [];
    Object.keys(USER_ROLES).map((key) => {
      temp.push(USER_ROLES[key]);
    });
    Object.keys(USER_ROLES).map((key) => {
      temp.push(USER_ROLES[key]);
    });
    // Filter product admin role id user cannot see them
    const canAddProductAdmin = PermissionService.hasPermission(
      PERMISSIONS.USER.ADD_PRODUCT_ADMIN,
    );
    this.roles = temp.filter(
      (r) => canAddProductAdmin || r.id !== USER_ROLES.PRODUCT_ADMIN.id,
    );

    this.getRedtailUsers();
  },
};
</script>
<style lang="scss" scoped>
h1 {
  font-size: 26px;
}
.btn.mini {
  max-width: 150px;
  display: inline-block;
}
::v-deep {
  .user-table tbody > tr > td {
    vertical-align: baseline;
  }
  .user-table tbody > tr:has(*:disabled) {
    color: var(--cb-color);
  }
  .v-data-table-header-mobile {
    display: none;
  }
  .v-data-table__mobile-table-row {
    display: inline-block !important;
    border: none;
    margin-bottom: 4px;
  }
  .v-data-table__mobile-table-row > .v-data-table__mobile-row {
    justify-content: start;
    padding: 4px 16px !important;
    border-top: none;
    border-bottom: none;

    .v-data-table__mobile-row__header {
      font-weight: 400;

      &:after {
        content: 'Select:';
      }
    }
    .v-data-table__mobile-row__cell {
      font-weight: 500;
    }
  }
  .v-data-table__mobile-table-row td:first-of-type {
    border-top: 1px solid var(--secondary-color);
  }
  .v-data-table__mobile-table-row td:last-of-type {
    border-bottom: 1px solid var(--secondary-color);
  }
  .v-data-table__mobile-table-row
    > .v-data-table__mobile-row
    ~ .v-data-table__mobile-row {
    .v-data-table__mobile-row__header {
      &:after {
        content: ':';
      }
    }
  }
  .faux-upload {
    display: inline-block;
    padding: 0px;

    .upload-2b {
      color: var(--color-white) !important;
      text-decoration: none;
      max-width: 160px;
    }

    .pdf {
      margin-right: 8px;
      color: #fff;
    }
    .v-input__slot {
      display: block;
      &:before,
      &:after {
        border-color: transparent !important;
        text-decoration-color: transparent !important;
        background-color: transparent;
      }

      .v-input__append-inner {
        width: 0px;
      }
      .v-text-field__slot {
        //display: none;
        font-size: 12px;
      }
    }
  }
}
</style>
