<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      width="430"
      :overlay-opacity="0.8"
      :overlay-color="overlayColor"
      content-class="plain-dialog"
    >
      <v-card flat>
        <v-card-text class="pa-7">
          <div class="modal-title">
            Are you ready to save the changes for this transition?
          </div>
          <div class="modal-actions">
            <v-row class="justify-center">
              <v-col class="step-form-container radio-container">
                <a @click="saveTransition()" class="btn mt-3"
                  >Save and Complete</a
                >
                <a @click="cancel()" class="btn secondary mt-3">Cancel</a>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-form>
      <v-container class="steps-step-container">
        <v-row class="mb-8">
          <v-col class="instructions">
            <h1>
              <span class="headerlight">
                Congrats! Let's <strong>review &amp; finish.</strong>
              </span>
            </h1>
          </v-col>
        </v-row>
        <div class="justify-center steps-table">
          <v-row class="section-row editable">
            <v-col
              class="col-4 step-col"
              :class="isPrimaryContactComplete ? '' : 'error'"
            >
              <span class="error-icon" v-if="!isPrimaryContactComplete">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-on="on" v-bind="attrs">
                      <v-icon small :color="errorColor">
                        fas fa-exclamation-triangle
                      </v-icon>
                    </span>
                  </template>
                  <span>This section is incomplete!</span>
                </v-tooltip>
              </span>
              <span class="section-name"> Primary Contact </span>
            </v-col>

            <v-col class="col-8 data-col">
              <div v-if="editMode.primaryContact === false" class="val">
                <div>
                  <span class="detail-header"> Name: </span>
                  <span class="detail-item">
                    {{ transition.primaryContact.firstName }}
                    {{ transition.primaryContact.lastName }}
                  </span>
                </div>

                <div>
                  <span class="detail-header">Phone: </span>
                  <span class="detail-item">
                    {{ formatPhoneNumber(transition.primaryContact.mobile) }}
                  </span>
                </div>
                <div>
                  <span class="detail-header">Email: </span>
                  <span class="detail-item">
                    {{ transition.primaryContact.email }}
                  </span>
                </div>

                <div
                  class="edit"
                  @click="editMode.primaryContact = !editMode.primaryContact"
                >
                  <i class="fas fa-pencil"></i>
                </div>
              </div>
              <div v-else>
                <v-text-field
                  v-model="transition.primaryContact.firstName"
                  label="First Name"
                  single-line
                  dense
                ></v-text-field>
                <v-text-field
                  v-model="transition.primaryContact.lastName"
                  label="Last Name"
                  single-line
                  dense
                ></v-text-field>
                <v-text-field
                  v-model="transition.primaryContact.mobile"
                  label="Phone"
                  single-line
                  dense
                ></v-text-field>
                <v-text-field
                  v-model="transition.primaryContact.email"
                  label="Email"
                  single-line
                  dense
                ></v-text-field>

                <div
                  class="edit"
                  @click="editMode.primaryContact = !editMode.primaryContact"
                >
                  <i class="fas fa-times"></i>
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row class="section-row editable" v-if="hasSecond()">
            <v-col
              class="col-4 step-col"
              :class="isSecondaryContactComplete ? '' : 'error'"
            >
              <span class="error-icon" v-if="!isSecondaryContactComplete">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-on="on" v-bind="attrs">
                      <v-icon small :color="errorColor">
                        fas fa-exclamation-triangle
                      </v-icon>
                    </span>
                  </template>
                  <span>This section is incomplete!</span>
                </v-tooltip>
              </span>
              <span class="section-name"> Secondary Contact </span>
            </v-col>

            <v-col class="col-8 data-col">
              <div v-if="editMode.secondaryContact">
                <v-text-field
                  v-model="transition.secondaryContact.firstName"
                  label="First Name"
                  single-line
                  dense
                ></v-text-field>
                <v-text-field
                  v-model="transition.secondaryContact.lastName"
                  label="Last Name"
                  single-line
                  dense
                ></v-text-field>
                <v-text-field
                  v-model="transition.secondaryContact.mobile"
                  label="Phone"
                  single-line
                  dense
                ></v-text-field>
                <v-text-field
                  v-model="transition.secondaryContact.email"
                  label="Email"
                  single-line
                  dense
                ></v-text-field>
                <v-text-field
                  v-model="transition.secondaryContact.address"
                  label="Address"
                  single-line
                  dense
                ></v-text-field>

                <div
                  class="edit"
                  @click="
                    editMode.secondaryContact = !editMode.secondaryContact
                  "
                >
                  <i class="fas fa-times"></i>
                </div>
              </div>

              <div v-else class="val">
                <div>
                  <span class="detail-header"> Name: </span>
                  <span class="detail-item">
                    {{ transition.secondaryContact.firstName }}
                    {{ transition.secondaryContact.lastName }}
                  </span>
                </div>
                <div>
                  <span class="detail-header">Phone: </span>
                  <span class="detail-item">
                    {{ formatPhoneNumber(transition.secondaryContact.mobile) }}
                  </span>
                </div>
                <div>
                  <span class="detail-header">Email: </span>
                  <span class="detail-item">
                    {{ transition.secondaryContact.email }}
                  </span>
                </div>

                <div
                  class="edit"
                  @click="
                    editMode.secondaryContact = !editMode.secondaryContact
                  "
                >
                  <i class="fas fa-pencil"></i>
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row class="section-row editable">
            <v-col
              class="col-4 step-col first"
              :class="isFirmRolesComplete ? '' : 'error'"
            >
              <span class="error-icon" v-if="!isFirmRolesComplete">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-on="on" v-bind="attrs">
                      <v-icon small :color="errorColor">
                        fas fa-exclamation-triangle
                      </v-icon>
                    </span>
                  </template>
                  <span>This section is incomplete!</span>
                </v-tooltip>
              </span>
              <span class="section-name"> Firm Roles </span>
            </v-col>
            <v-col class="col-8 d-flex data-col">
              <div v-if="!editMode.firmRoles" class="val">
                <div>
                  <div class="detail-header">Primary Advisor</div>
                  <div class="detail-listitem">
                    {{
                      transition.firmRoles &&
                      transition.firmRoles.primaryAdvisor
                        ? transition.firmRoles.primaryAdvisor.personalInfo
                            .firstName +
                          ' ' +
                          transition.firmRoles.primaryAdvisor.personalInfo
                            .lastName
                        : '&lt;none&gt;'
                    }}
                  </div>
                </div>
                <div>
                  <div class="detail-header">Primary CSA</div>
                  <div class="detail-listitem">
                    {{
                      transition.firmRoles && transition.firmRoles.primaryCSA
                        ? transition.firmRoles.primaryCSA.personalInfo
                            .firstName +
                          ' ' +
                          transition.firmRoles.primaryCSA.personalInfo.lastName
                        : '&lt;none&gt;'
                    }}
                  </div>
                </div>
                <div
                  class="edit"
                  @click="editMode.firmRoles = !editMode.firmRoles"
                >
                  <i class="fas fa-pencil"></i>
                </div>
              </div>
              <div v-else>
                <v-select
                  hide-details="auto"
                  :items="advisorsList"
                  v-model="transition.firmRoles.primaryAdvisor.name"
                  item-text="name"
                  label="Select Advisor"
                  :menu-props="{ offsetY: true }"
                  outlined
                ></v-select>

                <br />

                <v-select
                  hide-details="auto"
                  :items="csaList"
                  v-model="transition.firmRoles.primaryCSA.name"
                  item-text="name"
                  :value="transition.firmRoles.primaryCSA"
                  label="Select CSA"
                  :menu-props="{ offsetY: true }"
                  outlined
                ></v-select>

                <div
                  class="edit"
                  @click="editMode.firmRoles = !editMode.firmRoles"
                >
                  <i class="fas fa-times"></i>
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row class="section-row editable">
            <v-col
              class="col-4 step-col"
              :class="isAssetsValueComplete ? '' : 'error'"
            >
              <span class="error-icon" v-if="!isAssetsValueComplete">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-on="on" v-bind="attrs">
                      <v-icon small :color="errorColor">
                        fas fa-exclamation-triangle
                      </v-icon>
                    </span>
                  </template>
                  <span>This section is incomplete!</span>
                </v-tooltip>
              </span>
              <span class="section-name"> Assets value </span>
            </v-col>

            <v-col class="col-8 data-col">
              <div v-if="editMode.assetsValue">
                <v-text-field
                  v-model="formattedAssetsValue"
                  single-line
                  dense
                ></v-text-field>
                <div
                  class="edit"
                  @click="editMode.assetsValue = !editMode.assetsValue"
                >
                  <i class="fas fa-times"></i>
                </div>
              </div>

              <div v-else class="val">
                {{ formattedAssetsValue }}

                <div
                  class="edit"
                  @click="editMode.assetsValue = !editMode.assetsValue"
                >
                  <i class="fas fa-pencil"></i>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-form>
    <bottom-nav
      v-on:go-prev="prev()"
      v-on:go-next="finish()"
      :nextText="nextButtonText"
    ></bottom-nav>
  </div>
</template>

<script>
import BottomNav from '@/components/common/BottomNav';
import {
  NOTIFICATION_TYPES,
  NotificationService,
} from '@/services/notification.service';
import { MENUS } from '@/common/constants';
import { GET_TEAM_USERS, EDIT_TRANSITION } from '@/stores/actions.type';
import { mapGetters } from 'vuex';
import Themable from '@/common/mixins/themable.mixin';

export default {
  name: 'EditTransition',

  mixins: [Themable],

  components: { BottomNav },

  data: () => ({
    nextButtonText: 'Save',
    editMode: {
      assetsValue: false,
      primaryContact: false,
      secondaryContact: false,
      firmRoles: false,
    },
    teamUsers: [],
    transition: {},
    MENUS: MENUS,
    dialog: false,
    saveDecision: null,
  }),
  validations() {
    return {};
  },

  computed: {
    isFirmRolesComplete() {
      const firmRolesComplete =
        !!this.transition.firmRoles &&
        !!this.transition.firmRoles.primaryAdvisor &&
        !!this.transition.firmRoles.primaryCSA;
      return firmRolesComplete;
    },
    isSecondaryContactComplete() {
      var dataComplete =
        !!this.transition.secondaryContact.firstName &&
        !!this.transition.secondaryContact.lastName &&
        !!this.transition.secondaryContact.mobile;
      this.transition.transitionType === 'Protocol'
        ? (dataComplete =
            dataComplete && !!this.transition.secondaryContact.email)
        : dataComplete;
      return dataComplete;
    },
    isPrimaryContactComplete() {
      var dataComplete =
        !!this.transition.primaryContact.firstName &&
        !!this.transition.primaryContact.lastName &&
        !!this.transition.primaryContact.mobile;

      this.transition.transitionType === 'Protocol'
        ? (dataComplete =
            dataComplete && !!this.transition.primaryContact.email)
        : dataComplete;
      return dataComplete;
    },
    isAssetsValueComplete() {
      return !!this.transition.assetsValue;
    },

    areAllStepsComplete() {
      const nonProtocolSteps =
        this.isPrimaryContactComplete &&
        this.isAssetsValueComplete &&
        this.isFirmRolesComplete;

      return this.hasSecond()
        ? nonProtocolSteps && this.isSecondaryContactComplete
        : nonProtocolSteps;
    },
    advisorsList() {
      return this.teamUsers
        .filter((user) => user.setupComplete && user.hasADV2B)
        .map((user) => user.name);
    },
    csaList() {
      return this.teamUsers
        .filter((user) => user.setupComplete)
        .map((user) => user.name);
    },
    ...mapGetters(['transitioningHousehold']),

    finishErrors() {
      return this.getSaveMessage();
    },

    formattedAssetsValue: {
      get() {
        return this.transition.assetsValue
          ? this.transition.assetsValue.toLocaleString()
          : '';
      },
      set(newValue) {
        this.transition.assetsValue = parseInt(newValue.replace(/,/g, ''));
      },
    },
  },

  methods: {
    formatPhoneNumber(phoneNumber) {
      return (
        phoneNumber && phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
      );
    },
    getSaveMessage() {
      const incompleteClientTwo =
        this.transition.addSecond === 'Y' &&
        !(
          this.transition.secondaryContact.firstName &&
          this.transition.secondaryContact.lastName
        );
      const incompleteClientOne = !(
        this.transition.primaryContact.firstName &&
        this.transition.primaryContact.lastName
      );
      let canSaveMsg =
        incompleteClientOne || incompleteClientTwo
          ? 'You must complete all client names before saving!'
          : null;
      return canSaveMsg;
    },
    combineNames(person) {
      return person && person.personalInfo
        ? `${person.personalInfo.firstName} ${person.personalInfo.lastName}`
        : '';
    },

    initializeFirmRoles() {
      this.$store
        .dispatch(GET_TEAM_USERS)
        .then((response) => {
          response.forEach((u) => {
            u.name = `${u.firstName} ${u.lastName}`;
          });
          this.teamUsers = response;
        })
        .catch((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: `Error getting users for team selection.`,
              okTitle: 'Ok',
            },
            response,
          );
        });
    },

    hasSecond() {
      return (
        !!this.transition.secondaryContact &&
        Object.keys(this.transition.secondaryContact).length > 0
      );
    },

    /* Cancel the save confirmation */
    cancel() {
      this.dialog = false;
      this.saveDecision = null;
    },

    /* Save a working client that has no incomplete data */
    finish() {
      if (this.finishErrors) {
        NotificationService.alert({
          type: NOTIFICATION_TYPES.ERROR,
          title: 'Error',
          message: this.finishErrors,
          okTitle: 'Ok',
        });
      } else {
        if (this.areAllStepsComplete) {
          this.dialog = true;
        } else {
          const payload = this.mapTransitionPayload();
          this.saveTransition(payload);
        }
      }
    },
    mapTransitionPayload() {
      return {
        assetsValue: this.transition.assetsValue,
        primaryContact: {
          firstName: this.transition.primaryContact.firstName,
          lastName: this.transition.primaryContact.lastName,
          email: this.transition.primaryContact.email,
          mobile: this.transition.primaryContact.mobile,
        },
        secondaryContact: this.transition.secondaryContact
          ? {
              firstName: this.transition.secondaryContact.firstName,
              lastName: this.transition.secondaryContact.lastName,
              email: this.transition.secondaryContact.email,
              mobile: this.transition.secondaryContact.mobile,
            }
          : null,

        primaryAdvisor: this.transition.firmRoles.primaryAdvisor._id,
        primaryCSA: this.transition.firmRoles.primaryCSA._id,
        transitionType: this.transition.transitionType,
        id: this.transition.id,
      };
    },
    saveTransition() {
      const payload = this.mapTransitionPayload();
      this.$store
        .dispatch(EDIT_TRANSITION, payload)
        .then(() => {
          NotificationService.alert({
            type: NOTIFICATION_TYPES.SUCCESS,
            title: 'Success',
            message: 'Transition saved successfully.',
            okTitle: 'Ok',
          });
          this.$router.push({ name: MENUS.CLIENTS.id });
        })
        .catch((error) => {
          this.dialog = false;
          NotificationService.alert({
            type: NOTIFICATION_TYPES.ERROR,
            title: 'Error',
            message: error.response.data.message.message[0],
            okTitle: 'Ok',
          });
        });
    },
    /* Nav to Previous Page in flow */
    prev() {
      this.$router.push({
        name: MENUS.CLIENTS.id,
      });
    },
  },

  created() {
    this.initializeFirmRoles();

    this.transition = JSON.parse(JSON.stringify(this.transitioningHousehold));
    this.transition.firmRoles.primaryAdvisor.name = this.combineNames(
      this.transition.firmRoles.primaryAdvisor,
    );
    this.transition.firmRoles.primaryCSA.name = this.combineNames(
      this.transition.firmRoles.primaryCSA,
    );
  },
};
</script>

<style lang="scss" scoped>
.steps-table {
  width: 100%;
  max-width: 100%;
  border: 2px solid var(--secondary-color);
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  .row {
    margin: 0;

    &.section-row {
      border-top: 2px solid var(--secondary-color);
    }
    &.editable {
      position: relative;
    }

    .edit {
      border: none !important;
      position: absolute;
      right: 8px;
      top: 20px;
      height: 16px;
      width: 16px;
      background-size: contain;
      background-repeat: no-repeat;
      cursor: pointer;
      color: var(--primary-color);
      &:hover {
        color: var(--primary-color);
      }
    }

    .col {
      padding: 20px 26px;
      border-top: none;

      &.data-col {
        border-left: 2px solid var(--secondary-color);
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 960px) {
          border-left: none;
        }

        .val {
          border: none !important;
          font-weight: 500;
        }

        .button-area {
          margin-top: 20px;
          margin-bottom: 5px;

          .btn {
            margin-right: 15px;
          }
        }
      }

      &.step-col {
        background-color: var(--color-light-grey);

        @media screen and (max-width: 960px) {
          background-color: transparent;
          font-size: 18px;
          font-weight: 500;
          padding-bottom: 0px;
        }

        .error-icon {
          margin-right: 5px;
          display: inline-block;
        }
        .section-name {
          display: inline-block;
        }
        &.error {
          .section-name {
            vertical-align: text-top;
          }
        }
      }

      @media screen and (max-width: 960px) {
        min-width: 100%;
      }

      .mobile-label {
        display: none;
        @media screen and (max-width: 960px) {
          display: inline-block;
        }
      }
    }

    .data-area {
      flex-direction: column;
      padding-top: 20px;

      .col {
        padding: 0 8px 0 0;
      }
    }

    .detail-header {
      margin-top: 8px;
    }
    .detail-subheader {
      font-size: 16px;
      font-weight: 400;

      &.small {
        font-weight: 500;
        font-size: 14px;
      }
    }
    .detail-item {
      font-size: 14px;
      font-weight: 400;

      .missing {
        color: var(--error-color);
      }
    }
    .detail-listitem {
      font-size: 14px;
      font-weight: 400;

      &:before {
        content: '\2022';
        margin-left: 5px;
        width: 10px;
        color: var(--color-text);
        font-size: 20px;
        vertical-align: middle;
      }
    }
  }
}
.steps-step-container {
  max-width: 1024px;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 6%;
}
</style>
