<template>
  <v-navigation-drawer
    class="help-drawer"
    v-model="drawer"
    right
    temporary
    stateless
    id="nav-drawer"
    :overlay-color="overlayColor"
    overlay-opacity="0.8"
  >
    <div class="d-flex justify-end">
      <a class="close" @click="drawer = false">
        <i class="fas fa-times"></i>
      </a>
    </div>
    <h4 class="help-title">{{ title }}</h4>
    <div class="help-body-wrapper">
      <slot></slot>
    </div>
  </v-navigation-drawer>
</template>
<script>
import Themable from '@/common/mixins/themable.mixin';
import EventListener from '@/common/mixins/eventlistener.mixin';

export default {
  name: 'HelpDrawer',
  mixins: [Themable, EventListener],
  props: {
    title: {
      type: String,
      default: 'Help Text',
    },
  },

  data: () => ({
    drawer: false,
  }),

  computed: {},
  methods: {},

  created() {
    // Listen for event to toggle the drawer
    this.listen('toggle-help', () => {
      this.drawer = true;
    });
  },
};
</script>
<style lang="scss" scoped>
.help-drawer {
  top: 0;
  position: fixed;
  z-index: 2000 !important;
  width: 400px !important;
  padding: 30px;
  max-width: 90vw !important;

  .help-title {
    margin-bottom: 15px;
    padding-bottom: 15px;
    padding-top: 15px;
    border-bottom: 1px solid var(--secondary-color);
  }

  .help-body-wrapper {
    line-height: 24px;

    ::v-deep .small {
      font-size: 16px;
      line-height: 18px;

      ol > li {
        display: list-item;
      }
    }
  }
}
.close {
  padding: 0px;
  font-size: 24px;

  &:hover {
    border-bottom: none !important;
  }
}
</style>
