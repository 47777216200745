<template>
  <div class="step-container client">
    <help-drawer :title="helpTitle">
      <div>
        <p>
          You may want to provide an additional phone number as a secondary form
          of communication (home, work, other)
        </p>
      </div>
    </help-drawer>
    <v-form name="AlternatePhone" class="form">
      <v-container class="client-step-container">
        <v-row class="mb-8">
          <v-col class="instructions">
            <h1>
              <span class="headerlight">
                Your mobile phone is
                <strong> {{ formattedPrimaryPhone }} </strong>. Would you like
                to add another?
              </span>
            </h1>
            <div class="help-link">
              <a @click="toggleHelp()">{{ helpTitle }}</a>
            </div>
          </v-col>
        </v-row>
        <data-submitted-message
          :submitted="isAlternatePhoneComplete"
          v-on:hide-form="toggleFormState"
        />
        <div v-if="!formHidden">
          <v-row class="justify-center">
            <v-col class="step-form-container radio-container mb-4">
              <v-radio-group
                v-model="hasAlternatePhone"
                :error-messages="alternatePhoneErrors"
                hide-details="auto"
                @change="$v.hasAlternatePhone.$touch()"
              >
                <v-radio
                  :ripple="false"
                  class="v-faux-radio mb-4"
                  label="Yes"
                  :value="true"
                ></v-radio>

                <div class="expander" v-show="hasAlternatePhone">
                  <div
                    v-for="(phone, index) in alternatePhones"
                    :key="phone"
                    class="phone-block"
                  >
                    <div class="remove-area">
                      <span @click="removeAlternatePhone(index)">
                        <v-icon small :color="textColor"> fas fa-trash </v-icon>
                      </span>
                    </div>
                    <div class="faux-label">Phone Type</div>
                    <div>
                      <v-select
                        v-model="phone.phoneType"
                        :items="phoneTypes"
                        :backgroundColor="inputBackgroundColor"
                        outlined
                        placeholder="Choose"
                        dense
                        hide-details="auto"
                        @change="
                          $v.alternatePhones.$each[index].phoneType.$touch()
                        "
                        :error-messages="phoneTypeErrors(index)"
                        class="mb-2"
                      >
                      </v-select>
                      <label for="phone">Phone</label>
                      <v-text-field
                        outlined
                        type="text"
                        maxlength="256"
                        name="phone"
                        v-model.trim="phone.phoneNumber"
                        :backgroundColor="inputBackgroundColor"
                        @blur="
                          $v.alternatePhones.$each[index].phoneNumber.$touch()
                        "
                        :error-messages="phoneNumberErrors(index)"
                        hide-details="auto"
                        inputmode="numeric"
                      />
                    </div>
                  </div>
                  <a
                    v-if="alternatePhones.length < 3"
                    @click="addAlternatePhone"
                  >
                    <i class="fas fa-plus"></i> Add another alternate phone
                  </a>
                </div>
                <v-radio
                  :ripple="false"
                  class="v-faux-radio"
                  label="No"
                  :value="false"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <div class="skip-for-now">
            <a @click="skip()">Skip for now</a>
          </div>
        </div>
      </v-container>
    </v-form>
    <bottom-nav v-on:go-prev="prev()" v-on:go-next="next()"></bottom-nav>
  </div>
</template>

<script>
import HelpDrawer from '@/components/HelpDrawerComponent';
import BottomNav from '@/components/common/BottomNav';
import DataSubmittedMessage from '@/components/client/DataSubmittedMessage';
import InterviewClientMixin from '@/common/mixins/interview.client.mixin';
import { required, requiredIf } from 'vuelidate/lib/validators';
import {
  MENUS,
  ALTERNATE_PHONE_TYPES,
  formatPhone,
  validatePhoneLocal,
  formatPhoneLocal,
} from '@/common/constants';

// Custom phone type dup validator
const duplicateType = (phones) => (value) => {
  const typeCount = phones.filter((f) => f.phoneType === value);
  return typeCount && typeCount.length === 1;
};

// Custom phone number dup validator
const duplicatePhone = (phones, primary) => (value) => {
  const phoneCount = phones.filter((f) => f.phoneNumber === value);
  return phoneCount && phoneCount.length === 1 && value !== primary;
};

export default {
  name: 'AlternatePhone',

  components: { BottomNav, HelpDrawer, DataSubmittedMessage },
  mixins: [InterviewClientMixin],

  data: () => ({
    hasAlternatePhone: null,
    alternatePhones: [
      {
        phoneType: null,
        phoneNumber: null,
      },
    ],
  }),

  validations() {
    return {
      hasAlternatePhone: {
        required,
      },
      alternatePhones: {
        $each: {
          phoneType: {
            required: requiredIf(() => this.hasAlternatePhone),
            dups: duplicateType(this.alternatePhones),
          },
          phoneNumber: {
            required: requiredIf(() => this.hasAlternatePhone),
            validatePhoneLocal,
            dups: duplicatePhone(this.alternatePhones, this.primaryPhone),
          },
        },
      },
      validationGroup: ['hasAlternatePhone', 'alternatePhones'],
    };
  },

  computed: {
    formattedPrimaryPhone() {
      return formatPhoneLocal(this.primaryPhone);
    },
    primaryPhone() {
      return this.clientData.primaryPhone;
    },
    helpTitle() {
      return 'Do I need another one?';
    },
  },

  methods: {
    /* Validatons for a phone instance type */
    phoneTypeErrors(idx) {
      const errors = [];
      if (!this.$v.alternatePhones.$each[idx].phoneType.$dirty) return errors;
      !this.$v.alternatePhones.$each[idx].phoneType.required &&
        errors.push('A selection is required');
      !this.$v.alternatePhones.$each[idx].phoneType.dups &&
        errors.push('Duplicate phone type');
      return errors;
    },

    /* Validatons for a phone instance number */
    phoneNumberErrors(idx) {
      const errors = [];
      if (!this.$v.alternatePhones.$each[idx].phoneNumber.$dirty) return errors;
      !this.$v.alternatePhones.$each[idx].phoneNumber.required &&
        errors.push('Phone Number is required');
      !this.$v.alternatePhones.$each[idx].phoneNumber.validatePhoneLocal &&
        errors.push('Invalid Phone');
      !this.$v.alternatePhones.$each[idx].phoneNumber.dups &&
        errors.push('Phone Number must be unique');
      return errors;
    },

    /* Add an Alternate phone instance */
    addAlternatePhone() {
      this.alternatePhones.push({
        phoneType: null,
        phoneNumber: null,
      });
    },

    /* Remove a specific Alternate phone instance */
    removeAlternatePhone(idx) {
      this.alternatePhones.splice(idx, 1);
    },

    /* Nav to Next Page in flow */
    next(route = null) {
      const to = route || MENUS.CLIENT.EMPLOYMENT_STATUS.id;

      if (!this.formHidden) {
        this.$v.$touch();
        if (this.$v.$invalid) return;
      } else {
        this.goNext(to);
        return;
      }

      const data = {};
      if (this.hasAlternatePhone) {
        data.alternatePhones = [];
        this.alternatePhones.forEach((p) => {
          data.alternatePhones.push({
            type: p.phoneType,
            number: formatPhone(p.phoneNumber),
          });
        });
      }

      this.completeStep(MENUS.CLIENT.ALTERNATE_PHONE.id, data)
        .then(() => {
          this.goNext(to);
        })
        .catch(() => {});
    },

    /* Nav to Previous Page in flow */
    prev() {
      this.goPrev(MENUS.CLIENT.DOB.id);
    },

    /* Skip to next Page in flow */
    skip() {
      this.goPrev(MENUS.CLIENT.EMPLOYMENT_STATUS.id);
    },
  },
  created() {
    // Initialize form data for current interview
    this.phoneTypes = ALTERNATE_PHONE_TYPES;
    this.formHidden = this.isAlternatePhoneComplete;
  },
};
</script>
<style lang="scss" scoped>
.expander {
  margin-bottom: 20px;

  .phone-block {
    padding-top: 10px;
    padding-bottom: 20px;
    border-top: 1px solid var(--secondary-color);
  }
  .remove-area {
    display: flex;
    justify-content: flex-end;
  }
}
.second-alternate {
  margin-top: 30px;
  padding-top: 15px;
  border-top: 1px solid var(--secondary-color);
}
</style>
