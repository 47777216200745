<template>
  <v-dialog
    v-model="guardDialog"
    persistent
    width="430"
    :overlay-opacity="0.8"
    :overlay-color="overlayColor"
    content-class="plain-dialog lg"
  >
    <v-card flat>
      <v-card-text class="pa-7">
        <div class="modal-title">{{ titleText }}</div>
        <div class="modal-actions">
          <a @click="save()" v-if="showSave" class="btn mt-3">
            {{ saveButtonText }}
          </a>
          <a
            @click="quit()"
            class="btn mt-3"
            :class="showSave ? 'secondary' : ''"
          >
            {{ quitButtonText }}</a
          >
          <a @click="cancel()" class="btn secondary mt-3">Cancel</a>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Themable from '@/common/mixins/themable.mixin';
import EventListener from '@/common/mixins/eventlistener.mixin';

export default {
  name: 'LeaveGuardComponent',

  mixins: [Themable, EventListener],

  components: {},

  data: () => ({
    guardDialog: false,
    exitFn: () => {},
    showSave: false,
    saveFn: () => {},
  }),

  computed: {
    inNewClientFlow() {
      return this.$route.path.includes('/advisor/');
    },
    saveButtonText() {
      return this.inNewClientFlow ? 'Store and Continue' : 'Save and Continue';
    },
    quitButtonText() {
      return this.inNewClientFlow
        ? 'Quit without Storing'
        : 'Quit without Saving';
    },
    titleText() {
      return this.inNewClientFlow
        ? 'You have unstored changes. Are you sure you want to Leave?'
        : 'You have unsaved changes. Are you sure you want to Leave?';
    },
  },

  methods: {
    cancel() {
      this.guardDialog = false;
    },

    quit() {
      this.guardDialog = false;
      this.exitFn();
    },
    save() {
      this.guardDialog = false;
      this.saveFn();
    },
  },

  created() {
    // Listen for event to display the dialog
    this.listen('display-leave-guard', (showSave, exitFn, saveFn) => {
      this.guardDialog = true;
      this.showSave = showSave;
      this.exitFn = exitFn;
      this.saveFn = saveFn;
    });
  },

  beforeDestroy() {
    this.guardDialog = false;
  },
};
</script>

<style lang="scss" scoped>
.modal-title {
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 28px;
  color: var(--color-text);
}
.btn {
  font-size: 16px;
}
</style>
