<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="700"
    scrollable
    :overlay-color="overlayColor"
    overlay-opacity="0.8"
    content-class="plain-dialog instructions-dialog"
  >
    <v-card flat>
      <v-card-text class="pa-7">
        <div class="modal-title">Add Company</div>
        <div class="modal-body">
          <v-row>
            <v-col>
              <label for="companyName" class="required"> Company Name </label>
              <div class="expander">
                <div class="faux-label">Document Type</div>
                <div>
                  <v-select
                    v-model="docusignDocumentType"
                    :items="
                      docusignDocumentTypes.map((item) =>
                        item.replace(
                          /(?<=[a-z])(?=[A-Z])|(?<=[A-Z])(?=[A-Z][a-z])/g,
                          ' ',
                        ),
                      )
                    "
                    :backgroundColor="inputBackgroundColor"
                    outlined
                    placeholder="Choose"
                    dense
                    hide-details="auto"
                    @change="$v.docusignDocumentTypes.$touch()"
                    :error-messages="docusignDocumentTypesErrors"
                    class="mb-2"
                  >
                  </v-select>
                </div>
              </div>
              <div>
                <input
                  class="label"
                  type="file"
                  @change="handleFileChange($event)"
                />
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="modal-actions">
          <a @click="upload()" class="btn mt-3"> Upload </a>
          <a @click="cancel()" class="btn secondary mt-3"> Cancel </a>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Themable from '@/common/mixins/themable.mixin';
import EventListener from '@/common/mixins/eventlistener.mixin';
import { validationMixin } from 'vuelidate';
import { DOCUSIGN_DOCUMENT_TYPES } from '@/common/constants';
import { UPSERT_DOCUSIGN_DOCUMENT } from '@/stores/actions.type';
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { EventService } from '../../../services/event.service';
import {
  NOTIFICATION_TYPES,
  NotificationService,
} from '@/services/notification.service';

/* Custom validator */

export default {
  name: 'AddDocument',

  mixins: [validationMixin, Themable, EventListener],

  components: {},

  data: () => ({
    docusignDocumentType: null,
    dialog: false,
  }),
  validations() {
    return {
      docusignDocumentType: {
        required,
      },
      validationGroup: ['docusignDocumentType'],
    };
  },

  computed: {
    ...mapGetters(['currentUser']),

    docusignDocumentTypesErrors() {
      const errors = [];

      if (!this.$v.docusignDocumentType.$dirty) {
        return errors;
      }

      if (!this.$v.docusignDocumentType.required) {
        errors.push('A selection is required');
      }

      return errors;
    },
  },

  methods: {
    /* Initialize form data */
    init() {
      this.$v.$reset();
      this.asset = {
        file: null,
        fileName: '',
      };
    },

    handleFileChange() {
      this.asset.file = event.target.files[0];
    },

    uploadDocument(asset) {
      NotificationService.confirm({
        type: NOTIFICATION_TYPES.WARNING,
        message: `Are you sure you want to update this file?`,
        okTitle: "Yes I'm sure",
        okMethod: () => {
          this.performDocumentUpload(asset);
        },
      });
    },

    performDocumentUpload(asset) {
      const action = UPSERT_DOCUSIGN_DOCUMENT;
      const payload = {
        file: asset.file,
        fileName: asset.fileName,
      };

      this.$store
        .dispatch(action, payload)
        .then((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.SUCCESS,
              title: 'Success',
              message: `File uploaded successfully.`,
              okTitle: 'Ok',
              okMethod: () => {
                this.dialog = false;
                EventService.emit('reload-asset-list');
              },
            },
            response,
          );
        })
        .catch((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: `Error changing asset status.`,
              okTitle: 'Ok',
            },
            response,
          );
        });
    },

    upload() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      this.asset.fileName = this.docusignDocumentType.replace(/\s/g, '');
      this.uploadDocument(this.asset);
    },

    /* Dismiss with no changes */
    cancel() {
      this.dialog = false;
    },
  },

  /* Lifecycle Hooks - copy payload from listened events */
  created() {
    this.docusignDocumentTypes = DOCUSIGN_DOCUMENT_TYPES;

    this.listen('display-add-document-modal', () => {
      this.dialog = true;
      this.init();
    });
  },
};
</script>

<style lang="scss" scoped>
.modal-title {
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 28px;
  color: var(--color-text);
}

.cb-label {
  margin-top: 5px;
}

.expander {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--secondary-color);

  &.empty {
    border-bottom: none;
  }
}

@media screen and (max-width: 960px) {
  .row.mobile {
    flex-direction: column;
  }
}
</style>
