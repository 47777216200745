import { organizationMapper } from './organizationMapper';

export function organizationsMapper(response = []) {
  return {
    result: response.result.map((company) => ({
      ...organizationMapper(company),
      totalIncompleteClients: company.totalIncompleteClients || 0,
      totalCompletedClients: company.totalCompletedClients || 0,
      totalAdvisors: company.totalAdvisors,
    })),
    totalResults: response.totalResults,
  };
}
