<template>
  <div class="alert" v-if="showAlert">
    <div class="alert-container">
      <span class="fa-icon left icon-span">
        <i class="fas fa-exclamation-triangle"></i>
      </span>
      You already submitted this question. If you choose to change your answers,
      your previous answers will be overwritten.
    </div>
    <a @click="toggleFormState()" class="btn mt-3">
      {{ buttonText }}
    </a>
  </div>
</template>
<script>
export default {
  props: {
    submitted: {
      type: Boolean,
      default: false,
    },
  },
  name: 'DataSubmittedMessage',
  data: () => ({
    formHidden: true,
    showAlert: false,
  }),

  computed: {
    buttonText() {
      return this.formHidden ? 'Change Answers' : "Don't Change Answers";
    },
  },
  methods: {
    /* Toggle the Form State */
    toggleFormState() {
      this.formHidden = !this.formHidden;
      this.$emit('hide-form', this.formHidden);
    },
  },
  created() {
    this.formHidden = this.submitted;
    this.showAlert = this.submitted;
  },
};
</script>
<style lang="scss" scoped>
.alert {
  flex-direction: column;
  text-align: center;
  align-items: center;

  .alert-container {
    margin-bottom: 10px;

    .icon-span {
      display: inline-block;
    }
  }
}
</style>
