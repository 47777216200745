<template>
  <div class="steps">
    <!-- Legal -->
    <div class="header-step-container">
      <a
        @click="go(MENUS.CLIENT.CITIZENSHIP.id)"
        class="step-icon"
        @mouseover="showLegalHover = true"
        @mouseout="showLegalHover = false"
        :class="legalData.iconClass"
      >
        <div class="step-icon-inner">
          <i class="fas fa-balance-scale"></i>
        </div>
      </a>
      <div
        class="step-hover-text"
        @click="go(MENUS.CLIENT.CITIZENSHIP.id)"
        :class="legalData.tooltipClass"
      >
        Legal Stuff
        <span class="completion-text"> ({{ legalData.stepsComplete }}/3) </span>
      </div>
    </div>

    <!-- Basic Info -->
    <div class="header-step-container">
      <a
        @click="go(MENUS.CLIENT.SSN.id)"
        class="step-icon"
        @mouseover="showBasicHover = true"
        @mouseout="showBasicHover = false"
        :class="basicData.iconClass"
      >
        <div class="step-icon-inner">
          <i class="fas fa-tasks"></i>
        </div>
      </a>
      <div
        class="step-hover-text"
        @click="go(MENUS.CLIENT.SSN.id)"
        :class="basicData.tooltipClass"
      >
        Basic Info
        <span class="completion-text"> ({{ basicData.stepsComplete }}/3) </span>
      </div>
    </div>

    <!-- Employment Info -->
    <div class="header-step-container">
      <a
        @click="go(MENUS.CLIENT.EMPLOYMENT_STATUS.id)"
        class="step-icon"
        @mouseover="showEmploymentHover = true"
        @mouseout="showEmploymentHover = false"
        :class="employmentData.iconClass"
      >
        <div class="step-icon-inner">
          <i class="fas fa-briefcase"></i>
        </div>
      </a>
      <div
        class="step-hover-text"
        @click="go(MENUS.CLIENT.EMPLOYMENT_STATUS.id)"
        :class="employmentData.tooltipClass"
      >
        Employment
        <span class="completion-text">
          ({{ employmentData.stepsComplete }}/{{ totalEmploymentSteps }})
        </span>
      </div>
    </div>

    <!-- Beneficiary Info -->
    <div class="header-step-container" v-if="canDesignateBeneficiaries">
      <a
        @click="go(firstBeneficiaryRoute)"
        class="step-icon"
        @mouseover="showBeneficiaryHover = true"
        @mouseout="showBeneficiaryHover = false"
        :class="beneficiaryData.iconClass"
      >
        <div class="step-icon-inner">
          <i class="fas fa-user-plus"></i>
        </div>
      </a>
      <div
        class="step-hover-text"
        @click="go(firstBeneficiaryRoute)"
        :class="beneficiaryData.tooltipClass"
      >
        Beneficiaries
        <span class="completion-text">
          ({{ beneficiaryData.stepsComplete }}/{{ totalBeneficiarySteps }})
        </span>
      </div>
    </div>

    <!-- Remaining Data -->
    <div class="header-step-container">
      <a
        @click="go(MENUS.CLIENT.FINISH.id)"
        class="step-icon"
        @mouseover="showRemainingHover = true"
        @mouseout="showRemainingHover = false"
        :class="remainingData.iconClass"
      >
        <div class="step-icon-inner">
          <i class="fas fa-clipboard-list"></i>
        </div>
      </a>
      <div
        class="step-hover-text"
        @click="go(MENUS.CLIENT.FINISH.id)"
        :class="remainingData.tooltipClass"
      >
        Remaining
        <span class="completion-text empty"> </span>
      </div>
    </div>
  </div>
</template>
<script>
import ClientMixin from '@/common/mixins/unauthenticated.client.mixin';
import { EventService } from '@/services/event.service';
import { MENUS } from '@/common/constants';

export default {
  name: 'MainMenu',

  mixins: [ClientMixin],

  components: {},

  data: () => ({
    MENUS: MENUS,
    showLegalHover: false,
    showBasicHover: false,
    showEmploymentHover: false,
    showBeneficiaryHover: false,
    showRemainingHover: false,
  }),

  computed: {
    legalData() {
      let d = {
        tooltipClass: this.showLegalHover ? 'show' : '',
        stepsComplete: this.legalCompletionSteps,
      };
      d.iconClass = this.isLegalComplete ? 'complete' : 'empty';
      if (this.isLegalRoute) d.iconClass += ' current';
      return d;
    },
    basicData() {
      let d = {
        tooltipClass: this.showBasicHover ? 'show' : '',
        stepsComplete: this.basicCompletionSteps,
      };
      d.iconClass = this.isBasicComplete ? 'complete' : 'empty';
      if (this.isBasicRoute) d.iconClass += ' current';
      return d;
    },
    employmentData() {
      let d = {
        tooltipClass: this.showEmploymentHover ? 'show' : '',
        stepsComplete: this.employmentCompletionSteps,
      };
      d.iconClass = this.isEmploymentComplete ? 'complete' : 'empty';
      if (this.isEmploymentRoute) d.iconClass += ' current';
      return d;
    },
    beneficiaryData() {
      let d = {
        tooltipClass: this.showBeneficiaryHover ? 'show' : '',
        stepsComplete: this.beneficiaryCompletionSteps,
      };
      d.iconClass = this.isBeneficiaryComplete ? 'complete' : 'empty';
      if (this.isBeneficiaryRoute) d.iconClass += ' current';
      return d;
    },
    remainingData() {
      let d = {
        tooltipClass: this.showRemainingHover ? 'show' : '',
        stepsComplete: this.remainingCompletionSteps,
      };
      d.iconClass = this.isRemainingComplete ? 'complete' : 'empty';
      if (this.isFinishRoute) d.iconClass += ' current';
      return d;
    },
  },

  methods: {
    // Emit event indicating navigation to a page
    go(route) {
      EventService.emit('navigate-to', route);
    },
  },

  created() {},
};
</script>

<style lang="scss" scoped>
.steps {
  z-index: 100000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1263px) {
    flex-direction: column;
    align-items: stretch;
  }
}
.header-step-container {
  position: relative;
  display: flex;
  margin-right: 10px;
  margin-left: 10px;
  flex-direction: column;
  align-items: center;
}

.step-icon {
  position: relative;
  z-index: 1;
  display: flex;
  width: 40px;
  height: 40px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--primary-color);
  transition: all 200ms ease;
  color: var(--color-text-dark);

  &.empty {
    background-color: var(--label-text);
  }

  &.complete {
    .step-icon-inner {
      background-color: var(--primary-color);
      color: var(--color-white);
    }
  }

  &.disabled {
    background-color: var(--secondary-color);
    cursor: not-allowed;

    .step-icon-inner {
      color: var(--secondary-color);
      background-color: var(--color-white);
    }
  }

  &.current {
    .step-icon-inner {
      color: var(--primary-color);
      background-color: var(--color-white);
    }
    background-color: var(--primary-color);
  }

  .step-icon-inner {
    position: absolute;
    left: auto;
    top: auto;
    right: auto;
    bottom: auto;
    z-index: 3;
    display: flex;
    width: 85%;
    height: 85%;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: var(--color-white);
    color: var(--label-text);
  }
}

.bullet-number {
  position: absolute;
  left: auto;
  top: 1px;
  right: 7px;
  bottom: auto;
  z-index: 4;
  font-family: Caveat, sans-serif;
  font-size: 12px;
  font-weight: 700;
}
.step-hover-text {
  position: absolute;
  left: auto;
  right: auto;
  bottom: -140%;
  display: flex;
  padding-top: 50px;
  flex-direction: column;
  font-family: Roboto, sans-serif;
  color: var(--color-text-dark);
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  opacity: 0;

  &.show {
    opacity: 1;
  }

  .completion-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    line-height: 12px;

    &.empty {
      min-height: 12px;
    }
  }
}

@media screen and (max-width: 1263px) {
  .step-hover-text {
    position: relative;
    bottom: auto;
    padding-top: 0px;
    font-size: 16px;
    opacity: 1;
  }
  .steps {
    flex-direction: column;
    align-items: stretch;
  }
  .header-step-container {
    display: flex;
    margin-bottom: 20px;
    flex-direction: row;
    align-items: center;
  }
  .step-icon {
    overflow: hidden;
    width: 50px;
    height: 50px;
    margin-right: 15px;
    margin-bottom: 0px;
    margin-left: 0px;
    font-size: 24px;
  }
  .completion-text {
    align-items: start !important;
    font-weight: 400;
  }
}
</style>
