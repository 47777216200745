<template>
  <div>
    <v-layout v-resize="onResize" column style="">
      <v-data-table
        :headers="headers"
        :items="companies"
        :items-per-page="5"
        :hide-default-header="isMobile"
        class="responsive-table company-table"
        :class="{ mobile: isMobile }"
        :loading="tableLoading"
        :page.sync="pagination.page"
        @pagination="getCompanies"
      >
        <template v-slot:top>
          <v-row>
            <v-col class="status-col">
              <v-select
                v-model="companyNameFilter"
                :items="companyOptions"
                :backgroundColor="inputBackgroundColor"
                outlined
                placeholder="Organization"
                ref="companyNameFilter"
                dense
              >
                <template v-slot:prepend-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        class="prepend"
                        @click="clearCompanyNameFilter()"
                      >
                        Clear Filter
                        <a class="close">
                          <div><i class="fas fa-times"></i></div>
                        </a>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <v-col class="calendar-col">
              <v-menu
                ref="calendarMenu"
                v-model="calendarMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on">Filter by date</v-btn>
                </template>

                <v-container>
                  <v-row justify="space-around">
                    <!-- <v-date-picker color="primary"></v-date-picker> -->
                    <v-date-picker
                      v-model="dateRange"
                      range
                      @change="handleDateChange"
                    ></v-date-picker>
                  </v-row>
                </v-container>
              </v-menu>
            </v-col>
          </v-row>
        </template>
        <template slot="item" slot-scope="props">
          <tr v-if="!isMobile">
            <td v-for="col in tableColumns" :key="col" :width="col.width">
              <div v-if="!col.isActionColumn">
                <span
                  :class="col.classFn(props.item)"
                  @click="col.clickFn ? col.clickFn(props.item) : () => {}"
                >
                  {{ col.valueFn(props.item) }}
                </span>
              </div>
              <div v-else class="action-column">
                <v-menu
                  left
                  nudge-left="15"
                  nudge-bottom="25"
                  v-if="canPerformActions(props.item)"
                  min-width="125"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span class="actions" v-bind="attrs" v-on="on">
                      <font-awesome-icon icon="ellipsis-h"></font-awesome-icon>
                    </span>
                  </template>
                  <v-list class="menu">
                    <div v-for="action in col.actions" :key="action">
                      <v-list-item
                        v-if="
                          !action.isDivider && action.conditionFn(props.item)
                        "
                        @click="action.clickFn(props.item)"
                      >
                        <v-list-item-title class="menu-item-wrapper">
                          <v-icon x-small :key="props.item.status">
                            {{ action.iconFn(props.item) }}
                          </v-icon>
                          <span class="label">
                            {{ action.labelFn(props.item) }}
                          </span>
                        </v-list-item-title>
                      </v-list-item>
                      <div
                        class="divider"
                        v-if="action.isDivider && canShowDivider(props.item)"
                      ></div>
                    </div>
                  </v-list>
                </v-menu>
              </div>
            </td>
          </tr>
          <tr v-else>
            <td>
              <ul class="flex-content">
                <li class="flex-item" v-for="col in tableColumns" :key="col">
                  <div v-if="!col.isActionColumn">
                    <span class="mobile-label" v-if="col.dataLabel">
                      {{ col.dataLabel }}:
                    </span>
                    <span
                      :class="col.classFn(props.item)"
                      @click="col.clickFn ? col.clickFn(props.item) : () => {}"
                    >
                      {{ col.valueFn(props.item) }}
                    </span>
                  </div>
                  <div v-else class="actions-container">
                    <v-bottom-sheet v-if="canPerformActions(props.item)">
                      <template v-slot:activator="{ on, attrs }">
                        <span class="actions" v-bind="attrs" v-on="on">
                          <font-awesome-icon
                            icon="ellipsis-h"
                          ></font-awesome-icon>
                        </span>
                      </template>
                      <v-sheet height="auto">
                        <v-list class="sheet">
                          <div v-for="action in col.actions" :key="action">
                            <v-list-item
                              v-if="
                                !action.isDivider &&
                                action.conditionFn(props.item)
                              "
                              @click="action.clickFn(props.item)"
                            >
                              <v-list-item-title class="menu-item-wrapper">
                                <v-icon x-small :key="props.item.status">
                                  {{ action.iconFn(props.item) }}
                                </v-icon>
                                <span class="label">
                                  {{ action.labelFn(props.item) }}
                                </span>
                              </v-list-item-title>
                            </v-list-item>
                            <div
                              class="divider"
                              v-if="
                                action.isDivider && canShowDivider(props.item)
                              "
                            ></div>
                          </div>
                          <div class="bottom-spacer"></div>
                        </v-list>
                      </v-sheet>
                    </v-bottom-sheet>
                  </div>
                </li>
              </ul>
            </td>
          </tr>
        </template>
        <div class="no-results" slot="no-results" :value="true">
          Your search/filter found no results.
        </div>
        <div class="no-data" slot="no-data" :value="true">
          No companies found.
        </div>
      </v-data-table>
    </v-layout>
  </div>
</template>

<script>
import Themable from '@/common/mixins/themable.mixin';
import EventListener from '@/common/mixins/eventlistener.mixin';
import DataTableMixin from '@/common/mixins/datatable.mixin';
import { GET_COMPANIES } from '@/stores/actions.type';
import {
  NOTIFICATION_TYPES,
  NotificationService,
} from '@/services/notification.service';
import { COMPANY_STATUSES } from '@/common/constants';

export default {
  data: () => ({
    companyNameFilter: null,
    companyOptions: [],
    isMobile: false,
    companies: [],
    tableLoading: false,
    dateRange: [null, null],
  }),

  mixins: [Themable, EventListener, DataTableMixin],
  computed: {
    /* Table Header Definitions */
    headers() {
      return [
        {
          text: 'Company',
          value: 'name',
          filter: (value) => {
            if (!this.search || this.search.length === 0) return true;
            return value.match(new RegExp(this.search, 'i'));
          },
        },
        {
          text: 'Users',
          value: 'totalAdvisors',
          filter: (value) => {
            if (!this.filterStatus) return true;

            return value === this.filterStatus;
          },
        },
        {
          text: 'Onbords Started',
          value: 'totalIncompleteClients',
        },
        {
          text: 'Onbords Completed',
          align: 'totalCompletedClients',
        },
      ];
    },

    /* Table Column Definitions */
    tableColumns() {
      const cols = [
        {
          width: '40%',
          valueFn: (item) => {
            return item.name;
          },
          clickFn: () => {},
          classFn: () => {
            return 'company-name';
          },
        },
        {
          width: '20%',
          valueFn: (item) => {
            return item.totalAdvisors;
          },
          classFn: (item) => {
            return item.totalAdvisors;
          },
        },
        {
          width: '20%',
          valueFn: (item) => {
            return item.totalIncompleteClients;
          },
          classFn: () => {
            return '';
          },
        },
        {
          width: '20%',
          valueFn: (item) => {
            return item.totalCompletedClients;
          },
          classFn: () => {
            return '';
          },
        },
      ];
      return cols;
    },
  },
  watch: {
    companyNameFilter(newValue, oldValue) {
      // Call getUserList whenever filterStatus changes
      if (newValue !== oldValue) {
        this.getCompanies();
      }
    },
    dateRange(newValue) {
      if (newValue && newValue.length && newValue[0] && newValue[1])
        this.getCompanies();
    },
  },
  methods: {
    handleDateChange() {
      this.calendarMenu = false;
    },
    /* If any action is still applicable for the client we can show the actions context menu */
    // TODO:
    canPerformActions() {
      return true;
    },

    clearCompanyNameFilter() {
      this.companyNameFilter = null;
      this.$refs.companyNameFilter.blur();
    },
    /* Can a divider be shown in the action context menu */
    canShowDivider() {
      return true;
    },

    /* Determine Mobile vs Desktop view */
    onResize() {
      this.isMobile = window.innerWidth < 769;
    },
    /* Get the list of companies */
    getCompanies(paginationOptions) {
      paginationOptions = paginationOptions || {
        page: 1,
        itemsPerPage: 10,
      };

      // Set a default value for itemsPerPage if not provided
      this.pagination.itemsPerPage = paginationOptions.itemsPerPage || 10;
      this.pagination.page = paginationOptions.page || 1;

      const { page, itemsPerPage, itemsLength } = this.pagination;
      // The Vuetify library returns a limit = -1 when we select to show all users and, to avoid an error on the FE, we need to implement this following logic
      const query = {
        limit: itemsPerPage < 0 ? itemsLength : itemsPerPage,
        page,
        name: this.companyNameFilter,
        from: this.dateRange[0],
        to: this.dateRange[1],
      };

      // Again we hide the table while loading to force
      // it to be redrawn as Safari on mobile seems to have issues
      // compiling icons
      this.tableLoading = true;
      this.$store
        .dispatch(GET_COMPANIES, query)
        .then((response) => {
          this.companies = response.result.map((company) => ({
            name: company.name,
            totalAdvisors: company.totalAdvisors,
            totalCompletedClients: company.totalCompletedClients,
            totalIncompleteClients: company.totalIncompleteClients,
          }));
          // Only populate the first time
          if (!this.companyOptions.length)
            this.companyOptions = response.result.map((c) => c.name);
        })
        .catch((response) => {
          this.companies = [];

          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: 'Error getting list of companies!',
              okTitle: 'Ok',
            },
            response,
          );
        })
        .finally(() => {
          // Regardless of outcome, force re-draw
          this.tableLoading = false;
        });
    },
  },

  /* Lifecycle Hook */
  created() {
    this.pagination.sortBy = 'name';

    // Set initial static status list
    this.statuses = [];
    Object.keys(COMPANY_STATUSES).map((key) => {
      this.statuses.push(COMPANY_STATUSES[key]);
    });

    // Get the list of companies
    this.getCompanies();

    // Listen for reload events
    this.listen('reload-company-list', () => {
      this.getCompanies();
    });
  },
};
</script>

<style lang="scss" scoped>
.mobile {
  color: var(--color-text-dark);
}

.prepend {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--secondary-color);
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.search-input {
  ::v-deep {
    .v-input__icon--clear {
      height: 16px;
    }

    .fa-times-circle {
      font-size: 16px;
      margin-right: 8px;
    }
  }
}

.action-column {
  text-align: end !important;
}

.actions {
  padding: 2px 4px;
  line-height: 16px;

  &[aria-expanded='true'] {
    background-color: var(--secondary-color);

    ::v-deep .v-icon {
      color: var(--primary-color);
    }
  }
}

.menu {
  background-color: var(--color-white);

  ::v-deep {
    .v-list-item {
      min-height: 24px !important;
      padding: 0;
    }
  }
}

.menu-item-wrapper {
  padding-left: 10px;
  color: var(--color-text) !important;
  font-size: 14px !important;
  font-weight: 700;
  margin-bottom: 0;
  padding: 8px 12px;
  margin: 0 4px;

  ::v-deep {
    .v-icon {
      font-weight: 700;
      color: var(--color-text) !important;
      //margin-right: 8px;
      margin-top: -2px;
    }
  }

  &:hover {
    color: var(--primary-color) !important;
    background-color: var(--input-bg-color);
    text-decoration: underline;

    ::v-deep .v-icon {
      color: var(--primary-color) !important;
    }
  }

  .label {
    padding-left: 8px;
  }
}

.sheet {
  background-color: var(--color-white);

  ::v-deep {
    .v-list-item {
      min-height: 24px !important;
      padding: 0;
    }
  }

  .menu-item-wrapper {
    font-size: 16px !important;
    padding: 12px;
  }

  .bottom-spacer {
    height: 25px;
  }
}

.status-col {
  width: 40%;
}

.calendar-col {
  width: 40%;
  .v-btn {
    width: 100%;
  }
}

.flex-content {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;

  .actions-container {
    position: absolute;
    top: 0;
    right: 0;
    line-height: normal;
  }

  .edit-client-link {
    color: var(--color-text);
    font-weight: 700;
  }

  .flex-item {
    padding: 5px;
    line-height: 24px;
    font-size: 16px;

    .company-name {
      padding-right: 30px;
      display: inline-block;
      line-height: normal;
    }

    .mobile-label {
      font-weight: 400;
      margin-right: 10px;
    }
  }
}

.search {
  margin-right: 12px;
}

.divider {
  height: 1px;
  margin: 8px;
  background-color: var(--secondary-color);
}

.company-table {
  font-weight: 500;
  color: var(--color-text);

  .Disabled {
    padding: 2px 15px;
    border-radius: 30px;
    line-height: 24px;
    background-color: hsla(0, 0%, 72.7%, 0.16);
    color: var(--label-text);
    font-weight: 500;
  }

  .Active {
    padding: 2px 15px;
    border-radius: 30px;
    line-height: 24px;
    background-color: rgba(30, 59, 112, 0.15);
    color: var(--primary-color);
    font-weight: 500;
  }
}
.v-picker__title {
  color: darkgreen !important;
}

::v-deep .v-btn--rounded {
  // background-color:  !important;
  color: black !important;
  z-index: 9999;
  font-weight: 800;
}
</style>
