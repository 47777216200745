<!-- Notification Component -->
<template>
  <v-dialog
    v-model="notificationDialog"
    persistent
    width="430"
    :overlay-opacity="overlayOpacity"
    :overlay-color="overlayColorBg"
    content-class="plain-dialog nc"
  >
    <v-card flat>
      <v-card-text class="pa-7">
        <div v-if="data.title" class="modal-title title" :class="dialogClass">
          <!--<v-icon id="title-icon">{{ icon }}</v-icon>-->
          {{ data.title }}
        </div>
        <div class="modal-body"><span v-html="data.message"></span></div>
        <div class="modal-actions">
          <a @click="ok" class="btn mt-3">{{ data.okTitle }}</a>
          <a v-if="canCancel" @click="cancel" class="btn secondary mt-3">{{
            data.cancelTitle
          }}</a>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import EventListener from '@/common/mixins/eventlistener.mixin';
import { NOTIFICATION_TYPES } from '@/services/notification.service';
import Themable from '@/common/mixins/themable.mixin';

export default {
  name: 'Notification',

  components: {},
  mixins: [EventListener, Themable],

  data: () => ({
    notificationDialog: false,
    canCancel: false,
    data: {},
  }),

  computed: {
    /* ICON is based on Notification Type */
    icon() {
      let icon = 'fas fa-info-circle';
      if (this.data.type === NOTIFICATION_TYPES.ERROR)
        icon = 'fas fa-exclamation-triangle';
      else if (this.data.type === NOTIFICATION_TYPES.WARNING)
        icon = 'fas fa-exclamation-circle';
      else if (this.data.type === NOTIFICATION_TYPES.SUCCESS)
        icon = 'fas fa-check-circle';
      return icon;
    },

    /* Dialog (Header) Class is based on Notification Type */
    dialogClass() {
      let clazz = 'info';
      if (this.data.type === NOTIFICATION_TYPES.ERROR) clazz = 'error';
      else if (this.data.type === NOTIFICATION_TYPES.WARNING) clazz = 'warning';
      else if (this.data.type === NOTIFICATION_TYPES.SUCCESS) clazz = 'success';
      return clazz;
    },

    overlayColorBg() {
      if (this.data.type === NOTIFICATION_TYPES.ERROR) return this.errorColor;
      //else if (this.data.type === NOTIFICATION_TYPES.SUCCESS) color = this.primaryColor;
      return this.overlayColor;
    },

    overlayOpacity() {
      if (this.data.type === NOTIFICATION_TYPES.ERROR) return '0.5';
      return '0.8';
    },
  },

  methods: {
    /* Cancel function calls cancelMethod of payload */
    cancel() {
      if (this.canCancel) this.data.cancelMethod();
      this.notificationDialog = false;
    },

    /* OK function calls okMethod of payload */
    ok() {
      this.data.okMethod();
      this.notificationDialog = false;
    },
  },

  /* Lifecycle Hook - copy payload from listened events */
  created() {
    this.listen('display-confirm', (payload) => {
      this.notificationDialog = true;
      this.data = payload;
      this.canCancel = true;
    });
    this.listen('display-alert', (payload) => {
      this.notificationDialog = true;
      this.data = payload;
      this.canCancel = false;
    });
    this.listen('close-notification', () => {
      this.notificationDialog = false;
    });
  },

  beforeDestroy() {
    this.notificationDialog = false;
  },
};
</script>

<style lang="scss" scoped>
.modal-title,
.modal-body {
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 28px;
  color: var(--color-text);
}
.modal-title {
  text-align: center;

  &.info {
    color: var(--color-text-dark);
  }
  &.success {
    color: var(--primary-color);
  }
  &.error {
    color: #fff;
    background-color: var(--error-notification-color);
    margin-top: -28px;
    margin-left: -28px;
    margin-right: -28px;
    padding: 14px;
  }
  &.warning {
    color: #fff;
    background-color: var(--color-warning);
    margin-top: -28px;
    margin-left: -28px;
    margin-right: -28px;
    padding: 14px;
  }
}
.btn {
  font-size: 16px;
}
</style>
