export function organizationMapper(request) {
  const {
    id,
    name,
    phone,
    address1,
    city,
    state,
    zip,
    iaFirmName,
    docusignDisabled,
    externalAccounts,
  } = request;

  const organization = {
    _id: id,
    name,
    phone,
    riaName: iaFirmName,
    docuSign: docusignDisabled,
    externalAccounts,
  };

  const address = {
    street: address1,
    city,
    state,
    zip,
  };

  const isAddressEmpty = Object.values(address).every(
    (value) => value === undefined,
  );

  if (!isAddressEmpty) {
    return {
      ...organization,
      address,
    };
  }

  return organization;
}
