<template>
  <div class="auth-container">
    <div class="inner-container">
      <div class="logo-container">
        <img
          :src="`${publicPath}images/defaultLogo.png`"
          width="140"
          loading="lazy"
          alt="OnBord"
          class="logo"
        />
      </div>
      <div class="main-title">
        Enter a new password for<br /><strong>{{ email }}</strong>
      </div>
      <div class="form-block w-form">
        <v-form name="changePasswordForm" class="form">
          <v-row class="mb-1">
            <v-col>
              <label for="password" class="required">Password</label>
              <v-text-field
                outlined
                type="password"
                maxlength="256"
                name="password"
                aria-required="true"
                v-model.trim="password"
                :backgroundColor="inputBackgroundColor"
                append-icon="far fa-eye"
                @blur="$v.password.$touch()"
                :error-messages="passwordErrors"
                hide-details="auto"
              >
                <template v-slot:append>
                  <password-strength-meter
                    :value="password"
                  ></password-strength-meter>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="mb-5">
            <v-col>
              <label for="confirmPassword" class="required"
                >Confirm Password</label
              >
              <v-text-field
                outlined
                type="password"
                maxlength="256"
                name="confirmPassword"
                aria-required="true"
                v-model.trim="confirmPassword"
                :backgroundColor="inputBackgroundColor"
                append-icon="far fa-eye"
                @input="$v.confirmPassword.$touch()"
                @blur="$v.confirmPassword.$touch()"
                :error-messages="confirmPasswordErrors"
                hide-details="auto"
              >
                <template v-slot:append><span></span></template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="mb-5">
            <v-col>
              <label for="confirmationCode" class="required"
                >Confirmation Code</label
              >
              <v-text-field
                outlined
                type="text"
                maxlength="256"
                name="confirmationCode"
                aria-required="true"
                v-model.trim="confirmationCode"
                :backgroundColor="inputBackgroundColor"
                append-icon="far fa-eye"
                @input="$v.confirmationCode.$touch()"
                @blur="$v.confirmationCode.$touch()"
                :error-messages="confirmationCodeErrors"
                hide-details="auto"
              >
                <template v-slot:append><span></span></template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <a class="btn" @click="changePassword(password, confirmationCode)"
                >Save Password &amp; Log In</a
              >
            </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
  </div>
</template>
<script>
import { validationMixin } from 'vuelidate';
import { required, sameAs, minLength } from 'vuelidate/lib/validators';
import { RESET_PASSWORD } from '@/stores/actions.type';
import LoginMixin from '@/common/mixins/login.mixin';
import Themable from '@/common/mixins/themable.mixin';
import {
  NOTIFICATION_TYPES,
  NotificationService,
} from '@/services/notification.service';
import PasswordStrengthMeter from '@/components/PasswordStrengthMeter';
import { MENUS, passwordComplexity } from '@/common/constants';
import PublicAssets from '@/common/mixins/publicasset.mixin';

export default {
  name: 'ResetPassword',
  props: {
    token: String,
  },

  components: {
    PasswordStrengthMeter,
  },

  mixins: [PublicAssets, validationMixin, LoginMixin, Themable],

  data: () => ({
    password: null,
    confirmPassword: null,
    confirmationCode: null,
  }),

  validations() {
    return {
      password: {
        required,
        minLength: minLength(6),
        passwordComplexity,
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs(() => this.password),
      },
      confirmationCode: {
        required,
        length: minLength(6),
      },
      validationGroup: ['password', 'confirmPassword', 'confirmationCode'],
    };
  },

  computed: {
    /* Dynamic Password errors */
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push('New Password is required');
      !this.$v.password.minLength &&
        errors.push('New Password must be at least 6 characters');
      !this.$v.password.passwordComplexity &&
        errors.push('At least 1 uppercase and 1 number are required');
      return errors;
    },

    /* Dynamic Confirm Password errors */
    confirmPasswordErrors() {
      const errors = [];
      if (!this.$v.confirmPassword.$dirty) return errors;
      !this.$v.confirmPassword.required &&
        errors.push('Confirm Password is required');
      !this.$v.confirmPassword.sameAsPassword &&
        errors.push('Password and Confirm Password do not match');
      return errors;
    },

    /* Dynamic Confirmation Code errors */
    confirmationCodeErrors() {
      const errors = [];
      if (!this.$v.confirmationCode.$dirty) return errors;
      !this.$v.confirmationCode.required &&
        errors.push('Confirmation Code is required');
      !this.$v.confirmationCode.length === 6 &&
        errors.push('Confirmation Code must be 6 digits');
      return errors;
    },

    email() {
      return this.$route.query.email;
    },
  },

  methods: {
    /* Perform Password Reset */
    changePassword(password, confirmationCode) {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.$store
        .dispatch(RESET_PASSWORD, { password, confirmationCode })
        .then(() => {
          NotificationService.alert({
            type: NOTIFICATION_TYPES.SUCCESS,
            title: 'Success',
            message: 'Password Reset. Please log in with new password.',
            okTitle: 'Ok',
            okMethod: () => {
              this.$router.push({ name: MENUS.LOGIN.id });
            },
          });
        })
        .catch((error) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message:
                "Error resetting password! You may need to send another Reset Password Confirmation Code via 'Forgot Password' ",
              okTitle: 'Ok',
              okMethod: () => {
                this.$router.push({ name: MENUS.FORGOT_PASSWORD.id });
              },
            },
            error,
          );
        });
    },
  },
};
</script>
<style lang="scss" scoped></style>
