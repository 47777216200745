<template>
  <div>
    <div class="question-container">
      <span class="headerlight">{{ questionParts[0] }}</span>
      <v-textarea
        v-if="questionParts.length > 1"
        id="textarea"
        required
        auto-grow
        :rows="1"
        v-model.trim="answer"
        hide-details
        :cols="200"
        class="fill-in-blank"
        @change="updateAnswer"
        @blur="$v.answer.$touch()"
      />
        <span v-if="questionParts.length > 1" class="headerlight">{{ questionParts[1] }}</span>
    </div>
    <div v-if="answerErrors.length" class="error-message">
        <span v-for="error in answerErrors" :key="error" class="error-message">{{ error }}</span> <br />
    </div>
  </div>
  
</template>


<script>
import { required } from 'vuelidate/lib/validators';
import InterviewClientMixin from '@/common/mixins/interview.client.mixin';
import Themable from '@/common/mixins/themable.mixin';
import { EventService } from '@/services/event.service';

export default {
  name: 'FillInBlank',
  props: {
    question: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      required: true
    },
  },
  mixins: [InterviewClientMixin, Themable],
  validations() {
    return {
      answer: {
        required
      }
    };
  },
  data() {
    return {
      answer: null
    }
  },
  computed: {
    questionParts() {
      return this.question.split('_');
    },
    answerErrors() {
      const errors = [];
      if (this.$v.answer.$dirty) {
        if (!this.$v.answer.required) {
          errors.push('Answer is required');
        }
      }
      return errors;
    }
  },
  methods: {
    updateAnswer() {
      EventService.emit('update-answer', this.answer);
    },
  }
}
</script>


<style>
.question-container {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 8px;
  font-weight: 300;
  font-size: 24px;
}

.fill-in-blank textarea {
  width: 100px;
}

.error-message {
  color: var(--error-color);
  margin-top: 10px;
  text-align: center;
}
</style>