<template>
  <div class="step-container advisor">
    <!--<add-client-instructions></add-client-instructions>-->
    <v-form name="DataSelection" class="form">
      <v-container class="client-step-container">
        <v-row class="mb-4">
          <v-col class="instructions">
            <h1>
              <span class="headerlight">
                Select <strong>all</strong> that apply.
              </span>
            </h1>
          </v-col>
        </v-row>
        <v-row class="step-form-container radio-container">
          <v-col class="">
            <v-checkbox
              v-model="doClientProfiling"
              hide-details
              :ripple="false"
              :color="primaryColor"
              class="v-faux-checkbox"
              @change="clientProfilingChanged()"
            >
              <template v-slot:label>
                <span class="cb-label">Client Profile</span>
              </template>
            </v-checkbox>
            <v-checkbox
              v-model="sendFormADVCRS"
              hide-details
              :ripple="false"
              :color="primaryColor"
              class="v-faux-checkbox"
              :disabled="true"
            >
              <template v-slot:label>
                <span class="cb-label">Send ADV2B/CRS</span>
              </template>
            </v-checkbox>
            <v-checkbox
              v-model="addAccountsSelected"
              hide-details
              :ripple="false"
              :color="primaryColor"
              class="v-faux-checkbox"
              @change="dataSelectionChanged()"
              :disabled="addAccountsDisabled"
            >
              <template v-slot:label>
                <span class="cb-label">Open Accounts</span>
              </template>
            </v-checkbox>
            <v-checkbox
              v-model="esignEnabled"
              hide-details
              :ripple="false"
              :color="primaryColor"
              class="v-faux-checkbox"
              :disabled="
                !addAccountsSelected ||
                !currentUser.docusignEnabled ||
                currentCompany.docusignDisabled ||
                !docusignLinked
              "
            >
              <template v-slot:label>
                <span class="cb-label">DocuSign</span>
              </template>
            </v-checkbox>
            <v-checkbox
              v-model="customTemplates"
              hide-details
              :ripple="false"
              :color="primaryColor"
              class="v-faux-checkbox"
            >
              <template v-slot:label>
                <span class="cb-label">Custom Questions</span>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <bottom-nav v-on:go-prev="prev()" v-on:go-next="next()"></bottom-nav>
  </div>
</template>
<script>
import BottomNav from '@/components/common/BottomNav';
import AdvisorClientMixin from '@/common/mixins/advisor.client.mixin';
import { MENUS } from '@/common/constants';
import { required } from 'vuelidate/lib/validators';
import { EventService } from '@/services/event.service';
import {
  NOTIFICATION_TYPES,
  NotificationService,
} from '@/services/notification.service';
export default {
  name: 'DataSelection',
  data: () => ({
    doClientProfiling: true,
    sendFormADVCRS: true,
    addAccountsSelected: null,
    esignEnabled: null,
    customTemplates: false,
    docusignLinked: false,
  }),

  components: {
    BottomNav,
  },
  mixins: [AdvisorClientMixin],
  validations() {
    return {
      addAccountsSelected: {
        required,
      },
    };
  },

  computed: {
    addAccountsDisabled() {
      return (
        this.currentClient.client1.preserveInterview &&
        (!this.currentClient.client2 ||
          this.currentClient.client2.preserveInterview)
      );
    },
  },

  methods: {
    clientProfilingChanged() {
      if (!this.doClientProfiling) {
        this.addAccountsSelected = false;
        this.esignEnabled = false;
        this.customTemplates = true;
      }
    },
    dataSelectionChanged() {
      if (this.addAccountsSelected) {
        this.doClientProfiling = true;
        if (
          this.currentUser.docusignEnabled &&
          !this.currentCompany.docusignDisabled &&
          this.docusignLinked
        ) {
          this.esignEnabled = true;
        }
      } else {
        this.esignEnabled = false;
      }

      EventService.emit('data-selection-changed', {
        addAccountsSelected: this.addAccountsSelected,
      });
    },

    /* Map form data to working client */
    mapData() {
      this.currentClient.doClientProfiling = this.doClientProfiling;
      this.currentClient.addAccountsSelected = this.addAccountsSelected;
      this.currentClient.addCustomQuestionsSelected = this.customTemplates;
      this.currentClient.esignEnabled = this.esignEnabled;

      // Wipe Account data if no longer applicable
      if (!this.canAddAccounts) {
        this.currentClient.client1.accounts = null;
        if (this.currentClient.client2 && this.currentClient.client2.accounts)
          this.currentClient.client2.accounts = null;
        this.currentClient.rateType = null;
        // UNUSED
        this.currentClient.featuresVisited = null;
      }
    },

    /* Nav to Next Page in flow */
    next(route = null) {
      if (!this.customTemplates && !this.doClientProfiling) {
        NotificationService.alert({
          type: NOTIFICATION_TYPES.ERROR,
          title: 'Error',
          message: 'Please select at least one option.',
        });
        return;
      }
      this.mapData();

      const to = route ? route.name : MENUS.ADVISOR.CLIENT.CLIENT_ONE_NAME.id;
      this.goNext(to);
    },

    /* Nav to prev page */
    prev() {
      this.goPrev(MENUS.ADVISOR.CLIENT.CLIENT_SELECTION.id);
    },

    getText(val) {
      return val;
    },
  },

  created() {
    const currentUserHasDocusignLinked =
      this.currentUser.integrations &&
      this.currentUser.integrations.some(
        (integration) => integration.integrationType === 'DocumentSigning',
      );

    this.docusignLinked =
      currentUserHasDocusignLinked || this.currentCompany.docusignLinked;
    this.customTemplates = !!this.currentClient.addCustomQuestionsSelected;
    this.doClientProfiling =
      this.currentClient.doClientProfiling !== undefined
        ? this.currentClient.doClientProfiling
        : true;
    this.addAccountsSelected =
      !!this.currentClient.client1.preserveInterview ||
      this.addAccountsSelected;

    this.esignEnabled =
      !!this.esignEnabled ||
      [
        !!this.addAccountsSelected,
        !!this.currentUser.docusignEnabled,
        !this.currentCompany.docusignDisabled,
      ].every((x) => x);
  },
};
</script>

<style lang="scss" scoped>
.cb-label {
  font-size: 16px;
}
</style>
