import { ASSET_KEYS, USER_STATUSES } from '../../../common/constants';

export function advisorsMapper({ result, totalResults }) {
  const mapped = result.map(
    ({ _id, personalInfo, role, status, clientsCount = 0, assets = [] }) => {
      const advisorADV2B = assets.find(
        ({ assetType }) => assetType === ASSET_KEYS.ADV2B,
      );
      return {
        id: _id,
        firstName: personalInfo.firstName,
        lastName: personalInfo.lastName,
        login: personalInfo.email,
        adv2bUploadedOn: advisorADV2B ? advisorADV2B.createdAt : undefined,
        status: status || USER_STATUSES.ACTIVE, // Until we migrate all old records, we are treating account with status: undefined as Active
        clients: clientsCount,
        roles: [role],
      };
    },
  );

  return { result: mapped, totalResults };
}
