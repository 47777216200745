<template>
  <v-container class="d-flex align-center pa-0 justify-end">
    <a class="rate-control subtract" @click="subtract()">
      <i class="fas fa-minus"></i>
    </a>
    <v-text-field
      outlined
      type="text"
      maxlength="6"
      aria-required="true"
      v-model.trim="internalModel"
      :backgroundColor="inputBackgroundColor"
      @blur="checkValid()"
      @keypress="inputCheck"
      hide-details="auto"
      inputmode="decimal"
      class="rate"
    >
      <template v-slot:append>
        <span v-if="pickerType === PICKER_TYPES.ALLOCATION">
          <v-icon class="append-icon" x-small>fas fa-percent</v-icon>
        </span>
      </template>
    </v-text-field>
    <a class="rate-control add" @click="add()">
      <i class="fas fa-plus"></i>
    </a>
  </v-container>
</template>
<script>
import Themable from '@/common/mixins/themable.mixin';
import { PICKER_TYPES } from '@/common/constants';

export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
    pickerType: {
      type: Object,
      default: PICKER_TYPES.ADVISORY_RATE,
    },
    minValue: {
      type: Number,
      default: 0,
    },
    maxValue: {
      type: Number,
      default: 100,
    },
  },
  mixins: [Themable],
  data: () => ({
    internalModel: null,
  }),
  watch: {
    value: {
      immediate: true,
      handler() {
        /^\d+\.\d+$/.test(this.value);
        this.internalModel = this.value ? this.value : this.minValue;
      },
    },
  },
  computed: {
    tickAmount() {
      return this.pickerType.tickAmount;
    },
    decimalDigits() {
      return this.pickerType.decimalDigits;
    },
  },
  methods: {
    add() {
      let m = parseFloat(this.internalModel);
      m += this.tickAmount;

      this.checkValid(m);
    },

    subtract() {
      let m = parseFloat(this.internalModel);
      m -= this.tickAmount;

      this.checkValid(m);
    },
    checkValid(val = this.internalModel) {
      let m = parseFloat(val);
      if (isNaN(m)) m = this.minValue;
      else {
        if (m > this.maxValue) m = this.maxValue;
        if (m < this.minValue) m = this.minValue;
      }

      this.$emit('update-number', m.toFixed(this.decimalDigits));
    },
    inputCheck($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;

      // only allow number and one dot
      if (
        (keyCode < 48 || keyCode > 57) &&
        ((this.decimalDigits > 0 && keyCode !== 46) ||
          this.decimalDigits === 0 ||
          this.internalModel.indexOf('.') !== -1)
      ) {
        // 46 is dot
        $event.preventDefault();
      }

      // restrict to 2 decimal places
      if (
        this.price != null &&
        this.price.indexOf('.') > -1 &&
        this.price.split('.')[1].length > this.decimalDigits
      ) {
        $event.preventDefault();
      }
    },
  },
  created() {
    this.PICKER_TYPES = PICKER_TYPES;
  },
};
</script>
<style lang="scss" scoped>
.rate {
  max-width: 80px;
}

.append-icon {
  margin-right: 8px !important;
}
.rate-control {
  color: var(--color-text);
  font-size: 14px;

  &:hover {
    color: var(--primary-color);
  }

  &.subtract {
    margin-right: 15px;
  }
  &.add {
    margin-left: 10px;
    margin-right: 5px;
  }
}
</style>
