import { mapGetters } from 'vuex';
import Themable from '@/common/mixins/themable.mixin';
import LeaveGuard from '@/common/mixins/leaveguard.mixin';
import { validationMixin } from 'vuelidate';
import EventListener from '@/common/mixins/eventlistener.mixin';
import { EventService } from '@/services/event.service';
import ClientMixin from '@/common/mixins/unauthenticated.client.mixin';
import { PURGE_AUTHENTICATED_INTERVIEW } from '@/stores/actions.type';
import {
  UPDATE_INTERVIEW,
  GET_INTERVIEW_PROGRESS,
} from '@/stores/actions.type';
import {
  NOTIFICATION_TYPES,
  NotificationService,
} from '@/services/notification.service';
import { MENUS } from '@/common/constants';

const InterviewClientMixin = {
  data: () => ({
    ignoreInProgessClient: false,
    formHidden: false,
  }),

  mixins: [validationMixin, EventListener, LeaveGuard, Themable, ClientMixin],

  computed: {
    ...mapGetters([
      'clientProgress',
      'clientData',
      'isAuthenticated',
      'isClientAuthenticated',
    ]),
  },

  methods: {
    // Toggle Help Drawer
    toggleHelp() {
      EventService.emit('toggle-help');
    },

    /* Always ask to save when leaving page */
    showSaveFunction() {
      return true;
    },

    /*
      Called before we exit the page
      If we are leaving the interview process, delete the working interview
    */
    beforeExit(route) {
      if (!route.path.includes('client-interview')) {
        this.$store.dispatch(PURGE_AUTHENTICATED_INTERVIEW);
      }
    },

    /* 
      toggle form state from hidden/shown.
      If hidden, reset form errors
    */
    toggleFormState(hide) {
      this.formHidden = hide;
      if (hide) {
        this.$v.$reset();
      }
    },

    /* 
      Go to the next route.
    */
    goNext(route) {
      const routeObj = typeof route === 'object' ? route : { name: route };
      if (this.formErrors) this.formErrors.$reset();
      this.ignoreInProgessClient = true;
      this.$router.push(routeObj).catch(() => {});
    },

    /*
      Go to previous route 
    */
    goPrev(route) {
      const routeObj = typeof route === 'object' ? route : { name: route };
      if (this.formErrors) this.formErrors.$reset();
      this.ignoreInProgessClient = true;
      this.$router.push(routeObj).catch(() => {});
    },

    /*
      Remove a page from the page list (if present)
    */
    removePage(pageName, instance = null) {
      const progressPage = this.getPage(pageName, instance);
      if (progressPage) {
        const pageIndex = this.clientProgress.progress.findIndex((p) => {
          if (instance) {
            return (
              p.pageName === pageName &&
              p.instance === instance.name &&
              p.label === instance.label
            );
          }
          return p.pageName === pageName;
        });
        this.clientProgress.progress.splice(pageIndex, 1);
      }
    },

    /*
      Add a page to the page list (if not present)
    */
    addPage(pageName, instance = null) {
      let progressPage = this.getPage(pageName, instance);
      if (!progressPage) {
        progressPage = {
          id: null,
          interviewName: 'onbord',
          pageName: pageName,
          lastCompleted: null,
          instance: null,
          label: null,
        };
        if (instance) {
          progressPage.instance = instance.name;
          progressPage.label = instance.label;
        }
        this.clientProgress.progress.push(progressPage);
      }
    },

    /*
      Complete a step.
      Save Interview page data to backend.
      Update working (in memory) progress
      TODO: In future - just make API call to get updated progress?
                        Implies no in-memory progress... 
    */
    completeStep(pageName, data, instance = null) {
      const payload = {
        interviewName: 'onbord',
        pageName: pageName,
        instance: null,
        data: data,
      };
      if (instance) {
        payload.instance = instance.name;
        payload.label = instance.label;
      }

      const progressPage = this.getPage(pageName, instance);
      if (progressPage) {
        payload.id = progressPage.id;
      }

      // Update backend interview data
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch(UPDATE_INTERVIEW, payload)
          .then(() => {
            this.$store
              .dispatch(GET_INTERVIEW_PROGRESS)
              .finally(() => resolve());
          })
          .catch((response) => {
            // Mark page as incomplete?
            if (progressPage) {
              progressPage.lastCompleted = null;
            }

            // Backend returned Error
            NotificationService.alert(
              {
                type: NOTIFICATION_TYPES.ERROR,
                title: 'Error',
                message: 'Error updating progress!',
                okTitle: 'Ok',
                okMethod: () => {
                  this.$store
                    .dispatch(GET_INTERVIEW_PROGRESS)
                    .finally(() => reject());
                },
              },
              response,
            );
          });
      });
    },

    /* 
      Get next Beneficiary Route when skip/continue called
    */
    getNextBeneficiaryRoute(instance) {
      const accounts = this.clientData.accounts;

      // Are we currently on a Primary Beneficiary Screen?
      const onPrimaryScreen =
        this.$route.name === MENUS.CLIENT.ACCOUNT_PRIMARY_BENEFICIARIES.id;

      // Find current account instance
      const index = accounts.findIndex(
        (a) =>
          a.instance.name === instance.name &&
          a.instance.label === instance.label,
      );

      // Found account
      if (index >= 0) {
        const hasContingentBeneficiaryPage =
          this.getPage(
            MENUS.CLIENT.ACCOUNT_CONTINGENT_BENEFICIARIES.id,
            accounts[index].instance,
          ) !== undefined;

        // If we are on the Primary page for account and account has Contingent page available
        // Go to the Contingent page next
        if (onPrimaryScreen && hasContingentBeneficiaryPage) {
          return {
            name: MENUS.CLIENT.ACCOUNT_CONTINGENT_BENEFICIARIES.id,
            params: {
              name: accounts[index].instance.name,
              label: accounts[index].instance.label,
            },
          };
        }

        // Otherwise,
        // If we are not the last account (and are either on a Contingent page or no Contingent Page available for account)
        // Go to next account Primary page.
        if (index + 1 < accounts.length)
          return {
            name: MENUS.CLIENT.ACCOUNT_PRIMARY_BENEFICIARIES.id,
            params: {
              name: accounts[index + 1].instance.name,
              label: accounts[index + 1].instance.label,
            },
          };
      }

      // No next beneficiary page exits
      return null;
    },

    /* 
      Get next Beneficiary Route when previous called
    */
    getPrevBeneficiaryRoute(instance) {
      const accounts = this.clientData.accounts;

      // Are we on a Contingent Beneficiary page?
      const onContingentScreen =
        this.$route.name === MENUS.CLIENT.ACCOUNT_CONTINGENT_BENEFICIARIES.id;

      // Find current account instance
      const index = accounts.findIndex(
        (a) =>
          a.instance.name === instance.name &&
          a.instance.label === instance.label,
      );

      // Found account
      if (index >= 0) {
        // If we are on a Contingent page, go to Primary page for current account
        if (onContingentScreen) {
          return {
            name: MENUS.CLIENT.ACCOUNT_PRIMARY_BENEFICIARIES.id,
            params: {
              name: accounts[index].instance.name,
              label: accounts[index].instance.label,
            },
          };
        }

        // Otherwise (we are on a Primary Page)
        // If first account, no previous beneficiary page available.
        if (index === 0) return null;

        // Not first account
        // If previous account has a Contingent Beneficiary Page, go to that
        const hasContingentBeneficiaryPage =
          this.getPage(
            MENUS.CLIENT.ACCOUNT_CONTINGENT_BENEFICIARIES.id,
            accounts[index - 1].instance,
          ) !== undefined;
        if (hasContingentBeneficiaryPage) {
          return {
            name: MENUS.CLIENT.ACCOUNT_CONTINGENT_BENEFICIARIES.id,
            params: {
              name: accounts[index - 1].instance.name,
              label: accounts[index - 1].instance.label,
            },
          };
        }

        // Otherwise go to previous account primary page
        return {
          name: MENUS.CLIENT.ACCOUNT_PRIMARY_BENEFICIARIES.id,
          params: {
            name: accounts[index - 1].instance.name,
            label: accounts[index - 1].instance.label,
          },
        };
      }

      // No previous beneficiary
      return null;
    },

    getNextCustomQuestionRoute(currentQuestionId = null) {
      const questionPages = this.clientProgress.progress.filter(
        (p) => p.pageName === MENUS.CLIENT.CUSTOM_QUESTIONS.id,
      );

      if (questionPages.length === 0) return null;

      if (!currentQuestionId) {
        const firstQuestion =
          questionPages.length > 0 ? questionPages[0] : null;

        return firstQuestion
          ? {
              name: MENUS.CLIENT.CUSTOM_QUESTIONS.id,
              params: {
                questionId: firstQuestion.data.question._id,
              },
            }
          : null;
      }

      const currentQuestionIndex = currentQuestionId
        ? questionPages.findIndex(
            (p) => p.data.question._id === currentQuestionId,
          )
        : null;

      if (
        currentQuestionIndex !== -1 &&
        currentQuestionIndex + 1 < questionPages.length
      ) {
        return {
          name: MENUS.CLIENT.CUSTOM_QUESTIONS.id,
          params: {
            questionId:
              questionPages[currentQuestionIndex + 1].data.question._id,
          },
        };
      }

      return null;
    },

    getPreviousCustomQuestionRoute(currentQuestionId = null) {
      const questionPages = this.clientProgress.progress.filter(
        (p) => p.pageName === MENUS.CLIENT.CUSTOM_QUESTIONS.id,
      );
      if (!currentQuestionId) {
        const lastQuestion =
          questionPages.length > 0
            ? questionPages[questionPages.length - 1]
            : null;

        return lastQuestion
          ? {
              name: MENUS.CLIENT.CUSTOM_QUESTIONS.id,
              params: {
                questionId: lastQuestion.data.question._id,
              },
            }
          : null;
      }

      const currentQuestionIndex = currentQuestionId
        ? questionPages.findIndex(
            (p) => p.data.question._id === currentQuestionId,
          )
        : null;

      if (currentQuestionIndex !== -1 && currentQuestionIndex > 0) {
        return {
          name: MENUS.CLIENT.CUSTOM_QUESTIONS.id,
          params: {
            questionId:
              questionPages[currentQuestionIndex - 1].data.question._id,
          },
        };
      }

      return null;
    },

    getCustomQuestion(questionId) {
      const questionPages = this.clientProgress.progress.filter(
        (p) => p.pageName === MENUS.CLIENT.CUSTOM_QUESTIONS.id,
      );
      const questionPage = questionPages.find(
        (p) => p.data.question._id === questionId,
      );
      return questionPage ? questionPage.data.question : null;
    },

    getCustomQuestionPageIndex(questionId) {
      const questionPages = this.clientProgress.progress.filter(
        (p) => p.pageName === MENUS.CLIENT.CUSTOM_QUESTIONS.id,
      );
      return questionPages.findIndex((p) => p.data.question._id === questionId);
    },
  },

  created() {
    /* 
      On navigate from menu
      Leave and close nav drawer
    */
    this.listen('navigate-to', (route) => {
      this.ignoreInProgessClient = true;
      const routeObj = typeof route === 'object' ? route : { name: route };
      this.$router.push(routeObj).catch(() => {
        EventService.emit('toggle-main-drawer');
      });
    });

    // Prompt leave via manual url change if dirty
    window.addEventListener('beforeunload', function (e) {
      // Cancel the event
      e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
      // Chrome requires returnValue to be set
      e.returnValue = '';
    });
  },
};
export default InterviewClientMixin;
