<template>
  <div></div>
</template>
<script>
import { AUTHENTICATE_INTERVIEW } from '@/stores/actions.type';
import ClientMixin from '@/common/mixins/unauthenticated.client.mixin';
import {
  NOTIFICATION_TYPES,
  NotificationService,
} from '@/services/notification.service';
import { MENUS, CLIENT_EXIT_KEYS, LOCAL_KEYS } from '@/common/constants';
import { PermissionService } from '@/services/permission.service';
import Storage from '@/services/storage.service';
import PublicAssets from '@/common/mixins/publicasset.mixin';

export default {
  name: 'Authenticate',
  props: {
    token: {
      type: String,
      default: null,
    },
  },
  mixins: [ClientMixin, PublicAssets],

  computed: {},
  methods: {
    /* Cache Company Logo */
    cacheLogo() {
      const me = this;
      Storage.setLocal(LOCAL_KEYS.COMPANY_LOGO, this.companyLogo);

      let { route, data } = PermissionService.getClientHomePage();
      if (route === MENUS.CLIENT.CUSTOM_QUESTIONS.id) {
        return me.$router.push({
          name: route,
          params: {
            questionId: data.question._id,
          },
        });
      }
      me.$router.push({
        name: route,
      });
    },

    /* Authenticate interview token */
    authenticateInterview() {
      this.$store
        .dispatch(AUTHENTICATE_INTERVIEW, this.token)
        .then(() => {
          // Authentication is successful

          // Cache the company logo as a data url
          this.cacheLogo();
        })
        .catch((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: 'Invalid Token!',
              okTitle: 'Ok',
              okMethod: () => {
                const route = this.isAuthenticated
                  ? { name: MENUS.CLIENTS.id }
                  : {
                      name: MENUS.INTERVIEW.UNAUTHENTICATED.id,
                      params: { flag: CLIENT_EXIT_KEYS.EXIT_NOT_AUTHORIZED },
                    };
                this.$router.push(route).catch(() => {});
              },
            },
            response,
          );
        });
    },
  },

  created() {
    // Authenticate token on load
    this.authenticateInterview();
  },
};
</script>
<style lang="scss" scoped></style>
