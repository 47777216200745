<template>
  <custom-questions-table></custom-questions-table>
</template>

<script>
import CustomQuestionsTable from '@/components/advisor/tables/CustomQuestionsTable';

export default {
  name: 'CompanyCustomQuestions',

  components: {
    CustomQuestionsTable,
  },

  data: () => ({}),

  methods: {},
  created() {},
};
</script>
<style lang="scss" scoped></style>
