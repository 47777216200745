<template>
  <div class="auth-container">
    <div class="inner-container">
      <div class="logo-container">
        <img
          :src="`${publicPath}images/defaultLogo.png`"
          width="140"
          loading="lazy"
          alt="OnBord"
          class="logo"
        />
      </div>
      <div class="main-title" v-if="!notificationSent">
        Enter your email address and we’ll send you a link to reset your
        password.
      </div>
      <div class="main-title" v-if="notificationSent">
        <div>Notification Sent</div>
        <div class="sub">
          {{ successMessage }}
        </div>
      </div>
      <div class="form-block w-form" v-if="!notificationSent">
        <v-form name="changePasswordForm" class="form">
          <v-row class="mb-5">
            <v-col>
              <label for="email">Email</label>
              <v-text-field
                outlined
                type="email"
                maxlength="256"
                name="email"
                aria-required="true"
                v-model.trim="login"
                :backgroundColor="inputBackgroundColor"
                @blur="$v.login.$touch()"
                :error-messages="loginErrors"
                hide-details="auto"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <a
                id="sendResetEmail"
                class="btn"
                @click="doSendPasswordResetEmail(login)"
              >
                Send Password Reset Confirmation Code
              </a>
            </v-col>
          </v-row>
        </v-form>
        <v-row class="or-container">
          <v-col>
            <div class="or">or</div>
          </v-col>
        </v-row>
      </div>
      <v-row class="mt-3">
        <v-col>
          <router-link :to="goToRoute" class="btn secondary">
            Continue
          </router-link>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import { SEND_PASSWORD_RESET_EMAIL } from '@/stores/actions.type';
import {
  NOTIFICATION_TYPES,
  NotificationService,
} from '@/services/notification.service';
import Themable from '@/common/mixins/themable.mixin';
import LoginMixin from '@/common/mixins/login.mixin';
import PublicAssets from '@/common/mixins/publicasset.mixin';

export default {
  name: 'ForgotPassword',

  mixins: [PublicAssets, validationMixin, Themable, LoginMixin],

  data: () => ({
    login: null,
    notificationSent: false,
    goToRoute: {
      name: 'reset-password',
    },
    successMessage: '',
  }),

  validations() {
    return {
      login: {
        required,
        email,
      },
      validationGroup: ['login'],
    };
  },

  computed: {
    /* Dynamic Login errors */
    loginErrors() {
      const errors = [];
      if (!this.$v.login.$dirty) return errors;
      !this.$v.login.required && errors.push('Email is required');
      !this.$v.login.email && errors.push('Invalid email address');
      return errors;
    },
  },

  methods: {
    /* Perform Password Reset */
    doSendPasswordResetEmail(login) {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      const payload = { email: login };
      this.$store
        .dispatch(SEND_PASSWORD_RESET_EMAIL, payload)
        .then((response) => {
          const action = response.action;
          if (action === 'RESEND') {
            NotificationService.alert(
              {
                type: NOTIFICATION_TYPES.SUCCESS,
                title: 'Success',
                message:
                  'New login details sent due to unchanged temporary password. Please check your email.',
                okTitle: 'Ok',
              },
              response,
            );
            this.successMessage =
              'New login details sent due to unchanged temporary password. Please check your email and login again to change your new temporary password.';
            this.goToRoute = {
              name: 'login',
            };
          } else {
            NotificationService.alert(
              {
                type: NOTIFICATION_TYPES.SUCCESS,
                title: 'Success',
                message: 'Password reset code link sent!',
                okTitle: 'Ok',
              },
              response,
            );
            this.successMessage = `We sent an notification to your preferred contact method which contains a verification code to reset your Onbord password.`;
          }
          this.notificationSent = true;
        })
        .catch((error) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message:
                typeof error === 'string'
                  ? error
                  : 'Error sending password reset email link!',
              okTitle: 'Ok',
            },
            error,
          );
        });
    },
  },
};
</script>
<style lang="scss" scoped></style>
