<template>
  <div class="steps">
    <!-- Client Selection -->
    <div class="header-step-container">
      <a
        @click="go('clientSelection')"
        class="step-icon"
        @mouseover="showClientSelectionHover = true"
        @mouseout="showClientSelectionHover = false"
        :class="clientData.iconClass"
      >
        <div class="step-icon-inner">
          <i class="fas fa-people-arrows"></i>
        </div>
      </a>
      <div
        class="step-hover-text"
        @click="go('clientSelection')"
        :class="clientData.tooltipClass"
      >
        <span class="step-text"> Client Selection </span>
        <span class="completion-text empty"> &nbsp; </span>
      </div>
    </div>

    <!-- Data Selection -->
    <div class="header-step-container">
      <a
        @click="go('dataSelection')"
        class="step-icon"
        @mouseover="showSelectionHover = true"
        @mouseout="showSelectionHover = false"
        :class="selectionData.iconClass"
      >
        <div class="step-icon-inner">
          <i class="fas fa-database"></i>
        </div>
      </a>
      <div
        class="step-hover-text"
        @click="go('dataSelection')"
        :class="selectionData.tooltipClass"
      >
        <span class="step-text"> Data Selection </span>
        <span class="completion-text empty"> &nbsp; </span>
      </div>
    </div>

    <!-- Client 1 -->
    <div class="header-step-container">
      <a
        @click="go('clientOneName')"
        class="step-icon"
        @mouseover="showClientOneHover = true"
        @mouseout="showClientOneHover = false"
        :class="clientOneData.iconClass"
      >
        <div class="step-icon-inner">
          <i class="fas fa-user"></i>
        </div>
      </a>
      <div
        class="step-hover-text"
        @click="go('clientOneName')"
        :class="clientOneData.tooltipClass"
      >
        <span class="step-text"> Client 1 </span>
        <span class="completion-text">
          ({{ clientOneData.stepsComplete }}/3)
        </span>
      </div>
    </div>

    <!-- Client 2 -->
    <div class="header-step-container" v-if="showClientTwoMenus">
      <a
        @click="go('clientTwoName')"
        class="step-icon"
        @mouseover="showClientTwoHover = true"
        @mouseout="showClientTwoHover = false"
        :class="clientTwoData.iconClass"
      >
        <div class="step-icon-inner">
          <i class="fas fa-user"></i>
        </div>
      </a>
      <div
        class="step-hover-text"
        @click="go('clientTwoName')"
        :class="clientTwoData.tooltipClass"
      >
        <span class="step-text"> Client 2 </span>
        <span class="completion-text">
          ({{ clientTwoData.stepsComplete }}/3)
        </span>
      </div>
    </div>

    <!-- Account Data -->
    <div class="header-step-container" v-if="showAccountMenus">
      <a
        @click="go('accountSelection')"
        class="step-icon"
        @mouseover="showAccountsHover = true"
        @mouseout="showAccountsHover = false"
        :class="accountData.iconClass"
      >
        <div class="step-icon-inner">
          <i class="fas fa-tasks"></i>
        </div>
      </a>
      <div
        class="step-hover-text"
        @click="go('accountSelection')"
        :class="accountData.tooltipClass"
      >
        <span class="step-text"> Account Selections </span>
        <span class="completion-text">
          ({{ accountData.stepsComplete }}/2)
        </span>
      </div>
    </div>

    <!-- Advisory Data -->
    <div class="header-step-container" v-if="showAccountMenus">
      <a
        @click="go('advisoryRate')"
        class="step-icon"
        @mouseover="showAdvisoryHover = true"
        @mouseout="showAdvisoryHover = false"
        :class="advisoryData.iconClass"
      >
        <div class="step-icon-inner">
          <i class="fas fa-percent"></i>
        </div>
      </a>
      <div
        class="step-hover-text"
        @click="go('advisoryRate')"
        :class="advisoryData.tooltipClass"
      >
        <span class="step-text"> Advisory Rate </span>
        <span class="completion-text empty"> &nbsp; </span>
      </div>
    </div>

    <!-- Firm Roles -->
    <div class="header-step-container">
      <a
        @click="go('firmRoles')"
        class="step-icon"
        @mouseover="showFirmRolesHover = true"
        @mouseout="showFirmRolesHover = false"
        :class="firmRolesData.iconClass"
      >
        <div class="step-icon-inner">
          <i class="fas fa-address-card"></i>
        </div>
      </a>
      <div
        class="step-hover-text"
        @click="go('firmRoles')"
        :class="firmRolesData.tooltipClass"
      >
        <span class="step-text"> Firm Roles </span>
        <span class="completion-text empty"> &nbsp; </span>
      </div>
    </div>
    <!-- Firm Roles -->
    <div class="header-step-container">
      <a
        @click="go('customQuestions')"
        class="step-icon"
        @mouseover="showCustomQuestionsHover = true"
        @mouseout="showCustomQuestionsHover = false"
        :class="firmRolesData.iconClass"
      >
        <div class="step-icon-inner">
          <i class="fas fa-address-card"></i>
        </div>
      </a>
      <div
        class="step-hover-text"
        @click="go('customQuestions')"
        :class="customQuestionsData.tooltipClass"
      >
        <span class="step-text"> Custom Questions </span>
        <span class="completion-text empty"> &nbsp; </span>
      </div>
    </div>

    <!-- Review Data -->
    <div class="header-step-container">
      <a
        @click="go('reviewAndFinish')"
        class="step-icon"
        @mouseover="showReviewHover = true"
        @mouseout="showReviewHover = false"
        :class="reviewData.iconClass"
      >
        <div class="step-icon-inner">
          <i class="fas fa-clipboard-list"></i>
        </div>
      </a>
      <div
        class="step-hover-text"
        @click="go('reviewAndFinish')"
        :class="reviewData.tooltipClass"
      >
        <span class="step-text"> Review </span>
        <span class="completion-text empty"> &nbsp; </span>
      </div>
    </div>
  </div>
</template>
<script>
import ClientMixin from '@/common/mixins/client.mixin';
import { EventService } from '@/services/event.service';
import EventListener from '@/common/mixins/eventlistener.mixin';

export default {
  name: 'ClientMenu',
  mixins: [ClientMixin, EventListener],

  components: {},

  data: () => ({
    showClientSelectionHover: false,
    showSelectionHover: false,
    showClientOneHover: false,
    showClientTwoHover: false,
    showAccountsHover: false,
    showAdvisoryHover: false,
    showFirmRolesHover: false,
    showCustomQuestionsHover: false,
    showReviewHover: false,
    selectionType: null,
    secondClient: null,
  }),

  computed: {
    clientData() {
      let d = {
        tooltipClass: this.showClientSelectionHover ? 'show' : '',
        stepsComplete: this.clientSelectionCompletionSteps,
      };
      d.iconClass = this.isClientSelectionComplete ? 'complete' : 'empty';
      if (this.isClientSelectionRoute) d.iconClass += ' current';

      return d;
    },
    selectionData() {
      let d = {
        tooltipClass: this.showSelectionHover ? 'show' : '',
        stepsComplete: this.selectionCompletionSteps,
      };
      d.iconClass = this.isDataSelectionComplete ? 'complete' : 'empty';
      if (this.isDataSelectionRoute) d.iconClass += ' current';

      return d;
    },
    clientOneData() {
      let d = {
        tooltipClass: this.showClientOneHover ? 'show' : '',
        stepsComplete: this.clientOneCompletionSteps,
      };
      d.iconClass = this.isClientOneComplete ? 'complete' : 'empty';
      if (this.isClientOneRoute) d.iconClass += ' current';

      return d;
    },
    clientTwoData() {
      let d = {
        tooltipClass: this.showClientTwoHover ? 'show' : '',
        stepsComplete: this.clientTwoCompletionSteps,
      };
      d.iconClass = this.isClientTwoComplete ? 'complete' : 'empty';
      if (this.isClientTwoRoute) d.iconClass += ' current';

      return d;
    },
    accountData() {
      let d = {
        tooltipClass: this.showAccountsHover ? 'show' : '',
        stepsComplete: this.accountCompletionSteps,
      };
      d.iconClass = this.isAccountComplete ? 'complete' : 'empty';
      if (this.isAccountRoute) d.iconClass += ' current';

      return d;
    },
    advisoryData() {
      let d = {
        tooltipClass: this.showAdvisoryHover ? 'show' : '',
        stepsComplete: this.advisoryCompletionSteps,
      };
      d.iconClass = this.isAdvisoryComplete ? 'complete' : 'empty';
      if (this.isAdvisoryRoute) d.iconClass += ' current';

      return d;
    },
    firmRolesData() {
      let d = {
        tooltipClass: this.showFirmRolesHover ? 'show' : '',
        stepsComplete: 0,
      };
      d.iconClass = this.isFirmRolesComplete ? 'complete' : 'empty';
      if (this.isFirmRolesRoute) d.iconClass += ' current';

      return d;
    },
    customQuestionsData() {
      let d = {
        tooltipClass: this.showCustomQuestionsHover ? 'show' : '',
        stepsComplete: 0,
      };
      d.iconClass = this.isCustomQuestionComplete ? 'complete' : 'empty';
      if (this.isCustomQuestionsRoute) d.iconClass += ' current';

      return d;
    },
    reviewData() {
      let d = {
        tooltipClass: this.showReviewHover ? 'show' : '',
        stepsComplete: this.reviewCompletionSteps,
      };
      d.iconClass = this.isReviewComplete ? 'complete' : 'empty';
      if (this.isReviewRoute) d.iconClass += ' current';

      return d;
    },
    showAccountMenus() {
      return this.selectionType
        ? this.DATA_TYPES.TYPE_TWO === this.selectionType ||
            this.DATA_TYPES.TYPE_THREE === this.selectionType
        : this.canAddAccounts;
    },
    showClientTwoMenus() {
      return this.secondClient
        ? 'Y' === this.secondClient
        : this.addedSecondClient;
    },
  },

  methods: {
    // Emit event indicating navigation to a page
    go(route) {
      EventService.emit('navigate-to', route);
    },
  },

  /* Lifecycle hook */
  created() {
    // Listen for event indicating the initial data selection changed
    // This can hide/show account related sections
    this.listen('data-selection-changed', (selectionType) => {
      this.selectionType = selectionType;
    });

    // Listen for event indicating second client selection changed
    // This can hide/show second client related sections
    this.listen('add-second-client-changed', (secondClient) => {
      this.secondClient = secondClient;
    });
  },
};
</script>
<style lang="scss" scoped>
.steps {
  z-index: 100000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1263px) {
    flex-direction: column;
    align-items: stretch;
  }

  .header-step-container {
    position: relative;
    display: flex;
    margin-right: 10px;
    margin-left: 10px;
    flex-direction: column;
    align-items: center;

    .step-icon {
      position: relative;
      z-index: 1;
      display: flex;
      width: 40px;
      height: 40px;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: var(--primary-color);
      transition: all 200ms ease;
      color: var(--color-text-dark);

      &.empty {
        background-color: var(--label-text);
      }

      &.complete {
        .step-icon-inner {
          background-color: var(--primary-color);
          color: var(--color-white);
        }
      }

      &.current {
        .step-icon-inner {
          color: var(--primary-color);
          background-color: var(--color-white);
        }
        background-color: var(--primary-color);
      }

      .step-icon-inner {
        position: absolute;
        left: auto;
        top: auto;
        right: auto;
        bottom: auto;
        z-index: 3;
        display: flex;
        width: 85%;
        height: 85%;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: var(--color-white);
        color: var(--label-text);
      }

      @media screen and (max-width: 1263px) {
        overflow: hidden;
        width: 50px;
        height: 50px;
        margin-right: 15px;
        margin-bottom: 0px;
        margin-left: 0px;
        font-size: 24px;
      }
    }

    .step-hover-text {
      position: absolute;
      left: auto;
      right: auto;
      bottom: -140%;
      display: flex;
      padding-top: 50px;
      flex-direction: column;
      font-family: Roboto, sans-serif;
      color: var(--color-text-dark);
      font-size: 14px;
      font-weight: 500;
      white-space: nowrap;
      opacity: 0;

      &.show {
        opacity: 1;
      }

      .step-text {
        background-color: #fff;
      }

      .completion-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        line-height: 12px;

        &.empty {
          min-height: 12px;
        }
      }
      @media screen and (max-width: 1263px) {
        position: relative;
        bottom: auto;
        padding-top: 0px;
        font-size: 16px;
        opacity: 1;

        .completion-text {
          align-items: start !important;
          font-weight: 400;
        }
      }
    }

    @media screen and (max-width: 1263px) {
      display: flex;
      margin-bottom: 20px;
      flex-direction: row;
      align-items: center;
    }
  }
}
</style>
