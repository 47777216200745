<template>
  <div class="align-buttons">
    <span class="title-section">Select Transition Type</span>
    <v-row class="fill-width">
      <v-col class="buttons-container">
        <v-radio-group
          v-model="selectedTransition"
          row
          class="transition-radio-group"
        >
          <!-- TODO: radio group container should be responsive-->
          <v-col class="text-center" :cols="6">
            <v-radio
              :value="transitionTypes.PROTOCOL"
              :ripple="false"
              :color="primaryColor"
              label="Protocol"
              class="v-faux-checkbox"
            ></v-radio
          ></v-col>
          <v-col class="text-center" :cols="6">
            <v-radio
              :value="transitionTypes.NON_PROTOCOL"
              :ripple="false"
              :color="primaryColor"
              label="Non Protocol"
              class="v-faux-checkbox"
            ></v-radio
          ></v-col>
        </v-radio-group>
      </v-col>
    </v-row>
    <bottom-nav
      :nextDisabled="!isContinueActive"
      v-on:go-prev="prev()"
      v-on:go-next="continueAction()"
    ></bottom-nav>
  </div>
</template>

<script>
import { MENUS } from '@/common/constants';
import BottomNav from '@/components/common/BottomNav';
import { SET_SELECTED_TRANSITION_TYPE } from '@/stores/actions.type';
import { TRANSITION_TYPES } from '@/common/constants';

export default {
  components: { BottomNav },
  data: () => ({
    transitionTypes: TRANSITION_TYPES, // Expose the TransitionType to the template directly
    selectedTransition: TRANSITION_TYPES.NONE,
  }),
  computed: {
    isContinueActive() {
      // Check if a transition type has been selected
      return this.selectedTransition !== TRANSITION_TYPES.NONE;
    },
  },
  created() {
    this.$store.getters.selectedTransitionType
      ? (this.selectedTransition = this.$store.getters.selectedTransitionType)
      : (this.selectedTransition = TRANSITION_TYPES.NONE);
  },
  methods: {
    continueAction() {
      // Define actions based on the selected transition type
      const { PROTOCOL, NON_PROTOCOL } = this.transitionTypes;

      const actions = {
        [PROTOCOL]: () => {
          this.$store
            .dispatch(SET_SELECTED_TRANSITION_TYPE, TRANSITION_TYPES.PROTOCOL)
            .then(() => {
              this.$router.push({
                name: MENUS.TRANSITIONS.TRANSITION_CLIENTS.id,
              });
            });
        },
        [NON_PROTOCOL]: () => {
          this.$store
            .dispatch(
              SET_SELECTED_TRANSITION_TYPE,
              TRANSITION_TYPES.NON_PROTOCOL,
            )
            .then(() => {
              this.$router.push({
                name: MENUS.TRANSITIONS.TRANSITION_CLIENTS.id,
              });
            });
        },
        default: () => console.log('Continue for other cases'),
      };
      // Execute the selected action
      const selectedAction =
        actions[this.selectedTransition] || actions.default;
      selectedAction();
    },
    prev() {
      this.$router
        .push({
          name: MENUS.CLIENTS.id,
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.align-buttons {
  width: 100%;
  height: 80vh !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5% 0;

  @media (max-width: 768px) {
    padding: 10% 0;
  }
}

.fill-width {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  @media (max-width: 768px) {
    padding-top: 10%;
  }
}

.buttons-container {
  margin-top: 10%;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
}

.title-section {
  font-family: Roboto;
  font-size: 40px;
  font-weight: 400;
  line-height: 47px;
  letter-spacing: 0em;
  text-align: center;

  @media (max-width: 375px) {
    font-size: 20px;
    font-weight: 400;
  }
}

.v-faux-checkbox {
  width: 300px;
}

.text-center {
  display: flex;
  justify-content: center;
}

.transition-radio-group {
  width: 100%;
  display: flex;
  align-items: center;

  //FIXME: this media's not working, see why
  @media (max-width: 768px) {
    flex-direction: column !important;
    align-items: center;
  }
}
</style>
