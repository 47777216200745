<template>
  <div class="advisor">
    <help-drawer :title="helpTitle">
      <div>
        <p>
          <ul class="help-list">
            <li><strong>Primary Advisor</strong> &mdash;
              The primary advisor that formulates investment advice for the client/s and has direct client contact. Only one
              advisor may be given this role on this screen. The ADV part 2B for the selected advisor will be sent to the
              client/s.
            </li>
            <li><strong>Provides Advice</strong> &mdash;
              Any other advisor who formulates investment advice for the client/s and has direct client contact. Multiple
              advisors may be given this role on this screen. The ADV part 2B for all advisors selected will be sent to the
              client/s.
            </li>
            <li><strong>Primary CSA</strong> &mdash;
              The primary client service advisor who will handle communications with the client/s but will not be providing
              financial advice. Only one CSA may be given this role on this screen.
            </li>
            <li><strong>Secondary CSA</strong> &mdash;
              Any other client service advisors with access to this client/s.
            </li>
          </ul>
        </p>
      </div>
    </help-drawer>
    <v-container v-if="batchResponse">
      <div>
        <div v-if="batchResponse.failures.length === 0 && batchResponse.successCount > 0">
          <h1 class="mb-8">
            <span class="headerlight">All clients ({{ batchResponse.successCount }}) added successfully</span>
          </h1>
          <v-row justify="center">
            <v-btn @click="backToClients">Done</v-btn>
          </v-row>
        </div>
        <div v-else>
          <h1><span class="headerlight">{{ batchResponse.successCount }} clients added successfully</span></h1>
          <v-row justify="center">
            <h3>The following clients were not added:</h3>
          </v-row>
          <v-row justify="center" class="mb-8">
            <v-list dense>
              <v-list-item-group>
                <v-list-item v-for="(failure, index) in batchResponse.failures" :key="index">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ failure.data.primaryContact.firstName }} {{ failure.data.primaryContact.lastName }}
                      <span v-if="failure.data.secondaryContact"> & {{ failure.data.secondaryContact.firstName }} {{
                        failure.data.secondaryContact.lastName }}</span>
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ failure.error }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-row>
          <v-row justify="center">
            <v-btn @click="backToClients">Done</v-btn>
          </v-row>
        </div>
      </div>
    </v-container>
    <v-form v-if="!batchResponse" name="FirmRoles" class="form">
      <v-container class="teams-step-container">
        <v-row justify="center" class="mb-2">
          <v-col class="instructions text-center">
            <h1>
              <span class="headerlight">
                Select a <strong>CSV Batch File</strong> with Client Information
              </span>
            </h1>
            <v-file-input v-model="batchFile" prepend-icon="" class="faux-upload " hide-details="auto" accept="text/csv"
              ref="upload-batch" :clearable="false">
              <template v-slot:prepend-inner>
                <a class="btn upload-batch" style="display: block"
                  @click="() => $refs['upload-batch'].$refs.input.click()">
                  <v-icon small class="csv"> fas fa-file-csv </v-icon>
                  Upload Client List
                </a>
              </template>
            </v-file-input>
            <v-row class="mb-8 mt-8 d-flex flex-column align-center">
          <span class="mb-2">
            Download the <strong>CSV Template</strong>
          </span>
          <a class="upload-batch" @click="downloadTemplate()">
            <v-icon small class="csv"> fas fa-file-csv </v-icon>
            Download Template
          </a>
        </v-row>
          </v-col>
        </v-row>
        <v-row v-if="showTeam" class="mb-8">
          <v-col class="instructions">
            <h1>
              <span class="headerlight">
                Select <strong>Firm Roles</strong> for <strong>All Clients in Batch</strong>
              </span>
            </h1>

            <div class="help-link">
              <a @click="toggleHelp()"> {{ helpTitle }} </a>
            </div>
          </v-col>
        </v-row>
        <!-- No Firm Roles -->
        <div v-if="showTeam" class="justify-center teams-table">
          <v-row class="header-row">
            <v-col class="col-4 first">
              <h4> OnBord User </h4>
            </v-col>
            <v-col class="text-center">
              <h4>Role</h4>
              <span class="small">(Exactly ONE Primary Advisor and ONE Primary CSA MUST be selected)</span>
            </v-col>
          </v-row>
          <v-row v-for="(user, idx) in teamUsers" :key="user.id">
            <v-col class="col-4 first name">
              {{ user.name }}
            </v-col>
            <v-col>
              <v-row>
                <v-col>
                  <v-btn-toggle v-model="user.advisorRole" @change="advisorChanged(idx, $event)">

                    <v-btn small class="text-capitalize" v-if="user.hasADV2B && user.setupComplete"
                      :value="FIRM_ROLE_TYPES.PRIMARY_ADVISOR">
                      <font-awesome-icon size="2x" class="role-icon" icon="comments-dollar"></font-awesome-icon>
                      Primary Advisor
                    </v-btn>
                    <!-- We create a fake button as the else instead of making a disabled button so we can add a working tooltip -->
                    <div v-else class="v-btn v-btn--flat theme--light v-size--small text-capitalize"
                      style="color: rgba(0, 0, 0, 0.26)">
                      <v-tooltip top transition="none">
                        <template v-slot:activator="{ on }">
                          <div v-on="on">
                            <font-awesome-icon size="lg" class="role-icon" :style="{ color: errorColor }"
                              icon="exclamation-triangle"></font-awesome-icon>
                          </div>
                        </template>
                        <span v-html="getTooltipText(user, FIRM_ROLE_TYPES.PRIMARY_ADVISOR)"></span>
                      </v-tooltip>
                      Primary Advisor
                    </div>

                    <v-btn small class="text-capitalize" v-if="user.hasADV2B && user.setupComplete"
                      :value="FIRM_ROLE_TYPES.PROVIDES_ADVICE">
                      <font-awesome-icon size="2x" class="role-icon" style="color: rgb(64, 64, 96);"
                        icon="comments-dollar"></font-awesome-icon>
                      Provides Advice
                    </v-btn>
                    <!-- We create a fake button as the else instead of making a disabled button so we can add a working tooltip -->
                    <div v-else class="v-btn v-btn--flat theme--light v-size--small text-capitalize"
                      style="color: rgba(0, 0, 0, 0.26)">
                      <v-tooltip top transition="none">
                        <template v-slot:activator="{ on }">
                          <div v-on="on">
                            <font-awesome-icon size="lg" class="role-icon" :style="{ color: errorColor }"
                              icon="exclamation-triangle"></font-awesome-icon>
                          </div>
                        </template>
                        <span v-html="getTooltipText(user, FIRM_ROLE_TYPES.PROVIDES_ADVICE)"></span>
                      </v-tooltip>
                      Provides Advice
                    </div>
                  </v-btn-toggle>
                </v-col>

                <v-col>
                  <v-btn-toggle v-model="user.csaRole" @change="csaChanged(idx, $event)">
                    <v-btn small class="text-capitalize" v-if="user.setupComplete" :value="FIRM_ROLE_TYPES.PRIMARY_CSA">
                      <font-awesome-icon size="2x" class="role-icon" icon="user-headset"></font-awesome-icon>
                      Primary CSA
                    </v-btn>
                    <!-- We create a fake button as the else instead of making a disabled button so we can add a working tooltip -->
                    <div v-else class="v-btn v-btn--flat theme--light v-size--small text-capitalize"
                      style="color: rgba(0, 0, 0, 0.26)">
                      <v-tooltip top transition="none">
                        <template v-slot:activator="{ on }">
                          <div v-on="on">
                            <font-awesome-icon size="lg" class="role-icon" :style="{ color: errorColor }"
                              icon="exclamation-triangle"></font-awesome-icon>
                          </div>
                        </template>
                        <span v-html="getTooltipText(user, FIRM_ROLE_TYPES.PRIMARY_CSA)"></span>
                      </v-tooltip>
                      Primary CSA
                    </div>

                    <v-btn small class="text-capitalize" v-if="user.setupComplete" :value="FIRM_ROLE_TYPES.SECONDARY_CSA">
                      <font-awesome-icon size="2x" class="role-icon" icon="user-headset"
                        style="color: rgb(64, 64, 96);"></font-awesome-icon>
                      Secondary CSA
                    </v-btn>
                    <!-- We create a fake button as the else instead of making a disabled button so we can add a working tooltip -->
                    <div v-else class="v-btn v-btn--flat theme--light v-size--small text-capitalize"
                      style="color: rgba(0, 0, 0, 0.26)">
                      <v-tooltip top transition="none">
                        <template v-slot:activator="{ on }">
                          <div v-on="on">
                            <font-awesome-icon size="lg" class="role-icon" :style="{ color: errorColor }"
                              icon="exclamation-triangle"></font-awesome-icon>
                          </div>
                        </template>
                        <span v-html="getTooltipText(user, FIRM_ROLE_TYPES.SECONDARY_CSA)"></span>
                      </v-tooltip>
                      Secondary CSA
                    </div>
                  </v-btn-toggle>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <v-row justify="end">
          <v-btn class="my-8 mx-4" @click="sendBatchRequest" :disabled="$v.$invalid">Add Clients</v-btn>
          <v-btn class="my-8 mx-4" @click="backToClients">Cancel</v-btn>
        </v-row>
        <div v-if="selectionErrors.length > 0">
          <v-row class="step-form-container mobile align-center " v-for="error in selectionErrors" :key="error">
            <v-col class="error-area">
              {{ error }}
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-form>
  </div>
</template>
<script>
import HelpDrawer from "@/components/HelpDrawerComponent";
import { EventService } from "@/services/event.service";
import { validationMixin } from "vuelidate";
import { FIRM_ROLE_TYPES, MENUS } from "@/common/constants";
import { GET_TEAM_USERS, ADD_CLIENT_BATCH, DOWNLOAD_CSV_CLIENTS_TEMPLATE } from "@/stores/actions.type";
import {
  NOTIFICATION_TYPES,
  NotificationService,
} from "@/services/notification.service";

const primaryRequired = (value, vm) => {
  const primary = vm.teamUsers.find((u) => u.advisorRole === FIRM_ROLE_TYPES.PRIMARY_ADVISOR);
  return primary !== undefined;
};

const csaRequired = (value, vm) => {
  const csa = vm.teamUsers.find((u) => u.csaRole === FIRM_ROLE_TYPES.PRIMARY_CSA);
  return csa !== undefined;
};

export default {
  name: "AddClientBatch",

  components: { HelpDrawer },
  mixins: [validationMixin],

  data: () => ({
    teamUsers: [],
    firmRoles: null,
    batchFile: null,
    batchResponse: null,
  }),

  validations() {
    return {
      selections: {
        primaryRequired,
        csaRequired,
      },
    };
  },

  computed: {
    /* Dynamic errors */
    selectionErrors() {
      let errors = [];
      if (!this.$v.selections.$dirty) return errors;
      if (!this.$v.selections.primaryRequired)
        errors.push("A Primary Advisor is required");
      if (!this.$v.selections.csaRequired)
        errors.push("A Primary CSA is required");
      return errors;
    },
    helpTitle() {
      return "What does this mean?";
    },
    showTeam() {
      return this.batchFile !== null;
    }
  },

  methods: {
    downloadTemplate() {
      this.$store.dispatch(DOWNLOAD_CSV_CLIENTS_TEMPLATE)
    },
    advisorChanged(idx, newValue) {
      this.$v.$touch();
      if (newValue === FIRM_ROLE_TYPES.PRIMARY_ADVISOR) {
        this.teamUsers.forEach((u, uidx) => {
          if (idx !== uidx && u.advisorRole === FIRM_ROLE_TYPES.PRIMARY_ADVISOR)
            u.advisorRole = null;
        });
      }
    },
    csaChanged(idx, newValue) {
      this.$v.$touch();
      if (newValue === FIRM_ROLE_TYPES.PRIMARY_CSA) {
        this.teamUsers.forEach((u, uidx) => {
          if (idx !== uidx && u.csaRole === FIRM_ROLE_TYPES.PRIMARY_CSA)
            u.csaRole = null;
        });
      }
    },
    getTooltipText(user, type) {
      let text = "";
      if (type === FIRM_ROLE_TYPES.PRIMARY_CSA || type === FIRM_ROLE_TYPES.SECONDARY_CSA) {
        if (!user.setupComplete) {
          text = "This user cannot be designated a CSA<br>until they have finished completing their profile.";
        }
      } else if (type === FIRM_ROLE_TYPES.PRIMARY_ADVISOR) {
        if (!user.setupComplete) {
          text =
            "This user cannot be designated a Primary Advisor<br>until they have finished completing their profile.";
        } else if (!user.hasADV2B) {
          text =
            "This user cannot be designated a Primary Advisor<br>until your Company Administrator uploads their ADV 2B form.";
        }
      } else if (type === FIRM_ROLE_TYPES.PROVIDES_ADVICE) {
        if (!user.setupComplete) {
          text =
            "This user cannot be designated an Advisor providing advice<br>until they have finished completing their profile.";
        } else if (!user.hasADV2B) {
          text =
            "This user cannot be designated an Advisor providing advice<br>until your Company Adminstrator uploads their ADV 2B form.";
        }
      }

      return text;
    },

    /* Initialize Firm Roles*/
    initializeFirmRoles() {
      this.$store
        .dispatch(GET_TEAM_USERS)
        .then((response) => {
          response.forEach((u) => {
            u.name = `${u.firstName} ${u.lastName}`;
          });
          this.teamUsers = response;
        })
        .catch((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.SUCCESS,
              title: "Error",
              message: `Error getting users for team selection.`,
              okTitle: "Ok",
            },
            response
          );
        });
    },

    /* Set the Firm Roles */
    mapFirmRoles() {
      this.firmRoles = {
        primaryAdvisor: null,
        primaryCSA: null,
        secondaryAdvisor: [],
        secondaryCSA: [],
      };
      const primary = this.teamUsers.find((u) => u.advisorRole === FIRM_ROLE_TYPES.PRIMARY_ADVISOR);
      this.firmRoles.primaryAdvisor = primary
        ? { id: primary.id, name: primary.name }
        : null;
      const csa = this.teamUsers.find((u) => u.csaRole === FIRM_ROLE_TYPES.PRIMARY_CSA);
      this.firmRoles.primaryCSA = csa
        ? { id: csa.id, name: csa.name }
        : null;

      this.firmRoles.secondaryAdvisor = [];
      this.teamUsers.forEach((u) => {
        if (u.advisorRole === FIRM_ROLE_TYPES.PROVIDES_ADVICE)
          this.firmRoles.secondaryAdvisor.push({
            id: u.id,
            name: u.name,
          });
      });

      this.firmRoles.secondaryCSA = [];
      this.teamUsers.forEach((u) => {
        if (u.csaRole === FIRM_ROLE_TYPES.SECONDARY_CSA)
          this.firmRoles.secondaryCSA.push({
            id: u.id,
            name: u.name,
          });
      });
    },
    sendBatchRequest() {
      this.mapFirmRoles();
      const addBatchFormData = new FormData();
      addBatchFormData.append("team", new Blob([JSON.stringify(this.firmRoles)], { type: 'application/json' }));
      addBatchFormData.append("file", this.batchFile);
      this.$store
        .dispatch(ADD_CLIENT_BATCH, addBatchFormData)
        .then((response) => {
          this.batchResponse = response;
        })
        .catch(({ response }) => {
          let message = "Unexpected Error";
          
          if (response.data.message && response.data.message.error){
            message = response.data.message.error;
          }

          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: "Error",
              message: message,
              okTitle: "Ok",
            },
            response
          );
        });
    },
    backToClients() {
      this.$router
        .push({ name: MENUS.CLIENTS.id })
        .catch(() => { });
    },
    // Toggle Help Drawer
    toggleHelp() {
      EventService.emit("toggle-help");
    },
  },

  created() {
    this.FIRM_ROLE_TYPES = FIRM_ROLE_TYPES;
    this.MENUS = MENUS;

    // Initialize the current firm roles
    this.initializeFirmRoles();
  },
};
</script>
<style lang="scss" scoped>
.help-list li {
  display: list-item;
}

.teams-step-container {
  max-width: 1024px;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.teams-table {
  width: 100%;
  max-width: 100%;
  border: 2px solid var(--secondary-color);
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  .header-row {
    font-weight: 500;

    .col {
      flex-direction: column;
      justify-content: start !important;
      align-items: flex-start !important;
    }

    .col-2,
    .col-3 {
      @media screen and (max-width: 960px) {
        display: none !important;
      }
    }
  }

  >div:not(:first-of-type) {
    border-top: 2px solid var(--secondary-color);
  }

  >.row>div:not(:last-of-type) {
    border-right: 2px solid var(--secondary-color);

    @media screen and (max-width: 960px) {
      border-right: none;
    }
  }

  >.row {
    margin: 0;

    .cb-label {
      color: var(--color-text);
      font-size: 16px;
      font-weight: 500;
      margin-bottom: -5px;
    }

    .col {
      padding: 20px 14px;
      border-top: none;

      &.first {
        background-color: var(--color-light-grey);
      }

      &.name {
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
      }

      .warn {
        width: 24px;
        height: 24px;

        @media screen and (max-width: 960px) {
          width: auto;

          svg {
            width: 24px;
            height: 24px;
          }
        }

        .mobile-label {
          padding-left: 10px;
        }
      }

      @media screen and (max-width: 960px) {
        min-width: 100%;
      }

      .mobile-label {
        display: none;

        @media screen and (max-width: 960px) {
          display: inline-block;
        }
      }

      .v-btn {
        /* Fix a ~2px difference between buttons and divs styled as buttons */
        letter-spacing: normal;
        text-indent: 0;
      }

      .role-icon {
        /* keep button icons a fixed width so disabled buttons with warnings are equal in width */
        width: 36px;
      }

      .small {
        font-size: 0.75em;
      }

      ::v-deep .v-input--checkbox {
        margin-top: 0px;
      }
    }

    .col-2,
    .col-3 {
      justify-content: center;
      display: flex;

      @media screen and (max-width: 960px) {
        justify-content: start;
      }
    }

  }
}

::v-deep {
  .faux-upload {
    display: inline-block;
    padding: 0px;

    .upload-batch {
      color: var(--color-white) !important;
      text-decoration: none;
      max-width: 260px;
    }

    .csv {
      margin-right: 8px;
      color: #fff;
    }

    .v-input__slot {

      &:before,
      &:after {
        border-color: transparent !important;
        text-decoration-color: transparent !important;
        background-color: transparent;
      }

      .v-input__append-inner {
        width: 0px;
      }

      .v-text-field__slot {
        //display: none;
        font-size: 24px;
      }
    }
  }
}</style>
