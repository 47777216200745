export function replaceEndpointSlugsFromState(
  endpoint,
  rootState,
  from = 'state',
) {
  if (!rootState || !rootState.auth) {
    throw new Error('Invalid state provided to replaceEndpointSlugsFromState');
  }

  let parsed = '';

  switch (from) {
    case 'state': {
      const {
        user: { id: advisorId } = {},
        organisation: { id: organisationId } = {},
      } = rootState.auth.currentUser;

      if (!advisorId || !organisationId) {
        throw new Error(
          'Missing advisorId or organisationId in provided state',
        );
      }

      parsed = endpoint
        .replace(':advisorsId', advisorId)
        .replace(':organisationsId', organisationId);

      break;
    }
    case 'interview': {
      const { sessionToken: interviewId } = rootState.auth || {};

      if (!interviewId) {
        throw new Error('Missing interviewId in provided state');
      }

      parsed = endpoint.replace(':interviewsId', interviewId);

      break;
    }
    default: {
      throw new Error(
        `Invalid 'from' input (${from}) provided to replaceEndpointSlugs`,
      );
    }
  }

  return parsed;
}

export function replaceEndpointSlugs(
  endpoint,
  { advisorId, organisationId, transitionId },
) {
  const parsed = endpoint
    .replace(':advisorsId', advisorId)
    .replace(':organisationsId', organisationId)
    .replace(':transitionsId', transitionId);

  return parsed;
}
