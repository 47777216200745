<template>
  <div class="templates-container">
    <v-card class="edit-card">
      <v-card-title class="title">
        <span> Protocol Transitions </span>
      </v-card-title>
      <v-card-text class="data">
        <v-row>
          <v-col class="col-12">
            <div class="instructions">
              <p>
                Use the provided placeholders in your template to insert client
                or advisor information. <br />
              </p>
            </div>
            <v-textarea
              class="textarea sms-template"
              outlined
              name="protocolMessageTemplate"
              aria-required="true"
              auto-grow
              v-model="protocolMessageTemplate"
              :backgroundColor="inputBackgroundColor"
              hide-details="auto"
            />
            <div class="instructions">
              <p>
                <b>Mandatory placeholders</b> for Protocol Transitions:
                <span
                  v-for="placeholder in mandatoryProtocolPlaceholders"
                  :key="placeholder"
                >
                  <strong> {{ placeholder }} </strong>
                </span>
              </p>
              <p>
                <b>Available placeholders:</b>
                <span
                  v-for="placeholder in generalPlaceholders"
                  :key="placeholder"
                >
                  <strong> {{ placeholder }} </strong>
                </span>
              </p>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-col class="col-12">
          <a
            class="btn primary"
            :class="{ 'btn-disabled': !isProtocolTemplateChanged }"
            @click="
              isProtocolTemplateChanged &&
                saveMessageTemplate(transitionTypes.PROTOCOL)
            "
          >
            Save
          </a>
        </v-col>
      </v-card-actions>
    </v-card>
    <v-card class="edit-card">
      <v-card-title class="title">
        <span> Non-Protocol Transitions </span>
      </v-card-title>
      <v-card-text class="data">
        <v-row>
          <v-col class="col-12">
            <div class="instructions">
              <p>
                Use the provided placeholders in your template to insert client
                or advisor information. <br />
              </p>
            </div>
            <v-textarea
              class="textarea sms-template"
              outlined
              name="nonProtocolMessageTemplate"
              aria-required="true"
              auto-grow
              v-model="nonProtocolMessageTemplate"
              :backgroundColor="inputBackgroundColor"
              hide-details="auto"
            />
            <div class="instructions">
              <p>
                <b>Mandatory placeholders</b> for Non-Protocol Transitions:
                <span
                  v-for="placeholder in mandatoryNonProtocolPlaceholders"
                  :key="placeholder"
                >
                  <strong> {{ placeholder }} </strong>
                </span>
              </p>
              <p>
                <b>Available placeholders:</b>
                <span
                  v-for="placeholder in generalPlaceholders"
                  :key="placeholder"
                >
                  <strong> {{ placeholder }} </strong>
                </span>
              </p>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-col class="col-12">
          <a
            class="btn primary"
            :class="{ 'btn-disabled': !isNonProtocolTemplateChanged }"
            @click="
              isNonProtocolTemplateChanged &&
                saveMessageTemplate(transitionTypes.NON_PROTOCOL)
            "
          >
            Save
          </a>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import {
  NOTIFICATION_TYPES,
  NotificationService,
} from '@/services/notification.service';
import { UPDATE_TRANSITIONS_SMS_TEMPLATE } from '@/stores/actions.type';
import {
  TRANSITION_TYPES,
  MESSAGE_PLACEHOLDERS,
} from '../../../common/constants';
import { mapGetters } from 'vuex';

export default {
  name: 'MessageTemplates',

  data: () => ({
    protocolMessageTemplate: '',
    nonProtocolMessageTemplate: '',
    originalProtocolMessageTemplate: '',
    originalNonProtocolMessageTemplate: '',
  }),

  computed: {
    ...mapGetters(['currentCompany']),

    transitionTypes() {
      return TRANSITION_TYPES;
    },

    generalPlaceholders() {
      return MESSAGE_PLACEHOLDERS.filter(
        (placeholder) =>
          !placeholder.includes('emailLink') &&
          !placeholder.includes('statementLink'),
      ).map((placeholder) =>
        placeholder.replace(/organisation/g, 'organization'),
      );
    },

    mandatoryProtocolPlaceholders() {
      return MESSAGE_PLACEHOLDERS.filter((placeholder) =>
        placeholder.includes('statementLink'),
      );
    },

    mandatoryNonProtocolPlaceholders() {
      return MESSAGE_PLACEHOLDERS.filter((placeholder) =>
        placeholder.includes('emailLink'),
      );
    },

    isProtocolTemplateChanged() {
      return (
        this.protocolMessageTemplate !== this.originalProtocolMessageTemplate
      );
    },

    isNonProtocolTemplateChanged() {
      return (
        this.nonProtocolMessageTemplate !==
        this.originalNonProtocolMessageTemplate
      );
    },
  },

  created() {
    this.initializeTemplates();
  },

  methods: {
    initializeTemplates() {
      const protocolTemplate = this.currentCompany.notificationTemplates.find(
        (template) =>
          template.templateType === 'sms' &&
          template.templateName === 'TransitionStatement',
      );
      const nonProtocolTemplate =
        this.currentCompany.notificationTemplates.find(
          (template) =>
            template.templateType === 'sms' &&
            template.templateName === 'NonProtocolTransitionRequestEmail',
        );

      this.protocolMessageTemplate = protocolTemplate
        ? protocolTemplate.template
        : '';
      this.nonProtocolMessageTemplate = nonProtocolTemplate
        ? nonProtocolTemplate.template
        : '';

      this.originalProtocolMessageTemplate = this.protocolMessageTemplate;
      this.originalNonProtocolMessageTemplate = this.nonProtocolMessageTemplate;
    },

    saveMessageTemplate(transitionType) {
      const template =
        transitionType === this.transitionTypes.PROTOCOL
          ? this.protocolMessageTemplate
          : this.nonProtocolMessageTemplate;

      const payload = {
        template,
        transitionType,
      };

      this.$store
        .dispatch(UPDATE_TRANSITIONS_SMS_TEMPLATE, payload)
        .then(() => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.SUCCESS,
              title: 'Success',
              message: 'Message Template Saved!',
              okTitle: 'Ok',
            },
            null,
          );
        })
        .catch(({ response }) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error saving Template',
              message: response.data.message,
              okTitle: 'Ok',
            },
            response,
          );
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.templates-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 16px;
  gap: 16px;
}

.title {
  justify-content: center;
}

.sms-template {
  ::v-deep .v-text-field__slot {
    padding: 16px;
  }
}

.instructions {
  margin-top: 10px;
  font-size: 0.9rem;
  color: #333;

  p {
    margin: 5px 0 5px;
  }

  strong {
    color: var(--primary-color);
  }
}

.btn-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
