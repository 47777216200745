<template>
  <div class="align-buttons">
    <span class="title-section">Select Data Entry Method</span>
    <v-row class="fill-width">
      <!-- Hidden input for file uploading -->
      <!-- <input type="file" ref="fileInput" @change="handleFileUpload" style="display: none" accept=".csv" /> -->

      <v-col cols="auto" class="buttons-container">
        <v-checkbox
          hide-details
          v-model="isUploadCSVActive"
          :ripple="false"
          multiple="false"
          :color="primaryColor"
          class="v-faux-checkbox"
          label="transitionOption.UPLOAD_CSV"
          @change="triggerFileInput"
        >
          <template v-slot:label>
            <span class="cb-label"> {{ transitionOption.UPLOAD_CSV }} </span>
          </template>
        </v-checkbox>
        <v-checkbox
          hide-details
          v-model="isEditFormActive"
          multiple="false"
          :ripple="false"
          :color="primaryColor"
          label="transitionOption.ENTER_MANUALLY"
          class="v-faux-checkbox"
          @change="editForm"
        >
          <template v-slot:label>
            <span class="cb-label">{{ transitionOption.ENTER_MANUALLY }}</span>
          </template>
        </v-checkbox>
      </v-col>
    </v-row>

    <bottom-nav
      :nextDisabled="isUploadCSVActive || isEditFormActive ? false : true"
      v-on:go-prev="prev()"
      v-on:go-next="continueAction()"
    ></bottom-nav>
  </div>
</template>

<script>
import BottomNav from '@/components/common/BottomNav';
import { MENUS } from '@/common/constants';
import { TRANSITION_OPTION_TYPE } from '@/common/constants';
export default {
  data() {
    return {
      selectedButton: null,
      isUploadCSVActive: false,
      isEditFormActive: false,
      transitionOption: TRANSITION_OPTION_TYPE,
      // clientOneType= 'Upload CSV'   v-model="clientOneType"
    };
  },

  components: { BottomNav },

  computed: () => {},
  methods: {
    triggerFileInput(value) {
      this.selectedButton = TRANSITION_OPTION_TYPE.UPLOAD_CSV;
      this.isUploadCSVActive = value;
      this.isEditFormActive = false;
    },
    editForm(value) {
      this.selectedButton = TRANSITION_OPTION_TYPE.ENTER_MANUALLY;
      this.isEditFormActive = value;
      this.isUploadCSVActive = false;
    },
    continueAction() {
      const actions = {
        [TRANSITION_OPTION_TYPE.UPLOAD_CSV]: () => {
          this.$router
            .push({
              name: MENUS.TRANSITIONS.TRANSITION_BATCH.id,
            })
            .catch(() => {});
        },
        [TRANSITION_OPTION_TYPE.ENTER_MANUALLY]: () => {
          this.$router.push({
            name: MENUS.TRANSITIONS.TRANSITION_FORM_SELECTION.id,
          });
        },
        default: () => {
          console.log('Continue action for other cases');
          // Handle any other cases or default behavior here.
        },
      };

      // Get the selected button action or the default action if not found
      const selectedAction = actions[this.selectedButton] || actions.default;
      // Execute the selected action
      selectedAction();
    },
    /* Nav to Previous Page in flow */
    prev() {
      this.$router
        .push({
          name: MENUS.TRANSITIONS.TRANSITION_TYPE.id,
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.align-buttons {
  width: 100%;
  height: 80vh !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5% 0;

  @media (max-width: 768px) {
    padding: 10% 0;
  }
}

.fill-width {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  @media (max-width: 768px) {
    padding-top: 10%;
  }
}

.buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 10%;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
}

.title-section {
  font-family: Roboto;
  font-size: 40px;
  font-weight: 400;
  line-height: 47px;
  letter-spacing: 0em;
  text-align: center;

  @media (max-width: 375px) {
    font-size: 20px;
    font-weight: 400;
  }
}

.cb-label {
  font-size: 14px;
  font-family: Roboto;
}

.v-faux-checkbox {
  min-width: 300px;
}

.buttons-content {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-end;
}
</style>
