<template>
  <div class="step-container advisor">
    <v-form name="ClientPhone" class="form">
      <v-container class="client-step-container">
        <v-row class="mb-8">
          <v-col class="instructions">
            <h1>
              <span class="headerlight">
                What's {{ computedFirstName }}'s
                <strong>mobile phone number</strong>?
              </span>
            </h1>
            <div class="sub-header" v-if="showPhoneMatch">
              This number cannot be the same as
              <strong>
                {{ computedOtherName }} ({{ formattedOtherPhone }}) </strong
              >.
            </div>
          </v-col>
        </v-row>
        <v-row class="client-phone-container step-form-container">
          <v-col class="d-flex justify-center">
            <v-text-field
              class="npa"
              outlined
              maxlength="3"
              aria-required="true"
              v-model.trim="npa"
              :backgroundColor="inputBackgroundColor"
              @input="
                touchPhone();
                focusNxx();
              "
              @blur="touchPhone()"
              hide-details="auto"
              inputmode="numeric"
            />
            <div class="dash">
              <i class="fas fa-minus"></i>
            </div>
            <v-text-field
              outlined
              class="nxx"
              maxlength="3"
              name="nxx"
              ref="nxx"
              aria-required="true"
              v-model.trim="nxx"
              :backgroundColor="inputBackgroundColor"
              @input="
                touchPhone();
                focusLine();
              "
              @blur="touchPhone()"
              hide-details="auto"
              inputmode="numeric"
            />
            <div class="dash">
              <i class="fas fa-minus"></i>
            </div>
            <v-text-field
              class="line"
              outlined
              maxlength="4"
              name="line"
              ref="line"
              aria-required="true"
              v-model.trim="line"
              :backgroundColor="inputBackgroundColor"
              @input="touchPhone()"
              @blur="touchPhone()"
              hide-details="auto"
              inputmode="numeric"
            />
          </v-col>
          <v-row
            class="step-form-container error-container"
            v-if="hasPhoneErrors"
          >
            <v-col class="error-area">{{ phoneErrors }}</v-col>
          </v-row>
        </v-row>
      </v-container>
    </v-form>
    <bottom-nav v-on:go-prev="prev()" v-on:go-next="next()"></bottom-nav>
  </div>
</template>

<script>
import BottomNav from '@/components/common/BottomNav';
import AdvisorClientMixin from '@/common/mixins/advisor.client.mixin';
import { MENUS, validatePhoneLocal } from '@/common/constants';
import { EventService } from '@/services/event.service';

const matchPhone = (value, vm) => {
  return (
    (!vm.isSecondClient && value !== vm.clientTwoPhone) ||
    (vm.isSecondClient && vm.clientOnePhone !== value)
  );
};

export default {
  name: 'ClientPhone',

  mixins: [AdvisorClientMixin],

  components: { BottomNav },

  data: () => ({
    npa: null,
    nxx: null,
    line: null,
    internalPhone: null,
  }),

  validations() {
    return {
      phone: {
        validatePhoneLocal,
        matchPhone,
      },
      validationGroup: ['phone'],
    };
  },
  computed: {
    phone() {
      return this.review
        ? this.internalPhone
        : `${this.npa}${this.nxx}${this.line}`;
    },

    // Dynamic Email errors */
    phoneErrors() {
      let errors = '';

      if (!this.$v.phone.$dirty) return errors;
      if (!this.$v.phone.validatePhoneLocal) errors = 'Phone number is invalid';
      if (!this.$v.phone.matchPhone)
        errors = `Phone cannot match number for ${
          this.isSecondClient
            ? this.clientOne.firstName
            : this.clientTwo.firstName
        }`;
      return errors;
    },

    hasPhoneErrors() {
      return this.phoneErrors.length > 0;
    },

    showPhoneMatch() {
      return (
        (!this.isSecondClient && this.clientTwoPhone) ||
        (this.isSecondClient && this.clientOnePhone)
      );
    },

    computedFirstName() {
      return this.isSecondClient
        ? this.clientTwo.firstName || 'Client 2'
        : this.clientOne.firstName || 'Client 1';
    },
    computedOtherName() {
      return this.isSecondClient
        ? this.clientOne.firstName || 'Client 1'
        : this.clientTwo.firstName || 'Client 2';
    },
    formattedOtherPhone() {
      return this.isSecondClient
        ? this.clientOneFormattedPhone
        : this.clientTwoFormattedPhone;
    },
  },

  methods: {
    /*
       Do nothing for now.
       We don't mark phone as dirty until leave
    */
    touchPhone() {},

    /* Focus on NXX field when NPA is complete */
    focusNxx() {
      if (this.npa.length === 3) {
        this.$refs.nxx.focus();
      }
    },

    /* Focus on Line field when NXX is complete */
    focusLine() {
      if (this.nxx.length === 3) {
        this.$refs.line.focus();
      }
    },

    /* Map form data to working client */
    mapData() {
      this.clientObj.phone = this.phone;
    },

    /* Nav to Next Page in flow */
    next(route = null) {
      this.$v.phone.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.mapData();

      // Check if a route is provided else default case
      let to = route ? route.name : null;

      if (this.canAddAccounts) {
        to = MENUS.ADVISOR.CLIENT.ACCOUNT_SELECTION.id;
      } else {
        to = MENUS.ADVISOR.CLIENT.FIRM_ROLES.id;
      }

      // Handle second client case
      if (this.second) {
        to = this.canAddAccounts
          ? MENUS.ADVISOR.CLIENT.ACCOUNT_SELECTION.id
          : MENUS.ADVISOR.CLIENT.FIRM_ROLES.id;
      } else if (this.currentClient.addSecond === 'Y') {
        to = MENUS.ADVISOR.CLIENT.CLIENT_TWO_NAME.id;
      }

      this.goNext(to);
    },

    /* Nav to Previous Page in flow */
    prev() {
      // Previous in flow for current client
      this.goPrev(
        this.second
          ? MENUS.ADVISOR.CLIENT.CLIENT_TWO_EMAIL.id
          : MENUS.ADVISOR.CLIENT.CLIENT_ONE_EMAIL.id,
      );
    },
    formatPhoneNumber(phone) {
      return phone.replace(/\D/g, '').slice(-10);
    },
    containsNonDigits(phoneNumber) {
      return /\D/.test(phoneNumber);
    },
    getLast10Digits(phoneNumber) {
      return phoneNumber.slice(-10);
    }
  },

  created() {
    // Because we detect dirty on page leave
    // can't call goNext() on external nav as it will
    // always look like the form is dirty
    // Here we only mark the form as dirty if you modified
    // the phone when on the page.
    // This covers when you nav to page and immediately nav away using
    // top nav
    this.listen('navigate-to', (route) => {
      if (this.phone !== this.clientObj.phone) {
        this.$v.phone.$touch();
      }
      this.ignoreInProgessClient = true;
      this.$router.push({ name: route }).catch(() => {
        EventService.emit('toggle-main-drawer');
      });
    });

    let mobileNum

    if (this.clientObj.phone && this.clientObj.phone.trim().length > 0) {
      mobileNum =  this.containsNonDigits(this.clientObj.phone)
                    ? this.formatPhoneNumber(this.clientObj.phone)
                    : this.getLast10Digits(this.clientObj.phone);
    }
    

    // Initial form data for current client

    this.npa = mobileNum ? mobileNum.slice(0, 3) : null;
    this.nxx = mobileNum ? mobileNum.slice(3, 6) : null;
    this.line = mobileNum ? mobileNum.slice(-4) : null;
    this.internalPhone = mobileNum || null;
  },
};
</script>

<style lang="scss" scoped>
.client-phone-container {
  justify-content: center;
  align-content: center;
  flex-direction: column;

  .dash {
    display: inline-block;
    margin: auto 8px;
  }
  .npa,
  .nxx {
    max-width: 75px !important;
  }
  .line {
    max-width: 100px !important;
  }

  .error-container {
    justify-content: center;
    align-items: center;
  }
}
</style>
