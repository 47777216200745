/*
  Storage Service
  A wrapper around Local/Session Storage
*/
const Storage = {
  /* Set a value in session storage */
  ['set'](key, value) {
    let v = value;
    if (typeof v === 'object') {
      v = JSON.stringify(v);
    }
    window.sessionStorage.setItem(key, v);
  },

  /* Get a value from session storage */
  ['get'](key) {
    let v = window.sessionStorage.getItem(key);
    try {
      return JSON.parse(v);
    } catch (e) {
      return v;
    }
  },

  /* Remove a value from session storage */
  ['remove'](key) {
    window.sessionStorage.removeItem(key);
  },

  /* Remove ALL values from session storage */
  ['purge']() {
    window.sessionStorage.clear();
  },

  /* Set a value in local storage */
  ['setLocal'](key, value) {
    let v = value;
    if (typeof v === 'object') {
      v = JSON.stringify(v);
    }
    window.localStorage.setItem(key, v);
  },

  /* Get a value from local storage */
  ['getLocal'](key) {
    let v = window.localStorage.getItem(key);
    try {
      return JSON.parse(v);
    } catch (e) {
      return v;
    }
  },

  /* Remove a value from local storage */
  ['removeLocal'](key) {
    window.localStorage.removeItem(key);
  },

  /* Remove ALL values from local storage */
  ['purgeLocal']() {
    window.localStorage.clear();
  },
};

export default Storage;
