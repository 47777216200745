/*
  Message Service
  Manipulate messages
*/
const MessageService = {
  /* Contruct and error message from a response */
  constructErrorMessage(msg, response) {
    let message = msg;
    
    if (response && response.response && response.response.data) {
      let responseMessage = null;
  
      if (response.responseMessage) {
        responseMessage = response.responseMessage;
      } else {
        const { message } = response.response.data;
        if (typeof message === 'string') {
          responseMessage = message;
        } else if (typeof message === 'object') {
          responseMessage = message.error;
        }
      }
  
      if (responseMessage) {
        message += ` ${responseMessage}`;
      }
    }
  
    return message;
  }
};
export default MessageService;
