<template>
  <div class="auth-container">
    <div class="inner-container">
      <div class="logo-container">
        <img
          :src="logoPath"
          width="140"
          loading="lazy"
          alt="OnBord"
          class="logo"
          v-if="logoPath"
        />
      </div>
      <div>
        <h1>Email Link Requested</h1>
        <p class="paragraph">
          An email has been sent to your inbox. When you are ready to begin,
          click on the link provided in the email.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { SEND_DESKTOP_LINK } from '@/stores/actions.type';
import PublicAssets from '@/common/mixins/publicasset.mixin';

export default {
  name: 'DesktopLink',
  props: {
    token: {
      type: String,
      default: null,
    },
  },
  mixins: [PublicAssets],
  data: () => ({
    logoPath: null,
  }),

  computed: {},
  methods: {
    /* Send email link to access interview */
    sendEmailLink() {
      this.$store.dispatch(SEND_DESKTOP_LINK, this.token).then(() => {
        this.logoPath = `${this.publicPath}api/organization/assets/logo`;
      });
    },
  },

  created() {
    this.sendEmailLink();
  },
};
</script>
<style lang="scss" scoped>
.auth-container {
  align-items: flex-start;
  text-align: center;

  .inner-container {
    padding-top: 100px;
    max-width: 1024px;

    @media screen and (max-width: 960px) {
      padding-top: 0px;
    }
  }
}
</style>
