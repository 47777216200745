<template>
  <div class="step-container client">
    <help-drawer :title="helpTitle">
      <div>
        <p>
          An individual’s full name (no abbreviations or nicknames), middle name
          or initial (if you use this on official documents), and suffix which
          matches legal documents like a driver’s license or passport.
        </p>
      </div>
    </help-drawer>
    <v-form name="LegalName" class="form">
      <v-container class="client-step-container">
        <v-row class="mb-8">
          <v-col class="instructions">
            <h1>
              <span class="headerlight">
                What's your <strong>legal name?</strong>
              </span>
            </h1>
            <div class="help-link">
              <a @click="toggleHelp()">{{ helpTitle }}</a>
            </div>
          </v-col>
        </v-row>
        <data-submitted-message
          :submitted="isLegalNameComplete"
          v-on:hide-form="toggleFormState"
        />
        <v-row class="justify-center" v-if="!formHidden">
          <v-col class="step-form-container">
            <label for="firstName" class="required">First Name</label>
            <v-text-field
              outlined
              type="text"
              maxlength="256"
              name="firstName"
              aria-required="true"
              v-model.trim="firstName"
              :backgroundColor="inputBackgroundColor"
              @input="$v.firstName.$touch()"
              @blur="$v.firstName.$touch()"
              :error-messages="firstNameErrors"
              hide-details="auto"
              class="mb-2"
            />
            <label for="lastName" class="required">Last Name</label>
            <v-text-field
              outlined
              type="text"
              maxlength="256"
              name="lastName"
              aria-required="true"
              v-model.trim="lastName"
              :backgroundColor="inputBackgroundColor"
              @input="$v.lastName.$touch()"
              @blur="$v.lastName.$touch()"
              :error-messages="lastNameErrors"
              hide-details="auto"
              class="mb-2"
            />
            <v-row>
              <v-col>
                <label for="middleName">Middle Name</label>
                <v-text-field
                  outlined
                  type="text"
                  maxlength="256"
                  name="middleName"
                  v-model.trim="middleName"
                  :backgroundColor="inputBackgroundColor"
                  @input="$v.middleName.$touch()"
                  @blur="$v.middleName.$touch()"
                  hide-details="auto"
                />
              </v-col>
              <v-col>
                <label for="suffix">Suffix</label>
                <v-select
                  v-model="suffix"
                  ref="suffix"
                  :items="suffixes"
                  :backgroundColor="inputBackgroundColor"
                  outlined
                  placeholder="Choose"
                  dense
                  hide-details="auto"
                  @change="$v.suffix.$touch()"
                />
              </v-col>
            </v-row>
            <div class="skip-for-now">
              <a @click="skip()">Skip for now</a>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <bottom-nav v-on:go-prev="prev()" v-on:go-next="next()"></bottom-nav>
  </div>
</template>

<script>
import HelpDrawer from '@/components/HelpDrawerComponent';
import BottomNav from '@/components/common/BottomNav';
import DataSubmittedMessage from '@/components/client/DataSubmittedMessage';
import InterviewClientMixin from '@/common/mixins/interview.client.mixin';
import { MENUS, SUFFIXES } from '@/common/constants';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'LegalName',

  components: { BottomNav, HelpDrawer, DataSubmittedMessage },
  mixins: [InterviewClientMixin],

  data: () => ({
    firstName: null,
    lastName: null,
    middleName: null,
    suffix: null,
  }),
  validations() {
    return {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      middleName: {},
      suffix: {},
      validationGroup: ['firstName', 'lastName', 'middleName', 'suffix'],
    };
  },
  computed: {
    // Dynamic First Name errors */
    firstNameErrors() {
      const errors = [];
      if (!this.$v.firstName.$dirty) return errors;
      !this.$v.firstName.required && errors.push('First Name is required');
      return errors;
    },

    // Dynamic Last Name errors */
    lastNameErrors() {
      const errors = [];
      if (!this.$v.lastName.$dirty) return errors;
      !this.$v.lastName.required && errors.push('Last Name is required');
      return errors;
    },
    helpTitle() {
      return 'What does this mean?';
    },
  },
  methods: {
    /* Nav to Next Page in flow */
    next(route = null) {
      const to = route || MENUS.CLIENT.LEGAL_ADDRESS.id;
      if (!this.formHidden) {
        this.$v.$touch();
        if (this.$v.$invalid) return;
      } else {
        this.goNext(to);
        return;
      }

      const data = {
        firstName: this.firstName,
        lastName: this.lastName,
        middleName: this.middleName,
        suffix: this.suffix,
      };

      this.completeStep(MENUS.CLIENT.LEGAL_NAME.id, data)
        .then(() => {
          this.goNext(to);
        })
        .catch(() => {});
    },

    /* Nav to Previous Page in flow */
    prev() {
      this.goPrev(MENUS.CLIENT.CITIZENSHIP.id);
    },

    /* Skip to next Page in flow */
    skip() {
      this.goPrev(MENUS.CLIENT.LEGAL_ADDRESS.id);
    },
  },
  created() {
    // Initialize form data for current interview
    this.formHidden = this.isLegalNameComplete;
    this.suffixes = SUFFIXES;
  },
};
</script>
<style lang="scss" scoped></style>
