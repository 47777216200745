<template>
  <div v-cloak class="dashboard-main-container">
    <!-- Nav Drawer - Mobile -->
    <v-navigation-drawer
      v-model="drawer"
      right
      app
      clipped
      id="nav-drawer"
      :overlay-color="overlayColor"
      overlay-opacity="0.8"
      class="hidden-lg-and-up"
      width="220"
    >
      <user-views></user-views>
    </v-navigation-drawer>

    <!-- App Bar -->
    <v-app-bar app flat class="top-bar" height="69">
      <div class="top-bar-inner">
        <img :src="getLogo" alt="OnBord" class="logo" />
        <main-menu />
      </div>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <div
      v-if="!isProductAdmin && userNotComplete && !companyNotComplete"
      class="profile-not-complete"
    >
      You must complete your User Profile in order to access functionality.
    </div>
    <div
      v-if="!isProductAdmin && companyNotComplete"
      class="profile-not-complete"
    >
      You must complete the Company Profile in order to access functionality.
    </div>
    <v-container class="dashboard-content-container">
      <div class="page-area">
        <router-view :key="$route.fullPath"></router-view>
      </div>
    </v-container>
    <add-edit-company></add-edit-company>
    <add-edit-user></add-edit-user>
    <add-document></add-document>
    <add-template></add-template>
  </div>
</template>

<script>
import Themable from '@/common/mixins/themable.mixin';
import MainMenu from '@/components/advisor/AdvisorTopMenu';
import EventListener from '@/common/mixins/eventlistener.mixin';
import UserViews from '@/components/advisor/AdvisorViews';
import AddEditCompany from '@/components/advisor/dialog/AddEditCompany';
import AddDocument from '@/components/advisor/dialog/AddDocument';
import AddEditUser from '@/components/advisor/dialog/AddEditUser';
import PublicAssets from '@/common/mixins/publicasset.mixin';
import { GET_CURRENT_COMPANY } from '@/stores/actions.type';
import { mapGetters } from 'vuex';
import {
  NOTIFICATION_TYPES,
  NotificationService,
} from '@/services/notification.service';
import { PermissionService } from '@/services/permission.service';
import { ASSET_KEYS, USER_ROLES } from '@/common/constants';
export default {
  name: 'Dashboard',
  mixins: [PublicAssets, Themable, EventListener],

  components: {
    MainMenu,
    UserViews,
    AddEditCompany,
    AddEditUser,
    AddDocument,
  },

  data: () => ({
    drawer: null,
    companyObj: null,
  }),

  computed: {
    ...mapGetters(['currentUser', 'currentCompany']),
    userNotComplete() {
      return this.currentUser && !this.currentUser.user.setupComplete;
    },
    getLogo() {
      if (!this.currentCompany) return '';
      const logo = this.currentCompany.assets.find(
        (asset) => asset.assetType === ASSET_KEYS.LOGO,
      );
      return logo ? logo.assetLocation : '';
    },
    companyNotComplete() {
      return (
        this.currentCompany &&
        this.currentCompany &&
        !this.currentCompany.setupComplete
      );
    },
    isProductAdmin() {
      return (
        this.currentUser &&
        this.currentUser.roles &&
        this.currentUser.roles[0].id === USER_ROLES.PRODUCT_ADMIN.id
      );
    },
  },

  methods: {
    /* Toggle Nav Drawer */
    toggleDrawer() {
      this.drawer = !this.drawer;
    },

    /* Load the company data for a specific id */
    loadCompany(id) {
      this.companyObj = null;

      this.$store
        .dispatch(GET_CURRENT_COMPANY, id)
        .then((response) => {
          this.companyObj = response;
        })
        .catch((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: 'error retrieving company!',
              okTitle: 'Ok',
              okMethod: () => {
                this.$router.push({
                  name: PermissionService.getHomePage(
                    this.currentUser.roles[0],
                  ),
                });
              },
            },
            response,
          );
        });
    },
  },
  created() {
    // Listen for Drawer toggle event
    this.listen('toggle-main-drawer', this.toggleDrawer);
    this.loadCompany(this.currentUser.organisation.organisationId);

    // Force inital load to scroll to top
    // Helps Safari mobile initial view load
    window.scrollTo(0, 1);
  },
};
</script>

<style lang="scss" scoped>
.dashboard-main-container {
  height: 100vh;
  width: 100vw;
  padding-right: 0px !important;

  @media screen and (min-width: 961px) {
    overflow: auto;
  }

  @media screen and (max-width: 960px) {
    padding-top: 0px !important;
  }

  #nav-drawer {
    //border-left: 1px solid var(--secondary-color);
    background-color: var(--color-white);
    box-shadow: none;
    padding: 30px;
  }

  .top-bar {
    border-bottom: 1px solid hsla(0, 0%, 42.7%, 0.16) !important;
    background-color: var(--color-white) !important;
    right: 0px !important;
    justify-content: center;
    display: flex;

    @media screen and (max-width: 960px) {
      position: relative;
    }

    .top-bar-inner {
      align-items: center;
      justify-content: space-between;
      display: flex;
      width: 90vw;
      max-width: 1600px;

      .logo {
        height: 44px;

        @media screen and (max-width: 960px) {
          height: 32px;
          margin-left: 12px;
        }
      }
    }
  }

  .profile-not-complete {
    width: 100%;
    background-color: var(--error-color);
    color: var(--color-white);
    min-height: 32px;
    padding: 6px 0;
    line-height: normal;
    text-align: center;
    position: fixed;

    @media screen and (max-width: 960px) {
      position: relative;
    }

    z-index: 1;
  }

  .dashboard-content-container {
    height: auto;
    max-width: 100vw;
    min-height: calc(100vh - 69px);
    padding-bottom: 100px;
    background-color: var(--color-light-grey);

    .page-area {
      padding-top: 30px;
      max-width: 1024px;
      margin: auto;

      @media screen and (max-width: 960px) {
        padding-top: 25px;
      }

      .action-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;

        @media screen and (max-width: 960px) {
          flex-direction: column;
        }

        .btn {
          margin-bottom: 5px;
        }
      }
    }
  }
}
</style>
