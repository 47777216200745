<template>
  <div class="step-container client" :class="containerClass">
    <help-drawer :title="helpTitle">
      <div>
        <h4 class="pb-2">What is a beneficiary?</h4>
        <p>
          A <strong>beneficiary</strong> can be any person or entity the owner
          chooses to receive their <strong>retirement account</strong> after he
          or she dies. The total allocated to all beneficiaries must add up to
          100%.
          <a target="_blank" href="https://irs.gov">IRS.gov</a>
        </p>
        <h4 class="pb-2">What are Primary and Contingent beneficiaries?</h4>
        <p>
          A <strong>primary</strong> beneficiary will be the first in line to
          receive your <strong>IRA</strong> assets.<br />
          <strong>Contingent beneficiaries</strong> are also important because
          they will receive your assets if none of your
          <strong>primary beneficiaries</strong>
          are alive at the time of your passing.
        </p>
      </div>
    </help-drawer>
    <v-form name="AccountBeneficiaries" class="form">
      <v-container class="client-step-container beneficiary-container">
        <v-row class="mb-8">
          <v-col class="instructions">
            <h1>
              <span class="headerlight">
                Would you like to
                <strong> add Contingent Beneficiaries </strong>
                to
                <strong>
                  {{ clientData.applicantName }}'s {{ accountName }} ({{
                    label
                  }}) account?
                </strong>
              </span>
            </h1>
            <div class="pt-2 help-link">
              <a @click="toggleHelp()"> {{ helpTitle }} </a>
            </div>
          </v-col>
        </v-row>
        <data-submitted-message
          :submitted="isAccountContingentBeneficiaryComplete(instance)"
          v-on:hide-form="toggleFormState"
        />
        <div v-if="!formHidden">
          <v-row class="justify-center">
            <v-col class="step-form-container radio-container mb-4">
              <v-radio-group
                v-model="addAccountBeneficiaries"
                :error-messages="accountBeneficiaryErrors"
                hide-details="auto"
                @change="addInitialAccountBeneficiary()"
              >
                <v-radio
                  :ripple="false"
                  class="v-faux-radio mb-4"
                  label="Yes"
                  :value="true"
                ></v-radio>
                <div class="expander top" v-show="addAccountBeneficiaries">
                  <!-- Alternate Beneficiary -->
                  <div
                    v-for="(beneficiary, index) in alternateBeneficiaries"
                    :key="beneficiary"
                    class="beneficiary-block"
                  >
                    <div class="remove-area">
                      <span @click="removeAlternateBeneficiary(index)">
                        <v-icon small :color="textColor"> fas fa-trash </v-icon>
                      </span>
                    </div>
                    <v-row>
                      <v-col class="col-first-name">
                        <label for="firstName" class="required">
                          First Name
                        </label>
                        <v-text-field
                          outlined
                          type="text"
                          maxlength="256"
                          name="firstName"
                          aria-required="true"
                          v-model.trim="beneficiary.firstName"
                          :backgroundColor="inputBackgroundColor"
                          @input="
                            $v.alternateBeneficiaries.$each[
                              index
                            ].firstName.$touch()
                          "
                          @blur="
                            $v.alternateBeneficiaries.$each[
                              index
                            ].firstName.$touch()
                          "
                          :error-messages="
                            alternateBeneficiaryFirstNameErrors(index)
                          "
                          hide-details="auto"
                          class="mb-2"
                        />
                      </v-col>
                      <v-col class="col-last-name">
                        <label for="lastName" class="required">
                          Last Name
                        </label>
                        <v-text-field
                          outlined
                          type="text"
                          maxlength="256"
                          name="lastName"
                          aria-required="true"
                          v-model.trim="beneficiary.lastName"
                          :backgroundColor="inputBackgroundColor"
                          @input="
                            $v.alternateBeneficiaries.$each[
                              index
                            ].lastName.$touch()
                          "
                          @blur="
                            $v.alternateBeneficiaries.$each[
                              index
                            ].lastName.$touch()
                          "
                          :error-messages="
                            alternateBeneficiaryLastNameErrors(index)
                          "
                          hide-details="auto"
                          class="mb-2"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <label for="dob">Date of Birth</label>
                        <v-text-field
                          v-model="beneficiary.dob"
                          name="dob"
                          maxlength="8"
                          :placeholder="INPUT_DATE_FMT"
                          outlined
                          :backgroundColor="inputBackgroundColor"
                          @input="
                            $v.alternateBeneficiaries.$each[index].dob.$touch()
                          "
                          @blur="standardizeDate(index)"
                          :error-messages="alternateBeneficiaryDobErrors(index)"
                          dense
                          inputmode="numeric"
                        >
                          <template v-slot:append>
                            <span class="cal-icon">
                              <v-icon small color="#9b9b9b">
                                fas fa-calendar-alt
                              </v-icon>
                            </span>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col class="col-allocation-amt">
                        <label> Allocation Amount </label>
                        <allocation-picker
                          :value="beneficiary.allocationPercent"
                          :pickerType="PICKER_TYPES.ALLOCATION"
                          @update-number="updateAllocation($event, beneficiary)"
                        ></allocation-picker>
                      </v-col>
                    </v-row>
                    <div class="error-area" v-if="exceedsAllocationMax">
                      Total Allocations cannot exceed 100%
                    </div>
                  </div>
                  <div class="add-area">
                    <a @click="addAlternateBeneficiary">
                      <i class="fas fa-plus"></i>
                      Add another Contingent Beneficiary
                    </a>
                  </div>
                </div>
                <v-radio
                  :ripple="false"
                  class="v-faux-radio"
                  label="No"
                  :value="false"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <div class="skip-for-now">
            <a @click="skip()">Skip for now</a>
          </div>
        </div>
      </v-container>
    </v-form>
    <allocation-tracker :beneficiaries="allBeneficiaries"></allocation-tracker>
    <bottom-nav v-on:go-prev="prev()" v-on:go-next="next()"></bottom-nav>
  </div>
</template>

<script>
import HelpDrawer from '@/components/HelpDrawerComponent';
import BottomNav from '@/components/common/BottomNav';
import DataSubmittedMessage from '@/components/client/DataSubmittedMessage';
import InterviewClientMixin from '@/common/mixins/interview.client.mixin';
import { required, requiredIf } from 'vuelidate/lib/validators';
import {
  MENUS,
  formatDate,
  US_DATE_SHORT,
  INPUT_DATE_FMT,
  validInputDate,
  PICKER_TYPES,
  ACCOUNT_TYPES,
  DB_ACCOUNT_TYPES,
} from '@/common/constants';
import AllocationPicker from '@/components/common/ValuePicker.vue';
import AllocationTracker from '@/components/client/AllocationTracker';

const ALLOCATION_DEFAULT = 0;

// Custom validator for allocation totals
const allocationMin = (value, vm) => {
  if (!vm.addAccountBeneficiaries || vm.allBeneficiaries.length === 0)
    return true;
  return vm.allocationTotals === 100;
};

// Custom validator for number of beneficiaries
const atLeastOneBeneficiary = (value, vm) => {
  if (!vm.addAccountBeneficiaries) return true;

  return vm.allBeneficiaries.length > 0;
};

export default {
  name: 'AccountContingentBeneficiaries',
  props: {
    name: String,
    label: String,
  },

  components: {
    BottomNav,
    HelpDrawer,
    DataSubmittedMessage,
    AllocationPicker,
    AllocationTracker,
  },
  mixins: [InterviewClientMixin],

  data: () => ({
    addAccountBeneficiaries: null,
    maxDate: formatDate(window.moment()),
    alternateBeneficiaries: [],
  }),

  validations() {
    return {
      addAccountBeneficiaries: {
        required,
        allocationMin,
        atLeastOneBeneficiary,
      },
      alternateBeneficiaries: {
        $each: {
          firstName: {
            required: requiredIf(() => this.addAccountBeneficiaries),
          },
          lastName: {
            required: requiredIf(() => this.addAccountBeneficiaries),
          },
          dob: {
            validInputDate,
          },
          allocationPercent: {},
        },
      },
      validationGroup: ['addAccountBeneficiaries', 'alternateBeneficiaries'],
    };
  },
  watch: {
    alternateBeneficiaries: {
      deep: true,
      handler() {
        this.alternateBeneficiaries.forEach((b) => {
          const name = `${b.firstName ? b.firstName : ''} ${
            b.lastName ? b.lastName : ''
          }`;
          this.$set(b, 'name', name);
        });
      },
    },
  },
  computed: {
    /* Dynamic errors */
    accountBeneficiaryErrors() {
      const errors = [];
      if (!this.$v.addAccountBeneficiaries.$dirty) return errors;
      !this.$v.addAccountBeneficiaries.required &&
        errors.push('A selection is required');
      !this.$v.addAccountBeneficiaries.atLeastOneBeneficiary &&
        errors.push('A beneficiary must be chosen');
      !this.$v.addAccountBeneficiaries.allocationMin &&
        errors.push('Total allocations must equal 100%');
      return errors;
    },

    allBeneficiaries() {
      const all = [];
      if (this.formHidden || !this.addAccountBeneficiaries) return all;
      this.alternateBeneficiaries.forEach((b) => all.push(b));
      return all;
    },
    helpTitle() {
      return "What's a primary & contingent beneficiary?";
    },
    accountName() {
      return this.accountType === DB_ACCOUNT_TYPES.ITA
        ? ACCOUNT_TYPES.IRA
        : ACCOUNT_TYPES.ROTH_IRA;
    },
    allocationTotals() {
      let tots = 0;
      const benes = this.allBeneficiaries || [];
      benes.forEach((b) => {
        tots += b.allocationPercent ? parseInt(b.allocationPercent) : 0;
      });
      return tots;
    },
    exceedsAllocationMax() {
      return this.allocationTotals > 100;
    },
    containerClass() {
      return this.allBeneficiaries.length > 0 ? 'has-beneficiaries' : '';
    },
    instance() {
      return { name: this.accountType, label: this.label };
    },
  },

  methods: {
    // Standardize a date from input format to US Readable
    standardizeDate(index = -1) {
      // Linked Primary
      if (index !== -1) {
        // Alternate Beneficiary
        this.$v.alternateBeneficiaries.$each[index].dob.$touch();
        if (validInputDate(this.alternateBeneficiaries[index].dob)) {
          this.alternateBeneficiaries[index].dob = this.alternateBeneficiaries[
            index
          ].dob
            ? window
                .moment(
                  this.alternateBeneficiaries[index].dob,
                  INPUT_DATE_FMT,
                  true,
                )
                .format(US_DATE_SHORT)
            : null;
        }
      }
    },

    /* Validatons for a beneficiary instance first name */
    alternateBeneficiaryFirstNameErrors(idx) {
      const errors = [];
      if (!this.$v.alternateBeneficiaries.$each[idx].firstName.$dirty)
        return errors;
      !this.$v.alternateBeneficiaries.$each[idx].firstName.required &&
        errors.push('First Name is required');
      return errors;
    },

    /* Validatons for a beneficiary instance last name */
    alternateBeneficiaryLastNameErrors(idx) {
      const errors = [];
      if (!this.$v.alternateBeneficiaries.$each[idx].lastName.$dirty)
        return errors;
      !this.$v.alternateBeneficiaries.$each[idx].lastName.required &&
        errors.push('Last Name is required');
      return errors;
    },

    /* Validatons for a beneficiary instance DOB */
    alternateBeneficiaryDobErrors(idx) {
      const errors = [];
      if (!this.$v.alternateBeneficiaries.$each[idx].dob.$dirty) return errors;
      !this.$v.alternateBeneficiaries.$each[idx].dob.validInputDate &&
        errors.push('DOB must be a valid date (MMDDYYYY)');
      return errors;
    },

    /* Add the initial beneficiary instance */
    addInitialAccountBeneficiary() {
      this.$v.addAccountBeneficiaries.$touch();
      if (this.allBeneficiaries.length === 0) {
        this.addAlternateBeneficiary();
      }
    },

    /* Add a beneficiary instance */
    addAlternateBeneficiary() {
      this.alternateBeneficiaries.push({
        firstName: null,
        lastName: null,
        dob: null,
        allocationPercent: ALLOCATION_DEFAULT,
        activatorMenu: false,
      });
    },

    /* Remove a beneficiary instance */
    removeAlternateBeneficiary(idx) {
      this.alternateBeneficiaries.splice(idx, 1);
    },

    /* Update the allocation percentage for a beneficiary instance */
    updateAllocation(num, beneficiary) {
      beneficiary.allocationPercent = num;
    },

    /* Nav to Next Page in flow */
    next(route = null) {
      // Next is route passed in
      // Or next beneficiary route (if one exists)
      // Otherwise the finish page.
      const next =
        this.getNextBeneficiaryRoute(this.instance) ||
        this.getNextCustomQuestionRoute() ||
        MENUS.CLIENT.FINISH.id;
      const to = route || next;

      if (!this.formHidden) {
        this.$v.$touch();
        if (this.$v.$invalid || this.exceedsAllocationMax) return;
      } else {
        this.goNext(to);
        return;
      }

      const data = {
        instance: this.instance,
      };
      if (this.addAccountBeneficiaries) {
        data.beneficiaries = [];
        this.alternateBeneficiaries.forEach((b) => {
          const dobString = b.dob ? formatDate(b.dob, US_DATE_SHORT) : null;
          data.beneficiaries.push({
            firstName: b.firstName,
            lastName: b.lastName,
            dob: dobString,
            allocationAmount: b.allocationPercent,
          });
        });
      }

      this.completeStep(
        MENUS.CLIENT.ACCOUNT_CONTINGENT_BENEFICIARIES.id,
        data,
        this.instance,
      )
        .then(() => {
          this.goNext(to);
        })
        .catch(() => {});
    },

    /* Nav to Previous Page in flow */
    prev() {
      const prev = this.getPrevBeneficiaryRoute(this.instance);
      this.goPrev(prev);
    },

    /* Skip to next Page in flow */
    skip() {
      const next =
        this.getNextBeneficiaryRoute(this.instance) ||
        this.getNextCustomQuestionRoute() ||
        MENUS.CLIENT.FINISH.id;
      this.goPrev(next);
    },
  },

  created() {
    // Initialize form data for current interview
    this.accountType = this.name;
    this.PICKER_TYPES = PICKER_TYPES;
    this.INPUT_DATE_FMT = INPUT_DATE_FMT;
    this.formHidden = this.isAccountContingentBeneficiaryComplete(
      this.instance,
    );
  },
};
</script>
<style lang="scss" scoped>
.cal-icon {
  line-height: 32px;
  margin-right: 8px;
}
.expander {
  display: flex;
  flex-direction: column;

  &.top {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  .row {
    margin: -12px 0;

    @media screen and (max-width: 960px) {
      margin: -12px;
    }
  }

  .cb-label {
    margin-top: 5px;
  }

  .col {
    padding: 12px 0;

    &.col-first-name,
    &.col-dob {
      padding-right: 6px;
    }
    &.col-last-name,
    &.col-allocation-amt {
      padding-left: 6px;
    }

    @media screen and (max-width: 960px) {
      padding: 12px;
    }
  }
}
.half-width {
  width: calc(50% - 4px);
  overflow: hidden;
  margin: 0 0 0 4px !important;

  &.first {
    margin: 0 4px 0 0 !important;
  }
}
.has-beneficiaries {
  height: calc(100vh - 242px);
}
.beneficiary-container {
  padding-bottom: 60px;
}
.beneficiary-block {
  margin-top: 15px;
  padding-top: 10px;
  padding-bottom: 20px;
  border-top: 1px solid var(--secondary-color);
}
.add-area {
  margin-top: 10px;
}
.remove-area {
  display: flex;
  justify-content: flex-end;
}
</style>
