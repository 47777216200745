/*
  MIXIN for Public Assets
 */
const PublicAssetMixin = {
  data: () => ({
    publicPath: process.env.BASE_URL,
  }),
  computed: {
    lottiePath() {
      // Annoyance due to lottie-vue path not accepting absolute urls
      return `${this.publicPath.replace('/', '')}json/loaderAnimation.json`;
    },
  },
};

export default PublicAssetMixin;
