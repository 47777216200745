<template>
  <div class="step-container">
    <help-drawer :title="helpTitle">
      <div>
        <p>
          In order to open an investment account, your advisor and custodian are
          required by federal law
          <strong><i>(USA PATRIOT Act of 2001)</i></strong> to obtain your SSN
          to verify your identity and to help prevent money laundering and
          terrorist financing.
        </p>
      </div>
    </help-drawer>
    <v-form name="SSN" class="form">
      <v-container class="client-step-container">
        <v-row class="mb-8">
          <v-col class="instructions">
            <h1>
              <span class="headerlight">
                What's your <strong>Social Security Number?</strong>
              </span>
            </h1>
            <div class="help-link">
              <a @click="toggleHelp()">{{ helpTitle }}</a>
            </div>
          </v-col>
        </v-row>
        <data-submitted-message
          :submitted="isSSNComplete"
          v-on:hide-form="toggleFormState"
        />
        <div v-if="!formHidden">
          <v-row class="justify-center">
            <v-col class="step-form-container d-flex justify-center">
              <v-text-field
                class="ssn1"
                outlined
                maxlength="3"
                aria-required="true"
                v-model.trim="ssn1"
                :backgroundColor="inputBackgroundColor"
                @input="
                  touchSSN();
                  focusSSN2();
                "
                @blur="touchSSN()"
                hide-details="auto"
                inputmode="numeric"
              />
              <div class="dash">
                <i class="fas fa-minus"></i>
              </div>
              <v-text-field
                outlined
                class="ssn2"
                maxlength="2"
                name="ssn2"
                ref="ssn2"
                aria-required="true"
                v-model.trim="ssn2"
                :backgroundColor="inputBackgroundColor"
                @input="
                  touchSSN();
                  focusSSN3();
                "
                @blur="touchSSN()"
                hide-details="auto"
                inputmode="numeric"
              />
              <div class="dash">
                <i class="fas fa-minus"></i>
              </div>
              <v-text-field
                class="ssn3"
                outlined
                maxlength="4"
                name="ssn3"
                ref="ssn3"
                aria-required="true"
                v-model.trim="ssn3"
                :backgroundColor="inputBackgroundColor"
                @blur="touchSSN()"
                @input="touchSSN()"
                hide-details="auto"
                inputmode="numeric"
              />
            </v-col>
          </v-row>
          <v-row
            class="step-form-container justify-center align-center"
            v-if="hasSSNErrors"
          >
            <v-col class="error-area">{{ ssnErrors }}</v-col>
          </v-row>

          <div class="skip-for-now">
            <a @click="skip()">Skip for now</a>
          </div>
        </div>
      </v-container>
    </v-form>
    <bottom-nav v-on:go-prev="prev()" v-on:go-next="next()"></bottom-nav>
  </div>
</template>

<script>
import HelpDrawer from '@/components/HelpDrawerComponent';
import BottomNav from '@/components/common/BottomNav';
import DataSubmittedMessage from '@/components/client/DataSubmittedMessage';
import { EventService } from '@/services/event.service';
import InterviewClientMixin from '@/common/mixins/interview.client.mixin';
import { MENUS } from '@/common/constants';

const validateSSN = (value) => {
  let v = value.match(/\d/g);
  let r = v && v.length === 9 ? true : false;
  return r;
};

export default {
  name: 'LegalName',

  components: { BottomNav, HelpDrawer, DataSubmittedMessage },
  mixins: [InterviewClientMixin],

  data: () => ({
    ssn1: null,
    ssn2: null,
    ssn3: null,
  }),
  validations() {
    return {
      ssn: {
        validateSSN,
      },
      validationGroup: ['ssn'],
    };
  },
  computed: {
    ssn() {
      return `${this.ssn1 ? this.ssn1 : ''}${this.ssn2 ? this.ssn2 : ''}${
        this.ssn3 ? this.ssn3 : ''
      }`;
    },

    // Dynamic  errors */
    ssnErrors() {
      let errors = '';

      if (!this.$v.ssn.$dirty) return errors;
      if (!this.$v.ssn.validateSSN)
        errors = 'SSN must follow format: XXX-XX-XXXX';
      return errors;
    },
    hasSSNErrors() {
      return this.ssnErrors.length > 0;
    },
    helpTitle() {
      return 'Why do you need this?';
    },
  },
  methods: {
    /*
       Do nothing for now.
       We don't mark ssn as dirty until leave
    */
    touchSSN() {},

    /* Focus on SSN2 field if first field is complete */
    focusSSN2() {
      if (this.ssn1.length === 3) {
        this.$refs.ssn2.focus();
      }
    },

    /* Focus on SSN2 field if second field is complete */
    focusSSN3() {
      if (this.ssn2.length === 2) {
        this.$refs.ssn3.focus();
      }
    },

    /* Nav to Next Page in flow */
    next(route = null) {
      const to = route || MENUS.CLIENT.DOB.id;

      if (!this.formHidden) {
        this.$v.$touch();
        if (this.$v.$invalid) return;
      } else {
        this.goNext(to);
        return;
      }

      const data = {
        ssn: this.ssn,
      };
      this.completeStep(MENUS.CLIENT.SSN.id, data)
        .then(() => {
          this.goNext(to);
        })
        .catch(() => {});
    },

    /* Nav to Previous Page in flow */
    prev() {
      this.goPrev(MENUS.CLIENT.LEGAL_ADDRESS.id);
    },

    /* Skip to next Page in flow */
    skip() {
      this.goPrev(MENUS.CLIENT.DOB.id);
    },
  },

  created() {
    this.formHidden = this.isSSNComplete;

    // Because we detect dirty on page leave
    // can't call goNext() on external nav as it will
    // always look like the form is dirty
    // Here we only mark the form as dirty if you modified
    // the ssn when on the page.
    // This covers when you nav to page and immediately nav away using
    // top nav
    this.listen('navigate-to', (route) => {
      if (this.ssn.length > 0 && !this.formHidden) {
        this.$v.ssn.$touch();
      }
      this.ignoreInProgessClient = true;
      const routeObj = typeof route === 'object' ? route : { name: route };
      this.$router.push(routeObj).catch(() => {
        EventService.emit('toggle-main-drawer');
      });
    });
  },
};
</script>
<style lang="scss" scoped>
.dash {
  display: inline-block;
  margin: auto 8px;
}
.ssn1,
.ssn2 {
  max-width: 75px !important;
}
.ssn3 {
  max-width: 100px !important;
}
.col.instructions {
  @media screen and (max-width: 960px) {
    strong {
      display: block;
    }
  }
}
</style>
