/*
  VUEX Store Configuration and Definition
*/
import Vue from 'vue';
import Vuex from 'vuex';
import { createStore } from 'vuex-extensions';
import auth from '@/stores/modules/auth.module';
import advisor from '@/stores/modules/advisor.module';
import client from '@/stores/modules/client.module';

Vue.use(Vuex);

export default createStore(Vuex.Store, {
  modules: {
    auth,
    advisor,
    client,
  },
});
