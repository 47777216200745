export function clientMapper(request) {
  return Object.entries(request).reduce((acc, [key, value]) => {
    if (key === 'provideAdvice') {
      acc.secondaryAdvisor = value;
    } else {
      acc[key] = value;
    }
    return acc;
  }, {});
}
