<template>
  <div class="password-check d-flex align-center justify-center flex-column">
    <div class="password-check--dot best" :class="bestClass"></div>
    <div class="password-check--dot better" :class="betterClass"></div>
    <div class="password-check--dot fair" :class="fairClass"></div>
    <div class="password-check--dot weak" :class="weakClass"></div>
  </div>
</template>
<script>
export default {
  name: 'PasswordStrengthMeter',
  props: {
    value: {
      type: String,
    },
  },

  computed: {
    bestClass() {
      const strength = this.checkPassword(this.value);
      if (strength < 4) return '';
      return 'level3';
    },
    betterClass() {
      const strength = this.checkPassword(this.value);
      if (strength < 3) return '';
      return 'level3';
    },
    fairClass() {
      const strength = this.checkPassword(this.value);
      if (strength < 2) return '';
      else if (strength < 3) return 'level2';
      return 'level3';
    },
    weakClass() {
      const strength = this.checkPassword(this.value);
      if (strength < 0) return '';
      if (strength < 2) return 'level1';
      else if (strength < 3) return 'level2';
      return 'level3';
    },
  },

  methods: {
    /* eslint-disable */
    checkPassword(password) {
      if (!password) return -1;

      let password_length = password.length;
      const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
      const contains_eight_characters = password_length > 8;
      const contains_number = /\d/.test(password);
      const contains_uppercase = /[A-Z]/.test(password);
      const contains_special_character = format.test(password);

      let strength = 0;
      if (contains_eight_characters) strength++;
      if (contains_number) strength++;
      if (contains_special_character) strength++;
      if (contains_uppercase) strength++;
      return strength;
    },
  },
};
</script>
<style lang="scss" scoped>
.password-check {
  width: 30px;
  margin-top: -10px;
}
.password-check--dot {
  width: 5px;
  height: 5px;
  margin-top: 2px;
  margin-bottom: 2px;
  border-radius: 50px;
  background-color: var(--label-text);
  &.level1 {
    background-color: var(--error-color);
  }
  &.level2 {
    background-color: var(--color-warning);
  }
  &.level3 {
    background-color: var(--color-green);
  }
}
</style>