<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="430"
    :overlay-color="overlayColor"
    overlay-opacity="0.8"
    content-class="plain-dialog instructions-dialog"
  >
    <v-card flat>
      <v-card-text class="pa-7">
        <div class="modal-title">{{ modalTitle }}</div>
        <div class="modal-body" v-if="editBasicInfo">
          <v-row>
            <v-col>
              <label for="firstName" class="required"> First Name </label>
              <v-text-field
                outlined
                type="text"
                maxlength="256"
                name="firstName"
                aria-required="true"
                v-model.trim="firstName"
                :backgroundColor="inputBackgroundColor"
                @input="$v.firstName.$touch()"
                @blur="$v.firstName.$touch()"
                :error-messages="firstNameErrors"
                hide-details="auto"
                class="mb-2"
              />
            </v-col>
            <v-col>
              <label for="lastName" class="required"> Last Name </label>
              <v-text-field
                outlined
                type="text"
                maxlength="256"
                name="lastName"
                aria-required="true"
                v-model.trim="lastName"
                :backgroundColor="inputBackgroundColor"
                @input="$v.lastName.$touch()"
                @blur="$v.lastName.$touch()"
                :error-messages="lastNameErrors"
                hide-details="auto"
                class="mb-2"
              />
            </v-col>
          </v-row>
          <v-row
            v-if="isEditingSelf"
            class="client-phone-container step-form-container"
          >
            <v-col>
              <label for="phoneNumber" class="required"> Phone Number </label>
              <v-text-field
                class="phoneNumber"
                outlined
                name="phoneNumber"
                ref="phoneNumber"
                aria-required="true"
                v-model.trim="phoneNumber"
                :backgroundColor="inputBackgroundColor"
                @blur="$v.phoneNumber.$touch()"
                hide-details="auto"
                placeholder="e.g., 123-456-7890"
              />
              <div class="error-wrapper" v-if="hasPhoneErrors">
                <p class="error-area">{{ phoneErrors }}</p>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="!canChangeRole">
            <v-col>
              <label for="Title"> Role </label>
              <v-text-field
                outlined
                type="text"
                maxlength="256"
                name="role"
                v-model.trim="roleName"
                :backgroundColor="inputBackgroundColor"
                hide-details="auto"
                readonly
                class="mb-2"
              />
            </v-col>
          </v-row>
          <v-row v-if="canChangeRole">
            <v-col>
              <label class="required"> Role </label>
              <v-checkbox
                v-for="r in roles"
                :key="r.id"
                hide-details
                v-model="role"
                multiple="false"
                :ripple="false"
                :color="primaryColor"
                :value="r.id"
                class="role-box"
                dense
                @change="$v.role.$touch()"
              >
                <template v-slot:label>
                  <span class="cb-label"> {{ r.name }} </span>
                </template>
              </v-checkbox>
              <v-row
                v-if="roleErrors.length > 0"
                class="justify-center align-center"
              >
                <v-col class="error-area"> {{ roleErrors }} </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <div class="modal-body" v-if="editEmail">
          <v-row>
            <v-col>
              <label for="emailAddress" class="required">
                New Email Address
              </label>
              <v-text-field
                outlined
                type="email"
                maxlength="256"
                name="emailAddress"
                aria-required="true"
                v-model.trim="emailAddress"
                :backgroundColor="inputBackgroundColor"
                @blur="$v.emailAddress.$touch()"
                :error-messages="emailAddressErrors"
                hide-details="auto"
                class="mb-2"
              />
              <label for="confirmEmailAddress" class="required">
                Confirm Email Address
              </label>
              <v-text-field
                outlined
                type="email"
                maxlength="256"
                name="confirmEmailAddress"
                aria-required="true"
                v-model.trim="confirmEmailAddress"
                :backgroundColor="inputBackgroundColor"
                @blur="$v.confirmEmailAddress.$touch()"
                :error-messages="confirmEmailAddressErrors"
                hide-details="auto"
                class="mb-2"
              >
                <template v-slot:append><span></span></template>
              </v-text-field>
            </v-col>
          </v-row>
        </div>
        <div class="modal-body" v-if="!isImpersonator && editPassword">
          <v-row>
            <v-col>
              <label for="currentPassword" class="required">
                Current Password
              </label>
              <v-text-field
                outlined
                type="password"
                maxlength="256"
                name="currentPassword"
                aria-required="true"
                v-model.trim="currentPassword"
                :backgroundColor="inputBackgroundColor"
                append-icon="far fa-eye"
                @input="$v.currentPassword.$touch()"
                @blur="$v.currentPassword.$touch()"
                :error-messages="currentPasswordErrors"
                hide-details="auto"
              >
                <template v-slot:append><span></span></template>
              </v-text-field>

              <label for="password" class="required">New Password</label>
              <v-text-field
                outlined
                type="password"
                maxlength="256"
                name="password"
                aria-required="true"
                v-model.trim="password"
                :backgroundColor="inputBackgroundColor"
                append-icon="far fa-eye"
                @blur="$v.password.$touch()"
                :error-messages="passwordErrors"
                hide-details="auto"
              >
                <template v-slot:append>
                  <password-strength-meter
                    :value="password"
                  ></password-strength-meter>
                </template>
              </v-text-field>
              <label for="confirmPassword" class="required">
                Confirm New Password
              </label>
              <v-text-field
                outlined
                type="password"
                maxlength="256"
                name="confirmPassword"
                aria-required="true"
                v-model.trim="confirmPassword"
                :backgroundColor="inputBackgroundColor"
                append-icon="far fa-eye"
                @input="$v.confirmPassword.$touch()"
                @blur="$v.confirmPassword.$touch()"
                :error-messages="confirmPasswordErrors"
                hide-details="auto"
              >
                <template v-slot:append><span></span></template>
              </v-text-field>
            </v-col>
          </v-row>
        </div>
        <div class="modal-body" v-if="canEditRedtail && editRedtailInfo">
          <v-row>
            <v-col>
              <label for="crmUsername" class="required">
                Redtail Username
              </label>
              <v-text-field
                outlined
                type="text"
                maxlength="256"
                name="crmUsername"
                aria-required="true"
                v-model.trim="crmUsername"
                :backgroundColor="inputBackgroundColor"
                @input="$v.crmUsername.$touch()"
                @blur="$v.crmUsername.$touch()"
                :error-messages="crmUsernameErrors"
                hide-details="auto"
                class="mb-2"
              />
              <label for="crmPassword" class="required">
                Redtail Password
              </label>
              <v-text-field
                outlined
                type="password"
                maxlength="256"
                name="crmPassword"
                aria-required="true"
                v-model.trim="crmPassword"
                :backgroundColor="inputBackgroundColor"
                append-icon="far fa-eye"
                @input="$v.crmPassword.$touch()"
                @blur="$v.crmPassword.$touch()"
                :error-messages="crmPasswordErrors"
                hide-details="auto"
              >
                <template v-slot:append><span></span></template>
              </v-text-field>
            </v-col>
          </v-row>
        </div>
        <div class="modal-body" v-if="addNewUser">
          <v-row>
            <v-col>
              <label for="firstName" class="required"> First Name </label>
              <v-text-field
                outlined
                type="text"
                maxlength="256"
                name="firstName"
                aria-required="true"
                v-model.trim="firstName"
                :backgroundColor="inputBackgroundColor"
                @input="$v.firstName.$touch()"
                @blur="$v.firstName.$touch()"
                :error-messages="firstNameErrors"
                hide-details="auto"
                class="mb-2"
              />
            </v-col>
            <v-col>
              <label for="lastName" class="required"> Last Name </label>
              <v-text-field
                outlined
                type="text"
                maxlength="256"
                name="lastName"
                aria-required="true"
                v-model.trim="lastName"
                :backgroundColor="inputBackgroundColor"
                @input="$v.lastName.$touch()"
                @blur="$v.lastName.$touch()"
                :error-messages="lastNameErrors"
                hide-details="auto"
                class="mb-2"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <label for="emailAddress" class="required"> Email Address </label>
              <v-text-field
                outlined
                type="email"
                maxlength="256"
                name="emailAddress"
                aria-required="true"
                v-model.trim="emailAddress"
                :backgroundColor="inputBackgroundColor"
                @blur="$v.emailAddress.$touch()"
                :error-messages="emailAddressErrors"
                hide-details="auto"
                class="mb-2"
              />
              <template v-slot:append><span></span></template>
              <!-- </v-text-field> -->
            </v-col>
          </v-row>
          <v-row class="client-phone-container step-form-container">
            <v-col>
              <label for="phoneNumber" class="required"> Phone Number </label>
              <v-text-field
                class="phoneNumber"
                outlined
                name="phoneNumber"
                ref="phoneNumber"
                aria-required="true"
                v-model.trim="phoneNumber"
                :backgroundColor="inputBackgroundColor"
                @blur="$v.phoneNumber.$touch()"
                hide-details="auto"
                placeholder="e.g., 123-456-7890"
              />
              <div class="error-wrapper" v-if="hasPhoneErrors">
                <p class="error-area">{{ phoneErrors }}</p>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <label class="required"> Role </label>
              <v-checkbox
                v-for="r in newUserRoles"
                :key="r.id"
                hide-details
                v-model="role"
                multiple="false"
                :ripple="false"
                :color="primaryColor"
                :value="r.id"
                class="role-box"
                dense
                @change="$v.role.$touch()"
              >
                <template v-slot:label>
                  <span class="cb-label"> {{ r.name }} </span>
                </template>
              </v-checkbox>
              <v-row
                v-if="roleErrors.length > 0"
                class="justify-center align-center"
              >
                <v-col class="error-area"> {{ roleErrors }} </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div>
                <strong> ADV 2B: </strong>
              </div>
              <div>
                <v-file-input
                  v-model="documents.adv2b"
                  prepend-icon=""
                  class="faux-upload"
                  ref="upload-2B"
                  hide-details="auto"
                  :clearable="false"
                  accept="application/pdf"
                >
                  <template v-slot:prepend-inner>
                    <a
                      id="upload-2b"
                      class="btn mini"
                      style="display: block"
                      @click="() => $refs['upload-2B'].$refs.input.click()"
                    >
                      <v-icon small class="pdf"> fas fa-file-pdf </v-icon>
                      choose file
                    </a>
                  </template>
                </v-file-input>
                <span class="upload-date" v-if="documents.adv2b">
                  <p>
                    {{ uploadedFileName }}
                  </p>
                </span>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="modal-actions">
          <a v-if="editBasicInfo" @click="saveChanges()" class="btn mt-3">
            Save Changes
          </a>
          <a v-if="editEmail" @click="sendConfirmationEmail()" class="btn mt-3">
            Send Confirmation Email
          </a>
          <a v-if="editPassword" @click="savePassword()" class="btn mt-3">
            Save Password
          </a>
          <a v-if="editRedtailInfo" @click="linkCrm()" class="btn mt-3">
            Confirm Redtail Credentials
          </a>
          <a v-if="addNewUser" @click="createNewUser()" class="btn mt-3">
            Create
          </a>
          <a @click="cancel()" class="btn secondary mt-3"> Cancel </a>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Themable from '@/common/mixins/themable.mixin';
import EventListener from '@/common/mixins/eventlistener.mixin';
import { validationMixin } from 'vuelidate';
import { requiredIf, email, sameAs, minLength } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import {
  MENUS,
  USER_PROFILE_SECTION_TYPES,
  passwordComplexity,
  validatePhoneLocal,
  formatPhone,
  formatPhoneLocal,
} from '@/common/constants';
import PasswordStrengthMeter from '@/components/PasswordStrengthMeter';
import { USER_ROLES, EXPIRY_MINUTES } from '@/common/constants';
import {
  NOTIFICATION_TYPES,
  NotificationService,
} from '@/services/notification.service';
import {
  CHANGE_LOGIN_EMAIL,
  EDIT_USER,
  EDIT_SELF,
  CHANGE_PASSWORD,
  LINK_REDTAIL,
  LOGOUT,
  ADD_USER,
} from '@/stores/actions.type';
import { EventService } from '@/services/event.service';

const sameAsEmail = (value, vm) => {
  return value == vm;
};

const sameAsCurrent = (value, vm) => {
  if (!vm.currentPassword) return true;
  return value !== vm.currentPassword;
};

export default {
  name: 'AddEditUser',

  mixins: [validationMixin, Themable, EventListener],

  components: { PasswordStrengthMeter },

  data: () => ({
    roles: [],
    dialog: false,
    firstName: null,
    lastName: null,
    emailAddress: null,
    confirmEmailAddress: null,
    title: null,
    role: null,
    roleName: null,
    currentPassword: null,
    password: null,
    confirmPassword: null,
    crmUsername: null,
    crmPassword: null,
    id: null,
    section: null,
    isSetupComplete: false,
    isImpersonator: false,
    phoneNumber: null,
    documents: {
      adv2b: null,
    },
  }),

  validations() {
    return {
      firstName: {
        required: requiredIf(() => this.editBasicInfo || this.addNewUser),
      },
      lastName: {
        required: requiredIf(() => this.editBasicInfo || this.addNewUser),
      },
      phoneNumber: {
        required: requiredIf(() => this.editBasicInfo || this.addNewUser),
        validatePhoneLocal,
      },
      emailAddress: {
        required: requiredIf(() => this.editEmail || this.addNewUser),
        email,
      },
      confirmEmailAddress: {
        required: requiredIf(() => this.editEmail),
        sameAsEmail: (value) => {
          if (this.editEmail) return sameAsEmail(value, this.emailAddress);
          return true;
        },
      },
      role: {
        required: requiredIf(() => this.editBasicInfo || this.addNewUser),
      },
      currentPassword: {
        required: requiredIf(() => this.editPassword),
      },
      password: {
        required: requiredIf(() => this.editPassword),
        minLength: minLength(6),
        sameAsCurrent,
        passwordComplexity,
      },
      confirmPassword: {
        required: requiredIf(() => this.editPassword),
        sameAsPassword: sameAs(() => this.password),
      },
      crmUsername: {
        required: requiredIf(() => this.editRedtailInfo),
      },
      crmPassword: {
        required: requiredIf(() => this.editRedtailInfo),
      },
      validationGroup: [
        'firstName',
        'lastName',
        'emailAddress',
        'role',
        'confirmEmailAddress',
        'currentPassword',
        'password',
        'confirmPassword',
        'crmUsername',
        'crmPassword',
        'phoneNumber',
      ],
    };
  },

  computed: {
    ...mapGetters(['currentUser', 'currentUserRole', 'currentCompany']),

    newUserRoles() {
      return this.roles.filter((r) => r.id !== USER_ROLES.PRODUCT_ADMIN.id);
    },
    uploadedFileName() {
      return this.documents.adv2b ? this.documents.adv2b.name : '';
    },
    firstNameErrors() {
      const errors = [];
      if (!this.$v.firstName.$dirty) return errors;
      !this.$v.firstName.required && errors.push('First Name is required');
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      if (!this.$v.lastName.$dirty) return errors;
      !this.$v.lastName.required && errors.push('Last Name is required');
      return errors;
    },
    emailAddressErrors() {
      const errors = [];
      if (!this.$v.emailAddress.$dirty) return errors;
      !this.$v.emailAddress.required && errors.push('Email is required');
      !this.$v.emailAddress.email && errors.push('Invalid email address');
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.phoneNumber.$dirty) return errors;
      if (!this.$v.phoneNumber.required)
        errors.push('Phone number is required');
      if (!this.$v.phoneNumber.validatePhoneLocal)
        errors.push('Phone number must in US format: e.g., 123-456-7890');
      return errors;
    },
    hasPhoneErrors() {
      return this.phoneErrors.length > 0;
    },
    confirmEmailAddressErrors() {
      const errors = [];
      if (!this.$v.confirmEmailAddress.$dirty) return errors;
      !this.$v.confirmEmailAddress.required &&
        errors.push('Confirm email is required');
      !this.$v.confirmEmailAddress.sameAsEmail &&
        errors.push('Confirm email address does not match email address');
      return errors;
    },
    roleErrors() {
      let errors = '';
      if (!this.$v.role.$dirty) return errors;
      if (!this.$v.role.required) errors = 'Role is required';
      return errors;
    },
    currentPasswordErrors() {
      const errors = [];
      if (!this.$v.currentPassword.$dirty) return errors;
      !this.$v.currentPassword.required &&
        errors.push('Current Password is required');
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push('New Password is required');
      !this.$v.password.sameAsCurrent &&
        errors.push('New Password cannot be same as current password');
      !this.$v.password.minLength &&
        errors.push('New Password must be at least 6 characters');
      !this.$v.password.passwordComplexity &&
        errors.push(
          'At least 1 uppercase, 1 number and 1 special character are required',
        );
      return errors;
    },
    confirmPasswordErrors() {
      const errors = [];
      if (!this.$v.confirmPassword.$dirty) return errors;
      !this.$v.confirmPassword.required &&
        errors.push('Confirm Password is required');
      !this.$v.confirmPassword.sameAsPassword &&
        errors.push('Password and Confirm Password do not match');
      return errors;
    },
    crmUsernameErrors() {
      const errors = [];
      if (!this.$v.crmUsername.$dirty) return errors;
      !this.$v.crmUsername.required && errors.push('Username is required');
      return errors;
    },
    crmPasswordErrors() {
      const errors = [];
      if (!this.$v.crmPassword.$dirty) return errors;
      !this.$v.crmPassword.required && errors.push('Password is required');
      return errors;
    },
    modalTitle() {
      //if (!this.isExistingUser) return "Add User";
      let title = 'Edit Basic Info';
      switch (this.section) {
        case USER_PROFILE_SECTION_TYPES.EMAIL:
          title = 'Edit Email';
          break;
        case USER_PROFILE_SECTION_TYPES.PASSWORD:
          title = 'Change Password';
          break;
        case USER_PROFILE_SECTION_TYPES.REDTAIL:
          title = 'Edit Redtail Account';
          break;
        case USER_PROFILE_SECTION_TYPES.NEW_USER:
          title = 'Add User';
          break;
      }

      return title;
    },
    canCreateProductAdmin() {
      return true;
    },
    canChangeRole() {
      return (
        !this.isEditingSelf &&
        [USER_ROLES.COMPANY_ADMIN, USER_ROLES.PRODUCT_ADMIN].some(
          (allowedRole) => allowedRole.id === this.currentUserRole.id,
        )
      );
    },
    canEditRedtail() {
      return !this.isImpersonator && !this.isSetupComplete;
    },
    editBasicInfo() {
      return this.section === USER_PROFILE_SECTION_TYPES.BASIC;
    },
    editEmail() {
      return this.section === USER_PROFILE_SECTION_TYPES.EMAIL;
    },
    editPassword() {
      return this.section === USER_PROFILE_SECTION_TYPES.PASSWORD;
    },
    editRedtailInfo() {
      return this.section === USER_PROFILE_SECTION_TYPES.REDTAIL;
    },
    addNewUser() {
      return this.section === USER_PROFILE_SECTION_TYPES.NEW_USER;
    },
    isProductAdmin() {
      return this.currentUserRole.id === USER_ROLES.PRODUCT_ADMIN.id;
    },
    emailExpiry() {
      return EXPIRY_MINUTES.EMAIL_CHANGE;
    },
    isEditingSelf() {
      return this.currentUser && this.currentUser.user.id === this.id;
    },
    phone() {
      return formatPhoneLocal(this.phoneNumber);
    },
  },

  methods: {
    init() {
      this.firstName = null;
      this.lastName = null;
      this.emailAddress = null;
      this.confirmEmailAddress = null;
      this.title = null;
      this.role = null;
      this.roleName = null;
      this.currentPassword = null;
      this.password = null;
      this.confirmPassword = null;
      this.crmUsername = null;
      this.crmPassword = null;
    },

    /* Save User Data Changes - Add/Edit */
    saveChanges() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      const action = this.isEditingSelf ? EDIT_SELF : EDIT_USER;

      // All Users can change first/last name
      // Also Role (if not read-only)
      const userPayload = {
        advisorId: this.id,
        organisationId: this.organisationId,
        personalInfo: {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          phone: formatPhone(this.phone),
        },
        role: this.role,
      };

      this.$store
        .dispatch(action, userPayload)
        .then(() => {
          NotificationService.alert({
            type: NOTIFICATION_TYPES.SUCCESS,
            title: 'Success',
            message: `Changes Saved.`,
            okTitle: 'Ok',
            okMethod: () => {
              this.dialog = false;
              // Editing an existing user (from profile page)
              // Force the user to be reloaded.
              EventService.emit('reload-user-profile', this.id);
              // TODO: Should we log out current user if that's who is being edited.
            },
          });
        })
        .catch((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: 'An error occurred saving user changes.',
              okTitle: 'Ok',
            },
            response,
          );
        });
    },

    /* 
      Send email to confirm login change.
      Change will not take effect until they confirm.
    */
    sendConfirmationEmail() {
      this.$v.$touch();
      if (this.$v.emailAddress.$invalid || this.$v.confirmEmailAddress.$invalid)
        return;

      const userPayload = {
        isImpersonator: this.isImpersonator,
        advisorId: this.id,
        organisationId: this.organisationId,
        personalInfo: {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.emailAddress,
          role: this.role,
        },
      };
      if (this.isImpersonator) userPayload.id = this.user.id;

      const message = !this.isImpersonator
        ? `You should receive an email at the address you entered. 
                You must click on the link to confirm the email address change within
                the next ${this.emailExpiry} minutes for the address change to take effect.`
        : `The user should receive an email at the address you entered. 
                They must click on the link to confirm the email address change within
                the next ${this.emailExpiry} minutes for the address change to take effect.`;

      this.$store
        .dispatch(CHANGE_LOGIN_EMAIL, userPayload)
        .then(() => {
          NotificationService.alert({
            type: NOTIFICATION_TYPES.SUCCESS,
            title: 'Success',
            message: message,
            okTitle: 'Ok',
            okMethod: () => {
              this.dialog = false;
              // Log out current user if that's who is being edited.
              if (!this.isImpersonator) {
                this.$store
                  .dispatch(LOGOUT)
                  .then(() => {
                    this.$router.push({ name: MENUS.LOGIN.id });
                  })
                  .catch(() => {});
              } else {
                // Editing another user - refresh the user
                EventService.emit('reload-user-profile', this.id);
              }
            },
          });
        })
        .catch((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message:
                'Email Address change failed. The address you entered might be already in use!',
              okTitle: 'Ok',
            },
            response,
          );
        });
    },
    createNewUser() {
      this.$v.$reset();
      this.$v.$touch();
      if (this.$v.$invalid) return;
      const userPayload = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.emailAddress,
        phone: formatPhone(this.phone),
        role: this.role,
        adv2b: this.documents.adv2b,
      };
      // Add user
      this.$store
        .dispatch(ADD_USER, userPayload)
        .then(() => {
          // Success notification
          NotificationService.alert({
            type: NOTIFICATION_TYPES.SUCCESS,
            title: 'Success',
            message:
              'User successfully created!<br>User should receive an email invitation to log in.',
            okTitle: 'Ok',
            okMethod: () => {
              this.dialog = false;
            },
          });
        })
        .catch((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message:
                "User was unable to be created! It's possible a user with that email already exists in OnBord.",
              okTitle: 'Ok',
            },
            response,
          );
        });
    },
    /* Save password change */
    savePassword() {
      this.$v.$touch();
      if (
        this.$v.currentPassword.$invalid ||
        this.$v.password.$invalid ||
        this.$v.confirmPassword.$invalid
      )
        return;

      const payload = {
        oldPassword: this.currentPassword,
        newPassword: this.password,
        email: this.email,
      };
      this.$store
        .dispatch(CHANGE_PASSWORD, payload)
        .then(() => {
          NotificationService.alert({
            type: NOTIFICATION_TYPES.SUCCESS,
            title: 'Success',
            message: 'Your password has been changed',
            okTitle: 'Ok',
            okMethod: () => {
              this.dialog = false;
            },
          });
        })
        .catch((error) => {
          const errorMessage =
            error.response &&
            error.response.data &&
            error.response.data.exceptionMessage
              ? error.response.data.exceptionMessage
              : 'Password change failed.';
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: errorMessage,
              okTitle: 'Ok',
            },
            error,
          );
        });
    },

    /* Save/Confirm Redtail Credentials */
    linkCrm() {
      this.$v.$touch();
      if (this.$v.crmPassword.$invalid || this.$v.crmUsername.$invalid) {
        NotificationService.alert({
          type: NOTIFICATION_TYPES.ERROR,
          title: 'Error',
          message: 'Wrong credentials.',
          okTitle: 'Ok',
        });
        return;
      }

      const payload = {
        role: this.role,
        crmType: 'Redtail',
        credentials: {
          username: this.crmUsername,
          password: this.crmPassword,
        },
      };

      this.$store
        .dispatch(LINK_REDTAIL, payload)
        .then(() => {
          NotificationService.alert({
            type: NOTIFICATION_TYPES.SUCCESS,
            title: 'Success',
            message: `Successfully authenticated with Redtail`,
            okTitle: 'Ok',
            okMethod: () => {
              this.dialog = false;
              EventService.emit('reload-user-profile', this.id);
            },
          });
        })
        .catch((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: 'Failed to authenticate with Redtail.',
              okTitle: 'Ok',
            },
            response,
          );
        });
    },

    /* Dismiss with no changes */
    cancel() {
      this.dialog = false;
    },
  },

  /* Lifecycle Hook - copy payload from listened events */
  created() {
    const temp = [];
    Object.keys(USER_ROLES).map((key) => {
      temp.push(USER_ROLES[key]);
    });
    this.roles = temp.filter(
      (r) => this.isProductAdmin || r.id !== USER_ROLES.PRODUCT_ADMIN.id,
    );

    /* Listen for Events to invoke this modal */
    this.listen(
      'display-add-edit-user-modal',
      (user = null, section = USER_PROFILE_SECTION_TYPES.BASIC) => {
        this.init();
        this.dialog = true;
        this.$v.$reset();
        let userObj = user;
        this.section = section;
        if (user) {
          this.role = userObj.roles[0].id;
          this.roleName = userObj.roles[0].name;
          this.firstName = userObj.user.firstName;
          this.lastName = userObj.user.lastName;
          this.title = userObj.user.title;
          this.email = userObj.user.email;
          this.id = userObj.user.id;
          this.isSetupComplete = userObj.user.setupComplete;
          this.isImpersonator = userObj.isImpersonator;
          this.organisationId = userObj.user.organisationId;
          this.phoneNumber = formatPhoneLocal(userObj.user.mobilePhone);
        }
      },
    );
  },
};
</script>

<style lang="scss" scoped>
.modal-title {
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 28px;
  color: var(--color-text);
}

.error-wrapper {
  text-align: center;
  margin-top: 8px;
}

.role-box {
  padding-top: 0px;
  margin-top: 0px;
}
.faux-upload {
  display: inline-block;
  padding: 0px;
  ::v-deep .v-input__slot {
    &:before,
    &:after {
      border-color: transparent !important;
      text-decoration-color: transparent !important;
      background-color: transparent;
    }
    .v-input__append-inner {
      width: 0px;
    }
    .v-text-field__slot {
      display: none;
    }
    .pdf {
      margin-right: 8px;
      color: #fff;
    }
  }
}
.client-phone-container {
  justify-content: center;
  align-content: center;
  flex-direction: column;

  .dash {
    display: inline-block;
    margin: auto 8px;
  }

  .countryCode {
    max-width: 35% !important;
  }

  .number {
    max-width: 65% !important;
  }

  .error-container {
    justify-content: center;
    align-items: center;
    margin-top: 0;
  }
}

.cb-label {
  margin: 2px 0;
}
</style>
