/*
  MIXIN for Data Tables
 */

const DataTableMixin = {
  data: () => ({
    pagination: {},
  }),
  computed: {
    firstItem() {
      // the index of the item that is the top row displayed on the screen
      return this.pagination.pageStart;
    },
    itemsPerPage() {
      // the current number of items per page
      return this.pagination.itemsPerPage;
    },
  },
  methods: {
    updatePage(pagination) {
      // detect change in itemsPerPage
      if (this.itemsPerPage !== pagination.itemsPerPage) {
        for (let p = 0; p < pagination.pageCount; p++) {
          // figure out the page bounds
          const start = p * pagination.itemsPerPage;
          const end = start + pagination.itemsPerPage;
          // is the target item on this page?
          if (this.firstItem >= start && this.firstItem < end) {
            // yes, so set the page to this page
            pagination.page = p + 1;
            // break out of the loop
            break;
          }
        }
      }
      // update the pagination info
      this.pagination = pagination;
    },
  },
};

export default DataTableMixin;
