<template>
  <div class="step-container advisor">
    <v-form name="ClientEmail" class="form">
      <v-container class="client-step-container">
        <v-row class="mb-8">
          <v-col class="instructions">
            <h1>
              <span class="headerlight">
                What's {{ computedFirstName }}'s <strong>email address</strong>?
              </span>
            </h1>
          </v-col>
        </v-row>
        <v-row class="client-email-container">
          <v-col class="step-form-container">
            <v-text-field
              outlined
              type="email"
              maxlength="256"
              name="email"
              aria-required="true"
              v-model.trim="email"
              :backgroundColor="inputBackgroundColor"
              @blur="$v.email.$touch()"
              :error-messages="emailErrors"
              hide-details="auto"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <bottom-nav v-on:go-prev="prev()" v-on:go-next="next()"></bottom-nav>
  </div>
</template>

<script>
import BottomNav from '@/components/common/BottomNav';
import AdvisorClientMixin from '@/common/mixins/advisor.client.mixin';
import { MENUS } from '@/common/constants';
import { required, email } from 'vuelidate/lib/validators';

export default {
  name: 'ClientEmail',

  mixins: [AdvisorClientMixin],

  components: { BottomNav },

  data: () => ({
    email: null,
  }),
  validations() {
    return {
      email: {
        required,
        email,
      },
      validationGroup: ['email'],
    };
  },
  computed: {
    // Dynamic Email errors */
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.required && errors.push('Email is required');
      !this.$v.email.email && errors.push('Invalid email address');
      return errors;
    },
    computedFirstName() {
      return this.isSecondClient
        ? this.clientTwo.firstName || 'Client 2'
        : this.clientOne.firstName || 'Client 1';
    },
    computedOtherName() {
      return this.isSecondClient
        ? this.clientOne.firstName || 'Client 1'
        : this.clientTwo.firstName || 'Client 2';
    },
    computedOtherEmail() {
      return this.isSecondClient ? this.clientOne.email : this.clientTwo.email;
    },
  },

  methods: {
    /* Map form data to working client */
    mapData() {
      this.clientObj.email = this.email;
    },

    /* Nav to Next Page in flow */
    next(route = null) {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.mapData();

      // Next route passed in or next in flow for current client
      const to = route
        ? route.name
        : this.second
        ? MENUS.ADVISOR.CLIENT.CLIENT_TWO_PHONE.id
        : MENUS.ADVISOR.CLIENT.CLIENT_ONE_PHONE.id;
      this.goNext(to);
    },

    /* Nav to Previous Page in flow */
    prev() {
      // Previous in flow for current client
      this.goPrev(
        this.second
          ? MENUS.ADVISOR.CLIENT.CLIENT_TWO_NAME.id
          : MENUS.ADVISOR.CLIENT.CLIENT_ONE_NAME.id,
      );
    },
  },

  created() {
    // Initialize form data for current client
    this.email = this.clientObj.email;
  },
};
</script>
<style lang="scss" scoped>
.help {
  margin-bottom: -4px;
}
.tooltip {
  max-width: 200px;
}
.client-email-container {
  justify-content: center;
  align-content: align-center;
  flex-direction: column;
}
</style>
