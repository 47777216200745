<template>
  <div class="step-container advisor">
    <v-form name="ClientEmail" class="form">
      <v-container class="client-step-container">
        <v-row class="mb-8">
          <v-col class="instructions">
            <h1>
              <span class="headerlight">
                What's the
                <strong>Household Value</strong>?
              </span>
            </h1>
          </v-col>
        </v-row>
        <v-row class="client-household-container">
          <v-col class="step-form-container">
            <v-text-field
              outlined
              ref="householdValueInput"
              type="text"
              maxlength="256"
              name="householdValue"
              aria-required="true"
              v-model.trim="householdValue"
              inputmode="numeric"
              @input="$v.householdValue.$touch()"
              :backgroundColor="inputBackgroundColor"
              :error-messages="numberErrors"
              hide-details="auto"
            />
          </v-col>
          <a @click="skip()" class="btn secondary mt-3"> Skip for now </a>
        </v-row>
      </v-container>
    </v-form>
    <v-dialog
      v-model="addAnotherHouseholdDialog"
      persistent
      width="430"
      :overlay-opacity="0.8"
      :overlay-color="overlayColor"
      content-class="plain-dialog"
    >
      <v-card flat>
        <v-card-text class="pa-7">
          <div
            class="modal-title"
            style="font-size: 18px; text-align: center; line-height: 30px"
          >
            Are you ready to transition your clients?
          </div>
          <div class="modal-actions">
            <v-row class="justify-center">
              <v-col class="step-form-container radio-container">
                <v-radio-group
                  v-model="isReadyToTransition"
                  hide-details="auto"
                  class="pb-7"
                >
                  <v-radio
                    :ripple="false"
                    class="v-faux-radio mb-4"
                    label="Finish and Review"
                    :value="true"
                    @click="reviewTransitioningClients"
                  ></v-radio>
                  <v-radio
                    :ripple="false"
                    class="v-faux-radio"
                    label="Add another Household"
                    :value="false"
                    @click="addAnotherHousehold"
                  ></v-radio>
                </v-radio-group>
                <a
                  @click="saveDecisionModal()"
                  v-if="isReadyToTransition !== null"
                  class="btn mt-3"
                  >Proceed</a
                >
                <a @click="cancelDecisionModal()" class="btn secondary mt-3"
                  >Cancel</a
                >
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <bottom-nav
      :nextDisabled="$v.householdValue.$invalid"
      v-on:go-prev="prev()"
      v-on:go-next="next()"
    ></bottom-nav>
  </div>
</template>

<script>
import BottomNav from '@/components/common/BottomNav';
import { validationMixin } from 'vuelidate';
import { MENUS } from '@/common/constants';
import { required, decimal } from 'vuelidate/lib/validators';
import {
  STORE_HOUSEHOLD_VALUE,
  ADD_TRANSITION_HOUSEHOLDS,
  EMPTY_TRANSITIONING_HOUSEHOLD,
} from '@/stores/actions.type';
import AdvisorTransitionMixin from '@/common/mixins/advisor.transition.mixin';
import { mapGetters } from 'vuex';

export default {
  name: 'TransitionHouseholdValue',

  mixins: [validationMixin, AdvisorTransitionMixin],

  components: { BottomNav },

  data: () => ({
    householdValue: null,
    invalidInputError: '',
    addAnotherHouseholdDialog: false,
    decisionDialog: false,
    isReadyToTransition: null,
  }),

  validations() {
    return {
      householdValue: {
        required,
        decimal,
        positive: (value) => value >= 0,
      },
      validationGroup: ['number'],
    };
  },

  computed: {
    ...mapGetters(['transitioningHousehold', 'selectedTransitionType']),
    transitionType() {
      return this.selectedTransitionType;
    },
    formattedHouseholdValue() {
      return this.formatNumber(this.householdValue);
    },
    numberErrors() {
      const errors = [];
      if (!this.$v.householdValue.$dirty) return errors;
      !this.$v.householdValue.required &&
        errors.push('Household Value is required');
      !this.$v.householdValue.decimal && errors.push('Invalid Household value');
      if (this.invalidInputError) errors.push(this.invalidInputError);
      return errors;
    },
  },

  methods: {
    skip() {
      //To be sure the popup with the "Add another household" or "finish and review" appears:
      this.addAnotherHouseholdDialog = true;
      this.$store.dispatch(STORE_HOUSEHOLD_VALUE, this.householdValue);
    },
    formatNumber(value) {
      if (Number.isNaN(value)) return value;
      return new Intl.NumberFormat('en-US').format(value);
    },
    // If the advisor wants to add more households:
    addAnotherHousehold() {
      this.isReadyToTransition = false;
    },
    // If the advisor is ready to transition clients
    reviewTransitioningClients() {
      this.isReadyToTransition = true;
    },
    // "Proceed" button, inside the popup
    saveDecisionModal() {
      this.$store
        .dispatch(STORE_HOUSEHOLD_VALUE, this.householdValue)
        .then(() => {
          this.$store
            .dispatch(ADD_TRANSITION_HOUSEHOLDS, this.transitioningHousehold)
            .then(() => {
              this.$store.dispatch(EMPTY_TRANSITIONING_HOUSEHOLD);
            });
        });

      if (this.isReadyToTransition) {
        this.addAnotherHouseholdDialog = false;
        this.$router.push({
          name: MENUS.TRANSITIONS.ENTER_MANUALLY.REVIEW.id,
          replace: true,
        });
      } else {
        this.addAnotherHouseholdDialog = false;
        this.$router.push({
          name: MENUS.TRANSITIONS.TRANSITION_FORM_SELECTION.id,
          replace: true,
        });
      }
    },
    cancelDecisionModal() {
      this.addAnotherHouseholdDialog = false;
      this.isReadyToTransition = null;
    },

    next() {
      //To be sure the popup with the "Add another household" or "finish and review" appears:
      this.addAnotherHouseholdDialog = true;
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.$store.dispatch(STORE_HOUSEHOLD_VALUE, this.householdValue);
      //We don't need to do the routing here because that's being handled by the modal
    },
    prev() {
      this.$router.push({
        name: MENUS.TRANSITIONS.ENTER_MANUALLY.FIRM_ROLES.id,
      });
    },
  },

  created() {
    if (this.transitioningHousehold) {
      this.householdValue = this.formatNumber(
        this.transitioningHousehold.householdValue || 0,
      );
    }
  },
};
</script>

<style lang="scss" scoped>
.help {
  margin-bottom: -4px;
}

.tooltip {
  max-width: 200px;
}

.modal-title {
  margin-bottom: 18px;
  font-size: 20px;
  line-height: 32px;
  color: var(--color-text);
}

.btn {
  font-size: 18px;
}

.client-household-container {
  justify-content: center;
  align-content: align-center;
  flex-direction: column;
}
</style>
