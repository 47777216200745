<template>
  <div class="step-container advisor">
    <v-form name="ClientName" class="form">
      <v-container class="client-step-container">
        <v-row class="mb-8">
          <v-col class="instructions">
            <h1>
              <span class="headerlight"
                >What's your {{ clientLabel }} <strong>name</strong>?</span
              >
            </h1>
          </v-col>
        </v-row>
        <v-row class="client-name-container">
          <v-col class="step-form-container">
            <label for="firstName" class="required">First Name</label>
            <v-text-field
              outlined
              type="text"
              maxlength="256"
              name="firstName"
              aria-required="true"
              v-model.trim="firstName"
              :backgroundColor="inputBackgroundColor"
              @input="$v.firstName.$touch()"
              @blur="$v.firstName.$touch()"
              :error-messages="firstNameErrors"
              hide-details="auto"
            />
          </v-col>
          <v-col class="step-form-container">
            <label for="lastName" class="required">Last Name</label>
            <v-text-field
              outlined
              type="text"
              maxlength="256"
              name="lastName"
              aria-required="true"
              v-model.trim="lastName"
              :backgroundColor="inputBackgroundColor"
              @input="$v.lastName.$touch()"
              @blur="$v.lastName.$touch()"
              :error-messages="lastNameErrors"
              hide-details="auto"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <bottom-nav v-on:go-prev="prev()" v-on:go-next="next()"></bottom-nav>
  </div>
</template>

<script>
import BottomNav from '@/components/common/BottomNav';
import AdvisorClientMixin from '@/common/mixins/advisor.client.mixin';
import { MENUS } from '@/common/constants';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'ClientName',

  mixins: [AdvisorClientMixin],
  data: () => ({
    firstName: null,
    lastName: null,
  }),

  components: { BottomNav },

  validations() {
    return {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      validationGroup: ['firstName', 'lastName'],
    };
  },

  computed: {
    // Dynamic First Name errors */
    firstNameErrors() {
      const errors = [];
      if (!this.$v.firstName.$dirty) return errors;
      !this.$v.firstName.required && errors.push('First Name is required');
      return errors;
    },

    // Dynamic Last Name errors */
    lastNameErrors() {
      const errors = [];
      if (!this.$v.lastName.$dirty) return errors;
      !this.$v.lastName.required && errors.push('Last Name is required');
      return errors;
    },

    clientLabel() {
      return this.second ? "2nd client's" : "client's";
    },
  },

  methods: {
    /* Map form data to working client */
    mapData() {
      this.clientObj.firstName = this.firstName;
      this.clientObj.lastName = this.lastName;
    },

    /* Nav to Next Page in flow */
    next(route = null) {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.mapData();

      // Next route passed in or next in flow for current client
      const to = route
        ? route.name
        : this.second
        ? MENUS.ADVISOR.CLIENT.CLIENT_TWO_EMAIL.id
        : MENUS.ADVISOR.CLIENT.CLIENT_ONE_EMAIL.id;
      this.goNext(to);
    },

    /* Nav to Previous Page in flow */
    prev() {
      // Previous in flow for current client
      // If second client, previous is second client selection page
      // Otherwise, previous is data selectio page (first page in add client flow)
      this.goPrev(
        this.second
          ? MENUS.ADVISOR.CLIENT.CLIENT_ONE_PHONE.id
          : MENUS.ADVISOR.CLIENT.DATA_SELECTION.id,
      );
    },
  },

  created() {
    // Initialize form data for current client
    this.firstName = this.clientObj.firstName;
    this.lastName = this.clientObj.lastName;
  },
};
</script>
<style lang="scss" scoped>
.client-name-container {
  justify-content: center;
  align-content: align-center;
  flex-direction: column;
}
</style>
