/*
  MIXIN to use with Vuelidate enabled pages.

  Shows Dialog if form is Dirty when leaving.
 */
import { mapGetters } from 'vuex';
import { EventService } from '@/services/event.service';

const LeaveGuard = {
  data: () => ({
    exitModal: false,
  }),
  computed: {
    ...mapGetters(['isAuthenticated', 'isClientAuthenticated']),

    /* Can leave if no validation errors */
    canLeave() {
      if (!this.formErrors) return true;
      return !this.formErrors || !this.formErrors.$anyDirty;
    },
    formErrors() {
      return this.viewValidations || this.$v;
    },
  },
  methods: {},

  /*  Hook before leaving route */
  beforeRouteLeave(to, from, next) {
    const additionalErrors = this.getAdditionalDirtyIndicator
      ? this.getAdditionalDirtyIndicator(to)
      : false;

    if (
      to.path.includes('transitions') ||
      (from.path.includes('transitions') && !to.path.includes('transitions'))
    ) {
      next();
      return;
    }

    if (
      (additionalErrors || !this.canLeave) &&
      (this.isAuthenticated || this.isClientAuthenticated)
    ) {
      // disable leave until notification is confirmed
      next(false);
      const showSave = this.showSaveFunction
        ? this.showSaveFunction(to)
        : false;
      const exitFn = () => {
        if (this.formErrors) {
          this.formErrors.$reset();
        }
        if (this.beforeExit) this.beforeExit(to);
        next();
      };
      const saveFn = () => {
        this.next(to);
      };
      EventService.emit('display-leave-guard', showSave, exitFn, saveFn);
    } else {
      // Can leave, so do so.
      if (this.beforeExit) this.beforeExit(to);
      next();
    }
  },
};

export default LeaveGuard;
