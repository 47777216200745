<template>
  <div>
    <v-toolbar flat color="white">
      <v-text-field
        outlined
        type="text"
        maxlength="256"
        v-model="search"
        placeholder="Search Templates..."
        single-line
        hide-details
        class="search-input mb-2"
        v-model.trim="search"
      >
        <template v-slot:append>
          <span class="search" v-if="!search || search.length === 0">
            <v-icon small>fas fa-search</v-icon>
          </span>
        </template></v-text-field
      >
      <v-spacer></v-spacer>
      <a class="btn" color="primary" dark @click="openNewTemplatePage">
        Add Template
        <span class="fa-icon"><i class="fas fa-plus-circle"></i></span>
      </a>
    </v-toolbar>
    <v-data-table
      class="responsive-table question-table"
      :headers="templateHeaders"
      :items="templatesSearch"
      :items-per-page="limit"
      :page.sync="page"
      :server-items-length="totalItems"
      @pagination="handlePagination"
    >
      <template v-slot:item.name="{ item }">
        {{ item.templateName }}
      </template>

      <!-- Slot for actions like edit and delete -->
      <template v-slot:item.actions="{ item }">
        <a class="p-0" @click="editTemplate(item)">
          <v-icon small class="mr-8"> fas fa-pencil </v-icon>
        </a>
        <a class="p-0" @click="confirmDeleteTemplate(item)">
          <v-icon small> fas fa-trash </v-icon>
        </a>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {
  NOTIFICATION_TYPES,
  NotificationService,
} from '@/services/notification.service';
import EventListener from '@/common/mixins/eventlistener.mixin';
import {
  GET_CUSTOM_QUESTIONS_TEMPLATES,
  ADD_CUSTOM_QUESTION_TEMPLATE,
  UPDATE_CUSTOM_QUESTION_TEMPLATE,
  DELETE_CUSTOM_QUESTION_TEMPLATE,
} from '@/stores/actions.type';

export default {
  name: 'CustomQuestionsTemplates',
  mixins: [EventListener],
  data() {
    return {
      newTemplateDialog: false,
      editingTemplate: null,
      templates: [],
      templatesSearch: [],
      templateHeaders: [
        { text: 'Template Name', value: 'name', width: '75%' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      page: 1,
      limit: 5,
      totalItems: 0,
      search: '',
    };
  },
  watch: {
    search() {
      return this.fetchTemplates();
    },
  },
  methods: {
    handlePagination({ page, itemsPerPage }) {
      this.page = page;
      this.limit = itemsPerPage;
      this.fetchTemplates();
    },
    openNewTemplatePage() {
      this.$router.push('/dashboard/custom-questions/templates/new');
    },
    fetchTemplates() {
      this.$store
        .dispatch(GET_CUSTOM_QUESTIONS_TEMPLATES, {
          page: this.page,
          limit: this.limit,
          search: this.search,
        })
        .then((response) => {
          this.templates = response.result;
          this.templatesSearch = response.result;
          this.totalItems = response.totalResults;
        })
        .catch(() => {
          NotificationService.alert({
            type: NOTIFICATION_TYPES.ERROR,
            title: 'Error!',
            message: `Error fetching templates`,
          });
        });
    },
    saveTemplate(templateData) {
      if (!this.isTemplateValid) {
        NotificationService.alert({
          type: NOTIFICATION_TYPES.WARNING,
          title: 'Validation Error',
          message: 'Please correct the errors before saving.',
        });
        return;
      }
      const action = templateData._id
        ? UPDATE_CUSTOM_QUESTION_TEMPLATE
        : ADD_CUSTOM_QUESTION_TEMPLATE;
      this.$store
        .dispatch(action, templateData)
        .then(() => {
          this.fetchTemplates();
          this.newTemplateDialog = false;
        })
        .catch(() => {
          NotificationService.alert({
            type: NOTIFICATION_TYPES.ERROR,
            title: 'Error!',
            message: `Error saving template`,
          });
        });
    },
    editTemplate(template) {
      if (template && template._id) {
        // Navigate to edit template route with ID
        this.$router.push(
          `/dashboard/custom-questions/templates/${template._id}`,
        );
      }
    },
    confirmDeleteTemplate(template) {
      NotificationService.confirm({
        type: NOTIFICATION_TYPES.WARNING,
        title: 'Delete Template',
        message:
          'Are you sure you want to remove this template? Existing customers will remain unaffected.',
        okMethod: () => {
          this.deleteTemplate(template);
        },
        cancelMethod: () => {},
      });
    },
    deleteTemplate(template) {
      this.$store
        .dispatch(DELETE_CUSTOM_QUESTION_TEMPLATE, template._id)
        .then(() => {
          this.fetchTemplates();
        })
        .catch(() => {
          NotificationService.error({
            title: 'Error!',
            message: `Error deleting template`,
          });
        });
    },
  },
  mounted() {
    this.fetchTemplates();
  },
};
</script>

<!-- Styles here if needed -->

<style lang="scss" scoped>
.mobile {
  color: var(--color-text-dark);
}

.search-input {
  ::v-deep {
    .v-input__icon--clear {
      height: 16px;
    }

    .fa-times-circle {
      font-size: 16px;
      margin-right: 8px;
    }
  }
}

.filter-col {
  max-width: 200px;

  &.role {
    max-width: 300px;
  }
}

.edit-profile-link {
  color: var(--color-text);
  font-weight: 700;

  &:hover {
    color: var(--primary-color);
  }
}

.flex-content {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;

  .actions-container {
    position: absolute;
    top: 0;
    right: 0;
    line-height: normal;
  }

  .flex-item {
    padding: 5px;
    line-height: 24px;
    font-size: 16px;

    .edit-profile-link {
      padding-right: 30px;
      display: inline-block;
      line-height: normal;
    }

    .mobile-label {
      font-weight: 400;
      margin-right: 10px;
    }
  }
}

.search {
  margin-right: 12px;
}

.admin-role {
  ::v-deep .v-icon {
    font-size: 12px;
    color: var(--color-warning);
    vertical-align: baseline;
  }
}

.name-item {
  height: 60px;
}

.user-email {
  color: var(--label-text);
  font-size: 11px;
  font-weight: 400;
  line-height: normal;
}

.actions {
  padding: 2px 4px;
  line-height: 16px;

  &[aria-expanded='true'] {
    background-color: var(--secondary-color);
    border-radius: 4px;

    ::v-deep .v-icon {
      color: var(--primary-color);
    }
  }
}

.menu {
  background-color: var(--color-white);

  ::v-deep {
    .v-list-item {
      min-height: 24px !important;
      padding: 0;
    }
  }
}

.menu-item-wrapper {
  padding-left: 10px;
  color: var(--color-text) !important;
  font-size: 14px !important;
  font-weight: 700;
  margin-bottom: 0;
  padding: 8px 12px;
  margin: 0 4px;
  cursor: pointer;

  ::v-deep {
    .v-icon {
      font-weight: 700;
      color: var(--color-text) !important;
      margin-top: -2px;
    }
  }

  &:hover {
    color: var(--primary-color) !important;
    background-color: var(--input-bg-color);

    text-decoration: underline;

    ::v-deep .v-icon {
      color: var(--primary-color) !important;
    }
  }

  .label {
    padding-left: 8px;
    cursor: pointer;
  }
}

.sheet {
  background-color: var(--color-white);

  ::v-deep {
    .v-list-item {
      min-height: 24px !important;
      padding: 0;
    }
  }

  .menu-item-wrapper {
    font-size: 16px !important;
    padding: 12px;
  }

  .bottom-spacer {
    height: 25px;
  }
}

.divider {
  height: 1px;
  margin: 8px;
  background-color: var(--secondary-color);
}

.user-table {
  font-weight: 500;
  color: var(--color-text);

  .Pending,
  .Archived {
    padding: 2px 15px;
    border-radius: 30px;
    line-height: 24px;
    background-color: hsla(0, 0%, 72.7%, 0.16);
    color: var(--label-text);
  }

  .Active {
    padding: 2px 15px;
    border-radius: 30px;
    line-height: 24px;
    background-color: rgba(30, 59, 112, 0.15);
    color: var(--primary-color);
  }

  .filter-action-row {
    padding-bottom: 12px;
  }

  .edit-profile-link.disabled {
    text-decoration: none;
    color: var(--color-text);

    &:hover {
      color: var(--color-text);
      cursor: text;
    }
  }
}

@media screen and (max-width: 769px) {
  .filter-action-row {
    flex-direction: column;

    .filter-col {
      max-width: 100%;
      padding: 4px 12px;
    }

    .button-area {
      justify-content: flex-start;
    }
  }
}

.v-toolbar {
  margin: 10px 0 5px 0;
}
</style>
