export function redtailClientsMapper(response) {
  return response
    .map((item) => {
      let emails = [];
      let phones = [];
      if (item.additionalInfo) {
        emails = item.additionalInfo.emails || [];
        phones = item.additionalInfo.phones || [];
      } else {
        emails = item.emails || [];
        phones = item.phones || [];
      }
      const email = emails.find((e) => e.isPrimary || e.is_primary) || {};
      const phone = phones.find((p) => p.isPrimary || p.is_primary) || {};
      return {
        firstName: item.firstName || item.first_name,
        lastName: item.lastName || item.last_name,
        email: email.address,
        crmId: item.id,
        phone: phone.number,
        crmProfileUrl: item.profileUrl,
        canSkipInterview: item.isAlreadyOnbord,
      };
    })
    .filter((item) => !!item.firstName);
}
