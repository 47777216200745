<!-- eslint-disable prettier/prettier -->
<template>
  <div class="button-footer d-flex justify-center">
    <div class="d-flex action-container align-center" :class="containerClass">
      <a
        id="previous"
        v-if="hasPrevious"
        @click="$emit('go-prev')"
        class="btn secondary"
      >
        <span class="fa-icon flip">
          <i class="fas fa-angle-left"></i>
        </span>
        <span>{{ previousText }}</span>
      </a>
      
      <v-btn
        id="continue"
        v-if="hasNext"
        @click="$emit('go-next')"
        class="btn custom-color-button"
        :disabled="nextDisabled"
      >
        <span>{{ nextText }}</span>
        <span class="fa-icon">
          <i class="fas fa-angle-right"></i>
        </span>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    nextDisabled: {
      type: Boolean,
      default: false,
    },
    hasPrevious: {
      type: Boolean,
      default: true,
    },
    previousText: {
      type: String,
      default: 'Previous',
    },
    hasNext: {
      type: Boolean,
      default: true,
    },
    nextText: {
      type: String,
      default: 'Continue',
    },
  },
  computed: {
    containerClass() {
      return this.hasPrevious ? 'justify-space-between' : 'justify-end';
    },
  },
};
</script>

<style lang="scss" scoped>
.button-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 100px;
  /* IF NEEDED FOR IOS 
  height: 144px;
  padding-bottom: 44px;
  */
  width: 100%;
  border-top: 2px solid var(--secondary-color);
  background-color: var(--color-white);

  /* Un-fix footer on mobile */
  /*
  @media screen and (max-width: 960px) {
    position: absolute;
    border-top: none;
  }
  */

  .action-container {
    max-width: 1600px;
    width: 90vw;
  }
}

.custom-color-button {
  width: 180px;
  height: 46px;
  border-radius: 90px;
  padding: 0 !important;
  background-color: #253a6c !important;
  color: white;
  @media (max-width: 768px) {
    margin: 10px 0;
  }
  &:hover {
    background-color: #253a6c !important;
    color: #fff;
  }
  &:disabled {
    background-color: #5c5c5c !important;
    color: #fff !important;
  }
  @media (max-width: 768px) {
    margin-top: 20%;
    width: 200px;
    margin-right: 5px;
  }
}
</style>
