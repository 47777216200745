<template>
  <div class="auth-container">
    <div class="inner-container">
      <div class="logo-container">
        <img
          :src="`${publicPath}images/defaultLogo.png`"
          width="140"
          loading="lazy"
          alt="OnBord"
          class="logo"
        />
      </div>
      <div class="main-title">
        Enter the code that we sent to<br />
        <strong>{{ hint }}</strong>
      </div>
      <div class="form-block w-form">
        <v-form
          name="changePasswordForm"
          class="form"
          v-on:submit.prevent="doVerifyCode(accessCode)"
        >
          <v-row class="mb-5">
            <v-col>
              <label for="accessCode">6-Digit Code</label>
              <v-text-field
                outlined
                type="text"
                maxlength="256"
                name="accessCode"
                autocomplete="off"
                autofill="off"
                aria-required="true"
                v-model.trim="accessCode"
                :backgroundColor="inputBackgroundColor"
                @blur="$v.accessCode.$touch()"
                :error-messages="accessCodeErrors"
                hide-details="auto"
                autofocus
                inputmode="numeric"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <input
                type="submit"
                id="verifyCode"
                class="btn"
                value="Verify Code"
              />
            </v-col>
          </v-row>
        </v-form>
        <v-row class="or-container">
          <v-col>
            <div class="or">or</div>
          </v-col>
        </v-row>
        <v-row class="mt-3">
          <v-col>
            <router-link :to="{ name: 'login' }" class="btn secondary">
              Return to Log In
            </router-link>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>
<script>
//import { mapGetters } from "vuex";
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';
import Themable from '@/common/mixins/themable.mixin';
import LoginMixin from '@/common/mixins/login.mixin';
import PublicAssets from '@/common/mixins/publicasset.mixin';

export default {
  name: 'ForgotPassword',
  props: {
    hint: String,
  },

  mixins: [PublicAssets, validationMixin, LoginMixin, Themable],

  data: () => ({
    accessCode: null,
  }),

  validations() {
    return {
      accessCode: {
        required,
        minLength: minLength(6),
      },
      validationGroup: ['accessCode'],
    };
  },

  computed: {
    /* Dynamic AccessCode errors */
    accessCodeErrors() {
      const errors = [];
      if (!this.$v.accessCode.$dirty) return errors;
      !this.$v.accessCode.required && errors.push('Access Code is required');
      !this.$v.accessCode.minLength && errors.push('Invalid Access Code');
      return errors;
    },
  },

  methods: {},

  created() {},
};
</script>
<style lang="scss" scoped>
#verifyCode {
  width: 100%;
}
</style>
