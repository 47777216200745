<template>
  <div>
    <v-tabs
      v-if="!currentCompany.transitionsDisabled"
      v-model="tab"
      background-color="primary"
    >
      <v-tab> Clients </v-tab>
      <v-tab> Transitions </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <client-table v-if="tab === 0"></client-table>
      </v-tab-item>

      <v-tab-item>
        <households-listing v-if="tab === 1"></households-listing>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import ClientTable from '@/components/advisor/tables/ClientTable';
import HouseholdsListing from '@/components/advisor/tables/HouseholdsListing';
import { mapGetters } from 'vuex';

export default {
  name: 'ClientList',

  components: {
    ClientTable,
    HouseholdsListing,
  },

  data() {
    return {
      tab: 0,
    };
  },
  created() {},

  computed: {
    ...mapGetters(['currentCompany']),
  },
  methods: {
    toggleView() {
      this.isClientsView = !this.isClientsView;
    },
  },
};
</script>
<style lang="scss" scoped>
.v-tabs {
  .v-tab {
    text-transform: uppercase;
    color: var(--color-white);
    background-color: transparent;
  }

  .v-tab--active {
    color: var(--primary-color);
    background-color: var(--input-bg-color);
    border-bottom: 5px solid var(--primary-color);
  }
  .v-tabs-slider {
    display: none;
  }
}
</style>
