import Authenticate from '@/views/client/Authenticate';
import SendEmailLink from '@/views/client/SendEmailLink';
import Unauthenticated from '@/views/client/Unauthenticated';
import ClientHome from '@/views/client/Home';
import Citizenship from '@/views/client/Citizenship';
import LegalName from '@/views/client/LegalName';
import LegalAddress from '@/views/client/LegalAddress';
import SSN from '@/views/client/SSN';
import DateOfBirth from '@/views/client/DateOfBirth';
import AlternatePhone from '@/views/client/AlternatePhone';
import EmploymentStatus from '@/views/client/EmploymentStatus';
import CompanyAddress from '@/views/client/CompanyAddress';
import JobDescription from '@/views/client/JobDescription';
import CompanyOfficial from '@/views/client/CompanyOfficial';
import BeneficialInterest from '@/views/client/BeneficialInterest';
import PrimaryBeneficiaries from '@/views/client/PrimaryBeneficiaries';
import ContingentBeneficiaries from '@/views/client/ContingentBeneficiaries';
import CustomQuestions from '@/views/client/CustomQuestions';
import StatementSubmitted from '@/views/client/transitions/StatementSubmited';
import TransitionsEmailRegister from '@/views/client/transitions/EmailRegister';
import Finish from '@/views/client/Finish';
import StatementUpload from '@/views/client/transitions/StatementUpload';
import { MENUS } from '@/common/constants';

const routes = [
  {
    path: '/statement-upload/:transitionId',
    name: MENUS.TRANSITIONS.STATEMENT_UPLOAD.id,
    props: (route) => ({
      transitionId: route.params.transitionId,
      relatesTo: route.query.relatesTo,
    }),
    component: StatementUpload,
    meta: {
      breadcrumb: '',
    },
  },
  {
    path: '/email-register/:transitionId',
    name: MENUS.TRANSITIONS.EMAIL_REGISTER.id,
    component: TransitionsEmailRegister,
    props: (route) => ({
      transitionId: route.params.transitionId,
      relatesTo: route.query.relatesTo,
    }),
    meta: {
      breadcrumb: '',
    },
  },
  {
    path: '/statement-submitted',
    name: MENUS.TRANSITIONS.STATEMENT_SUBMITTED.id,
    component: StatementSubmitted,
    meta: {
      breadcrumb: '',
    },
  },
  // Main
  {
    path: '/interview/:token?',
    name: MENUS.INTERVIEW.AUTHENTICATE.id,
    component: Authenticate,
    props: true,
  },
  {
    path: '/interview/:token/send-email',
    name: MENUS.INTERVIEW.SEND_EMAIL_LINK.id,
    component: SendEmailLink,
    props: true,
  },
  {
    path: '/interview-exited',
    name: MENUS.INTERVIEW.UNAUTHENTICATED.id,
    component: Unauthenticated,
    props: true,
  },
  {
    path: '/client-interview/',
    name: MENUS.CLIENT.HOME.id,
    component: ClientHome,
    meta: { breadcrumb: '' },
    children: [
      {
        path: '/client-interview/citizenship',
        name: MENUS.CLIENT.CITIZENSHIP.id,
        component: Citizenship,
      },
      {
        path: '/client-interview/legal-name',
        name: MENUS.CLIENT.LEGAL_NAME.id,
        component: LegalName,
      },
      {
        path: '/client-interview/legal-address',
        name: MENUS.CLIENT.LEGAL_ADDRESS.id,
        component: LegalAddress,
      },
      {
        path: '/client-interview/ssn',
        name: MENUS.CLIENT.SSN.id,
        component: SSN,
      },
      {
        path: '/client-interview/date-of-birth',
        name: MENUS.CLIENT.DOB.id,
        component: DateOfBirth,
      },
      {
        path: '/client-interview/alternate-phone',
        name: MENUS.CLIENT.ALTERNATE_PHONE.id,
        component: AlternatePhone,
      },
      {
        path: '/client-interview/employment-status',
        name: MENUS.CLIENT.EMPLOYMENT_STATUS.id,
        component: EmploymentStatus,
      },
      {
        path: '/client-interview/company-address',
        name: MENUS.CLIENT.COMPANY_ADDRESS.id,
        component: CompanyAddress,
      },
      {
        path: '/client-interview/job-description',
        name: MENUS.CLIENT.JOB_DESCRIPTION.id,
        component: JobDescription,
      },
      {
        path: '/client-interview/company-official',
        name: MENUS.CLIENT.COMPANY_OFFICIAL.id,
        component: CompanyOfficial,
      },
      {
        path: '/client-interview/beneficial-interest',
        name: MENUS.CLIENT.BENEFICIAL_INTEREST.id,
        component: BeneficialInterest,
      },
      {
        path: '/client-interview/account-primary-beneficiaries/:name/:label',
        name: MENUS.CLIENT.ACCOUNT_PRIMARY_BENEFICIARIES.id,
        props: true,
        component: PrimaryBeneficiaries,
      },
      {
        path: '/client-interview/account-contingent-beneficiaries/:name/:label',
        name: MENUS.CLIENT.ACCOUNT_CONTINGENT_BENEFICIARIES.id,
        props: true,
        component: ContingentBeneficiaries,
      },
      {
        path: '/client-interview/custom-question/:questionId',
        name: MENUS.CLIENT.CUSTOM_QUESTIONS.id,
        props: true,
        component: CustomQuestions,
      },
      {
        path: '/client-interview/finish',
        name: MENUS.CLIENT.FINISH.id,
        props: true,
        component: Finish,
      },
    ],
  },
];

export default routes;
